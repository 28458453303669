import React, {useEffect, useState} from "react";
import {latLng, LatLngBounds, LayerGroup as LeafletLayerGroup} from "leaflet";
import {MapContainer, TileLayer, LayerGroupProps} from "react-leaflet";
import {Spinner} from "../../../common/spinner/base-spinner";
import {LegendOverlayLayer} from "./map-layers/legend-overlay-layer";
import {MapLoader} from "./map-layers/map-loader";
import {ProjectMap} from "../../../model/project-map/project-map-model";
import {MapConfig} from "../../../model/map-config";
import {MapConfigOverlayLayer} from "../../../components/map/map-config-overlay-layer";
import {AddressMapLayer} from "./map-layers/building-map-layer";
import { useFrontendConfigurationFetchQuery } from "../../../model/frontend-configuration/frontend-configuration-api";

export const ProjectsMap = ({
  projectMapItems,
  reloading,
  maxBounds,
  additionalLayers
}: {
  projectMapItems: ProjectMap[] | undefined;
  additionalLayers?: any;
  reloading: boolean;
  maxBounds?: LatLngBounds;
}) => {
  const { data: frontendConfiguration, isLoading: configurationIsLoading } =
  useFrontendConfigurationFetchQuery();

  const [ mapConfig, setMapConfig ] = useState<MapConfig>({ clusterIcons: true });
  const [map, setMap] = useState<any>(null);
  useEffect(() => {
    map?.setMaxBounds(maxBounds);
  }, [map, maxBounds]);


  return (
    <div className="w-full rounded-xl pb-8">
      {projectMapItems && projectMapItems.length > 0 ? (
        <MapContainer
          center={latLng(50, 10)}
          zoom={12}
          className="rounded-2xl"
          style={{ width: "100%", height: "800px" }}
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        <MapLoader loading={configurationIsLoading} />
          {frontendConfiguration?.data ? (
            <AddressMapLayer
              projectMapItems={projectMapItems}
              clusterIcons={mapConfig.clusterIcons}
              clusterRadius={
                frontendConfiguration?.data?.attributes
                  ?.projectMapMaxClusterRadius || 80
              }
            />
          ) : null}
          <LegendOverlayLayer />
          <MapConfigOverlayLayer mapConfig={mapConfig} setMapConfig={setMapConfig} />
          <MapLoader loading={reloading} />
        </MapContainer>
      ) : (
        <div className="w-full bg-white h-[800px] border rounded-xl flex items-center justify-center">
          {reloading ? (
            <Spinner className="h-10 w-10 stroke-gray-500" />
          ) : (
            "Keine Projekte gefunden"
          )}
        </div>
      )}
    </div>
  );
};
