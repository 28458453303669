import React, {useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {ProjectListItem} from "./project-list-item";
import Alert from "../layout/alert";
import {Spinner} from "../../common/spinner/base-spinner";
import {buildFilter, PROJECTS_QUERY} from "./project-utils";
import InfiniteScroll from "react-infinite-scroll-component";
import {IProjectFilter} from "../filters/project-filter";

export interface IProjectList {
  filter: IProjectFilter,
  startCount?: number;
  showLoadMore?: boolean;
  sortBy?: { sortBy: string | null | undefined; direction: string | null | undefined } | null | undefined;
  jsFilter?: (project: any) => boolean,
}

const formatSort = (
  sortBy: { sortBy: string | null | undefined; direction: string | null | undefined } | null | undefined
) => {
  return sortBy && sortBy.direction && sortBy.sortBy ? `${sortBy?.sortBy}:${sortBy?.direction}` : "displayId:desc";
};

export const ProjectListWrapper = ({
  filter,
  sortBy,
  startCount = 20,
  showLoadMore = false,
  jsFilter,
}: IProjectList) => {
  const [pagesLoaded, setPagesLoaded] = useState(1);
  const [maxPages, setMaxPages] = useState(2000);

  const [fetchProjects, { data: projects, loading, error, fetchMore, refetch }] = useLazyQuery(PROJECTS_QUERY);

  useEffect(() => {
    fetchProjects({
      notifyOnNetworkStatusChange: true,
      variables: {
        sort: formatSort(sortBy),
        page: 1,
        pageSize: startCount,
        filters: buildFilter(
          filter
        ),
      },
    });
  }, [filter, sortBy, startCount]);

  useEffect(() => {
    if(projects?.projects?.meta?.pagination?.pageCount) {
      setMaxPages(projects?.projects?.meta?.pagination?.pageCount);
    }
  }, [projects?.projects?.meta?.pagination?.pageCount])

  useEffect(() => {
    async function resetPagination() {
      await refetch({
        sort: formatSort(sortBy),
        page: 1,
        pageSize: startCount,
        filters: buildFilter(
          filter
        ),
      });
      setPagesLoaded(2);
    }
    resetPagination();
  }, [
    filter,
    startCount,
    sortBy
  ]);

  if (error) {
    return (
      <Alert
        title="Es ist ein Fehler aufgetreten"
        message="Die Auftragsliste kann nicht angezeigt werden."
      />
    );
  }

  let safeJsFilter = jsFilter
  if (!safeJsFilter) {
    safeJsFilter = () => true;
  }

  return (
    <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-1 pb-4">
      {loading && pagesLoaded === 1 ? (
        <div className="flex w-full justify-center items-center">
          <Spinner className="stroke-gray-500 w-8 h-8" />
        </div>
      ) :
      projects && projects?.projects?.data?.length > 0 ? (
        <><InfiniteScroll
          dataLength={showLoadMore ? projects?.projects?.data?.length || 0 : startCount}
          hasMore={pagesLoaded <= maxPages && showLoadMore}
          next={async () => {
            await fetchMore({ variables: {
              page: pagesLoaded,
             }});
            setPagesLoaded(pagesLoaded + 1);
          }}
          loader={
            <div className="flex w-full justify-center items-center">
              <Spinner className="stroke-gray-500 w-8 h-8" />
            </div>
          }
          endMessage={
            showLoadMore ? (
              <p className="text-center mt-4 text-gray-500">
                Alle Aufträge geladen
              </p>
            ) : null
          }
        >
          {" "}
          {projects?.projects?.data?.filter(safeJsFilter).map((project: any) => (
            <ProjectListItem key={project.id} project={project} />
          ))}
        </InfiniteScroll>
        </>
      ) : <div className="flex w-full justify-center items-center" >Keine Aufträge gefunden</div> }
    </div>
  );
};
