import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { CLIENT_TYPE_QUERY, IClientType } from '../model/client-type';

interface IClientTypeFormatterProps {
  id: string
}

// TODO: This is super ugly: Add error handling, loading indication and refactor to Redux
export const ClientTypeFormatter = ({ id }: IClientTypeFormatterProps) => {
  const [ label, setLabel ] = useState('-');
  const { data: clientTypes } = useQuery(CLIENT_TYPE_QUERY);

  useEffect(() => {
    setLabel(clientTypes?.clientTypes?.data?.filter((ct: IClientType) => (ct.id === id))?.[0]?.attributes?.label);
  }, [id, clientTypes])

  return <span>{label}</span>
}