import { gql } from "@apollo/client"

export interface Employee {
  id: string,
  attributes: {
    username: string,
    abbreviation: string,
    image: {
      data: {
        id: number,
        attributes: {
          url: string
        }
      }
    }
  }
}

export interface AnalyticsEmployeeResult {
  employees: {
    "year": number,
    "user_id": number,
    "username": string,
    "abbreviation": string,
    "thumbnailUrl": string,
    "roleName": string,
    "projectType": string,
    "sum": number
  }[],
  netIncome:
    {
      year: number,
      sum: number
    }[]
}

export interface AnalyticsEmployeeMonthResult {
  employeesPerMonth: {
    "year": number,
    "month": number,
    "user_id": number,
    "username": string,
    "abbreviation": string,
    "thumbnailUrl": string,
    "roleName": string,
    "projectType": string,
    "sum": number
  }[]
}


export interface AnalyticsEmployeeTableResult {
  meta: {
    year1: number | null,
    year1trend: { text: string, up: boolean} | null,
    year2: number | null,
    year2trend: { text: string, up: boolean} | null,
    year3: number | null
    year3trend: { text: string, up: boolean} | null,
  },
  employees: [{
    userId: number,
    username: string,
    imageUrl: string,
    roleName: string,
    year1: number | null,
    year2: number | null,
    year3: number | null
  }]
}

export interface AnalyticsEmployeeChartResult {
  datasetLabel: string,
  sums: number[]
}

export const EMPLOYEE_QUERY = gql`
  query ($needle: String) {
    usersPermissionsUsers(
      filters: { username: { contains: $needle } }
      pagination: { limit: 200 }
    ) {
      data {
        id
        attributes {
          username
          abbreviation
          isAppraiser
          isEditor
          email
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;