import React, {EventHandler, ReactNode, SyntheticEvent, useEffect, useState,} from "react";
import {classNames} from "../../../shared/utils/class-names";
import {getItemTransparent} from "../../../shared/utils/storage-utils";
import {EmployeeDropDown} from "./employee-dropdown";

export interface IEmployeesSelect {
  selected: string[] | undefined | null;
  onChange: (selected: string[]) => void;
}

export const EmployeesSelect = ({ selected, onChange }: IEmployeesSelect) => {
  const [selectedTab, setSelectedTab] = useState("Alle");
  const [isOpen, setIsOpen] = useState(false);
  const user = getItemTransparent("user");

  useEffect(() => {
    if(selected && selected?.length > 0) {
      // Meine
      if(selected?.length === 1 && parseInt(selected?.[0]) === getItemTransparent('user')?.id) {
        setSelectedTab("Meine")
      } else {
        setSelectedTab("Mitarbeiter")
      }
    } else {
      setSelectedTab("Alle");
    }
  }, [selected])

  const items: {
    [key: string]: { onClick: EventHandler<SyntheticEvent>; label: ReactNode };
  } = {
    Alle: {
      label: "Alle",
      onClick: () => {
        onChange([]);
        setIsOpen(false);
      },
    },
    Meine: {
      label: "Meine",
      onClick: () => {
        onChange([getItemTransparent('user')?.id]);
        setIsOpen(false);
      },
    },
  };

  if (["superadmin", "geschaftsfuhrer", "admin", "teamleiter"].includes(user.role?.type)) {
    items['Mitarbeiter'] = {
      label: (
        <EmployeeDropDown
          isOpen={isOpen}
          close={() => {
            setIsOpen(false);
          }}
          selected={selected}
          onChange={onChange}
        >
          Mitarbeiter
        </EmployeeDropDown>
      ),
      onClick: (e) => {
        const target = e.target as HTMLElement
        if (target.id === 'search' || target.classList.contains('searchcancel')) {
          return false;
        }
        setIsOpen(!isOpen);
      },
    };
  }

  return (
    <div className="self-end my-2">
      <div className="hidden">
        <label htmlFor="tabs" className="sr-only">
          Einen Mitarbeiter auswählen
        </label>
        <select
          id="tabs"
          name="tabs"
          multiple
          className="block w-full rounded-md"
          defaultValue={selected || ""}
        >
          {Object.keys(items)?.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="bg-green-100 p-0.5 px-2 rounded-xl">
        <nav className="flex space-x-4" aria-label="Tabs">
          {Object.keys(items)?.map((tab) => (
            <div
              key={tab}
              onClickCapture={(e) => {
                items[tab]?.onClick(e);
                setSelectedTab(tab);
              }}
              className={classNames(
                selectedTab === tab
                  ? "bg-green-700 text-white"
                  : "text-green-700",
                "px-3 py-1.5 text-sm rounded-xl w-min-12 cursor-pointer flex flex-row items-center"
              )}
              aria-current={selectedTab === tab ? "page" : undefined}
            >
              {items[tab].label}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};
