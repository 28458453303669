import React, {useEffect, useState} from "react";
import axios from "axios";
import {getItemTransparent} from "../../shared/utils/storage-utils";
import {Spinner} from "../../common/spinner/base-spinner";

export interface IAuthImg {
    src: string,
    className: string,
    size?: "default" | "large" | "medium" | "small" | "thumbnail" | "xlarge" | "xsmall",
    placeholder?: string,
}

function modifySize(src: string , size: string) {
    if(size === "default" || src.endsWith(".svg")) {
        return src;
    }
    return src.replace("/uploads/", `/uploads/${size}_`)
}

export const AuthImg = ({ src, className, size = "default", placeholder = "/placeholder.png" }: IAuthImg) => {
    const [ loading, setLoading ] = useState(false);
    const [ url, setUrl ] = useState<string | null>(null);

    useEffect(() => {
        async function getImage() {
            setLoading(true);
            const srcWithSize = modifySize(src, size);
            try {
                const response = await axios.get(srcWithSize, {
                    responseType: 'blob',
                    timeout: 10000,
                    headers: {
                        'Authorization': 'Bearer ' + getItemTransparent('JWT'),
                        'Accept': 'image/avif,image/webp,*/*',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                const imgBlob = await response.data;
                const objectUrl = await window.URL.createObjectURL(imgBlob);
                setUrl(objectUrl);
            } catch(e) {
                setUrl(null);
            }
            setLoading(false);
        }
        getImage();
    }, [src])

    if(loading) {
        return <div className={className}><Spinner className='text-gray-200' /></div>
    }
    if(url) {
        return <img src={url} className={className} alt={""} />
    } else {
        return <img src={placeholder} className={className} alt={""} />
    }
}