import React, { useEffect } from "react";
import { Card } from "../../components/basics/card";
import { useLazyFetchHistoryQuery } from "../../model/project/project-api";
import Alert from "../../components/layout/alert";
import { Spinner } from "../../common/spinner/base-spinner";
import { ProjectListItem } from "../../components/project-list/project-list-item";
import dayjs from "dayjs";

export const ProjectHistory = ({ project, reload }: any) => {

  const [fetchData, { data: fetchedData, error, isFetching }] =
    useLazyFetchHistoryQuery();

  useEffect(() => {
    fetchData({ id: project?.project?.data?.id, queryParams: { } });
  }, [project])

  if (error) {
    return (
      <Alert title="Projekthistorie konnte nicht abgerufen werden" message="Bitte versuchen Sie es erneut. Falls das Problem bestehen bleibt, kontaktieren Sie einen Administrator." />
    )
  }

  const sortedProjects = fetchedData 
  ? [...fetchedData.data]?.sort((b, a) => {
      const aDate = a?.attributes?.deadlineDate ? dayjs(a?.attributes?.deadlineDate) : dayjs("1970-01-01");
      const bDate = b?.attributes?.deadlineDate ? dayjs(b?.attributes?.deadlineDate) : dayjs("1970-01-01");
      const comp = dayjs(aDate).diff(bDate, 'hour');
      console.log(`${a?.attributes?.displayId} - ${b?.attributes?.displayId}: ${comp}`);
      return comp;
    })
    : [];
  const tailProjects = sortedProjects.filter((p, index) => (index !== 0));

  return (
    <div className="rounded-xl p-4 bg-green-100 font-sans">
      <h2 className="tracking-wide text-base mb-2 uppercase font-special font-medium text-gray-200">Letzte Bewertung</h2>
      {
        isFetching ? <Spinner className="w-16 h-16" /> : null
      }
      {
        sortedProjects?.[0]
          ? <ProjectListItem key={sortedProjects?.[0]?.id} project={sortedProjects?.[0]} currentProjectId={project?.project?.data?.id} />
          : null
      }
      {
        tailProjects && tailProjects.length > 0
          ? <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-1 pb-4 mt-10">
            <h2 className="tracking-wide text-base mb-2 uppercase font-special font-medium text-gray-200">Bewertungsverlauf</h2>
            {
              tailProjects?.map((p) => (
                <ProjectListItem key={p.id} project={p} currentProjectId={project?.project?.data?.id} />
              ))
            }
          </div>
          : null
      }

    </div>
  );
};
