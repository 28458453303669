import { classNames } from "../../../shared/utils/class-names";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { h3Classes } from "../../basics/typography-classes";
import { ChangePasswordForm } from "./change-password";
import { UsernameEmailEditForm } from "./username-email-edit-form";

export const ProfileEdit = () => {
    const user = getItemTransparent("user");
    return (
        <div className="max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl font-special">
            <div className="space-y-10 divide-y divide-gray-900/10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-lg font-semibold leading-7 text-gray-900">Hallo, {user?.username}</h2>
                        <p className="mt-1 leading-6 text-gray-600">
                            Hier kannst du deine Profilangaben anpassen.
                        </p>
                    </div>
                    <div className="max-w-2xl gap-x-6 gap-y-8 md:col-span-2 bg-white rounded-2xl">
                        <div className="px-4 py-4 sm:px-8">
                            <h3 className={classNames(...h3Classes)}>Nutzername und E-Mail</h3>
                            <UsernameEmailEditForm />
                        </div>
                        <div className="border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <h3 className={classNames(...h3Classes)}>Passwort</h3>
                            <ChangePasswordForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}