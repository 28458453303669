export interface ChartProps {
  data: any[];
}

export interface PreparedFlatData {
  labels: string[];
  aggregatedData: number[];
}

export enum PreparedDataType {
  COUNT,
  SUM,
}

export function generateNumberArray(lower: number, upper: number): number[] {
  const result: number[] = [];
  for (let i = lower; i <= upper; i++) {
    result.push(i);
  }
  return result;
}


export function aggregateCountFn(data: any, selector: Function) {
  const aggregated = data?.reduce((acc: any, item: any) => {
    const selected = selector(item);
    if(selected) {
      acc[selected] = (acc[selected] ? acc[selected] + 1 : 1) as number;
    }
    return acc;
  }, {}) as { [key: string]: number }
  const sorted = Object.entries(aggregated).sort(([key1, value1], [key2, value2]) => (value2 - value1));
  return {
    labels: sorted.map(([k,v]) => (k)),
    aggregatedData: sorted.map(([k,v]) => (v)),
  };
}

export function aggregateSumFn(data: any, selector: Function, sumSelector: Function) {
  const aggregated = data?.reduce((acc: any, item: any) => {
    const selected = selector(item);
    const value = sumSelector(item);
    if(selected && value) {
      acc[selected] = (acc[selected] ? acc[selected] + value : value) as number;
    }
    return acc;
  }, {}) as { [key: string]: number }
  const sorted = Object.entries(aggregated).sort(([key1, value1], [key2, value2]) => (value2 - value1));
  return {
    labels: sorted.map(([k,v]) => (k)),
    aggregatedData: sorted.map(([k,v]) => (v)),
  };
}

export function prepareFlatData(
  data: any,
  selector: Function,
  aggregationType: PreparedDataType,
  sumSelector?: Function
): PreparedFlatData {
  switch(aggregationType) {
    case PreparedDataType.COUNT:
      return aggregateCountFn(data, selector);
    case PreparedDataType.SUM:
      if(!sumSelector) throw Error("No valid sumSelector provided");
      return aggregateSumFn(data, selector, sumSelector);
  }
}

export function sumReducer(acc: number, item: number) {
  return acc + item;
}