import React, { useEffect } from "react";
import { useQueryParams } from "use-query-params";
import { ResearchLightFilterBar } from "./research-light-filter-bar";
import { mapQueryParamsToFilter, researchQueryParams } from "./research-query-params";
import { AddressSearch } from "./address-search";
import { DIRECTION, LOCATION_LAT, LOCATION_LNG, LOCATION_RANGE, SORT_BY } from "../../components/filters/project-filter";
import { Polygon, latLng, polygon } from "leaflet";
import { ProjectNumberListWrapper } from "../../components/project-number-list/project-number-list-wrapper";
import { ProjectMapPolygonContainer } from "./projects-polygon-picker-map/project-map-wrapper";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectEditing, selectPolygon, toggleEditing } from "./projects-polygon-picker-map/layer/polygon-picker-slice";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { SortWrapper } from "../project-list/sort/sort-wrapper";
import { useLocation } from "react-router-dom";

export const ResearchLight = () => {
  const [filterQueryParams, setFilterQueryParams] = useQueryParams(researchQueryParams);
  let jsListFilter = undefined;
  let jsMapFilter = undefined;
  const filterPolygon = useAppSelector(selectPolygon);
  const polygonEditing = useAppSelector(selectEditing);
  const dispatch = useAppDispatch()
  const location = useLocation();

  useEffect(() => { if (polygonEditing) dispatch(toggleEditing); }, [location.pathname])

  if (filterPolygon.length > 2) {
    jsListFilter = (project: any) => {
      const projectCoords = [
        project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.lng,
        project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.lat
      ];
      const poly: Polygon = polygon(filterPolygon);
      return booleanPointInPolygon(projectCoords, poly.toGeoJSON())
    }
    jsMapFilter = (project: any) => {
      const projectCoords = [
        project?.lng,
        project?.lat
      ];
      const poly: Polygon = polygon(filterPolygon);
      return booleanPointInPolygon(projectCoords, poly.toGeoJSON())
    }
  } else {
    if (filterQueryParams[LOCATION_LAT] && filterQueryParams[LOCATION_LNG] && filterQueryParams[LOCATION_RANGE]) {
      jsListFilter = (project: any) => {
        const projectCoords = latLng(
          project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.lat,
          project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.lng
        );
        const targetCoords = latLng(filterQueryParams[LOCATION_LAT], filterQueryParams[LOCATION_LNG]);
        return projectCoords?.distanceTo(targetCoords) < filterQueryParams[LOCATION_RANGE] * 1000;
      }
    }
  }

  return (
    <>
      <div className="flex flex-row justify-start items-center w-full gap-4 mb-2">
        <h1 className="text-2xl font-special font-semibold">Research - Kartenansicht</h1>
        <AddressSearch />
      </div>
      <ResearchLightFilterBar />

      <div className="border-2 border-gray-300 rounded-xl flex flex-col items-center pt-8 px-4">
        <ProjectMapPolygonContainer
          filter={{ useForResearch: true, ...mapQueryParamsToFilter(filterQueryParams) }}
          jsFilter={jsMapFilter}
          drawing={filterPolygon.length > 0}
        />
        {filterQueryParams[LOCATION_LAT] ? <>
          <div className="w-full flex flex-row justify-end mb-4">
            <SortWrapper />
          </div>
          <ProjectNumberListWrapper
            startCount={10}
            filter={{ useForResearch: true, ...mapQueryParamsToFilter(filterQueryParams) }}
            jsFilter={jsListFilter}
            sortBy={{
              [SORT_BY]: filterQueryParams[SORT_BY],
              [DIRECTION]: filterQueryParams[DIRECTION],
            }}
            showLoadMore={true} />
        </> : null}
      </div>
    </>
  );
};
