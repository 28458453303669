import { useEffect, useState } from "react";
import {
  useLazyFetchAllMonthQuery,
  useLazyFetchAnalyticsAppraiserInvoicesMonthQuery,
} from "../../../model/analytics/employees/analytics-employees-api";
import Alert from "../../../components/layout/alert";
import { Card } from "../../../components/basics/card";
import { H5 } from "../../../components/layout/basics/typography";
import {
  AnalyticsEmployeeMonthResult,
  Employee,
} from "../../../model/analytics/employees/analytics-employees";
import { EmployeeChartLines } from "./employees-chart-lines";
import { DummyLineChart } from "./dummy-line-chart";
import { DummyBarChart } from "./dummy-bar-chart";
import { Spinner } from "../../../common/spinner/base-spinner";

export interface IEmployeeChartLinesProps {
  years: number[];
  isAppraiserChart: boolean;
  selectedEmployees: (Employee | undefined)[];
}

export const LineChartDataWrapper = ({
  isAppraiserChart,
  selectedEmployees,
  years,
}: IEmployeeChartLinesProps) => {
  const [data, setData] = useState<AnalyticsEmployeeMonthResult | null>(null);
  const [
    fetchLineChartEditorData,
    { data: fetchedEditorData, error: editorError },
  ] = useLazyFetchAllMonthQuery();
  const [
    fetchLineChartAppraiserData,
    {
      data: fetchedAppraiserData,
      error: appraiserError,
    },
  ] = useLazyFetchAnalyticsAppraiserInvoicesMonthQuery();

  useEffect(() => {
    if (selectedEmployees && selectedEmployees.filter((se) => se).length > 0) {
      if (isAppraiserChart) {
        fetchLineChartAppraiserData({
          queryParams: {
            filters: {
              years,
              employees: selectedEmployees.map((se) => se?.id),
            },
          },
        });
      } else {
        fetchLineChartEditorData({
          queryParams: {
            filters: {
              years,
              employees: selectedEmployees.map((se) => se?.id),
            },
          },
        });
      }
    } else {
      setData(null)
    }
  }, [selectedEmployees, years]);

  useEffect(() => {
    if (fetchedAppraiserData?.data && isAppraiserChart) {
      setData(fetchedAppraiserData?.data);
    }
  }, [fetchedAppraiserData, isAppraiserChart]);

  useEffect(() => {
    if (fetchedEditorData?.data && !isAppraiserChart) {
      setData(fetchedEditorData?.data);
    }
  }, [fetchedEditorData, isAppraiserChart]);

  if (editorError || appraiserError) {
    const error = editorError || appraiserError;
    const convertedError = error as any;
    return (
      <Alert
        title="Fehler beim Abholen der Daten"
        message={convertedError?.data?.error?.message}
      />
    );
  }

  return (
    <Card className="bg-white mb-4 min-h-[630px]">
      <H5>GESAMT-NETTOUMSATZ</H5>
      <div className="h-4"></div>
      { data ? (
        <EmployeeChartLines
          data={data}
          selectedEmployees={selectedEmployees}
          years={years}
          styles={{ height: "400px" }}
        />
      ) : (
        <DummyLineChart style={{ height: "400px" }} />
      )}
    </Card>
  );
};
