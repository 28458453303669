import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  useQueryParams,
} from "use-query-params";
import { statusMap } from "../../common/status-map";
import { SearchBox } from "../../components/basics/search-box";
import { h1Classes } from "../../components/basics/typography-classes";
import { CsvDownloadButton } from "../../components/layout/invoice-table/csv-download-button";
import {
  InvoiceStatusKey,
  InvoiceTable,
} from "../../components/layout/invoice-table/invoice-table";
import { SumRow } from "../../components/layout/invoice-table/sum-row";
import { MultiFilterSelect } from "../../components/filters/multi-filter-select";
import { classNames } from "../../shared/utils/class-names";
import { IKeyValuePair } from "../../shared/utils/entity-utils";
import { CustomerMultiSelect } from "../../components/filters/customer-select";
import { FilterSelect } from "../../components/filters/filter-select";
import { StatusSelect } from "../../components/filters/status-select";
import {
  INVOICE_QUERY_PARAM_CONFIG,

} from "./query-param-config";


import {
  CUSTOMER_STATUS,
  DATE_FILTER_FROM,
  DATE_FILTER_UNTIL,
  INVOICE_STATUS, PROJECT_STATUS, SEARCH_TERM,
  SORT_BY
} from "../../components/filters/project-filter";

const sortItems = [
  { key: "project.displayId:desc", label: "Auftragsnummer" },
  {
    key: "outgoingInvoiceDate:desc",
    label: "Rechnungsdatum",
  },
  { key: "periodOfPayment:desc", label: "Zahlungsziel" },
];

const invoiceStatusItems = [
  { key: "offen", label: "Keine Rechnung (noch nicht gestellt)" },
  { key: "ausstehend", label: "Ausstehend" },
  { key: "bezahlt", label: "Bezahlt" },
  { key: "zahlungserinnerung", label: "Zahlungserinnerung" },
  { key: "mahnung1", label: "1.Mahnung" },
  { key: "mahnung2", label: "2. Mahnung" },
];

const statusItems = Object.keys(statusMap).map((key: string) => ({
  key,
  label: statusMap[key].displayString,
}));

export const InvoiceOverview = () => {
  const [projectParams, setProjectParams] = useQueryParams(
    INVOICE_QUERY_PARAM_CONFIG
  );
  const [startDate, setStartDate] = useState(dayjs(projectParams[DATE_FILTER_FROM]).toDate());
  const [endDate, setEndDate] = useState(dayjs(projectParams[DATE_FILTER_UNTIL]).toDate());

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setProjectParams({
        ...projectParams,
        [DATE_FILTER_FROM]: dayjs(startDate).format('YYYY-MM-DD'),
        [DATE_FILTER_UNTIL]: dayjs(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [startDate, endDate]);

  return (
    <div className="">
      <div className="flex flex-row items-center mb-4">
        <h2 className={classNames(...h1Classes, "mr-5")}>Rechnungsübersicht</h2>
        <SearchBox
          defaultValue={projectParams[SEARCH_TERM]}
          onChange={(val) => {
            setProjectParams({
              ...projectParams,
              [SEARCH_TERM]: val,
            });
          }}
          className="w-1/2 text-gray-400"
          placeholder="Rgs- und Auftragsnr. durchsuchen"
        />
        <div className="w-full">
          <div className="w-56 float-right text-sm">
            <FilterSelect
              items={sortItems}
              selected={sortItems.find(
                (si: IKeyValuePair) => si.key === projectParams[SORT_BY]
              )}
              onChange={(val) => {
                setProjectParams({
                  ...projectParams,
                  [SORT_BY]: val?.key,
                });
              }}
              className="h-10 rounded-xl py-0.5"
              prefix="Sortieren: "
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center mb-2">
        <div className="w-80 mr-3 text-sm">
          <StatusSelect
            selected={statusItems.filter((si: IKeyValuePair) =>
              (projectParams[PROJECT_STATUS] as Array<string>).includes(si.key)
            )}
            onChange={(selected) => {
              setProjectParams({
                ...projectParams,
                [PROJECT_STATUS]: selected.map((i) => i.key),
              });
            }}
            items={statusItems}
          />
        </div>
        <div className="w-80 text-sm mr-3">
          <MultiFilterSelect
            placeholder={"Rechnungsstatus auswählen"}
            selected={invoiceStatusItems.filter((si: IKeyValuePair) =>
              (projectParams[INVOICE_STATUS] as Array<string>).includes(si.key)
            )}
            onChange={(selected) => {
              const newSelection = selected as {
                key: InvoiceStatusKey;
                label: string;
              }[];
              setProjectParams({
                ...projectParams,
                [INVOICE_STATUS]: newSelection.map((i) => i.key),
              });
            }}
            items={invoiceStatusItems}
          />
        </div>
        <div className="w-80 text-sm mr-3">
          <CustomerMultiSelect
            placeholder={"Stammkunden"}
            selected={projectParams[CUSTOMER_STATUS]}
            onChange={(selected) => {
              setProjectParams({
                ...projectParams,
                [CUSTOMER_STATUS]: selected,
              });
            }}
          />
        </div>
        <ReactDatePicker
          selected={startDate}
          showPopperArrow={false}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd.MM.yyyy"
          selectsRange
          monthsShown={2}
          locale="de"
          className="w-52 ml-3 py-1.5 rounded-xl font-special focus:ring-green-500 focus:border-green-500 sm:text-sm border-gray-300"
          calendarClassName="font-special border-gray-300 shadow"
        />
        <div className='self-end'>
          <CsvDownloadButton />
        </div>
      </div>
      <SumRow />
      <InvoiceTable />
    </div>
  );
};
