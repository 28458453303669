import Alert from "../../../components/layout/alert";
import { MagicUpdate } from "../../../components/magic-update/magic-update";
import { SidebarEmployeeDropdown } from "./sidebar-employee-dropdown";

export const EditorSidebarEdit = ({
  project,
  refetch,
}: {
  project: any;
  refetch: Function;
}) => (
  <div className="grid grid-cols-2 min-w-full">
    <div className="w-1/2">
      <SidebarEmployeeDropdown
        model="project"
        id={project?.project?.data?.id}
        refetch={refetch}
        type="ID"
        field="editor"
        label="Bearbeiter"
        labelGap="mt-1"
        filter={(employee: any) => employee?.attributes?.isEditor}
        value={project?.project?.data?.attributes?.editor}
      />
      <div className='h-2'></div>
      <MagicUpdate
        model="project"
        id={project?.project?.data?.id}
        reload={refetch}
        type="Percentage"
        gqlType="Int"
        field="workShareEditor1"
        labelGap="mt-1"
        value={project?.project?.data?.attributes?.workShareEditor1}
      />
    </div>
    <div className="w-1/2">
      <SidebarEmployeeDropdown
        model="project"
        id={project?.project?.data?.id}
        refetch={refetch}
        type="ID"
        field="editor2"
        label="Bearbeiter 2"
        labelGap="mt-1"
        filter={(employee: any) => employee?.attributes?.isEditor}
        value={project?.project?.data?.attributes?.editor2}
      />
      <div className='h-2'></div>
      <MagicUpdate
        model="project"
        id={project?.project?.data?.id}
        reload={refetch}
        type="Percentage"
        gqlType="Int"
        field="workShareEditor2"
        labelGap="mt-1"
        value={project?.project?.data?.attributes?.workShareEditor2}
      />
    </div>
    { project?.project?.data?.attributes?.workShareEditor1 + project?.project?.data?.attributes?.workShareEditor2 !== 100 ? <div className="col-span-2"> <Alert title="Ungültige Eingabe" message="Anteile müssen zusammen 100 ergeben" /> </div>: null }
  </div>
);
