import { useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";
import { compareArray } from "../../../../common/compare-helpers";
import { Spinner } from "../../../../common/spinner/base-spinner";
import { Card } from "../../../../components/basics/card";
import { h4Classes } from "../../../../components/basics/typography-classes";
import { classNames } from "../../../../shared/utils/class-names";
import {
  ExcludeParam,
  REFERENCE_LIST_EXCLUDE,
} from "../filter/reference-filter-query-params";
import { ChartProps } from "./helpers";
import { ReferencesByTypeVolume } from "./references-by-building-type-volume";
import { ReferencesByClientType } from "./references-by-client-type";
import { ReferencesByCountryBarChart } from "./references-by-country";
import { ReferencesByCountryCard } from "./references-by-country-card";
import { ReferencesByCountryCategory } from "./references-by-country-category";
import { ReferencesByMarketValueCategory } from "./references-by-market-value-category";
import { ReferencesByProjectType } from "./references-by-project-type";
import { TileByCountryAbroad } from "./tile-abroad";
import { TileByCountryInGermany } from "./tile-in-germany";
import { TileOverallCount } from "./tile-overall-count";
import { TileOverallVolume } from "./tile-overall-volume";

interface ChartLayoutProps extends ChartProps {
  isLoading?: boolean;
}

export const ReferencesChartsLayout = ({
  data,
  isLoading,
}: ChartLayoutProps) => {
  const [excludeList, setExcludeList] = useState<string[]>([]);
  const [excludedData, setExcludedData] = useState<any[]>([]);
  const [excludeQueryParam, setExcludeQueryParam] = useQueryParam(
    REFERENCE_LIST_EXCLUDE,
    ExcludeParam
  );

  useEffect(() => {
    if (!compareArray(excludeQueryParam, excludeList)) {
      if (excludeQueryParam) {
        setExcludeList(excludeQueryParam as string[]);
      }
    }
  }, [excludeQueryParam]);

  useEffect(() => {
    if (excludeList) {
      setExcludedData(data.filter((d) => (!excludeList.includes(d.displayId))));
    } else {
      setExcludedData(data);
    }
  }, [excludeList, data]);

  return (
    <div className="grid md:grid-cols-2 print:grid-cols-2 grid-cols-1 gap-4">
      { isLoading ? <div className="z-[20] absolute w-full max-w-screen-xl h-screen bg-gray-100/50 flex flex-col items-center">
        <Spinner className="w-20 h-20 mt-64 -ml-14 stroke-gray-500" />
      </div> : null }
      <div className="w-full mt-4">
        <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>KEY FACTS</h4>
        <div className="grid grid-cols-2 gap-4">
          <TileOverallVolume data={excludedData} />
          <TileOverallCount data={excludedData} />
          <TileByCountryInGermany data={excludedData} />
          <TileByCountryAbroad data={excludedData} />
        </div>
      </div>
      <div className="w-full">
        <Card className="rounded-3xl">
          <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>
            VERTEILUNG EINZELVERKEHRSWERTE
          </h4>
          <div className="h-80 xl:h-96">
            <ReferencesByMarketValueCategory data={excludedData} />
          </div>
        </Card>
      </div>
      <div className="w-full">
        <Card className="rounded-3xl">
          <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>
            AUFTRAGGEBERART
          </h4>
          <div className="h-80 xl:h-96">
            <ReferencesByClientType data={excludedData} options={{}} />
          </div>
        </Card>
      </div>
      <div className="w-full">
        <Card className="rounded-3xl">
          <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>
            VERTEILUNG IN-/AUSLAND
          </h4>
          <div className="h-80 xl:h-96">
            <ReferencesByCountryCategory data={excludedData} options={{}} />
          </div>
        </Card>
      </div>
      <div className="w-full">
        <Card className="rounded-3xl mb-4">
          <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>AUFTRAGSART</h4>
          <div className="xl:h-[450px]">
            <ReferencesByProjectType data={excludedData} options={{}} />
          </div>
        </Card>
        <Card className="rounded-3xl">
          <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>
            OBJEKTARTEN (NACH VOLUMEN)
          </h4>
          <div className="h-80 xl:h-96">
            <ReferencesByTypeVolume data={excludedData} height={160} />
          </div>
        </Card>
      </div>
      <div className="w-full">
        <ReferencesByCountryCard data={excludedData} height={600} />
      </div>
    </div>
  );
};
