import { Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, {  useState } from "react";
import { Legend } from "./legend";

export const LegendOverlayLayer = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        className="absolute z-[2002] -right-2 w-8 h-1/3 top-1/3 rounded-xl shadow-xl bg-gray-50"
      > {
        open ? <ChevronRightIcon className="text-gray-900 p-1 -ml-1 h-8" />
        : <ChevronLeftIcon className="text-gray-900 p-1 -ml-1 h-8" />
      }
      </button>
      <Transition.Root show={open}>
        <div className={`absolute z-[2001] right-0 pointer-events-none h-full flex flex-col justify-center`}>
          <Transition.Child
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className='bg-transparent right-0 h-full'>
              <div className="bg-white rounded-xl shadow-xl">
                <Legend />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </>
  );
};
