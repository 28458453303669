import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { ProjectNumberListItem } from "./project-number-list-item";
import Alert from "../layout/alert";
import { Spinner } from "../../common/spinner/base-spinner";
import { buildFilter } from "../project-list/project-utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { IProjectFilter } from "../filters/project-filter";

export interface IProjectNumberList {
  filter: IProjectFilter, 
  startCount?: number;
  showLoadMore?: boolean;
  sortBy?: { sortBy: string | null | undefined; direction: string | null | undefined } | null | undefined;
  jsFilter?: (project: any) => boolean,
}

const formatSort = (
  sortBy: { sortBy: string | null | undefined; direction: string | null | undefined } | null | undefined
) => {
  return sortBy && sortBy.direction && sortBy.sortBy ? `${sortBy?.sortBy}:${sortBy?.direction}` : "displayId:desc";
};

export const PROJECTS_WITH_RESEARCH_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $filters: ProjectFiltersInput!, $sort: [String]) {
    projects(
      sort: $sort,
      pagination: { page: $page, pageSize: $pageSize },
      filters: $filters,
    ) {
      data {
        id
        attributes {
          name
          description
          type
          status
          contractor
          isCoappraiser
          mainAppraiser
          coAppraiser1
          coAppraiser2
          projectReceived
          deadlineDate
          displayId
          servicelevel
          owner
          rests
          building {
            data {
              id
              attributes {
                name
                type
                constructionMeassures
                dateOfConstruction
                dateOfLastRenovation
                monumentProtection
                address {
                  data {
                    id
                    attributes {
                      street
                      streetNumber
                      city
                      zip
                      country
                      lat
                      lng
                    }
                  }
                }
              }
            }
          }
          project_research {
            data {
              id
              attributes {
                faktorNichtUmlagefaehigeBetriebskosten
  faktorAnschaffungsnebenkosten
  mainValuerName
  mainValuerId
  mainValuerDateOfInspection
  coValuer1Name
  coValuer1ID
  coValuer1DateOfInspection
  coValuer2Name
  coValuer2ID
  coValuer2DateOfInspection
  rebaseType1
  rebaseType2
  additionalClientSpecificInformation
  rebaseObjAdditionalInformation
  dateOfAppraisal
  qualityDateOfAppraisal
  qualityDateOfAppraisalAsDate
  currency
  exchangeRate1EUR
  dateExchangeRate
  mainTypeOfUse
  shareMainTypeOfUse
  ancillaryTypeOfUse
  shareAncillaryTypeOfUse
  typeOfOwnership
  singleTenant
  purchasePrice
  dateOfPurchase
  priceOfSale
  dateOfSale
  locationQuality
  structuralCondition
  fitOutQuality
  stateOfCompletion
  maintenanceBacklog
  floors
  normalTotalEconomicLife
  remainingEconomicLife
  originalYearOfConstruction
  originalYearOfConstructionAsYearNumber
  yearOfLatestMajorRenovation
  calculatedYearOfConstruction
  dateOfChangeForRemainingEconomicLife
  landSize
  floorToAreaRatio
  siteCoverageRatio
  grossFloorSpaceOverground
  grossFloorSpaceBelowGround
  totalGrossFloorSpace
  totalRentableArea
  totalIncomeRelevantRentableAreaForStatistics
  runningCosts
  managementCosts
  maintenanceExpenses
  rentAllowance
  otherOperatingExpenses
  capitalizationRate
  valueByIncomeApproachWithoutPremiumsDiscounts
  discountsPremiums
  discountsPremiumsOverRents
  discountsPremiumsUnderRents
  discountsPremiumsRentConcession
  discountsPremiumsImprovementCharges
  discountsPremiumsTurnoverRent
  discountsPremiumsTemporaryLettings
  discountsPremiumsMallIncome
  discountsPremiumsVATCompensation
  discountsPremiumsTenantFitOutContribution
  deductionForVacancy
  deductionForVacancyLossOfRent
  deductionForVacancyOperatingCosts
  deductionConstructionWorks
  deductionConstructionWorksRestructuringCosts
  deductionConstructionWorksModernizationCosts
  othersDiscountsPremiums
  othersDiscountsPremiumsMarketAdjustment
  othersDiscountsPremiumsOthers
  valueByIncomeApproach
  costApproach
  landValue
  inCaseOfLeaseholdFictionalLandValueFullOwnership
  marketValue
  groundLease
  remainingLifeOfGroundLease
  groundRent
  groundLeaseRemarks
  rentalSituationOfficeLetArea
  rentalSituationOfficeContractualAnnualRent
  rentalSituationOfficeEstAnnRentForLetArea
  rentalSituationOfficeVacArea
  rentalSituationOfficeEstAnnRentForVacArea
  rentalSituationRetailLetArea
  rentalSituationRetailContractualAnnualRent
  rentalSituationRetailEstAnnRentForLetArea
  rentalSituationRetailVacArea
  rentalSituationRetailEstAnnRentForVacArea
  rentalSituationStorageLetArea
  rentalSituationStorageContractualAnnualRent
  rentalSituationStorageEstAnnRentForLetArea
  rentalSituationStorageVacArea
  rentalSituationStorageEstAnnRentForVacArea
  rentalSituationArchiveLetArea
  rentalSituationArchiveContractualAnnualRent
  rentalSituationArchiveEstAnnRentForLetArea
  rentalSituationArchiveVacArea
  rentalSituationArchiveEstAnnRentForVacArea
  rentalSituationGastroLetArea
  rentalSituationGastroContractualAnnualRent
  rentalSituationGastroEstAnnRentForLetArea
  rentalSituationGastroVacArea
  rentalSituationGastroEstAnnRentForVacArea
  rentalSituationResidentialLetArea
  rentalSituationResidentialContractualAnnualRent
  rentalSituationResidentialEstAnnRentForLetArea
  rentalSituationResidentialVacArea
  rentalSituationResidentialEstAnnRentForVacArea
  rentalSituationHotelLetArea
  rentalSituationHotelLetNumbers
  rentalSituationHotelContractualAnnualRent
  rentalSituationHotelEstimatedAnnualRentLet
  rentalSituationHotelVacArea
  rentalSituationHotelVacantNumbers
  rentalSituationHotelEstimatedAnnualRentVacant
  rentalSituationLeisureLetArea
  rentalSituationLeisureContractualAnnualRent
  rentalSituationLeisureEstAnnRentForLetArea
  rentalSituationLeisureVacArea
  rentalSituationLeisureEstAnnRentForVacArea
  rentalSituationSocialLetArea
  rentalSituationSocialLetNumbers
  rentalSituationSocialContractualAnnualRent
  rentalSituationSocialEstimatedAnnualRentLet
  rentalSituationSocialVacArea
  rentalSituationSocialVacantNumbers
  rentalSituationSocialEstimatedAnnualRentVacant
  rentalSituationBusGenLetArea
  rentalSituationBusGenContractualAnnualRent
  rentalSituationBusGenEstAnnRentForLetArea
  rentalSituationBusGenVacArea
  rentalSituationBusGenEstAnnRentForVacArea
  rentalSituationInparkLetNumbers
  rentalSituationInparkContractualAnnualRent
  rentalSituationInparkEstAnnRentForLetNumbers
  rentalSituationInparkVacantNumbers
  rentalSituationInparkEstAnnRentForVacantNumbers
  rentalSituationOutparkLetNumbers
  rentalSituationOutparkContractualAnnualRent
  rentalSituationOutparkEstAnnRentForLetNumbers
  rentalSituationOutparkVacantNumbers
  rentalSituationOutparkEstAnnRentForVacantNumbers
  rentalSituationMiscArea1LetArea
  rentalSituationMiscArea1ContractualAnnualRent
  rentalSituationMiscArea1EstAnnRentForLetArea
  rentalSituationMiscArea1VacArea
  rentalSituationMiscArea1EstAnnRentForVacArea
  rentalSituationMiscArea2LetArea
  rentalSituationMiscArea2ContractualAnnualRent
  rentalSituationMiscArea2EstAnnRentForLetArea
  rentalSituationMiscArea2VacArea
  rentalSituationMiscArea2EstAnnRentForVacArea
  rentalSituationMiscnumbers1LetNumbers
  rentalSituationMiscnumbers1ContractualAnnualRent
  rentalSituationMiscnumbers1EstAnnRentForLetNumbers
  rentalSituationMiscnumbers1VacantNumbers
  rentalSituationMiscnumbers1EstAnnRentForVacantNumbers
  rentalSituationMiscnumbers2LetNumbers
  rentalSituationMiscnumbers2ContractualAnnualRent
  rentalSituationMiscnumbers2EstAnnRentForLetNumbers
  rentalSituationMiscnumbers2VacantNumbers
  rentalSituationMiscnumbers2EstAnnRentForVacantNumbers
  useForResearch
  createdAt
  updatedAt
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageCount
          page
          pageSize
        }
      }
    }
  }
`;

export const ProjectNumberListWrapper = ({
  filter,
  sortBy,
  startCount = 20,
  showLoadMore = false,
  jsFilter,
}: IProjectNumberList) => {
  const [pagesLoaded, setPagesLoaded] = useState(1);
const [maxPages, setMaxPages] = useState(2000);

const [fetchProjects, { data: projects, loading, error, fetchMore, refetch }] = useLazyQuery(PROJECTS_WITH_RESEARCH_QUERY);

useEffect(() => {
  fetchProjects({
    notifyOnNetworkStatusChange: true,
    variables: {
      sort: formatSort(sortBy),
      page: 1,
      pageSize: startCount,
      filters: buildFilter(
        filter
      ),
    },
  });
}, [filter, sortBy, startCount]);

useEffect(() => {
  if (projects?.projects?.meta?.pagination?.pageCount) {
    setMaxPages(projects?.projects?.meta?.pagination?.pageCount);
  }
}, [projects?.projects?.meta?.pagination?.pageCount])

useEffect(() => {
  async function resetPagination() {
    await refetch({
      sort: formatSort(sortBy),
      page: 1,
      pageSize: startCount,
      filters: buildFilter(
        filter
      ),
    });
    setPagesLoaded(2);
  }
  resetPagination();
}, [
  filter,
  startCount,
  sortBy
]);

if (error) {
  return (
    <Alert
      title="Es ist ein Fehler aufgetreten"
      message="Die Auftragsliste kann nicht angezeigt werden."
    />
  );
}

let safeJsFilter = jsFilter || (() => true);

return (
  <div className="w-full grid grid-cols-1 gap-4 sm:grid-cols-1 pb-4">
    {loading && pagesLoaded === 1 ? (
      <div className="flex w-full justify-center items-center">
        <Spinner className="stroke-gray-500 w-8 h-8" />
      </div>
    ) :
      projects && projects?.projects?.data?.length > 0 ? (
        <><InfiniteScroll
          dataLength={showLoadMore ? projects?.projects?.data?.length || 0 : startCount}
          hasMore={pagesLoaded <= maxPages && showLoadMore}
          next={async () => {
            await fetchMore({
              variables: {
                page: pagesLoaded,
              }
            });
            setPagesLoaded(pagesLoaded + 1);
          }}
          loader={
            <div className="flex w-full justify-center items-center">
              <Spinner className="stroke-gray-500 w-8 h-8" />
            </div>
          }
          endMessage={
            showLoadMore ? (
              <p className="text-center mt-4 text-gray-500">
                Alle Aufträge geladen
              </p>
            ) : null
          }
        >
          {" "}
          {
          projects?.projects?.data?.filter(safeJsFilter).map((project: any) => (
            <ProjectNumberListItem key={project.id} project={project} />
          ))}
        </InfiniteScroll>
        </>
      ) : <div className="flex w-full justify-center items-center" >Keine Aufträge gefunden</div>}
  </div>
);
};
