import dayjs, { Dayjs } from "dayjs";

export type IProjectWithInvoices = {
  id: number;
  projectStatus: string;
  projectDisplayId: string;
  invoiceStatusCumulated: string[];
  name: string;
  customerId: string;
  customerName: string;
  buildingAddressStreet: string;
  buildingAddressHouseNumber: string;
  buildingAddressCity: string;
  buildingAddressZip: string;
  buildingAddressCountry: string;
  invoices: {  invoiceNumber: string;
    outgoingInvoiceDate: Dayjs;
    periodOfPayment: Dayjs;
    netIncome: number;
    expenses: number;
    vat: number;
    grossInvoiceAmount: number;
    paymentReminder: Dayjs;
    lateNotice1: Dayjs;
    lateNotice2: Dayjs;
    paymentReceived: Dayjs;
    vatId?: string;}[]
};

export const invoiceStatusMap = {
  "offen": { id: "offen", weight: 0 },
  "bezahlt": { id: "bezahlt", weight: 2},
  "ausstehend": { id: "ausstehend", weight: 3 },
  "zahlungserinnerung": { id: "zahlungserinnerung", weight: 4},
  "mahnung1": { id: "mahnung1", weight: 5 },
  "mahnung2": { id: "mahnung2", weight: 6 },
}

export type InvoiceStatus = keyof typeof invoiceStatusMap;

export const cumulateInvoiceStatus = (
  outgoingInvoiceDate: Dayjs,
  paymentReceived: Dayjs,
  paymentReminder: Dayjs,
  lateNotice1: Dayjs,
  lateNotice2: Dayjs) => {
  return [
    ...(!outgoingInvoiceDate.isValid() ? ['offen'] : []),
    ...(outgoingInvoiceDate.isValid() && !paymentReceived.isValid() && !paymentReminder.isValid() && !lateNotice1.isValid() && !lateNotice2.isValid() ? ['ausstehend'] : []),
    ...(paymentReceived.isValid() ? ['bezahlt'] : []),
    ...(paymentReminder.isValid() && !paymentReceived.isValid() && !lateNotice1.isValid() && !lateNotice2.isValid() ? ['zahlungserinnerung'] : []),
    ...(lateNotice1.isValid() && !paymentReceived.isValid() && !lateNotice2.isValid() ? ['mahnung1'] : []),
    ...(lateNotice2.isValid() && !paymentReceived.isValid() ? ['mahnung2'] : []),
  ]
};

export const mapProjectItem = (project: any): IProjectWithInvoices => {
  const projectStatus= project?.status;
  const invoices = project?.project_invoicings?.map((invoice: any) => {
    const paymentReceived = dayjs(invoice?.paymentReceived);
      const outgoingInvoiceDate =  dayjs(invoice?.outgoingInvoiceDate);
      const paymentReminder = dayjs(invoice?.paymentReminder);
      const lateNotice1 = dayjs(invoice?.lateNotice1);
      const lateNotice2 = dayjs(invoice?.lateNotice2);
      const periodOfPayment = dayjs(invoice?.periodOfPayment);
      const invoiceStatus = cumulateInvoiceStatus(outgoingInvoiceDate, paymentReceived, paymentReminder, lateNotice1, lateNotice2)?.[0];
    return {
      id: invoice?.id,
      invoiceNumber: invoice?.invoiceNumber,
      paymentReceived,
      outgoingInvoiceDate,
      paymentReminder,
      lateNotice1,
      lateNotice2,
      periodOfPayment,
      invoiceStatus,
      netIncome: invoice?.netIncome,
      expenses: invoice?.expenses,
      vat: invoice?.vat,
      grossInvoiceAmount: invoice?.grossInvoiceAmount,
    }
  });

  return {
    id: project?.id,
    projectStatus,
    projectDisplayId: project?.displayId,
    invoices,
    invoiceStatusCumulated: invoices?.map(((invoice:any) => (invoice?.invoiceStatus))).sort((a: InvoiceStatus, b: InvoiceStatus) => (invoiceStatusMap[a]?.weight - invoiceStatusMap[b]?.weight))?.[0],
    name: project?.name,
    customerId: project?.customer?.id,
    customerName:
      project?.customer?.name,
    buildingAddressStreet:
      project?.project_invoicings?.[0]?.street,
    buildingAddressHouseNumber:
    project?.project_invoicings?.[0]?.streetNumber,
    buildingAddressCity:
    project?.project_invoicings?.[0]?.city,
    buildingAddressZip:
    project?.project_invoicings?.[0]?.zip,
    buildingAddressCountry:
    project?.project_invoicings?.[0]?.country
  };
};
