import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../components/basics/button";
import { Card } from "../../components/basics/card";
import { Input } from "../../components/form-components/input";
import { useForm } from "react-hook-form";

import { resetPassword } from "./password-management";
import Alert from "../../components/layout/alert";
import { ValidationMessage } from "../../components/layout/validation";
import { toast } from "react-toastify";

const defaultErrorState = { isError: false, title: "", message: "" };

export const ResetPassword = (props: any) => {
  const [error, setError] = useState({ ...defaultErrorState });
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleResetPassword = async (data: any) => {
    const { password, passwordConfirmation } = data;
    if (password !== passwordConfirmation) {
      setError({
        isError: true,
        title: "Passwörter stimmen nicht überein",
        message: "Bitte überprüfen Sie die eingegebenen Daten.",
      });
      return false;
    }
    try {
      if (searchParams?.get("code")) {
        await resetPassword(
          searchParams.get("code")!,
          password,
          passwordConfirmation
        );
        toast.info("Password erfolgreich zurück gesetzt.");
        setError({ ...defaultErrorState });
        navigate("/");
      } // No else needed, as input is only shown, when code is set in first place
    } catch (e) {
      toast.error("Daten nicht korrekt angegeben.");
      setError({
        isError: true,
        title: "Fehler beim Passwort zurücksetzen",
        message: "Bitte überprüfen Sie die eingegebenen Daten.",
      });
    }
  };

  if (!searchParams.get("code")) {
    return (
      <div className="h-screen bg-green-500 flex flex-col items-center justify-center">
        <Card className="xl:w-1/4 md:w-2/5">
          <img
            src="../logo_green.png"
            className="w-[194px] mb-8 mt-4"
            alt="Logo der Schräder GmbH und Co. KG - ein stilisiertes Gebäude im klassischen Stil"
          />
          <div className="container mx-auto flex flex-col items-center w-full">
            <h1 className="font-special font-semibold text-xl mt-4 mb-4 text-green-900">
              Login
            </h1>
            Sie haben einen falschen Link eingegeben, bitte versuchen Sie es
            erneut.
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="h-screen bg-green-500 flex flex-col items-center justify-center">
      <Card className="xl:w-1/4 md:w-2/5">
        <div className="container mx-auto flex flex-col items-center w-full">
          <img
            src="../logo_green.png"
            className="w-[194px] mb-8 mt-4"
            alt="Logo der Schräder GmbH und Co. KG - ein stilisiertes Gebäude im klassischen Stil"
          />
          <h1 className="font-special font-semibold text-xl mt-4 mb-4 text-green-900">
            Passwort vergessen
          </h1>
          <form
            className="w-full"
            method="post"
            onSubmit={handleSubmit(handleResetPassword)}
          >
            <div className="mt-2 mb-2">
              <Input
                className={`w-full text-base ${
                  errors.password
                    ? "border-red-800 focus:border-red-800 focus:ring-red-800 placeholder-red-800"
                    : ""
                }`}
                placeholder="Password"
                type="password"
                register={register}
                minLength={8}
                validateRequired={{
                  required: "Bitte Passwort eingeben",
                }}
                pattern={{
                  value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
                  message:
                    "Bitte mindestens 8 Zeichen und eines dieser Sonderzeichen verwenden: !@#$%^&+=",
                }}
                name="password"
              />
              {errors.password?.type === "required" && (
                <ValidationMessage message="Bitte Passwort eingeben" />
              )}
              {errors.password?.type === "pattern" && (
                <ValidationMessage message={errors.password?.message as string} />
              )}
            </div>
            <div className="mt-2 mb-2">
              <Input
                className={`w-full text-base ${
                  errors.passwordConfirmation
                    ? "border-red-800 placeholder-red-800"
                    : ""
                }`}
                placeholder="Passwort Wiederholen"
                type="password"
                register={register}
                validateRequired={{
                  required: "Bitte Passwort nochmal eingeben",
                }}
                name="passwordConfirmation"
              />
              {errors.passwordConfirmation && (
                <ValidationMessage message="Bitte Passwort eingeben" />
              )}
            </div>

            <div className="flex flex-col items-center mb-8 mt-8">
              <Button primary type="submit" className="font-semibold">
                Passwort zurücksetzen
              </Button>
            </div>
            {error.isError ? (
              <Alert title={error.title} message={error.message} />
            ) : null}
          </form>
        </div>
      </Card>
    </div>
  );
};

export default ResetPassword;
