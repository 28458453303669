import React, { Fragment, MouseEventHandler, ReactNode, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { classNames } from '../../../shared/utils/class-names';
import { buttonClasses, defaultButtonClasses, primaryButtonClasses, warnButtonClasses } from '../../../components/basics/buttons-classes';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface IDuplicateModal {
  onClickDuplicate: MouseEventHandler,
  onClickHistory: MouseEventHandler,
  children: ReactNode,
}

export const DuplicateModal = ({ children, onClickDuplicate, onClickHistory }: IDuplicateModal) => {
  const [open, setOpen] = useState(false);
  return (<>
    <div className='w-full' onClick={() => { setOpen(true); }}>
        {children}
    </div>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-[1000] inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-xl px-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="relative">
                <button onClick={() => { setOpen(false) }} className="absolute top-0 right-0 cursor-pointer focus:outline-none">
                  <XMarkIcon className="h-6 w-6 text-black" aria-hidden="true" />
                </button>
                <div className="mt-2 sm:mt-2">
                  <Dialog.Title as="h4" className="text-md font-special text-center text-gray-900">
                  Wählen Sie, wie Sie den Auftrag duplizieren möchten:
                  </Dialog.Title>
                  <div className="mt-4">
                    <button className={classNames(...primaryButtonClasses, "bg-gray-500 w-full mb-2")} onClick={(e) => { setOpen(false); onClickHistory(e);}} ><p className='font-bold'>Folgeauftrag anlegen</p>und mit diesem verknüpfen</button>
                    <button className={classNames(...buttonClasses, "bg-green-800 text-green-500 w-full")} onClick={(e) => { setOpen(false); onClickDuplicate(e);}}><p className='font-bold'>Neuen, separaten Auftrag anlegen</p> und Grunddaten kopieren</button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    </>);
}