import React, {useEffect, useState} from "react";
import {LatLng, latLng, LatLngBounds} from "leaflet";
import {MapContainer, TileLayer} from "react-leaflet";
import {Spinner} from "../../../common/spinner/base-spinner";
import {ProjectMap} from "../../../model/project-map/project-map-model";
import {MapConfig} from "../../../model/map-config";
import {MapConfigOverlayLayer} from "../../../components/map/map-config-overlay-layer";
import {useFrontendConfigurationFetchQuery} from "../../../model/frontend-configuration/frontend-configuration-api";
import {MapLoader} from "../../project-list/project-map/map-layers/map-loader";
import {LegendOverlayLayer} from "../../project-list/project-map/map-layers/legend-overlay-layer";
import {PolygonPicker} from "./layer/polygon-picker";
import {ResearchMapLayer} from "./building-map-layer";

export const ProjectsPolygonPickerMap = ({
  projectMapItems,
  reloading,
  maxBounds,
}: {
  projectMapItems: ProjectMap[] | undefined;
  drawing: boolean;
  additionalLayers?: any;
  reloading: boolean;
  maxBounds?: LatLngBounds;
}) => {
  const { data: frontendConfiguration, isLoading: configurationIsLoading } =
  useFrontendConfigurationFetchQuery();

  const [mapConfig, setMapConfig] = useState<MapConfig>({clusterIcons: true});
  const [map, setMap] = useState<any>(null);
  const [lastMapCenter, setLastMapCenter] = useState<LatLng>(latLng(50.93635, 6.961501))
  const [lastMapZoom, setLastMapZoom] = useState<number>(15);

  useEffect(() => {
    map?.setMaxBounds(maxBounds);
  }, [map, maxBounds]);

  useEffect(() => {
    if (projectMapItems?.length && map) {
      setLastMapCenter(map.getCenter());
      setLastMapZoom(map.getZoom())
    } else {
      map?.setView(lastMapCenter, lastMapZoom);
    }
  }, [projectMapItems, map]);

  return (
    <div className="w-full rounded-xl pb-8">
      {projectMapItems ? (
        <MapContainer
          center={latLng(50, 10)}
          zoom={12}
          className="rounded-2xl"
          style={{ width: "100%", height: "800px" }}
          ref={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        <MapLoader loading={configurationIsLoading} />
          {frontendConfiguration?.data || !reloading ? (
            <ResearchMapLayer
              projectMapItems={projectMapItems}
              clusterIcons={mapConfig.clusterIcons}
              clusterRadius={
                frontendConfiguration?.data?.attributes
                  ?.projectMapMaxClusterRadius || 80
              }
            />
          ) : null}
          <LegendOverlayLayer />
          <PolygonPicker />
          <MapConfigOverlayLayer mapConfig={mapConfig} setMapConfig={setMapConfig} />
          <MapLoader loading={reloading} />
        </MapContainer>
      ) : (
        <div className="w-full bg-white h-[800px] border rounded-xl flex items-center justify-center">
          {reloading ? (
            <Spinner className="h-10 w-10 stroke-gray-500" />
          ) : (
            "Bei der Suche nach Aufträgen ist ein Fehler aufgetreten."
          )}
        </div>
      )}
    </div>
  );
};
