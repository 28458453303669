import {useEffect, useState} from "react";
import axios from "axios";
import {getLoginUrl} from "../../shared/utils/url-utils";
import {getItemTransparent} from "../../shared/utils/storage-utils";
import {statusMap} from "../../common/status-map";
import dayjs, { Dayjs } from "dayjs";

export interface IByMonthData {
  [key: string]: {
    [key: string]: number
  }[]
}

export interface IByStatusData {
  [key: string]: number
}

export interface IDateRange {
  from: Dayjs,
  until: Dayjs,
}

const employeeFilter = (employees: string[]) => {
  return {
    $or: [
      {editor: {id: {$in: employees}}},
      {editor2: {id: {$in: employees}}},
      {appraiser: {id: {$in: employees}}}
    ]
  }
}

export const dateRangeFilter = (field: string, dateRange: IDateRange | null) => {
  return dateRange ? {
    [field]: {
      $between: [
        dateRange.from.format("YYYY-MM-DD"),
        dateRange.until.format("YYYY-MM-DD"),
      ]
    }
  } : {}
}

const andFilter = (...filters: any) => {
  return {
    $and: [
      ...filters.filter((f: any) => (!!f))
    ]
  }
}

async function fetchStats(statsEndpoint: string, errorValue: any, filter: any = {}) {
  return await axios.get<any>(`${getLoginUrl()}/api/stats/${statsEndpoint}`, {
    headers: {
      'Authorization': 'Bearer ' + getItemTransparent('JWT')
    },
    params: { filter }
  }).then(t => t.data).catch(_ => errorValue);
}

/**
 * Fetch from the specified API endpoint without an employee filter.
 */
export const useSimpleStatsEndpoint = (endpoint: string, defaultValue: any = {}, errorValue: any = undefined, year: number = dayjs().year()) => {
  const [data, setData] = useState<any>(defaultValue);

  useEffect(() => {
    fetchStats(endpoint, errorValue, {})
      .then(setData)
  }, []);

  return data;
}

/**
 * Fetch from the specified API endpoint, applying the passed employee filter.
 *
 * Returns: [<all data>, <data for current employee>, <data for specified employees>, <is currently loading>]
 */
export const useStatsEndpoint = ({ endpoint, employees, defaultFilter, defaultValue = null, errorValue = undefined }: any) => {
  const [data, setData] = useState<any>(defaultValue);
  const [myData, setMyData] = useState<any>(defaultValue);
  const [filteredData, setFilteredData] = useState<any>(defaultValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchStats(endpoint, errorValue, defaultFilter)
      .then((data) => {
        setData(data);
        fetchStats(endpoint, errorValue, andFilter(defaultFilter, employeeFilter([getItemTransparent('user')?.id]))).then((data => {
          setMyData(data);
          setLoading(false);
        }))
      })
  }, []);

  useEffect(() => {
      const filter = andFilter(defaultFilter, employeeFilter(employees));
      if (!employees.length) {
        setFilteredData(data);
      } else if (employees.length === 1 && employees[0] === getItemTransparent('user')?.id) {
        setFilteredData(myData);
      } else {
        setLoading(true)
        fetchStats(endpoint, errorValue, filter)
          .then((data) => {
            setFilteredData(data);
            setLoading(false);
          });
      }
    }, [employees, data]
  );

  return [data, myData, filteredData, loading];
}

export const calcComplete = (data: IByStatusData) => {
  return data ? Object.values(data).reduce((acc, value) => (acc + value), 0) : 0;
}

export const calcFinished = (data: IByStatusData) => {
  return data ? Object.keys(data).reduce((acc, key) => {
    if (statusMap[key]?.percentage >= 1) {
      return acc + data[key];
    }
    return acc;
  }, 0) : 0;
}

export const calcActive = (data: IByStatusData) => {
  return calcComplete(data) - calcFinished(data);
}
