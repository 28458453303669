import React, { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";

export function HoverTooltip({ children, message }: any) {
  const [show, setShow] = useState<boolean>(false);
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    strategy: "absolute",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [40, 3],
        },
      },
    ],
  });

  return (
    <Popover className="relative z-[10000]">
      <Popover.Button
        as="div"
        ref={setReferenceElement as any}
        onMouseEnter={() => {
          setShow(true);
        }}
        onMouseLeave={() => {
          setShow(false);
        }}
      >
        {children}
      </Popover.Button>
      <Transition
        show={show}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          ref={setPopperElement as any}
          className="absolute p-3 text-sm text-gray-800 bg-white shadow-md rounded-xl"
          static
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setPopperElement as any}>{message}</div>
        </Popover.Panel>{" "}
      </Transition>
    </Popover>
  );
}
