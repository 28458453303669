import React from "react";
import { dateFormatter } from "../../shared/utils/date-utils";
import { BuildingType } from "../layout/building-type";
import { ProjectStatus } from "../layout/project-status";
import { getLoginUrl } from "../../shared/utils/url-utils";
import { DaysToDeadline } from "../layout/days-to-deadline";
import { projectTypeMap } from "../../common/project-type-map";
import { CompletionStatus } from "../layout/completion-status";
import { statusMap } from "../../common/status-map";
import { AvatarHead } from "../basics/avatar-head";
import { AuthImg } from "../layout/auth-img";
import { useFetchOneQuery } from "../../model/project/project-api";
import Alert from "../layout/alert";
import { Spinner } from "../../common/spinner/base-spinner";

// TODO: Refactor - devide between Layout and Data loading

export const MapMarkerItem = ({ projectId }: { projectId: number }) => {
  const {
    data: projectData,
    error,
    isLoading,
  } = useFetchOneQuery({
    id: projectId,
    queryParams: {
      populate: ["building", "images", "editor", "appraiser", "customer"],
    },
  });

  if (!projectId || error) {
    return (
      <Alert
        title="Details konnten nicht geladen werden"
        message="Laden Sie die Seite erneut"
      />
    );
  }

  if (isLoading) {
    return (
      <div className="w-[380px] h-[475px] flex flex-row justify-center">
        <Spinner className="h-6 w-6 stroke-gray-500" />
      </div>
    );
  }

  const project = projectData?.data;

  return (
    <div key={project?.id} className="w-[380px] h-[475px]">
      <div className="flex flex-row font-sans">
        <div className="w-[280px]">
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex flex-row items-center mb-2">
            <ProjectStatus status={project?.attributes?.status} />
            <BuildingType
              type={project?.attributes?.building?.data?.attributes?.type}
            />
          </div>
          <div className="w-full">
            <h3 className="text-lg font-special font-semibold text-gray-700 mb-2 truncate mr-4">
              {project?.attributes?.building?.data?.attributes?.name}
            </h3>
            <div className="text-base text-gray-500">
              <strong className="font-semibold">
                #{project?.attributes?.displayId}
              </strong>
            </div>
          </div>
        </div>
        <div className="w-[100px]">
          <div className="invisible lg:visible">
            {project?.attributes?.images?.data?.length > 0 ? (
              <AuthImg
                className="rounded-xl h-20 w-[100px]"
                src={`${getLoginUrl()}${
                  project?.attributes?.images?.data?.[0]?.attributes?.url
                }`}
                size={"thumbnail"}
              />
            ) : (
              <div className="rounded-xl h-20 bg-gray-500 w-[100px]"></div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <DaysToDeadline deadline={project?.attributes?.servicelevel} />
      </div>
      <div className="grid gap-4 grid-cols-2 mt-2 text-base">
        <div className="rounded-xl p-2 bg-gray-100 text-gray-400 font-sans">
          <h4 className="font-bold mb-1">Auftraggeber</h4>
          {project?.attributes?.customer?.data?.attributes?.name}
        </div>
        <div></div>
        <div className="rounded-xl p-2 bg-gray-100 text-gray-400 font-sans">
          <h4 className="font-bold mb-1">
            Auftragsart + Nr. der Folgebewertung
          </h4>
          {project?.attributes?.type &&
            projectTypeMap[project?.attributes?.type].displayString}
        </div>
        <div className="rounded-xl p-2 bg-gray-100 text-gray-400 font-sans">
          <h4 className="font-bold mb-1">Verfahren / Eigentümer</h4>
          {project?.attributes?.owner || "-"}
        </div>
        <div className="rounded-xl p-2 bg-gray-100 text-gray-400 font-sans">
          <h4 className="font-bold mb-1">Stichtag</h4>
          {dateFormatter(project?.attributes?.deadlineDate || "")}
        </div>
        <div className="rounded-xl p-2 bg-gray-100 text-gray-400 font-sans">
          <h4 className="font-bold mb-1">Servicelevel</h4>
          {dateFormatter(project?.attributes?.servicelevel || "")}
        </div>
      </div>
      <div>
        <CompletionStatus
          projectStatus={
            project?.attributes?.status &&
            statusMap[project?.attributes?.status]
          }
        />
      </div>
      <div className="flex flex-row gap-2 mt-2">
        <AvatarHead user={project?.attributes?.editor} />
        <AvatarHead user={project?.attributes?.appraiser} />
      </div>
    </div>
  );
};
