import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const APP_LOCAL_DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
const APP_LOCAL_DATE_FORMAT = 'DD.MM.YYYY';

dayjs.extend(localizedFormat)

export const convertDateTimeFromServer = (date: string | number | Dayjs | Date | null | undefined) => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (date: string | number | Dayjs | Date | null | undefined) => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const displayDefaultDate = () => dayjs().startOf('day').format(APP_LOCAL_DATE_FORMAT);

export const dayjsDateFormatter = (date: Dayjs) => {
  return date && date?.isValid() ?
  date.format(APP_LOCAL_DATE_FORMAT)
  : '-'
}

export const dateFormatter = (dateString: string) => {
  return dateString
    ? dayjs(dateString).format(APP_LOCAL_DATE_FORMAT)
    : '-';
}

export const dateTimeFormatter = (dateString: string) => {
  return dateString
    ? dayjs(dateString).format(APP_LOCAL_DATETIME_FORMAT)
    : '-';
}