import { ArrayParam, BooleanParam, DelimitedNumericArrayParam, NumberParam, StringParam, withDefault } from "use-query-params";
import { getCurrentYear } from "../../../../components/project-list/project-utils";

export const REFERENCE_LIST_YEARS_FILTER = "years";
export const REFERENCE_LIST_COUNTRY_FILTER = "only-germany";
export const REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER = "market-value-lower";
export const REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER = "market-value-upper";
export const REFERENCE_LIST_TYPE_FILTER = "project-type";
export const REFERENCE_LIST_EDITOR_FILTER = "editor";
export const REFERENCE_LIST_APPRAISER_FILTER = "appraiser";
export const REFERENCE_LIST_CLIENT_TYPE_FILTER = "client-type";
export const REFERENCE_LIST_EXCLUDE = "exclude";

export enum CountryParamEnum {
  ALL = "ALL",
  GERMANY = "GERMANY",
  NOT_GERMANY = "NOT_GERMANY"
}

export const countryParamMap = {
  [CountryParamEnum.ALL]: { label: "Alle" },
  [CountryParamEnum.GERMANY]: { label: "Inland" },
  [CountryParamEnum.NOT_GERMANY]: { label: "Ausland" },
}

const YearParam = withDefault(ArrayParam, [getCurrentYear().key] as string[])
const CountryParam = withDefault(StringParam, CountryParamEnum.ALL);
const MarketValueLowerBoundsParam = withDefault(NumberParam, 0);
const MarketValueUpperBoundsParam = withDefault(NumberParam, Number.MAX_SAFE_INTEGER);
const ClientTypeParam = withDefault(DelimitedNumericArrayParam, [] as number[]);
const ProjectTypeParam = withDefault(ArrayParam, [] as string []);
const EmployeeParam = withDefault(StringParam, null, true);
const AppraiserParam = withDefault(StringParam, null, true);
export const ExcludeParam = withDefault(ArrayParam, []);

export const referenceListQueryParams = {
  [REFERENCE_LIST_YEARS_FILTER]: YearParam,
  [REFERENCE_LIST_COUNTRY_FILTER]: CountryParam,
  [REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER]: MarketValueLowerBoundsParam,
  [REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER]: MarketValueUpperBoundsParam,
  [REFERENCE_LIST_CLIENT_TYPE_FILTER]: ClientTypeParam,
  [REFERENCE_LIST_TYPE_FILTER]: ProjectTypeParam,
  [REFERENCE_LIST_EDITOR_FILTER]: EmployeeParam,
  [REFERENCE_LIST_APPRAISER_FILTER]: AppraiserParam
}

export function referenceFilterResetToDefault(updateParams: (value: any) => void) {
  updateParams({
    [REFERENCE_LIST_YEARS_FILTER]: [getCurrentYear().key] as string[],
    [REFERENCE_LIST_COUNTRY_FILTER]: CountryParamEnum.ALL,
    [REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER]: 0,
    [REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER]: Number.MAX_SAFE_INTEGER,
    [REFERENCE_LIST_CLIENT_TYPE_FILTER]: [],
    [REFERENCE_LIST_TYPE_FILTER]: [],
    [REFERENCE_LIST_EDITOR_FILTER]: null,
    [REFERENCE_LIST_APPRAISER_FILTER]: null,
    [REFERENCE_LIST_EXCLUDE]: [],
  })
}