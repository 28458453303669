import { useEffect, useState } from "react";
import { useQueryParams } from "use-query-params";
import { EmployeeSingleSelect } from "../../../components/form-components/user-select/employee-select";
import { Employee } from "../../../model/analytics/employees/analytics-employees";
import { employeeFilterParams, EMPLOYEE_BAR_CHART_FILTER } from "./employees-table";

const EMPLOYEE_BAR_MAX_USERS = 10;

export const EmployeeFilterEmployeeSelect = ({employees, className}: { employees: Employee[], className: string }) => {
  const [selectedEmployeesBar, setSelectedEmployeesBar] = useState<number[]>([]);
  const [filterParams, setFilterParams] = useQueryParams(employeeFilterParams);

  useEffect(() => {
    setSelectedEmployeesBar(filterParams[EMPLOYEE_BAR_CHART_FILTER] as number[])
  }, [filterParams])

  return <EmployeeSingleSelect
    className={className}
    onChange={(value: Employee | null) => {
    const barChartEmployees = [
      ...(selectedEmployeesBar?.length >= EMPLOYEE_BAR_MAX_USERS - 1 ? selectedEmployeesBar?.slice(
        selectedEmployeesBar.length - EMPLOYEE_BAR_MAX_USERS + 1,
        selectedEmployeesBar.length
      ) : [...selectedEmployeesBar ||[]]),
      ...(value ? [parseInt(value?.id)] : []),
    ]
    setSelectedEmployeesBar(barChartEmployees);
    setFilterParams({
      ...filterParams,
      [EMPLOYEE_BAR_CHART_FILTER]: barChartEmployees
    })
  }}
  selected={null}
  exclude={selectedEmployeesBar}
  employees={employees}
/>
}