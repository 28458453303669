import { ClockIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../shared/utils/class-names";
import { TimelineIcon } from "../common/icons/timeline-icon";

export const HistoryMarker = ({ className }: any) => {
    return (
        <div className={classNames(className, "bg-green-800 p-1")}>
            <TimelineIcon className="h-full text-green-500 mx-auto"/>
        </div>
    )
}