import React from "react";

export const HandelsimmobilienIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.handelsimmobilien-${color}-cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;stroke:${color}}`}</style>
    </defs>
    <g>
      <polygon
        className={`handelsimmobilien-${color}-cls-1`}
        points="4.04 9.96 25.53 9.96 27.31 14.29 2.31 14.29 4.04 9.96"
      />
      <path
        className={`handelsimmobilien-${color}-cls-1`}
        d="M2.31,14.29c0,1.42,1.12,2.56,2.5,2.56s2.5-1.15,2.5-2.56"
      />
      <path
        className={`handelsimmobilien-${color}-cls-1`}
        d="M7.31,14.29c0,1.42,1.12,2.56,2.5,2.56s2.5-1.15,2.5-2.56"
      />
      <path
        className={`handelsimmobilien-${color}-cls-1`}
        d="M12.31,14.29c0,1.42,1.12,2.56,2.5,2.56s2.5-1.15,2.5-2.56"
      />
      <path
        className={`handelsimmobilien-${color}-cls-1`}
        d="M17.31,14.29c0,1.42,1.12,2.56,2.5,2.56s2.5-1.15,2.5-2.56"
      />
      <path
        className={`handelsimmobilien-${color}-cls-1`}
        d="M22.31,14.29c0,1.42,1.12,2.56,2.5,2.56s2.5-1.15,2.5-2.56"
      />
      <polyline
        className={`handelsimmobilien-${color}-cls-1`}
        points="3.88 16.67 3.88 28.94 18.06 28.94 23.47 28.94 25.69 28.94 25.69 16.69"
      />
      <polyline
        className={`handelsimmobilien-${color}-cls-1`}
        points="25.69 14.29 22.31 14.29 17.31 14.29 12.31 14.29 7.31 14.29 3.88 14.29"
      />
      <rect className={`handelsimmobilien-${color}-cls-1`} x="18.06" y="19.13" width="5.41" height="9.81" />
      <rect className={`handelsimmobilien-${color}-cls-1`} x="6.1" y="19.13" width="9.73" height="6.5" />
      <rect className={`handelsimmobilien-${color}-cls-1`} x="10.16" y="5.83" width="9.73" height="3.25" />
    </g>
    <line className={`handelsimmobilien-${color}-cls-1`} x1=".35" y1="28.94" x2="29.35" y2="28.94" />
  </svg>
);
