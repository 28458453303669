import { useEffect, useState } from "react";
import {
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { Spinner } from "../../../common/spinner/base-spinner";
import { Card } from "../../../components/basics/card";
import Alert from "../../../components/layout/alert";
import { AuthImg } from "../../../components/layout/auth-img";
import { SumElement } from "../../../components/layout/invoice-table/sum-element";
import { getCurrentYearAsNumber } from "../../../components/project-list/project-utils";
import {
  AnalyticsEmployeeResult,
  AnalyticsEmployeeTableResult,
} from "../../../model/analytics/employees/analytics-employees";
import {
  useLazyFetchAnalyticsAppraiserInvoicesQuery,
  useLazyFetchAnalyticsEditorInvoicesQuery,
} from "../../../model/analytics/employees/analytics-employees-api";
import { StrapiRestResponse } from "../../../model/meta/response-type";
import { classNames } from "../../../shared/utils/class-names";
import { formatBigNumber, formatCurrency } from "../../../shared/utils/formatters";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import dayjs from "dayjs";

export const EMPLOYEE_YEARS_FILTER = "compare-year";
export const EMPLOYEE_TYPE_FILTER = "employee-type";
export const EMPLOYEE_LINE_CHART_FILTER_1 = "employees-line-chart-first";
export const EMPLOYEE_LINE_CHART_FILTER_2 = "employees-line-chart-second";
export const EMPLOYEE_BAR_CHART_FILTER = "employees-bar-chart";

export const EMPLOYEE_TYPE_EDITOR = "editor";
export const EMPLOYEE_TYPE_APPRAISER = "appraiser";

export const employeeTypeMap: { [key: string]: string } = {
  [EMPLOYEE_TYPE_EDITOR]: "Bearbeiter(in)",
  [EMPLOYEE_TYPE_APPRAISER]: "Gutachter(in)",
};

export const employeeFilterParams = {
  [EMPLOYEE_YEARS_FILTER]: withDefault(NumberParam, null, true),
  [EMPLOYEE_TYPE_FILTER]: withDefault(StringParam, EMPLOYEE_TYPE_EDITOR),
  [EMPLOYEE_LINE_CHART_FILTER_1]: withDefault(NumberParam,null,true),
  [EMPLOYEE_LINE_CHART_FILTER_2]: withDefault(NumberParam,null,true),
  [EMPLOYEE_BAR_CHART_FILTER]: withDefault(
    DelimitedNumericArrayParam,
    null,
    true
  ),
};

function sumNetIncome(acc: number, employee: any) {
  return acc + (employee?.sum || 0);
}

function calculateTrend(trendYear: number, previousYear: number) {
  const difference = trendYear - previousYear;
  return {
    text: `${
      previousYear !== 0
        ? `${formatCurrency(difference)} (${formatBigNumber(
            (Math.abs(difference) / previousYear) *
            100
          )} %)`
        : "-"
    }`,
    up: trendYear > previousYear,
  };
}

function calculateMeta(
  netIncome: any,
  year1: number | null,
  year2: number,
  year3: number
) {
  const year1Income = netIncome?.find((ni: any) => ni.year === year1)?.sum | 0;
  const year1IncomePrevious = year1
    ? netIncome?.find((ni: any) => ni.year === year1 - 1)?.sum | 0
    : 0;
  const year2Income = netIncome?.find((ni: any) => ni.year === year2)?.sum | 0;
  const year2IncomePrevious = year2
    ? netIncome?.find((ni: any) => ni.year === year2 - 1)?.sum | 0
    : 0;
  const year3Income = netIncome?.find((ni: any) => ni.year === year3)?.sum | 0;
  const year3IncomePrevious = year3
    ? netIncome?.find((ni: any) => ni.year === year3 - 1)?.sum | 0
    : 0;

  return {
    year1: year1Income,
    year1trend: year1Income
      ? calculateTrend(year1Income, year1IncomePrevious)
      : null,
    year2: year2Income,
    year2trend: year2Income
      ? calculateTrend(year2Income, year2IncomePrevious)
      : null,
    year3: year3Income,
    year3trend: year3Income
      ? calculateTrend(year3Income, year3IncomePrevious)
      : null,
  };
}

function convertResultToTable(
  result: StrapiRestResponse<AnalyticsEmployeeResult>,
  queryYears: Array<number | null>
) {
  if (queryYears.length >= 2 && queryYears.length <= 3) {
    const queryYear1 = queryYears.length === 3 ? queryYears[0] : null;
    const queryYear2 =
      (queryYears.length === 3 ? queryYears[1] : queryYears[0]) ||
      getCurrentYearAsNumber() - 1;
    const queryYear3 =
      (queryYears.length === 3 ? queryYears[2] : queryYears[1]) ||
      getCurrentYearAsNumber();

    const employees = result?.data?.employees.reduce((acc: any, e: any) => {
      if (!acc.includes(e.user_id)) {
        acc.push(e.user_id);
      }
      return acc;
    }, []);

    return {
      meta: calculateMeta(
        result?.data?.netIncome,
        queryYear1,
        queryYear2,
        queryYear3
      ),
      employees: employees
        .map((userId: any) => {
          const year1 =
            result?.data?.employees
              ?.filter(
                (e: any) => e.year === queryYear1 && e.user_id === userId
              )
              .reduce(sumNetIncome, 0) || 0;
          const year2 =
            result?.data?.employees
              ?.filter(
                (e: any) => e.year === queryYear2 && e.user_id === userId
              )
              .reduce(sumNetIncome, 0) || 0;
          const year3 =
            result?.data?.employees
              ?.filter(
                (e: any) => e.year === queryYear3 && e.user_id === userId
              )
              .reduce(sumNetIncome, 0) || 0;
          const currentEmployee = result?.data.employees.filter(
            (e: any) => e.user_id === userId
          )?.[0];

          return {
            userId: userId,
            username: currentEmployee.username,
            roleName: currentEmployee.roleName,
            imageUrl: currentEmployee.thumbnailUrl,
            year1,
            year2,
            year3,
          };
        })
        ?.sort((a: any, b: any) => a?.username?.localeCompare(b?.username)),
    };
  } else {
    throw Error("Not enough years specified error");
  }
}

// TODO: Implement wrapper around, Refactor stuff, Proper Error handling

export const EmployeesTable = () => {
  const [data, setData] = useState<AnalyticsEmployeeTableResult | null>(null);
  const [
    fetchAppraiserItems,
    { error: appraiserError, isLoading: isAppraiserLoading },
  ] = useLazyFetchAnalyticsAppraiserInvoicesQuery();
  const [fetchEditorItems, { error: editorError, isLoading: isEditorLoading }] =
    useLazyFetchAnalyticsEditorInvoicesQuery();

  const [employeeFilter] = useQueryParams(employeeFilterParams);

  const year1 = employeeFilter[EMPLOYEE_YEARS_FILTER],
    year2 = dayjs().subtract(1, 'year').year(),
    year3 = dayjs().year();
  const years = [year1, year2, year3];

  useEffect(() => {
    async function updateData() {
      if (employeeFilter[EMPLOYEE_TYPE_FILTER] === EMPLOYEE_TYPE_EDITOR) {
        const data = await fetchEditorItems({
          queryParams: {
            filters: { years },
          },
        }).unwrap();
        setData(convertResultToTable(data, years));
      } else {
        if (employeeFilter[EMPLOYEE_TYPE_FILTER] === EMPLOYEE_TYPE_APPRAISER) {
          const data = await fetchAppraiserItems({
            queryParams: {
              filters: { years },
            },
          }).unwrap();
          setData(convertResultToTable(data, years));
        }
      }
    }
    updateData();
  }, [employeeFilter]);

  if (appraiserError) {
    return (
      <Alert
        title="Fehler beim Datenholen."
        message={JSON.stringify(appraiserError)}
      />
    );
  }

  if (editorError) {
    return (
      <Alert
        title="Fehler beim Datenholen."
        message={JSON.stringify(editorError)}
      />
    );
  }

  return (
    <>
      <div className="flex justify-between gap-3 mt-4 mb-6">
        <div className="w-1/4"></div>
        <SumElement
          heading={`Gesamt-Nettoumsatz ${year1}`}
          value={year1 !== null ? data?.meta?.year1 : Math.random() * 1000000.0}
          indicator={data?.meta.year1trend}
          className="w-1/4"
          key={1}
          isBlurred={year1 === null}
        />
        <SumElement
          heading={`Gesamt-Nettoumsatz ${year2}`}
          value={data?.meta?.year2}
          indicator={data?.meta.year2trend}
          className="w-1/4"
          key={2}
        />
        <SumElement
          heading={`Gesamt-Nettoumsatz ${year3}`}
          value={data?.meta?.year3}
          indicator={data?.meta.year3trend}
          className="w-1/4"
          key={3}
        />
      </div>
      <Card className="bg-gray-100 border border-gray-50">
        <div className="bg-transparent">
          <div className="flex flex-row min-w-full justify-between text-base font-semibold font-special text-gray-600">
            <div className="px-3 py-3.5 text-left font-semibold w-1/4">
              {employeeTypeMap[employeeFilter[EMPLOYEE_TYPE_FILTER]]}
            </div>
            <div
              className={classNames(
                "py-3.5 pl-4 pr-3 sm:pl-6 w-1/4 text-center",
                year1 === null ? "blur-sm" : ""
              )}
            >
              Jahr {year1 || Math.round(Math.random() * 2000)}
            </div>
            <div className="px-3 py-3.5 text-sm w-1/4 text-center">
              Jahr {year2}
            </div>
            <div className="px-3 py-3.5 text-sm w-1/4 text-center">
              Jahr {year3}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          {isAppraiserLoading || isEditorLoading ? (
            <Spinner className="w-8 h-8 text-gray-600" />
          ) : (
            data?.employees?.map((employee) => (
              <div
                key={employee.userId}
                className="flex flex-row min-w-full justify-between items-stretch rounded-2xl mb-1 bg-white font-special font-semibold"
              >
                <div className="whitespace-nowrap px-5 py-4 text-sm text-gray-600 w-1/4 flex flex-row justify-start">
                  <AuthImg
                    className="h-8 w-8 rounded-full"
                    src={`${getLoginUrl()}${employee.imageUrl}`}
                    size={"default"}
                  />
                  <span className="ml-4 flex items-center text-base">
                    {employee.username}
                  </span>
                </div>
                <div
                  className={classNames(
                    "whitespace-nowrap px-3 py-4 text-gray-600 w-1/4 text-center bg-gray-300 flex flew-row items-center justify-center",
                    year1 === null ? "blur-sm" : ""
                  )}
                >
                  {year1 !== null
                    ? formatCurrency(employee.year1)
                    : formatCurrency(Math.random() * 100000)}
                </div>
                <div className="whitespace-nowrap px-3 py-4 min-h-full text-gray-600 bg-gray-100 w-1/4 text-center flex flew-row items-center justify-center">
                  {formatCurrency(employee.year2)}
                </div>
                <div className="whitespace-nowrap px-3 py-4 text-gray-800 w-1/4 text-center flex flew-row items-center justify-center">
                  {formatCurrency(employee.year3)}
                </div>
              </div>
            ))
          )}
        </div>
      </Card>
    </>
  );
};
