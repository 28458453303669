import React, { useLayoutEffect } from 'react';

import { logout } from '../../shared/auth/authentication';

export const Logout = () => {

  useLayoutEffect(() => {
    logout();
  });

  return (
    <div className="p-5">
      <h4>Logged out successfully!</h4>
    </div>
  );
};

export default Logout;
