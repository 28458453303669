export const hClasses = [
  'font-special',
  'font-semibold'
]

export const h1Classes = [
  ...hClasses,
  'text-3xl',
];

export const h2Classes = [
  ...hClasses,
  'text-2xl',
];

export const h3Classes = [
  ...hClasses,
  'text-xl',
];

export const h4Classes = [
  ...hClasses,
  'text-md',
];