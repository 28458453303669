import React from "react";

export const HospitalityIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.hospitality-${color}-cls-1{stroke-width:.75px;}.hospitality-${color}-cls-1,.cls-2{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;}.cls-2{stroke-width:.25px;`}</style>
    </defs>
    <line className={`hospitality-${color}-cls-1`} x1=".33" y1="28.94" x2="29.33" y2="28.94" />
    <g>
      <rect className={`hospitality-${color}-cls-1`} x="3.88" y="5.21" width="21.91" height="23.73" />
      <g>
        <g>
          <rect
            className={`hospitality-${color}-cls-1`}
            x="7.07"
            y="12.42"
            width="3.55"
            height="2.27"
          />
          <rect
            className={`hospitality-${color}-cls-1`}
            x="13.06"
            y="12.42"
            width="3.55"
            height="2.27"
          />
          <rect
            className={`hospitality-${color}-cls-1`}
            x="19.04"
            y="12.42"
            width="3.55"
            height="2.27"
          />
        </g>
        <g>
          <rect
            className={`hospitality-${color}-cls-1`}
            x="7.07"
            y="8.33"
            width="3.55"
            height="2.27"
          />
          <rect
            className={`hospitality-${color}-cls-1`}
            x="13.06"
            y="8.33"
            width="3.55"
            height="2.27"
          />
          <rect
            className={`hospitality-${color}-cls-1`}
            x="19.04"
            y="8.33"
            width="3.55"
            height="2.27"
          />
        </g>
        <g>
          <rect
            className={`hospitality-${color}-cls-1`}
            x="7.07"
            y="16.51"
            width="3.55"
            height="2.27"
          />
          <rect
            className={`hospitality-${color}-cls-1`}
            x="13.06"
            y="16.51"
            width="3.55"
            height="2.27"
          />
          <rect
            className={`hospitality-${color}-cls-1`}
            x="19.04"
            y="16.51"
            width="3.55"
            height="2.27"
          />
        </g>
      </g>
      <rect className={`hospitality-${color}-cls-1`} x="10.62" y="2.94" width="8.42" height="2.27" />
      <g>
        <rect
          className={`hospitality-${color}-cls-1`}
          x="10.34"
          y="23.03"
          width="2.25"
          height="5.91"
        />
        <rect
          className={`hospitality-${color}-cls-1`}
          x="12.58"
          y="23.03"
          width="2.25"
          height="5.91"
        />
        <rect
          className={`hospitality-${color}-cls-1`}
          x="14.83"
          y="23.03"
          width="2.25"
          height="5.91"
        />
        <rect
          className={`hospitality-${color}-cls-1`}
          x="17.08"
          y="23.03"
          width="2.25"
          height="5.91"
        />
      </g>
      <g>
        <polygon
          className="cls-2"
          points="12.36 20.82 12.58 21.27 13.07 21.34 12.71 21.69 12.8 22.18 12.36 21.95 11.92 22.18 12 21.69 11.65 21.34 12.14 21.27 12.36 20.82"
        />
        <polygon
          className="cls-2"
          points="14.01 20.82 14.23 21.27 14.72 21.34 14.36 21.69 14.45 22.18 14.01 21.95 13.57 22.18 13.65 21.69 13.3 21.34 13.79 21.27 14.01 20.82"
        />
        <polygon
          className="cls-2"
          points="15.66 20.82 15.88 21.27 16.37 21.34 16.01 21.69 16.1 22.18 15.66 21.95 15.22 22.18 15.3 21.69 14.94 21.34 15.44 21.27 15.66 20.82"
        />
        <polygon
          className="cls-2"
          points="17.31 20.82 17.53 21.27 18.02 21.34 17.66 21.69 17.75 22.18 17.31 21.95 16.87 22.18 16.95 21.69 16.59 21.34 17.09 21.27 17.31 20.82"
        />
      </g>
    </g>
  </svg>
);
