import React from 'react';
class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        <div className="rounded border-1 border-red-400 bg-red-100 text-red-800">
          Diese Seite konnte nicht gefunden werden.
        </div>
      </div>
    );
  }
}

export default PageNotFound;
