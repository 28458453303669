import React, {useEffect, useState} from "react";
import {Spinner} from "../../common/spinner/base-spinner";
import {classNames} from "../../shared/utils/class-names";
import { useLazyFetchAddressSearchQuery } from "../../model/project-map/project-map-api";
import { AddressSearchResult } from "../../model/project-map/project-map-model";

export interface IAddressDropdown {
  query: string;
  onSelect: (location: AddressSearchResult) => void;
}

export const AddressDropdown = ({query, onSelect}: IAddressDropdown,) => {

  const [fetchAddressSearchData, { data: fetchedAddressData, error, isFetching }] = useLazyFetchAddressSearchQuery();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if(query) {
      fetchAddressSearchData(query)
    }
  }, [query])

  return (
    <div className="rounded-xl md:rounded-2xl md:shadow-lg md:ring-1 ring-black ring-opacity-5 overflow-hidden">
      {isFetching ? (
        <div className="flex justify-center items-center min-h-12">
          <Spinner className="w-10 h-10 stroke-gray-500 my-5"/>
        </div>
      ) : (
        <div className="relative grid gap-8 p-4 md:p-8 lg:grid-cols-1 bg-gray-100">
          {fetchedAddressData?.map((addressSearchResult: AddressSearchResult) => (
            <div
              key={Object.keys(addressSearchResult)?.[0]}
              className={classNames(
                "bg-white hover:bg-gray-300",
                "-m-3 p-3 flex items-start rounded-xl transition ease-in-out duration-150 relative"
              )}
            >
              <a
                className="ml-4 pt-1 block text-left cursor-pointer w-full"
                onClick={() => {
                  onSelect({...addressSearchResult});
                }}
              >
                {Object.keys(addressSearchResult)?.[0]}
              </a>
            </div>
          ))}
          {!fetchedAddressData || fetchedAddressData?.length === 0 ? "Keine Daten gefunden" : null}
        </div>
      )}
    </div>
  );
};
