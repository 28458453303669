import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { classNames } from '../../../shared/utils/class-names';
import { formatCurrency } from '../../../shared/utils/formatters';

export const SumElement = ({ value, className, heading, indicator, isBlurred = false }: { value: any, className?: string, heading: string, indicator?: { text: string, up: boolean } | null, isBlurred?: boolean }) => {

  const sum = value;

  return <div className={classNames('rounded-2xl bg-white py-5 text-center', isBlurred ? 'blur-sm' : '' , className || '')}>
    <span className='text-lg md:text-xl lg:text-3xl xl:text-4xl font-special font-semibold'>{formatCurrency(sum)}</span>
    <h3 className='text-base mt-4 text-gray-500'>{heading}</h3>
    {indicator ? <span className={classNames('text-sm', indicator.up ? 'text-green-300' : 'text-red-600')}>{indicator.up ? <ChevronUpIcon className='w-3 inline'/> : <ChevronDownIcon className='w-3 inline' /> }{indicator.text}</span> : null}
  </div>
}