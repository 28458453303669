import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../shared/utils/class-names";
import { getCurrentYearAsNumber } from "../project-list/project-utils";
import { XCircleIcon } from "@heroicons/react/24/solid";

export interface MultiYearRangePickerProps {
  selected?: [number, number] | null;
  yearsFrom?: number;
  placeholder?: string;
  columns?: 3 | 5;
  nonNullable?: boolean;
  onChange: (val: [number, number] | null) => void;
}

function generateYears(from = 2011): number[] {
  return [...Array(getCurrentYearAsNumber() - from - 1).keys()].map((i) =>
    dayjs().subtract(i, "year").year()
  );
}

export const MultiYearRangePicker: React.FC<MultiYearRangePickerProps> = ({
  selected,
  onChange,
  columns = 3,
  placeholder,
  yearsFrom = 2011,
  nonNullable = true,
}) => {

  const [newSelection, setNewSelection] = useState<[number | null, number | null]| null>(null);
  const [hoverYear, setHoverYear] = useState<number|null>(null);

  useEffect(() => { setNewSelection(null) }, [selected])


  return (
    <Popover className="relative">
      <Popover.Button className="relative w-full cursor-default rounded-xl border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-md">
        <span className="block truncate">
          {selected ? `${selected?.[0]} - ${selected?.[1]}` : <span className="text-gray-500">{placeholder}</span>}
        </span>
        {selected && placeholder && !nonNullable ? (
                <span
                  className="absolute inset-y-0 right-6 flex items-center pr-1 text-gray-400 cursor-pointer"
                  onClick={(e) => { e.stopPropagation(); setNewSelection(null); onChange(null) }}>
                  <XCircleIcon className="h-5" />
                </span>
              ) : null}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-6 text-gray-400"
            aria-hidden="true"
          />
        </span>{" "}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute mt-1 -ml-4 flex w-screen max-w-max max-h-3/4 px-4 z-[3001]">
          <div className="w-screen max-w-sm flex-auto overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 px-3 py-5">
            <div className={`grid ${columns === 3 ? 'grid-cols-3' : 'grid-cols-5'} gap-x-0 gap-y-1 items-center justify-between`} onMouseOut={() => { setHoverYear(null); }}>
              {Object.values(generateYears(yearsFrom - 2))
                .sort()
                .map((year) => {
                  const isLowerBound = year === selected?.[0];
                  const isUpperBound = year === selected?.[1];
                  const isBetweenSelection = selected && year > selected?.[0] && year < selected?.[1];

                  let isBetweenNewSelection = false;
                  let isLowerBoundNewSelection = false;
                  let isUpperBoundNewSelection = false;
                  if(newSelection && newSelection?.[0] !== null && hoverYear) {
                    const lowerBoundNewSelection = hoverYear > newSelection[0] ? newSelection[0] : hoverYear;
                    const upperBoundNewSelection = hoverYear > newSelection[0] ? hoverYear : newSelection[0];
                    isLowerBoundNewSelection = year === lowerBoundNewSelection && lowerBoundNewSelection !== upperBoundNewSelection;
                    isUpperBoundNewSelection = year === upperBoundNewSelection && lowerBoundNewSelection !== upperBoundNewSelection;
                    isBetweenNewSelection = year > lowerBoundNewSelection && year < upperBoundNewSelection && lowerBoundNewSelection !== upperBoundNewSelection;
                  }

                  return (
                    <div 
                      key={year}
                      className={classNames(
                      isBetweenNewSelection ? "bg-[#b3cab9]" :
                      isBetweenSelection && !isLowerBoundNewSelection && !isUpperBoundNewSelection ? "bg-green-100" : "",
                      "flex flex-row justify-between"
                      )}
                      >
                      <div className={classNames(
                        isUpperBoundNewSelection ? "bg-[#b3cab9]" :
                        (isUpperBound && !isBetweenNewSelection && isUpperBound !== isLowerBound ) || (isLowerBoundNewSelection && isBetweenSelection) ? "bg-green-100" : "",
                        "w-8 -mr-2")}></div>
                      <div
                        onClick={() => {
                          const isFirstClick = newSelection === null || (newSelection[0] !== null && newSelection[1] !== null);
                          if(isFirstClick) {
                            setNewSelection([year, null]);
                          } else {
                            if(newSelection[0] === null) { throw Error("Invalid internal component state for MultiYearRangeSelect") }
                            const isLower =  year < newSelection[0];
                            if(isLower) {
                              setNewSelection([year, newSelection[0]]);
                              onChange([year, newSelection[0]]);
                            } else {
                              setNewSelection([newSelection[0], year]);
                              onChange([newSelection[0], year]);
                            }
                          }
                        }}
                        onMouseOver={() => {
                          setHoverYear(year);
                        }}
                        className={classNames(
                          "grow text-center py-2 rounded-xl hover:bg-green-600 hover:text-white hover:cursor-pointer hover:font-bold tracking-wide z-10",
                          newSelection?.[0] === year ? "bg-green-600 text-white font-bold" : 
                          isLowerBound || isUpperBound ? "bg-green-500 text-white font-bold": "",
                        )}
                      >
                        {year}
                      </div>
                      <div className={classNames(
                        isLowerBoundNewSelection ? "bg-[#b3cab9]" :
                        (isLowerBound && !isBetweenNewSelection && isUpperBound !== isLowerBound) || (isUpperBoundNewSelection && isBetweenSelection) ? "bg-green-100" : "",
                        "w-8 -ml-2")}></div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
