import { useMutation } from "@apollo/client";
import { Transition } from "@headlessui/react";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import React from "react";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/spinner/base-spinner";
import { NOTE_UPDATE_ACCESS_RESTRICTION } from "../../../model/note";
import { INoteListItem } from "./note";

export const LockNote = ({ comment, refetch }: INoteListItem) => {
  const [toggleNoteLock, { loading }] = useMutation(
    NOTE_UPDATE_ACCESS_RESTRICTION
  );

  const toggleLock = async () => {
    try {
      await toggleNoteLock({
        variables: {
          id: comment.id,
          data: { accessRestricted: !comment?.attributes?.accessRestricted },
        },
      });
      refetch();
    } catch (e) {
      toast.error(
        "Konnte Beschränkung nicht anpassen. Sind Sie mit dem Internet verbunden?"
      );
    }
  };
  return (
    <div className="cursor-pointer" onClick={toggleLock}>
      {loading ? (
        <Spinner className="w-5 stroke-gray-500" />
      ) : (
          !comment?.attributes?.accessRestricted ?
          <Transition
            show={!comment?.attributes?.accessRestricted}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <LockOpenIcon className="w-5 text-gray-500" />
          </Transition>
          : <Transition
          show={comment?.attributes?.accessRestricted}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
          <LockClosedIcon className="w-5 text-gray-500" />
        </Transition>
      )}
    </div>
  );
};
