import React from 'react';
import { contractorKeyValuePairs } from '../../common/contractor-map';
import { FilterSelect } from '../filters/filter-select';
import { IKeyValuePair } from '../../shared/utils/entity-utils';
import { IUpdateField } from './types';

export const ContractorUpdateField: IUpdateField<string> = ({ label, value, error, onUpdate, className }) => {
  const items = contractorKeyValuePairs;
  return (
    <FilterSelect
      placeholder={label || "Auftragnehmer"}
      selected={items.find((item: IKeyValuePair) => ( item.key === value ))}
      onChange={(newVal) => { onUpdate(newVal?.key) }}
      items={items}
      error={error}
      className={className}
    />
  );
};
