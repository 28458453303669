import { faker } from "@faker-js/faker";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tick } from "chart.js";
import { Line } from "react-chartjs-2";
import { HoverTooltip } from "../../../components/layout/basics/hover-tooltip";

const labels = [
  "Jan.",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez.",
];

const options = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: "x" as const,
  },
  scales: {
    xAxis: {
      ticks: {
        font: {
          family: "Roboto",
        },
      },
    },
    yAxis: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: number, ticks: Tick[]) {
          return `${value} €`;
        },
        font: {
          family: "Roboto",
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          family: "Roboto",
        },
      },
    },
    tooltip: {
      yAlign: "top" as const,
      backgroundColor: "#fff",
      bodyColor: "#777",
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context: any) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
};

export const DummyLineChart = ({ height }: any) => {
  return (
    <div className="min-h-full">
      <div className="relative z-10 min-h-full flex flex-row items-center justify-center top-[200px]">
        <HoverTooltip
          message={
            <div className="w-64">
              <img src="/line-chart-help.gif" alt="" />
            </div>
          }
        >
          <div className="bg-white rounded-2xl p-6 flex flex-row items-center cursor-pointer">
            <InformationCircleIcon className="w-8 h-8 text-gray-500 mr-5" />
            Bitte wählen Sie zunächst einen bzw zwei Mitarbeiter aus der
            Bilderliste aus.
          </div>
        </HoverTooltip>{" "}
      </div>
      <div className="filter blur-sm">
        <Line
          options={options}
          style={{ height }}
          data={{
            labels,
            datasets: [
              {
                label: "2022",
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 100000 })
                ),
                borderColor: "#004f14",
                tension: 0.4,
              },
              {
                label: "2023",
                data: labels.map(() =>
                  faker.datatype.number({ min: 0, max: 100000 })
                ),
                borderColor: "#f6ba45",
                tension: 0.4,
              },
            ],
          }}
        />
      </div>
    </div>
  );
};
