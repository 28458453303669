import { getItemTransparent } from "../shared/utils/storage-utils";

const capabilities = {
  editAppraiser: ['superadmin', 'geschaftsfuhrer', 'admin', 'teamleiter'],
  editEditor: ['superadmin', 'geschaftsfuhrer', 'admin', 'teamleiter'],
  restrictNotes: ['superadmin', 'geschaftsfuhrer', 'admin'],
  createProjects: ['superadmin', 'geschaftsfuhrer', 'admin' ],
}

export type ICapability = keyof typeof capabilities;

// ! Strapi API does not use the data.attributes structure for users
export function userMay(user: any, capability: ICapability) {
  return capabilities[capability].includes(user?.role?.type);
}

export function currentUserMay(capability: ICapability) {
  return userMay(getItemTransparent('user'), capability);
}