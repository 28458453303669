import { configureStore } from '@reduxjs/toolkit'
import { analyticsEmployeeApi, middleware as analyticsEmployeeMiddleware } from './model/analytics/employees/analytics-employees-api'
import { analyticsReferencesApi, middleware as analyticsReferencesMiddleware } from './model/analytics/references/analytics-references-api'
import { frontendConfigAPI, middleware as frontendConfigMiddleware } from './model/frontend-configuration/frontend-configuration-api'
import { projectAPI, middleware as projectMiddleware } from './model/project/project-api'
import { projectMapAPI, middleware as projectMapMiddleware } from './model/project-map/project-map-api'
import { projectResearchApi, middleware as projectResearchMiddleware } from './model/project-research/project-research-api'
import { userApi, middleware as userMiddleware } from './model/users/user-api';
import { personalUserApi, middleware as personalUserMiddleware } from './model/users/personal-user-api'
import { roleApi, middleware as roleMiddleware } from './model/role/role-api';
import newProjectModalReducer from './components/layout/new-project/new-project-reducer';
import polygonPickerSlice from './pages/research/projects-polygon-picker-map/layer/polygon-picker-slice'

export const store = configureStore({
  reducer: {
    [projectAPI.reducerPath]: projectAPI.reducer,
    [projectMapAPI.reducerPath]: projectMapAPI.reducer,
    [projectResearchApi.reducerPath]: projectResearchApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [personalUserApi.reducerPath]: personalUserApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [analyticsEmployeeApi.reducerPath]: analyticsEmployeeApi.reducer,
    [analyticsReferencesApi.reducerPath]: analyticsReferencesApi.reducer,
    [frontendConfigAPI.reducerPath]: frontendConfigAPI.reducer,
    newProjectModal: newProjectModalReducer,
    polygonPicker: polygonPickerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(projectMiddleware)
      .concat(projectMapMiddleware)
      .concat(projectResearchMiddleware)
      .concat(userMiddleware)
      .concat(personalUserMiddleware)
      .concat(roleMiddleware)
      .concat(analyticsEmployeeMiddleware)
      .concat(analyticsReferencesMiddleware)
      .concat(frontendConfigMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch