import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import { defaultButtonClasses } from "../../../components/basics/buttons-classes";
import { HoverTooltip } from "../../../components/layout/basics/hover-tooltip";
import { classNames } from "../../../shared/utils/class-names";
import { IKeyValuePair } from "../../../shared/utils/entity-utils";

export interface ISortDirectionControl {
  items: IKeyValuePair[];
  selected: IKeyValuePair;
  onChange: (selected: IKeyValuePair | undefined) => void;
}

export const SortDirectionControl = ({
  items,
  onChange,
  selected,
}: ISortDirectionControl) => {
  return (
    <HoverTooltip message={ selected?.key === 'asc' ? 'Absteigend sortieren' : 'Aufsteigend sortieren' }>
    <button className={classNames(...defaultButtonClasses, 'h-9 border-gray-300 flex flex-row justify-center ring-0 cursor-pointer shadow-sm stroke-green-500 text-green-500 bg-white')} value={selected?.key} onClick={() => onChange(items.find(i => (i.key !== selected.key)))}>
      {
        selected?.key === 'asc'
        ? <BarsArrowDownIcon className='h-5' />
        : <BarsArrowUpIcon className='h-5' />
      }
    </button>
    </HoverTooltip>
  );
};
