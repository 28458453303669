import React from "react";
import { Controller } from "react-hook-form";
import { invoiceTypeItems } from "../../model/invoice";
import { FilterSelect } from "../filters/filter-select";
export interface IInvoiceTypeInput extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type?: string;
  placeholder?: string;
  control ?: any;
  validateRequired?: object;
  pattern?: any;
  name: string;
}

export const InvoiceTypeInput = ({
  children,
  name,
  validateRequired,
  control,
  pattern,
  className,
  ...rest
}: IInvoiceTypeInput) => (
  <Controller
    control={control}
    name={name}
    rules={{
      ...validateRequired
    }}
    render={({
      field: { onChange, value },
      fieldState: { error },
      formState,
    }) => {
      return (
        <FilterSelect
        placeholder='Rechnungsart'
        onChange={onChange}
        selected={value}
        items={invoiceTypeItems}
        />
      );
    }}
  />
);
