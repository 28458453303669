import React, { useEffect, useState } from "react";
import { LatLngBounds } from "leaflet";
import { useMapEvents } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { toast } from "react-toastify";
import { buildRestFilter } from "../../../../model/meta/request-utils";
import { useLazyFetchAllQuery } from "../../../../model/project-map/project-map-api";
import { useFrontendConfigurationFetchQuery } from "../../../../model/frontend-configuration/frontend-configuration-api";
import { ProjectMap } from "../../../../model/project-map/project-map-model";
import { MapLoader } from "../../../project-list/project-map/map-layers/map-loader";
import { MemoizedMapMarker } from "./memoized-map-marker";
import { MapConfigOverlayLayer } from "../../../../components/map/map-config-overlay-layer";
import { MapConfig } from "../../../../model/map-config";

const calculateExtendedMapBounds = (
  mapBounds: LatLngBounds,
  scale: number
): LatLngBounds => {
  const newEast =
    mapBounds.getEast() +
    (mapBounds.getEast() - mapBounds.getCenter().lng) * scale;
  const newWest =
    mapBounds.getWest() +
    (mapBounds.getWest() - mapBounds.getCenter().lng) * scale;
  const newNorth =
    mapBounds.getNorth() +
    (mapBounds.getNorth() - mapBounds.getCenter().lat) * scale;
  const newSouth =
    mapBounds.getSouth() +
    (mapBounds.getSouth() - mapBounds.getCenter().lat) * scale;
  mapBounds.extend(new LatLngBounds([newSouth, newWest], [newNorth, newEast]));
  return mapBounds;
};

export const BackgroundAddressMapLayer = () => {
  const [lastBounds, setLastBounds] = useState<LatLngBounds | undefined>();

  const map = useMapEvents({
    async zoomend(e) {
      if (!lastBounds?.contains(map.getBounds())) {
        const newBounds = calculateExtendedMapBounds(
          lastBounds || map.getBounds(),
          2
        );
        await reloadOnMapEvent(newBounds);
        setLastBounds(newBounds);
      }
    },
    async moveend(e) {
      if (!lastBounds?.contains(map.getBounds())) {
        const newBounds = calculateExtendedMapBounds(
          lastBounds || map.getBounds(),
          2
        );
        await reloadOnMapEvent(newBounds);
        setLastBounds(newBounds);
      }
    },
  });

  const [fetchProjectMap, { data: projectMapItems, isLoading }] =
    useLazyFetchAllQuery();
  const { data: frontendConfiguration, isLoading: configurationIsLoading } =
    useFrontendConfigurationFetchQuery();

  const reloadOnMapEvent = async (bounds: LatLngBounds) => {
    try {
      await fetchProjectMap({
        queryParams: {
          filters: buildRestFilter({
            geoBounds: bounds,
          }),
        },
      });
    } catch (e) {
      toast.error("Nachladen nicht möglich. Bitte prüfen Sie ihre Verbindung");
    }
  };

  useEffect(() => {
    async function loadOnInitialization() {
      const filters = buildRestFilter({
        geoBounds: lastBounds || map.getBounds(),
      });

      await fetchProjectMap({
        queryParams: {
          filters,
        },
      });
    }
    setLastBounds(calculateExtendedMapBounds(map.getBounds(), 2));
    loadOnInitialization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markers = Array.from(projectMapItems?.data || [])?.map(
    (a: ProjectMap) =>
      a.lat && a.lng ? (
        <MemoizedMapMarker key={a.projectId} mapMarkerItem={a} />
      ) : null
  );

  const [ mapConfig, setMapConfig ] = useState<MapConfig>({ clusterIcons: true });

  return (
    <>
      <MapLoader loading={isLoading && configurationIsLoading} />
      <MapConfigOverlayLayer mapConfig={mapConfig} setMapConfig={setMapConfig} />

      {frontendConfiguration?.data ? (
        <>
          <MarkerClusterGroup
            key={Math.random().toFixed(2)}
            disableClusteringAtZoom={ mapConfig.clusterIcons ? null : 1 }
            maxClusterRadius={
              frontendConfiguration?.data?.attributes?.prepareVisitMaxClusterRadius || 80
            }
          >
            {markers}
          </MarkerClusterGroup>
        </>
      ) : null}
    </>
  );
};
