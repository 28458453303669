import { Link, useLocation, useNavigate } from "react-router-dom";
import Alert from "../alert";
import { Input } from "../../form-components/input";
import { ValidationMessage } from "../validation";
import { ProfilePicture } from "./profile-picture";
import { classNames } from "../../../shared/utils/class-names";
import { defaultButtonClasses, primaryButtonClasses } from "../../basics/buttons-classes";
import { PortraitUpload } from "../../../pages/user-management/portrait-upload";
import { toast } from "react-toastify";
import { Spinner } from "../../../common/spinner/base-spinner";
import { FieldValues, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useFetchUserQuery } from "../../../model/users/user-api";
import { useUpdatePersonalUserMutation } from "../../../model/users/personal-user-api";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { resetUser } from "../../../shared/auth/authentication";

export const UsernameEmailEditForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = getItemTransparent("user").id;
    const [image, setImage] = useState<string>();
    const [newImage, setNewImage] = useState<boolean>(false);
    const { data: serverUser, error: usersError, isFetching, refetch } =
        useFetchUserQuery({ id: userId, queryParams: { filters: {}, populate: ["image", "role"] } });
    const [updateUser, { status, error }] = useUpdatePersonalUserMutation();
    const user = serverUser ? { ...serverUser, role: serverUser?.role?.id } : undefined;
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({ values: user });

    useEffect(() => { refetch(); }, [location.key]);

    const doSubmit = async (data: FieldValues) => {

        try {
            let result: any;
            if (image) {
                result = await updateUser({ id: userId, ...data, image });
            } else {
                result = await updateUser({ id: userId, ...data });
            }
            if (result?.error) {
                toast.error("Es ist ein Fehler aufgetreten.");
            } else {
                toast.info("Nutzer erfolgreich aktualisiert.");
                setNewImage(false);
                await resetUser(userId);
                navigate('/me');
            }
        } catch (e) {
            console.error(e);
        }
    }

    if (isFetching) {
        return (
            <Spinner className="h-12 w-12 stroke-gray-500" />
        )
    }
    return (<form onSubmit={handleSubmit(doSubmit)}>
    {error && <Alert title="Es ist ein Fehler beim Anlegen des Nutzers aufgetreten" message={(error as any)?.data?.error?.message} />}
    <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
        <div className="mt-1 sm:mt-0 sm:col-span-2 grow w-full">
            <div className="flex items-center justify-start">
                {
                    user?.image && !newImage
                    && ( <div className="w-full flex flex-row items-center justify-start gap-4">
                        <ProfilePicture className="h-24 w-24" profilePicturePath={user?.image?.url} />
                        <button
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); setNewImage(!newImage); }} 
                            className={classNames(...defaultButtonClasses, "text-sm")}>
                                Bild ändern
                            </button>
                        </div>)
                }
                {(newImage || !user?.image) &&
                    <PortraitUpload onChange={(imageId: string) => (setImage(imageId))} />}
            </div>
        </div>
    </div>
    <div className="sm:items-start sm:pt-5">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="flex flex-col items-start">
                <label className="pl-2 pb-2">Nutzername</label>
                <Input
                    name="username"
                    placeholder="Nutzername *"
                    register={register}
                    validateRequired={{
                        required: "Bitte Nutzername eingeben",
                    }}
                    className={`w-full ${errors.username ? "border-red-800 placeholder-red-800" : ""
                        }`}
                />
            </div>
            {errors.username?.type === "required" && (
                <ValidationMessage message={errors.username?.message as string} />
            )}
        </div>
    </div>
    <div className="sm:items-start sm:pt-5">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="flex flex-col items-start">
                <label className="pl-2 pb-2">E-Mail</label>
                <Input
                    name="email"
                    placeholder="E-Mail *"
                    register={register}
                    validateRequired={{
                        required: "Bitte E-Mail eingeben",
                    }}
                    className={`w-full ${errors.email ? "border-red-800 placeholder-red-800" : ""
                        }`}
                />
                {errors.email?.type === "required" && (
                    <ValidationMessage message={errors.email?.message as string} />
                )}
            </div>
        </div>
    </div>
    <div className="flex justify-end mt-6">
        <button className={classNames(...primaryButtonClasses, "w-40 text-center")} {...register} >Speichern </button>
    </div>
</form>)
}