
import { FilterSelect } from "../filters/filter-select";
import { IUpdateField } from "./types";
import { useFetchRolesQuery } from "../../model/role/role-api";

export const RoleUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  error,
  className,
}) => {
  const { data, error: dataError, isFetching } =
  useFetchRolesQuery({ queryParams: {filters: { type: { $ne: "public"} } } });

  // Roles need to be filtered client side as the UserPlugin does not support filtering, as it seems.
  const roles = data?.roles?.filter(item => (!["Public", "Authenticated"].includes(item.name)));

  if (dataError) {
    <div className="text-red-800">
      Rollen konnten nicht geladen werden.
    </div>;
  }

  return (
    <FilterSelect
      placeholder={label || "Rolle"}
      selected={roles?.filter((item: any) => item.id === value)?.map((role: any) => { return { key: role.id, label: role.name } })?.[0]}
      onChange={(newVal) => {
        onUpdate(newVal?.key);
      }}
      items={roles?.map((role: any) => { return { key: role.id, label: role.name } } )}
      error={error}
      className={className}
    />
  );
};
