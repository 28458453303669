export const defaultInputClasses = [
  'border',
  'rounded-xl',
  'pl-4',
  'p-2',
  'leading-[18px]',
  'border-gray-300',
  'bg-gray-100',
  'shadow-sm',
  'focus:outline-none',
  'focus:border-green-500',
  'focus:ring-0'
];