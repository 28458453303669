import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { CLIENT_TYPE_QUERY, IClientType } from "../../model/client-type";
import { FilterSelect } from "../filters/filter-select";
import { IKeyValuePair } from "../../shared/utils/entity-utils";
import { IUpdateField } from "./types";

export const ClientTypeUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  error,
  className,
}) => {
  const [items, setItems] = useState<IKeyValuePair[]>([]);
  const {
    data: clientTypes,
    error: dataError,
  } = useQuery(CLIENT_TYPE_QUERY);

  useEffect(() => {
    const newItems = clientTypes?.clientTypes?.data?.map(
      (clientType: IClientType) => {
        return {
          key: clientType.id,
          label: clientType?.attributes?.label,
        };
      }
    );
    setItems(newItems);
  }, [clientTypes]);

  if (dataError) {
    <div className="text-red-800">
      Auftraggeberarten konnten nicht geladen werden.
    </div>;
  }

  return (
    <FilterSelect
      placeholder={label || "Auftraggeberart"}
      selected={items?.find((item: IKeyValuePair) => item.key === value)}
      onChange={(newVal) => {
        onUpdate(newVal?.key);
      }}
      items={items}
      error={error}
      className={className}
    />
  );
};
