import { gql } from "@apollo/client";

export type IInvoice = any;

export const invoiceTypeItems = [
  { key: "Schlussrechnung", label: "Schlussrechnung" },
  { key: "Reisekosten", label: "Reisekosten" },
  { key: "Teilkostenabrechnung", label: "Teilkostenabrechnung" },
  { key: "ZusAbrechnung", label: "Zusätzliche Abrechnung" },
];

export const invoiceTypeItemMap:any = invoiceTypeItems.reduce((acc, item) => {
  return {
    [item.key]: item.label,
    ...acc,
  }
}, {})

export const CREATE_INVOICE_QUERY = gql`
  mutation ($data: ProjectInvoicingInput!) {
    createProjectInvoicing(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_INVOICE_QUERY = gql`
  mutation ($id: ID!, $data: ProjectInvoicingInput!) {
    updateProjectInvoicing(id: $id, data: $data) {
      data {
        id
        attributes {
          type
        }
      }
    }
  }
`;

export const DELETE_INVOICE_QUERY = gql`
  mutation deleteProjectInvoicing($invoiceId: ID!) {
    deleteProjectInvoicing(id: $invoiceId) {
      data {
        id
      }
    }
  }
`;