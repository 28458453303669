export const researchFieldInfo: any = {
    "faktorNichtUmlagefaehigeBetriebskosten": {
        "type":"Number",
        "gqlType": "Float"
      },
      "faktorAnschaffungsnebenkosten": {
        "type":"Number",
        "gqlType": "Float"
      },
      "mainValuerName": {
        "type": "String"
      },
      "mainValuerId": {
        "type": "String"
      },
      "mainValuerDateOfInspection": {
        "type": "Date"
      },
      "coValuer1Name": {
        "type": "String"
      },
      "coValuer1ID": {
        "type": "String"
      },
      "coValuer1DateOfInspection": {
        "type": "Date"
      },
      "coValuer2Name": {
        "type": "String"
      },
      "coValuer2ID": {
        "type": "String"
      },
      "coValuer2DateOfInspection": {
        "type": "Date"
      },
      "rebaseType1": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_REBASETYPE1",
        "enum": [
          "Fondsgutachten",
          "Privatgutachten",
          "Gerichtsgutachten",
          "Fremdgutachten"
        ],
        "labelMap": {
            "Fondsgutachten": "Fondsgutachten",
            "Privatgutachten": "Privatgutachten",
            "Gerichtsgutachten": "Gerichtsgutachten",
            "Fremdgutachten": "Fremdgutachten",
        },
      },
      "rebaseType2": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_REBASETYPE2",
        "enum": [
          "A",
          "U",
          "E",
          "N",
          "V"
        ],
        "labelMap": {
            "A": "Ankaufsgutachten",
            "U": "Unbekannt",
            "E": "Erstbewertung",
            "N": "Nachbewertung",
            "V": "sonstige Verkehrswertgutachten",
        },
      },
      "additionalClientSpecificInformation": {
        "type": "String"
      },
      "rebaseObjAdditionalInformation": {
        "type": "String"
      },
      "DateOfAppraisal": {
        "type": "String",
      },
      "qualityDateOfAppraisal": {
        "type": "String"
      },
      "qualityDateOfAppraisalAsDate": {
        "type": "Date"
      },
      "currency": {
        "type": "String",
      },
      "exchangeRate1EUR": {
        "type": "Number",
        "gqlType": "Float",
      },
      "dateExchangeRate": {
        "type": "Date"
      },
      "mainTypeOfUse": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_MAINTYPEOFUSE",
        "enum": [
            "Buero",
            "Handel",
            "Industrie_Lager_Hallen",
            "Keller_Archiv",
            "Gastronomie",
            "Hotel",
            "Wohnen",
            "Freizeit",
            "Soziales",
            "GewerbeAllgemein",
            "Garage_TG",
            "Aussenstellplaetze",
            "unbekannt"
        ],
        "labelMap": {
            "Buero":"Büro",
            "Handel":"Handel",
            "Industrie_Lager_Hallen":"Industrie (Lager, Hallen)",
            "Keller_Archiv":"Keller/Archiv",
            "Gastronomie":"Gastronomie",
            "Hotel":"Hotel",
            "Wohnen":"Wohnen",
            "Freizeit":"Freizeit",
            "Soziales":"Soziales",
            "GewerbeAllgemein":"Gewerbe Allgemein",
            "Garage_TG":"Garage/TG",
            "Aussenstellplaetze": "Aussenstellplätze",
            "unbekannt": "unbekannt",
        },
      },
      "shareMainTypeOfUse": {
        "type": "Number",
        "gqlType": "Float",
      },
      "ancillaryTypeOfUse": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_ANCILLARYTYPEOFUSE",
        "enum": [
            "Buero",
            "Handel",
            "Industrie_Lager_Hallen",
            "Keller_Archiv",
            "Gastronomie",
            "Hotel",
            "Wohnen",
            "Freizeit",
            "Soziales",
            "GewerbeAllgemein",
            "Garage_TG",
            "Aussenstellplaetze",
            "unbekannt"
        ],
        "labelMap": {
            "Buero":"Büro",
            "Handel":"Handel",
            "Industrie_Lager_Hallen":"Industrie (Lager, Hallen)",
            "Keller_Archiv":"Keller/Archiv",
            "Gastronomie":"Gastronomie",
            "Hotel":"Hotel",
            "Wohnen":"Wohnen",
            "Freizeit":"Freizeit",
            "Soziales":"Soziales",
            "GewerbeAllgemein":"Gewerbe Allgemein",
            "Garage_TG":"Garage/TG",
            "Aussenstellplaetze": "Aussenstellplätze",
            "unbekannt": "unbekannt",
        },
      },
      "shareAncillaryTypeOfUse": {
        "type": "Number",
        "gqlType": "Float",
      },
      "typeOfOwnership": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_TYPEOFOWNERSHIP",
        "enum": [
          "TOO0",
          "TOO1",
          "TOO2",
          "TOO3",
          "TOO4",
          "TOO5",
          "TOO6"
        ],
        "labelMap": {
            "TOO0": "0=unbekannt",
            "TOO1": "1=Dingliches Nutzungsrecht",
            "TOO2": "2=Erbbaurecht",
            "TOO3": "3=gemischte Eigentumsform",
            "TOO4": "4=Teileigentum",
            "TOO5": "5=Volleigentum",
            "TOO6": "6=Volumeneigentum",
        }
      },
      "singleTenant": {
        "type": "Boolean",
      },
      "purchasePrice": {
        "type": "Number",
        "gqlType": "Float",
      },
      "dateOfPurchase": {
        "type": "Date"
      },
      "priceOfSale": {
        "type": "Number",
        "gqlType": "Float",
      },
      "dateOfSale": {
        "type": "Date"
      },
      "locationQuality": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_LOCATIONQUALITY",
        "enum": [
          "LQ1a",
          "LQ1b",
          "LQ2a",
          "LQ2b",
          "LQ3",
          "unbekannt"
        ],
        "labelMap": {
            "LQ1a":"1a",
            "LQ1b":"1b",
            "LQ2a":"2a",
            "LQ2b":"2b",
            "LQ3":"3",
            "unbekannt":"(unbekannt)"
        }
      },
      "structuralCondition": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_STRUCTURALCONDITION",
        "enum": [
          "sehr_gut",
          "gut",
          "durchschnittlich",
          "schlecht",
          "unbekannt"
        ],
        labelMap: {
            "sehr_gut": "sehr gut",
            "gut": "gut",
            "durchschnittlich": "durchschnittlich",
            "schlecht": "schlecht",
            "unbekannt": "(unbekannt)",
        }
      },
      "fitOutQuality": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_FITOUTQUALITY",
        "enum": [
          "stark_gehoben",
          "gehoben",
          "mittel",
          "einfach",
          "unbekannt"
        ],
        labelMap: {
          "stark_gehoben": "stark gehoben",
          "gehoben":"gehoben",
          "mittel":"mittel",
          "einfach":"einfach",
          "unbekannt":"(unbekannt)"
        }
      },
      "stateOfCompletion": {
        "type": "enumeration",
        "gqlType": "ENUM_PROJECTRESEARCH_STATEOFCOMPLETION",
        "enum": [
          "SOCF",
          "SOCI",
          "SOCP",
          "SOC0"
        ],
        labelMap: {
            "SOCF":"F",
            "SOCI":"I",
            "SOCP":"P",
            "SOC0":"0"
        }
      },
      "maintenanceBacklog": {
        "type": "Boolean"
      },
      "floors": {
        "type": "String"
      },
      "normalTotalEconomicLife": {
        "type": "Number",
        "gqlType": "Int",
      },
      "remainingEconomicLife": {
        "type": "Number",
        "gqlType": "Float",
      },
      "originalYearOfConstruction": {
        "type": "String",
      },
      "originalYearOfConstructionAsYearNumber": {
        "type": "Number",
        "gqlType": "Int"
      },
      "yearOfLatestMajorRenovation": {
        "type": "Number",
        "gqlType": "Int"
      },
      "calculatedYearOfConstruction": {
        "type": "Number",
        "gqlType": "Int"
      },
      "dateOfChangeForRemainingEconomicLife": {
        "type": "Date",
      },
      "landSize": {
        "type": "Number",
        "gqlType": "Float",
      },
      "floorToAreaRatio": {
        "type": "Number",
        "gqlType": "Float",
      },
      "siteCoverageRatio": {
        "type": "Number",
        "gqlType": "Float",
      },
      "grossFloorSpaceOverground": {
        "type": "Number",
        "gqlType": "Float",
      },
      "grossFloorSpaceBelowGround": {
        "type": "Number",
        "gqlType": "Float",
      },
      "totalGrossFloorSpace": {
        "type": "Number",
        "gqlType": "Float",
      },
      "totalRentableArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "totalIncomeRelevantRentableAreaForStatistics": {
        "type": "Number",
        "gqlType": "Float",
      },
      "runningCosts": {
        "type": "Number",
        "gqlType": "Float",
      },
      "managementCosts": {
        "type": "Number",
        "gqlType": "Float",
      },
      "maintenanceExpenses": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentAllowance": {
        "type": "Number",
        "gqlType": "Float",
      },
      "otherOperatingExpenses": {
        "type": "Number",
        "gqlType": "Float",
      },
      "capitalizationRate": {
        "type": "Number",
        "gqlType": "Float",
      },
      "valueByIncomeApproachWithoutPremiumsDiscounts": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiums": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsOverRents": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsUnderRents": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsRentConcession": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsImprovementCharges": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsTurnoverRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsTemporaryLettings": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsMallIncome": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsVATCompensation": {
        "type": "Number",
        "gqlType": "Float",
      },
      "discountsPremiumsTenantFitOutContribution": {
        "type": "Number",
        "gqlType": "Float",
      },
      "deductionForVacancy": {
        "type": "Number",
        "gqlType": "Float",
      },
      "deductionForVacancyLossOfRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "deductionForVacancyOperatingCosts": {
        "type": "Number",
        "gqlType": "Float",
      },
      "deductionConstructionWorks": {
        "type": "Number",
        "gqlType": "Float",
      },
      "deductionConstructionWorksRestructuringCosts": {
        "type": "Number",
        "gqlType": "Float",
      },
      "deductionConstructionWorksModernizationCosts": {
        "type": "Number",
        "gqlType": "Float",
      },
      "othersDiscountsPremiums": {
        "type": "Number",
        "gqlType": "Float",
      },
      "othersDiscountsPremiumsMarketAdjustment": {
        "type": "Number",
        "gqlType": "Float",
      },
      "othersDiscountsPremiumsOthers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "valueByIncomeApproach": {
        "type": "Number",
        "gqlType": "Float",
      },
      "costApproach": {
        "type": "Number",
        "gqlType": "Float",
      },
      "landValue": {
        "type": "Number",
        "gqlType": "Float",
      },
      "inCaseOfLeaseholdFictionalLandValueFullOwnership": {
        "type": "Number",
        "gqlType": "Float",
      },
      "marketValue": {
        "type": "Number",
        "gqlType": "Float",
      },
      "groundLease": {
        "type": "Boolean",
      },
      "remainingLifeOfGroundLease": {
        "type": "Number",
        "gqlType": "Float",
      },
      "groundRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "groundLeaseRemarks": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOfficeLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOfficeContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOfficeEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOfficeVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOfficeEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationRetailLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationRetailContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationRetailEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationRetailVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationRetailEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationStorageLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationStorageContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationStorageEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationStorageVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationStorageEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationArchiveLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationArchiveContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationArchiveEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationArchiveVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationArchiveEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationGastroLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationGastroContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationGastroEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationGastroVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationGastroEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationResidentialLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationResidentialContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationResidentialEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationResidentialVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationResidentialEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelEstimatedAnnualRentLet": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationHotelEstimatedAnnualRentVacant": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationLeisureLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationLeisureContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationLeisureEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationLeisureVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationLeisureEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialEstimatedAnnualRentLet": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationSocialEstimatedAnnualRentVacant": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationBusGenLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationBusGenContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationBusGenEstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationBusGenVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationBusGenEstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationInparkLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationInparkContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationInparkEstAnnRentForLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationInparkVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationInparkEstAnnRentForVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOutparkLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOutparkContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOutparkEstAnnRentForLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOutparkVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationOutparkEstAnnRentForVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea1LetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea1ContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea1EstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea1VacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea1EstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea2LetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea2ContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea2EstAnnRentForLetArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea2VacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscArea2EstAnnRentForVacArea": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers1LetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers1ContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers1EstAnnRentForLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers1VacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers1EstAnnRentForVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers2LetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers2ContractualAnnualRent": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers2EstAnnRentForLetNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers2VacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "rentalSituationMiscnumbers2EstAnnRentForVacantNumbers": {
        "type": "Number",
        "gqlType": "Float",
      },
      "arealUnit": {
        "type": "String",
      },
      "project": {
        "type": "relation",
        "relation": "oneToOne",
        "target": "api::project.project",
        "inversedBy": "project_research"
      },
      "valxml": {
        "type": "media",
        "multiple": false,
        "required": false,
        "allowedTypes": [
          "files"
        ]
      },
      "useForResearch": {
        "type": "Boolean"
      }
}
