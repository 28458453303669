import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { classNames } from '../../../shared/utils/class-names';
import { ProfileShort } from './profile-short';
import { Link } from 'react-router-dom';

export const ProfileDropdown = ({ userNavigation, user } : any) => {

    return (
    <Menu as="div" className="ml-3 relative">
      <Menu.Button className='outline-none'>
        <ProfileShort />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-[10003] focus:outline-none">
          {userNavigation
          ?.filter((item: any) => ( !item?.roles || item?.roles?.includes(user.role?.type) ))
          ?.map((item: any) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Link
                  to={item.href}
                  onClick={item.action}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};