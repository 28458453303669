import { XCircleIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../shared/utils/class-names'

interface IAlertColorMap {
  [key: string]: {
    background: string,
    icon: string,
    title: string,
    message: string
  }
}

const colorMap : IAlertColorMap = {
  error: {
    background: "bg-red-50",
    icon: "text-red-400",
    title: "text-red-800",
    message: "text-red-700"
  },
  info: {
    background: "bg-gray-100",
    icon: "text-gray-400",
    title: "text-gray-800",
    message: "text-gray-700"
  }
}
export interface IAlert {
  title: string,
  severity?: string,
  message?: string
}

export default function Alert({ title, message, severity = "error" }: IAlert) {
  return (
    <div className={classNames("rounded-md p-4", colorMap?.[severity]?.background)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className={classNames("h-5 w-5", colorMap?.[severity]?.icon)} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={classNames("text-sm font-medium", colorMap?.[severity]?.title)}>{title}</h3>
          <div className={classNames("mt-2 text-sm", colorMap?.[severity]?.message)}>
            {message}
          </div>
        </div>
      </div>
    </div>
  )
}