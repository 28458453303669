import { Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useRef, useState } from 'react';

interface ISearchButton {
  isShowing: boolean,
  handleSearchClick: Function,
  handleClose: Function,
  handleChange: Function
}

export const SearchButton = ({ isShowing, handleSearchClick, handleClose, handleChange }: ISearchButton) => {
  const searchInput = useRef<null | HTMLInputElement>(null);
  const [query, setQuery] = useState('');

  return (
    <div className="flex flex-row mx-2">
        <div
          className="text-white cursor-pointer w-6 h-6"
          onClick={() => { handleSearchClick(); }}
        >
          <Transition
            show={!isShowing}
            as={Fragment}
            enter="delay-100 transition transform ease-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <MagnifyingGlassIcon />
          </Transition>
        </div>
        <Transition
          show={isShowing}
          as={Fragment}
          enter="transform ease-in duration-100 transition"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform transition ease-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 w-0"
          afterEnter={() => { searchInput?.current?.focus(); }}
        >
          <div className="max-w-sm w-48 ring-1 ring-green-500 ring-opacity-5 overflow-hidden focus:ring-2 focus:ring-green-300">
            <div className="relative">
              <MagnifyingGlassIcon
                className="absolute top-1.5 left-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                ref={searchInput}
                onChange={(e) => { setQuery(e.target.value); handleChange(e.target.value); }}
                className="h-8 rounded-lg w-full border-0 bg-white pl-8 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm focus:outline-none"
                placeholder="Suchen..."
                value={query}
              />
              <XCircleIcon
                className="absolute top-2 right-2 h-4 w-4 cursor-pointer text-gray-600"
                aria-hidden="true"
                onClick={() => {handleClose();}}
              />
            </div>
          </div>
        </Transition>
      </div>
  )
}