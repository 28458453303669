import React, {
  forwardRef,
  Fragment,
  LegacyRef,
  ReactNode,
  Ref,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Popover, Transition } from "@headlessui/react";
import { TooltipContext } from "./tooltip-manager";
import { useOutsideAlerter } from "./outsideClickHandler";
import { classNames } from "../../shared/utils/class-names";

const TooltipInner = forwardRef(
  ({ open, children, toggle, tooltipContent, align="right" }: any, ref) => {
    return (
      <Popover
        as="div"
        ref={ref as Ref<HTMLElement>}
        className="relative leading-3 font-sans text-gray-900"
      >
        <Popover.Button
          onClick={toggle}
          className="inline-flex justify-center w-full rounded-md text-sm font-medium text-gray-700 hover:text-green-500 focus:outline-none focus:ring-0 bg-transparent"
        >
          {children}
        </Popover.Button>

        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel
            static
            className={classNames("absolute mt-2 max-w-[25rem] min-w-[15rem] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[1000] bg-white p-3", align==="right" ? "origin-top-right right-0" :  "origin-top-left left-0")}
          >
            <div className="py-1 leading-5">{tooltipContent}</div>
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  }
);

export const Tooltip = ({ id, children, tooltipContent, align="right" }: any) => {
  const tooltipContext = useContext(TooltipContext);
  const tooltip = useRef();
  useOutsideAlerter(tooltip, () => {
    if (tooltipContext.isOpen(id)) {
      tooltipContext.toggleTooltip(id);
    }
  });

  useEffect(() => {
    if (tooltipContext) {
      tooltipContext.addTooltip({ id, open: false });
    }
  }, [id]);

  return (
    <TooltipInner
      ref={tooltip}
      open={tooltipContext.isOpen(id)}
      toggle={() => {
        tooltipContext.toggleTooltip(id);
      }}
      tooltipContent={tooltipContent}
      align={align}
    >
      {children}
    </TooltipInner>
  );
};
