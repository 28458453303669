import { Icon, latLng, latLngBounds, point } from "leaflet";
import { useEffect } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Link } from "react-router-dom";
import { ProjectMap } from "../../../model/project-map/project-map-model";
import { MapMarkerNumberItem } from "./layer/map-marker-number-item";
import { selectEditing } from "./layer/polygon-picker-slice";
import { useAppSelector } from "../../../hooks";

export function calcBoundingBox(addresses: any) {
  let x0: number | null = null,
    y0: number | null = null,
    x1: number | null = null,
    y1: number | null = null;
  addresses.forEach((address: any) => {
    x0 = (x0 || Number.MAX_VALUE) < address.lng ? x0 : address.lng;
    y0 = (y0 || Number.MAX_VALUE) < address.lat ? y0 : address.lat;
    x1 = (x1 || 0) > address.lng ? x1 : address.lng;
    y1 = (y1 || 0) > address.lat ? y1 : address.lat;
  });
  if (x0 && x1 && y0 && y1) {
    return latLngBounds(latLng(y0, x0), latLng(y1, x1));
  } else {
    return COLOGNE_DEFAULT;
  }
}

const COLOGNE_DEFAULT = latLngBounds(
  latLng([50.7955, 6.7693]),
  latLng([51.1197, 7.1651])
);

export const ResearchMapLayer = ({
  clusterIcons = true,
  clusterRadius,
  projectMapItems = [],
}: {
  clusterIcons: boolean,
  clusterRadius: number;
  projectMapItems: ProjectMap[];
}) => {
  const map = useMap();
  const editing = useAppSelector(selectEditing);

  useEffect(() => {
    if(!editing) {
      map.fitBounds(calcBoundingBox(projectMapItems));
    }
  }, [projectMapItems, map]);

  return (
    // The random key is needed to force rerender on changed map config (problem with the library)
    <MarkerClusterGroup key={Math.random().toFixed(2)} maxClusterRadius={clusterRadius} disableClusteringAtZoom={ clusterIcons ? null : 1 } >
      {Array.from(projectMapItems)?.map((a: ProjectMap) =>
        a.lat && a.lng ? (
          <Marker
            key={a?.projectId}
            position={latLng(a.lat, a.lng)}
            icon={
              new Icon({
                iconUrl: `/icons/map_${a?.objectType}.svg`,
                iconSize: [30, 30],
                iconAnchor: [15, 15],
              })
            }
            zIndexOffset={2000}
          >
            <Popup
              autoPan
              position={latLng(a.lat, a.lng)}
              offset={point([0, 0])}
              closeButton={false}
              maxWidth={380}
              maxHeight={475}
            >
              <Link to={`/projects/${a?.projectId}`} target="_blank">
                <MapMarkerNumberItem projectId={a?.projectId} />
              </Link>
            </Popup>
          </Marker>
        ) : null
      )}
    </MarkerClusterGroup>
  );
};