import React, { useEffect } from 'react';

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { classNames } from '../../shared/utils/class-names';
import { statusMap } from '../../common/status-map';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

export const StatusDropdown = ({ id, value, refetch }: any) => {
  const [selected, setSelected] = useState(value);

  const updateMutation = gql`
  mutation UpdateStatusInProject($value: ENUM_PROJECT_STATUS) {
    updateProject(id: ${id}, data: { status: $value }) {
      data {
        id
      }
    }
  }
`;

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const [update, { data, loading, error }] = useMutation(updateMutation);


  const updateOnServer = async (value: string) => {
    await update({variables: {value}})
    refetch();
    toast.info('Erfolgreich aktualisiert');
  }

  return (
    <Listbox value={selected} onChange={(val) => {setSelected(val); updateOnServer(val); }}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button style={{ backgroundColor: statusMap?.[selected]?.color }} className={classNames('text-white uppercase font-semibold relative w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm')}>
              <span className="block truncate">{statusMap?.[selected]?.displayString}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-[1000] mt-1 w-full bg-green-300 text-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {Object.keys(statusMap).map((statusKey:string) => (
                  <Listbox.Option
                    key={statusKey}
                    style={{
                      backgroundColor: statusMap[statusKey].color
                    }}
                    className={({ active }) =>
                      classNames(
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={statusKey}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate text-white')}>
                          {statusMap[statusKey]?.displayString}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}