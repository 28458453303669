import { FieldValues, useForm } from "react-hook-form";
import { Input } from "../../form-components/input";
import { toast } from "react-toastify";
import { getItemTransparent, setItemTransparent } from "../../../shared/utils/storage-utils";
import Alert from "../alert";
import { useNavigate } from "react-router-dom";
import { ValidationMessage } from "../validation";
import { ChangePassword, useChangePasswordMutation } from "../../../model/users/personal-user-api";
import { classNames } from "../../../shared/utils/class-names";
import { primaryButtonClasses } from "../../basics/buttons-classes";
import { resetUser } from "../../../shared/auth/authentication";

export const ChangePasswordForm = () => {

    const userId = getItemTransparent("user")?.id;

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, },
    } = useForm<ChangePassword>();
    const [changePassword, { status, error }] = useChangePasswordMutation();

    const doSubmit = async (data: FieldValues) => {

        try {
            let result: any = await changePassword({ ...data } as ChangePassword);
            if ((result)?.error) {
                toast.error("Es ist ein Fehler aufgetreten.");
            } else {
                if (result?.data?.jwt) {
                    setItemTransparent("jwt", result.data.jwt);
                }
                await resetUser(userId);
                navigate("/me");
                reset();
                toast.info("Passwort erfolgreich neu gesetzt.");
            }
        } catch (e) {
            console.error(e);
        }
    }

    if (!userId) {
        return <Alert title="Schwerwiegender Fehler" message="Ihr Nutzer ist nicht korrekt geladen. Bitte melden Sie sich neu an." />
    }

    return <form onSubmit={handleSubmit(doSubmit)}>
        <div>
            {error && <Alert title="Beim Ändern des Passworts ist ein Fehler aufgetreten" message={(error as any)?.data?.error?.message} />}
        </div>
        <div className="mt-2 mb-2">
            <Input
                className={`w-full text-base ${errors.currentPassword
                        ? "border-red-800 focus:border-red-800 focus:ring-red-800 placeholder-red-800"
                        : ""
                    }`}
                placeholder="Aktuelles Passwort"
                type="password"
                register={register}
                minLength={8}
                validateRequired={{
                    required: "Bitte Passwort eingeben",
                }}
                pattern={{
                    value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
                    message:
                        "Bitte mindestens 8 Zeichen und eines dieser Sonderzeichen verwenden: !@#$%^&+=",
                }}
                name="currentPassword"
            />
            {errors.currentPassword?.type === "required" && (
                <ValidationMessage message="Bitte altes Passwort eingeben" />
            )}
            {errors.currentPassword?.type === "pattern" && (
                <ValidationMessage message={errors.currentPassword?.message as string} />
            )}
        </div>
        <div className="mt-2 mb-2">
            <Input
                className={`w-full text-base ${errors.password
                        ? "border-red-800 focus:border-red-800 focus:ring-red-800 placeholder-red-800"
                        : ""
                    }`}
                placeholder="Neues Passwort"
                type="password"
                register={register}
                minLength={8}
                validateRequired={{
                    required: "Bitte neues Passwort eingeben",
                }}
                pattern={{
                    value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
                    message:
                        "Bitte mindestens 8 Zeichen und eines dieser Sonderzeichen verwenden: !@#$%^&+=",
                }}
                name="password"
            />
            {errors.password?.type === "required" && (
                <ValidationMessage message="Bitte altes Passwort eingeben" />
            )}
            {errors.password?.type === "pattern" && (
                <ValidationMessage message={errors.password?.message as string} />
            )}
        </div>
        <div className="mt-2 mb-2">
            <Input
                className={`w-full text-base ${errors.passwordConfirmation
                        ? "border-red-800 focus:border-red-800 focus:ring-red-800 placeholder-red-800"
                        : ""
                    }`}
                placeholder="Neues Passwort wiederholen"
                type="password"
                register={register}
                minLength={8}
                validateRequired={{
                    required: "Bitte neues Passwort wiederholen",
                }}
                pattern={{
                    value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
                    message:
                        "Bitte mindestens 8 Zeichen und eines dieser Sonderzeichen verwenden: !@#$%^&+=",
                }}
                name="passwordConfirmation"
            />
            {errors.passwordConfirmation?.type === "required" && (
                <ValidationMessage message="Bitte neues Passwort wiederholen" />
            )}
            {errors.passwordConfirmation?.type === "pattern" && (
                <ValidationMessage message={errors.passwordConfirmation?.message as string} />
            )}
        </div>
        <div className="flex justify-end mt-6">
            <button className={classNames(...primaryButtonClasses, "w-40 text-center")} {...register} >Speichern </button>
        </div>
    </form>
}