import React, { useEffect, useState } from "react";
import { IUpdateField, IUpdateFieldProps } from "./types";

export const BooleanUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  loading
}: IUpdateFieldProps<string>) => {
  const [newFieldValue, setNewFieldValue] = useState(value);

  useEffect(() => {
    setNewFieldValue(value);
  }, [value]);

  return (
    <div className="grid grid-cols-2 w-full">
      <div>
        <input
          id="ja"
          name="Ja"
          type="checkbox"
          className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded mr-2"
          checked={!!newFieldValue}
          onChange={() => {
            onUpdate(true);
          }}
        />
        <label htmlFor="ja" className="text-sm text-gray-900">
          Ja
        </label>
      </div>
      <div>
        <input
          id="nein"
          name="Nein"
          type="checkbox"
          className="focus:ring-orange-500 h-4 w-4 text-orange-500 border-gray-300 rounded mr-2"
          checked={!newFieldValue}
          onChange={() => {
            onUpdate(false);
          }}
        />
        <label htmlFor="nein" className="text-sm text-gray-900">
          Nein
        </label>
      </div>
    </div>
  );
};
