import React, { ReactNode } from "react";
import { classNames } from "../../../../shared/utils/class-names";
import { formatBigNumber } from "../../../../shared/utils/formatters";

export interface IMetric {
  name: string;
  number: number | string;
  icon: ReactNode;
  className?: string,
}

export const Tile = ({ name, number, icon, className = 'bg-green-500'}: IMetric) => {
  return (
    <div className="relative">
      <div className="absolute w-full">
        <div className={classNames(className, "mx-auto text-white rounded-xl p-4 h-12 w-12")}>
          {icon}
        </div>
      </div>
      <div className={classNames("rounded-3xl bg-white p-4 mt-4 pt-4 text-center h-40 print:border print:border-gray-50")}>
        <h2 className="text-4xl font-special font-semibold mt-8 mb-4">{typeof number === 'number' ? formatBigNumber(number, false) : !number ? '0': number}</h2>
        <p>{name}</p>
      </div>
    </div>
  );
};
