import { useMutation } from "@apollo/client";
import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { primaryButtonClasses } from "../../../components/basics/buttons-classes";
import { Input } from "../../../components/form-components/input";
import { InputCurrencyControlled } from "../../../components/form-components/input-currency-controlled";
import { InputDateControlled } from "../../../components/form-components/input-date-controlled";
import { InvoiceTypeInput } from "../../../components/form-components/invoice-type-input";
import { ValidationMessage } from "../../../components/layout/validation";
import { IAddress } from "../../../model/address";
import {
  CREATE_INVOICE_QUERY,
  IInvoice,
  invoiceTypeItems,
  UPDATE_INVOICE_QUERY,
} from "../../../model/invoice";
import { classNames } from "../../../shared/utils/class-names";
import { IKeyValuePair } from "../../../shared/utils/entity-utils";

export interface IInvoiceForm {
  projectId: string;
  invoice: IInvoice;
  onClose: Function;
  address: IAddress;
}

export const InvoiceForm = ({
  projectId,
  address,
  invoice,
  onClose,
}: IInvoiceForm) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const invoiceDate = watch("outgoingInvoiceDate");

  const [
    createInvoiceOnServer,
    { loading: createInvoiceLoading, error: createInvoiceError },
  ] = useMutation(CREATE_INVOICE_QUERY);

  const [
    updateInvoiceOnServer,
    { loading: updateInvoiceLoading, error: updateInvoiceError },
  ] = useMutation(UPDATE_INVOICE_QUERY);

  const [invoiceType, setInvoiceType] = useState<IKeyValuePair | null>(null);

  const createInvoice = async (projectId: string, data: any) => {
    await createInvoiceOnServer({
      variables: {
        data: {
          project: projectId,
          ...data,
          type: data?.type?.key,
          street: address?.attributes?.street,
          streetNumber: address?.attributes?.streetNumber,
          zip: address?.attributes?.zip,
          city: address?.attributes?.city,
          country: address?.attributes?.country
        },
      },
    });
  };

  const updateInvoice = async (data: FieldValues) => {
    let updateData: any = {
      ...data,
      receipts: [],
      type: data?.type?.key,
      project: projectId,
    };
    delete updateData["__typename"];
    await updateInvoiceOnServer({
      variables: {
        id: invoice.id,
        data: {
          ...updateData,
        },
      },
    });
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      if (!invoice) {
        await createInvoice(projectId, data);
        onClose();
      } else {
        await updateInvoice(data);
        onClose();
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (invoice) {
      reset({
        ...invoice?.attributes,
        type: invoiceTypeItems.find(
          (iti) => invoice?.attributes?.type === iti.key
        ),
      });
    }
  }, [reset, invoice]);

  useEffect(() => {
    if (invoiceDate && !invoice?.attributes?.periodOfPayment) {
      setValue(
        "periodOfPayment",
        dayjs(invoiceDate).add(4, "weeks").format("YYYY-MM-DD")
      );
    }
  }, [invoiceDate, setValue]);

  const labelClasses = "ml-1 text-gray-500 uppercase";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={classNames(
          "grid grid-flow-row xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 text-sm"
        )}
      >
        <div className="pt-1 sm:mt-5 sm:col-span-2">
          <label className={classNames(labelClasses)}>Rechnungsart *</label>
          <InvoiceTypeInput
            name="type"
            placeholder="Rechnungsart *"
            control={control}
            validateRequired={{
              required: "Rechnungsnummer bitte angeben",
            }}
            className={`w-full text-sm ${
              errors.type ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.type?.type === "required" && (
            <ValidationMessage message={errors.type?.message as string} />
          )}
        </div>
        <div></div>
        <div></div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Rechnungsnummer *</label>
          <Input
            name="invoiceNumber"
            placeholder="Rechnungsnummer *"
            register={register}
            validateRequired={{
              required: "Rechnungsnummer bitte angeben",
            }}
            className={`w-full text-sm ${
              errors.invoiceNumber ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.invoiceNumber?.type === "required" && (
            <ValidationMessage message={errors.invoiceNumber?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Rechnungsdatum *</label>
          <InputDateControlled
            name="outgoingInvoiceDate"
            placeholder="Rechnungsdatum *"
            control={control}
            validateRequired={{
              required: "Rechnungsdatum bitte angeben",
            }}
            className={`w-full text-sm ${
              errors.outgoingInvoiceDate
                ? "border-red-800 placeholder-red-800"
                : ""
            }`}
          />
          {errors.outgoingInvoiceDate?.type === "required" && (
            <ValidationMessage message={errors.outgoingInvoiceDate?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Leistungsdatum *</label>
          <InputDateControlled
            name="fullfilmentDate"
            placeholder="Leistungsdatum *"
            control={control}
            validateRequired={{
              required: "Leistungsdatum bitte angeben",
            }}
            className={`w-full text-sm ${
              errors.fullfilmentDate ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.fullfilmentDate?.type === "required" && (
            <ValidationMessage message={errors.fullfilmentDate?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Zahlungsziel</label>
          <InputDateControlled
            name="periodOfPayment"
            placeholder="Zahlungsziel"
            control={control}
            className={`w-full text-sm ${
              errors.periodOfPayment ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.periodOfPayment?.type === "required" && (
            <ValidationMessage message={errors.periodOfPayment?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Netto-Honorar *</label>
          <InputCurrencyControlled
            name="netIncome"
            placeholder="Netto-Honorar *"
            control={control}
            validateRequired={{
              required: "Netto-Honorar bitte angeben",
            }}
            className={`w-full text-sm ${
              errors.netIncome ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.netIncome?.type === "required" && (
            <ValidationMessage message={errors.netIncome?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Auslagen</label>
          <InputCurrencyControlled
            name="expenses"
            placeholder="Auslagen *"
            control={control}
            className={`w-full text-sm ${
              errors.expenses ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.expenses?.type === "required" && (
            <ValidationMessage message={errors.expenses?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Umsatzsteuer</label>
          <InputCurrencyControlled
            name="vat"
            placeholder="Umsatzsteuer *"
            control={control}
            className={`w-full text-sm ${
              errors.vat ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.vat?.type === "required" && (
            <ValidationMessage message={errors.vat?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Gesamtbetrag *</label>
          <InputCurrencyControlled
            name="grossInvoiceAmount"
            placeholder="Gesamtrechnungsbetrag *"
            control={control}
            validateRequired={{
              required: "Gesamtrechnungsbetrag bitte angeben",
            }}
            className={`w-full text-sm ${
              errors.grossInvoiceAmount
                ? "border-red-800 placeholder-red-800"
                : ""
            }`}
          />
          {errors.grossInvoiceAmount?.type === "required" && (
            <ValidationMessage message={errors.grossInvoiceAmount?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Zahlungserinnerung</label>
          <InputDateControlled
            name="paymentReminder"
            placeholder="Zahlungserinnerung"
            control={control}
            className={`w-full text-sm ${
              errors.paymentReminder ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.paymentReminder?.type === "required" && (
            <ValidationMessage message={errors.paymentReminder?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>1. Mahnung</label>
          <InputDateControlled
            name="lateNotice1"
            placeholder="1. Mahnung"
            control={control}
            className={`w-full text-sm ${
              errors.lateNotice1 ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.lateNotice1?.type === "required" && (
            <ValidationMessage message={errors.lateNotice1?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>2. Mahnung</label>
          <InputDateControlled
            name="lateNotice2"
            placeholder="2. Mahnung"
            control={control}
            className={`w-full text-sm ${
              errors.lateNotice2 ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.lateNotice2?.type === "required" && (
            <ValidationMessage message={errors.lateNotice2?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses)}>Zahlungseingang</label>
          <InputDateControlled
            name="paymentReceived"
            placeholder="Zahlungseingang"
            control={control}
            className={`w-full text-sm ${
              errors.paymentReceived ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.paymentReceived?.type === "required" && (
            <ValidationMessage message={errors.paymentReceived?.message as string} />
          )}
        </div>
        <div className="pt-1 sm:mt-5 sm:col-span-2 md:col-span-1">
          <label className={classNames(labelClasses, "block")}>
            VAT ID Rechnungsempfänger
          </label>
          <Input
            name="vatId"
            placeholder="DE1234567890"
            register={register}
            className={`w-full text-sm ${
              errors.vatId ? "border-red-800 placeholder-red-800" : ""
            }`}
          />
          {errors.vatId?.type === "required" && (
            <ValidationMessage message={errors.vatId?.message as string} />
          )}
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <button type="submit" className={classNames(...primaryButtonClasses, "w-40")}>
            Speichern
          </button>
        </div>
      </div>
    </form>
  );
};
