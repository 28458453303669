import React from "react";
import {formatBigNumber} from "../shared/utils/formatters";

export const CalculatedItem = ({label, value1, value2, unit1, unit2, className}: any) => {
    return (
        <div className="flex flex-col">
          <div
            className={"text-sm uppercase text-gray-500 flex flex-row group items-center" + (className ? " " + className : "")}>
            {label}
          </div>
            <div className="mt-1 flex rounded-md text-sm">
                {value1 ? `${formatBigNumber(value1)} ${unit1}` : " - "}<br />
                {value2 ? `${formatBigNumber(value2)} ${unit2}` : null }
            </div>
        </div>
    )
}
