import { useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";
import { primaryButtonClasses } from "../../../components/basics/buttons-classes";
import { Card } from "../../../components/basics/card";
import { DeleteWithPrompt } from "../../../components/delete-with-prompt/delete-with-prompt";
import { cumulateInvoiceStatus } from "../../../components/layout/invoice-table/flatten-invoice-data";
import { formatInvoiceStatus } from "../../../components/layout/invoice-table/invoice-status-formatter";
import { DaysToDeadline } from "../../../components/layout/days-to-deadline";
import { IAddress } from "../../../model/address";
import { DELETE_INVOICE_QUERY, IInvoice, invoiceTypeItemMap } from "../../../model/invoice";
import { classNames } from "../../../shared/utils/class-names";
import { dateFormatter } from "../../../shared/utils/date-utils";
import { formatCurrency } from "../../../shared/utils/formatters";
import { EditInvoiceModal } from "./edit-invoice-modal";

export interface IInvoiceEditor {
  projectId: string;
  address: IAddress;
  invoices: Array<any>;
  reload: Function;
}

export const InvoiceEditor = ({
  projectId,
  address,
  invoices,
  reload,
}: IInvoiceEditor) => {
  const [deleteInvoice, { error: errorDelete, loading: deleting }] =
    useMutation(DELETE_INVOICE_QUERY);

  const [editorModalOpen, setEditorModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<
    IInvoice | undefined
  >();

  return (
    <>
      <Card className="bg-green-100 mt-6">
        <h2 className="uppercase text-lg font-semibold">Rechnungen</h2>

        <div className={classNames("mt-3")}>
          <div
            className={classNames("grid mb-3 text-sm")}
            style={{
              gridTemplateColumns: "5% 16% 11% 11% 11% 11% 16% 11% 4% 4%",
            }}
          >
            <div></div>
            <div>Rechnungsart</div>
            <div>RgNr</div>
            <div>Summe</div>
            <div>Auslagen</div>
            <div>RE-Datum</div>
            <div>Zahlungsziel</div>
            <div>Zlg.Eing.</div>
            <div>Akt.</div>
          </div>
          {invoices?.map((invoice: any, index: number) => (
            <div
              key={`row-${index}`}
              className={classNames(
                "bg-white rounded-2xl grid mb-3 items-center py-3 text-sm text-gray-500"
              )}
              style={{
                gridTemplateColumns: "5% 16% 11% 11% 11% 11% 16% 11% 4% 4%",
              }}
            >
              <div className="px-2 ml-1">
                {formatInvoiceStatus(
                  cumulateInvoiceStatus(
                    dayjs(invoice?.attributes?.outgoingInvoiceDate),
                    dayjs(invoice?.attributes?.paymentReceived),
                    dayjs(invoice?.attributes?.paymentReminder),
                    dayjs(invoice?.attributes?.lateNotice1),
                    dayjs(invoice?.attributes?.lateNotice2)
                  )
                )}
              </div>
              <div className="truncate">{invoiceTypeItemMap?.[invoice?.attributes?.type]}</div>
              <div className="">{invoice?.attributes?.invoiceNumber}</div>

              <div className="">
                {formatCurrency(invoice?.attributes?.grossInvoiceAmount)}
              </div>
              <div className="">
                {formatCurrency(invoice?.attributes?.expenses)}
              </div>
              <div className="">
                {dateFormatter(invoice?.attributes?.outgoingInvoiceDate)}
              </div>
              <div className="px-1 lg:w-28">
                <DaysToDeadline
                  deadline={invoice?.attributes?.periodOfPayment}
                />
              </div>
              <div className="">
                {dateFormatter(invoice?.attributes?.paymentReceived)}
              </div>
              <div
                className="cursor-pointer flex flex-row justify-center"
                onClick={() => {
                  setSelectedInvoice(invoice);
                  setEditorModalOpen(true);
                }}
              >
                <PencilIcon className="w-5" />
              </div>
              <div
                className="cursor-pointer flex flex-row justify-center"
                onClick={() => {
                  // prevent expansion
                }}
              >
                {" "}
                {invoices.length > 1 ? (
                  <DeleteWithPrompt
                    text={"Wollen Sie die Rechnung wirklich entfernen?"}
                    cancelButtonText="Abbrechen"
                    confirmButtonText="Löschen"
                    onClick={async (e) => {
                      try {
                        await deleteInvoice({
                          variables: { invoiceId: invoice?.id },
                        });
                        reload();
                      } catch (e) {
                        toast.error("Kann nicht gelöscht werden.");
                      }
                    }}
                  >
                    <TrashIcon className="w-5" />
                  </DeleteWithPrompt>
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className="w-full text-right mb-3">
          <button
            className={classNames(...primaryButtonClasses, "w-40 text-sm")}
            onClick={() => {
              setSelectedInvoice(null);
              setEditorModalOpen(true);
            }}
          >
            + Weitere Rechnung
          </button>
        </div>
        <EditInvoiceModal
          projectId={projectId}
          address={address}
          invoice={selectedInvoice}
          onClose={async () => {
            await reload();
            setEditorModalOpen(false);
          }}
          open={editorModalOpen}
        />
      </Card>
    </>
  );
};
