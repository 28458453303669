import { Icon, latLng, point } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { IAddress } from "../../../../model/address";

export const AddressMapLayer = ({
  address,
  buildingType,
}: {
  address: IAddress;
  buildingType: string;
}) => {
  return address?.attributes?.lat && address?.attributes?.lng ? (
    <Marker
      position={[address.attributes.lat, address.attributes.lng]}
      icon={
        new Icon({
          iconUrl: `/icons/map_${buildingType}.svg`,
          iconSize: [30, 30],
          iconAnchor: [15, 15],
          className: "text-orange-500",
        })
      }
    >
      <Popup
        position={latLng(address.attributes.lat, address.attributes.lng)}
        offset={point([0, 0])}
        closeButton={false}
        maxWidth={380}
      >
        {address?.attributes?.street} {address?.attributes?.streetNumber}
        <br />
        {address?.attributes?.zip} {address?.attributes?.city}
      </Popup>
    </Marker>
  ) : null;
};

export default AddressMapLayer;