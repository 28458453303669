import axios from 'axios';
import React from 'react';
import { getItemTransparent } from '../../shared/utils/storage-utils';
import { getLoginUrl } from '../../shared/utils/url-utils';

export class FetchSurroundingProjectIds extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      prev: null,
      next: null
    }
  }

  updateData = async () => {
    const response = await axios.get(`${getLoginUrl()}/api/fetch-surrounding-projects/${this.props.displayId}`, {
      headers: {
        Authorization: "Bearer " + getItemTransparent("JWT"),
      },
    });
    this.setState({
      prev: response?.data?.prev,
      next: response?.data?.next,
    });
  }

  async componentDidMount() {
    this.updateData();
  }

  async componentDidUpdate(prevProps: any) {
    if(prevProps.displayId !== this.props.displayId) {
      await this.updateData();
    }
  }

  render() {
    const { children } = this.props;
    return <>
      {this.props.children
      ? (children as Function)(this.state)
      : null}
    </>
  }
}