import { gql } from '@apollo/client';

// TODO: Migrate to Redux
export const ME_QUERY = gql`
query {
  me {
      id
        username
        email
        role {
          id
          name
        }
  			image {
          data {
            attributes {
              url
            }
          }
        }
      }
}
`
export interface RoleMe {
  id: number,
  name: string
}
export interface ProfileMe {
  id: number,
  username: string,
  email: string,
  role: RoleMe,
  image: {
    data: {
      id: number
      attributes: {
        url: string
      }
    }
  }
}