import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useQueryParams} from "use-query-params";
import {contractorKeyValuePairs} from "../../common/contractor-map";
import {projectTypeKeyValuePairs} from "../../common/project-type-map";
import {statusKeyValuePairs} from "../../common/status-map";
import {MultiFilterSelect} from "../../components/filters/multi-filter-select";
import {generateYears, getCurrentYear} from "../../components/project-list/project-utils";
import {IKeyValuePair} from "../../shared/utils/entity-utils";
import {ClientTypeSelect} from "../../components/filters/client-type-select";
import {CoAppraiserSelect} from "../../components/filters/coappraiser-select";
import {CustomerMultiSelect} from "../../components/filters/customer-select";
import {EmployeesSelect} from "../../components/filters/employee-dropdown/employee-select";
import {FilterSelect} from "../../components/filters/filter-select";
import {StatusSelect} from "../../components/filters/status-select";
import {projectListQueryParams} from "./project-list-query-params";
import {HideHistorySelect} from "../../components/filters/hide-history-select";
import {
  PROJECT_LIST_APPRAISER_FILTER,
  PROJECT_LIST_CLIENT_TYPE_FILTER,
  PROJECT_LIST_CONTRACTOR_FILTER,
  PROJECT_LIST_CUSTOMER_FILTER,
  PROJECT_LIST_EMPLOYEE_FILTER,
  PROJECT_LIST_HIDE_HISTORY,
  PROJECT_LIST_STATUS_FILTER,
  PROJECT_LIST_TYPE_FILTER,
  PROJECT_LIST_YEARS_FILTER
} from "../../components/filters/project-filter";
import {compareArray, compareArrayWithKeyValuePairArrayByKey, extractKey} from "../../common/compare-helpers";

export const ProjectListFilterBar = () => {
  const yearList = generateYears();
  const projectTypes = projectTypeKeyValuePairs;
  const [status, setStatus] = useState<IKeyValuePair[]>([]);
  const [selectedProjectTypes, setSelectedProjectTypes] = useState<IKeyValuePair[]>([]);
  const [selectedClientTypes, setSelectedClientTypes] = useState<(number | null)[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<(number | null)[]>([]);
  const [contractor, setContractor] = useState<IKeyValuePair | undefined | null>();
  const [employees, setEmployees] = useState<(string)[]>([]);
  const [appraiser, setAppraiser] = useState<boolean | null>(null);
  const [years, setYears] = useState<string[]>([getCurrentYear().key]);
  const [history, setHistory] = useState<boolean | null>(null);
  const [searchParams, setSearchParams] = useQueryParams(projectListQueryParams);

  useEffect(() => {
    if( !compareArrayWithKeyValuePairArrayByKey(searchParams[PROJECT_LIST_STATUS_FILTER], status) ) {
      setStatus(statusKeyValuePairs.filter(s => (searchParams[PROJECT_LIST_STATUS_FILTER]?.includes(s.key))));
    }
    if( !compareArray( searchParams[PROJECT_LIST_CLIENT_TYPE_FILTER], selectedClientTypes ) ) {
      setSelectedClientTypes(searchParams[PROJECT_LIST_CLIENT_TYPE_FILTER] || []);
    }
    if( !compareArray( searchParams[PROJECT_LIST_CUSTOMER_FILTER], selectedCustomers ) ) {
      setSelectedCustomers(searchParams[PROJECT_LIST_CUSTOMER_FILTER] || []);
    }
    if( !compareArrayWithKeyValuePairArrayByKey(searchParams[PROJECT_LIST_TYPE_FILTER], selectedProjectTypes) ) {
      setSelectedProjectTypes(projectTypeKeyValuePairs.filter(p => (searchParams[PROJECT_LIST_TYPE_FILTER]?.includes(p.key))) || []);
    }
    if( !(searchParams[PROJECT_LIST_CONTRACTOR_FILTER] === contractor?.key)) {
      setContractor(contractorKeyValuePairs.find(c => searchParams[PROJECT_LIST_CONTRACTOR_FILTER]?.includes(c.key)));
    }
    if( !compareArray(searchParams[PROJECT_LIST_EMPLOYEE_FILTER], employees)) {
      setEmployees((searchParams[PROJECT_LIST_EMPLOYEE_FILTER] || []) as string[]);
    }
    if( !(appraiser === searchParams[PROJECT_LIST_APPRAISER_FILTER]) ) {
      setAppraiser(searchParams[PROJECT_LIST_APPRAISER_FILTER] as boolean);
    }
    if( !compareArray(searchParams[PROJECT_LIST_YEARS_FILTER], years)) {
      setYears((searchParams[PROJECT_LIST_YEARS_FILTER] || []) as string[]);
    }
    if( ! history === searchParams[PROJECT_LIST_HIDE_HISTORY]) {
      setHistory(searchParams[PROJECT_LIST_HIDE_HISTORY] as boolean);
    }
  }, [searchParams])

  return (
    <div className="mb-4 invisible h-0 lg:h-full lg:visible lg:grid lg:grid-cols-[9%_12%_10%_10%_10%_12%_6%_6%_1%_17%] gap-[0.5%] flex-wrap items-center">
        <MultiFilterSelect
          placeholder='Jahr'
          selected={yearList.filter(y => (years.includes(y.key)))}
          onChange={(val: IKeyValuePair[]) => {
            if(val) {
              if(val.length === 0) {
                toast.info("Es muss immer ein Jahr ausgewählt sein.")
              } else {
                setYears(val.map(extractKey));
                setSearchParams({
                  ...searchParams,
                  [PROJECT_LIST_YEARS_FILTER]: val.map(extractKey)
                });
              }
            }
          }}
          items={yearList}
        />
        <StatusSelect
          selected={status}
          onChange={(value) => {
            setStatus(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_STATUS_FILTER]: value.map(extractKey)
            });
          }}
          items={statusKeyValuePairs}
        />
        <MultiFilterSelect
          placeholder='Auftragsart'
          selected={selectedProjectTypes}
          onChange={(value) => {
            setSelectedProjectTypes(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_TYPE_FILTER]: value.map(extractKey)
            });
          }}
          items={projectTypes}
        />
        <FilterSelect
          placeholder='Auftragnehmer'
          selected={contractor}
          onChange={(value) => {
            setContractor(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_CONTRACTOR_FILTER]: value?.key
            });
          }}
          items={contractorKeyValuePairs}
        />
        <ClientTypeSelect
          selected={selectedClientTypes}
          onChange={(value) => {
            setSelectedClientTypes(value.map(extractKey).map(v => parseInt(v)));
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_CLIENT_TYPE_FILTER]: value?.map(extractKey).map(v => parseInt(v))
            });
          }}
        />
        <CustomerMultiSelect
          selected={selectedCustomers}
          onChange={(value) => {
            setSelectedCustomers(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_CUSTOMER_FILTER]: value
            });
          }}
        />
        <CoAppraiserSelect
          placeholder='NG?'
          value={appraiser}
          onChange={(value) => {
            setAppraiser(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_APPRAISER_FILTER]: value
            });
          }} />
        <HideHistorySelect
          placeholder='Alle'
          value={history}
          onChange={(value) => {
            setHistory(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_HIDE_HISTORY]: value
            });
          }} />
        <div className="grow"></div>
        <EmployeesSelect
          selected={employees}
          onChange={(value) => {
            setEmployees(value);
            setSearchParams({
              ...searchParams,
              [PROJECT_LIST_EMPLOYEE_FILTER]: value
            });
          }}
        />
      </div>
  )
}
