import { BellIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { MouseEventHandler } from "react";
import { notificationTypeMap } from "../../../common/notification-type-map";
import { INotification } from "../../../model/notifications";
import { classNames } from "../../../shared/utils/class-names";

export interface INotificationListItem {
  notification: INotification,
  onClick: MouseEventHandler,
  onMarkReadClick: MouseEventHandler
}

export const NotificationListItem = ({ notification, onClick, onMarkReadClick }: INotificationListItem) => {
  const item = notification?.attributes;
  const icon = item?.type
    ? notificationTypeMap?.[item.type]?.icon
    : <BellIcon className='w-6 h-6 stroke-white' />;
  return (
    <div
      key={notification.id}
      className={classNames(
        item?.read ? "bg-white hover:bg-gray-50" : "bg-[#EEE]",
        "p-3 flex hover:bg-gray-50 items-start rounded-xl  transition ease-in-out duration-150 relative"
      )}
    >
      <div
        className="flex-shrink-0 flex items-center justify-center h-8 w-10 rounded-full text-white sm:h-12 sm:w-12"
        style={{ backgroundColor: notificationTypeMap?.[item.type]?.bgColor || "#999" }}
      >
        {icon || <BellIcon className='w-6 h-6 stroke-white' />}
      </div>
      <button
        className="ml-4 pt-1 pr-4 block text-left cursor-pointer hover:underline"
        onClick={onClick}
      >
        <p className="text-base text-gray-900">
          <span className="font-bold">{item?.title}</span>
          <span> {item?.message}</span>
        </p>
        <p className="font-sans text-xs text-gray-500">
          {dayjs(item?.createdAt).locale("de").fromNow(false)}
        </p>
        {item.read ? null : (
          <div
            className="w-5 h-5 absolute z-[1000] top-2 right-2 hover:bg-gray-300 rounded-full cursor-pointer border-gray-500 hover-strikethrough"
            onClick={onMarkReadClick}
          >
            <BellIcon className="fill-orange-500 stroke-orange-500" />
          </div>
        )}
      </button>
    </div>
  );
};
