import React from "react";
import { classNames } from "../../shared/utils/class-names";
import { getLoginUrl } from "../../shared/utils/url-utils";
import {AuthImg} from "../layout/auth-img";
import { Link } from "react-router-dom";
import { HistoryMarker } from "../history-marker";
import { CalculatedItem } from "../calculated-item";
import { ProjectStatus } from "../layout/project-status";
import { BuildingType } from "../layout/building-type";
import { calcBruttoAnfangsrendite, calcFaktorAktuell, calcFaktorMarkt, calcNettoAnfangsrendite, calcWert, calcWertPerQm } from "../../pages/project/research/research-calc";
import { useFetchOneProjectResearchQuery } from "../../model/project-research/project-research-api";

const projectHasImage = (project: any) => {
  return !!project?.attributes?.images?.data?.[0]?.attributes?.url;
};

export interface IProjectListItem {
  project: any
  currentProjectId?: string
}

export const ProjectNumberListItem = ({ project, currentProjectId }: IProjectListItem) => {

  const projectResearchData = project?.attributes?.project_research;

  return (
    <Link
      key={project?.id}
      to={`/projects/${project.id}`}
      className="focus:outline-none"
    >
      <div className={classNames("relative grid grid-cols-[10%_20%_70%] lg:grid-cols-[10%_20%_70%] gap-[1%] rounded-lg border border-gray-300 px-5 py-3 shadow-sm space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-600 mb-3", project?.attributes?.rests && 'striped', currentProjectId && project?.id === parseInt(currentProjectId) ? 'bg-[rgb(216,224,215)]' : 'bg-white')}>
        <div className="invisible lg:visible flex-shrink-0">
          {projectHasImage(project) ? (
            <AuthImg
              className="h-24 rounded-xl w-28"
              src={`${getLoginUrl()}${
                project?.attributes?.images?.data?.[0]?.attributes?.url
              }`}
              size="thumbnail"
            />
          ) : (
            <div className="h-full rounded-xl bg-gray-300 w-full bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url("/placeholder.png")' }}>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between">
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex flex-row items-center mb-1">
            {project?.attributes?.successor?.data?.id || project?.attributes?.predecessor?.data?.id ? <HistoryMarker className="w-8 h-[1.8em] mr-1 rounded" /> : null}
            <ProjectStatus status={project?.attributes?.status} />
            <BuildingType type={project?.attributes?.building?.data?.attributes?.type} />
          </div>
          <div>
            <p className="text-lg font-special font-semibold text-gray-900 truncate leading-5">
              {project?.attributes?.building?.data?.attributes?.name}
            </p>
            <p className="text-sm leading-[0.85rem] font-sans text-gray-500 truncate">
              {project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.street} {project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.streetNumber} <br />
              {project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.city}
            </p>
          </div>
          <p className="text-sm text-gray-600">
            <strong>#{project?.attributes?.displayId}</strong>
          </p>
        </div>
        <div className="font-special grid grid-cols-5 gap-y-4">
        <CalculatedItem label="Verkehrswert" value1={calcWert(projectResearchData, "VKW")} value2={calcWertPerQm(projectResearchData, "VKW")} unit1="€" unit2="€/m²" />
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(projectResearchData, "VKW")} unit1="%" />
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(projectResearchData, "VKW")} unit1="%" />
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(projectResearchData, "VKW")} unit1="" />
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(projectResearchData, "VKW")} unit1="" />

          <CalculatedItem label="Kaufpreis" value1={calcWert(projectResearchData, "KP")} value2={calcWertPerQm(projectResearchData, "KP")} unit1="€" unit2="€/m²" />
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(projectResearchData, "KP")} unit1="%" />
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(projectResearchData, "KP")} unit1="%" />
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(projectResearchData, "KP")} unit1="" />
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(projectResearchData, "KP")} unit1="" />
        </div>
      </div>
    </Link>
  );
};
