import React, { useEffect, useState } from "react";
import {gql, useQuery} from "@apollo/client";
import { Combobox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ICustomer,
} from "../../model/customer";
import { classNames } from "../../shared/utils/class-names";
import { toast } from "react-toastify";
import axios from "axios";
import { getLoginUrl } from "../../shared/utils/url-utils";
import { getItemTransparent } from "../../shared/utils/storage-utils";

// TODO: Move to REST
export const REGULAR_CUSTOMER_QUERY = gql`
  query {
    customers (pagination: { limit: 1000 }, filters: { regularCustomer: { eq: true } }) {
      data {
        id
        attributes {
          name
          regularCustomer
          client_type {
            data {
              id
              attributes {
                label
              }
            }
          }
        }
      }
    }
  }
`;

export const CustomerSearchDropDown = ({
  onChange,
  selected,
  error,
  className = "",
}: any) => {
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const {
    data: customers,
  } = useQuery(REGULAR_CUSTOMER_QUERY);

  const [needle, setNeedle] = useState("");

  useEffect(() => {
    if (selectedCustomer && !(selectedCustomer === 'Neuen Auftraggeber anlegen')) {
      onChange(selectedCustomer);
    }
  }, [selectedCustomer, onChange]);

  const getOrCreateCustomer = async (needle: String) => {
    try {
      const result = await axios.post(
        `${getLoginUrl()}/api/customers/get-or-create`,
        { data: { needle } },
        {
          headers: {
            Authorization: "Bearer " + getItemTransparent("JWT"),
          },
        }
      );
      if (result?.data?.id) {
        return result?.data;
      }
    } catch (e) {
      throw Error("Neuer Auftraggeber konnte nicht angelegt werden.");
    }
  };

  // TODO: Has a small glitch, that leads to double save.
  const handleNonRecurringCustomer = async (e: any) => {
    if (needle && needle !== "") {
      try {
        const nonRecurringCustomer = await getOrCreateCustomer(needle);
        setSelectedCustomer({ id: nonRecurringCustomer.id, attributes: { ...nonRecurringCustomer }});
        toast.info("Erfolgreich angelegt");
      } catch (e: any) {
        toast.error(
          `Ein Fehler ist beim Erstellen des Auftraggebers aufgetreten. (${e?.message})`
        );
      }
    } else {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Combobox as="div" value={selected} onChange={onChange}>
      <div className="relative mt-1 shadow-sm">
        <Combobox.Input
          className={classNames(
            className,
            "cursor-text relative w-full rounded-xl border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-0 transition ease-in-out duration-150 sm:leading-5",
            !error
              ? " focus:border-green-500"
              : "focus:shadow-outline-red focus:border-red-800"
          )}
          autoComplete="off"
          placeholder="Auftraggeber *"
          onChange={(event) => {
            setNeedle(event.target.value);
          }}
          displayValue={(customer: ICustomer) => customer?.attributes?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronDownIcon
            className="h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-[1000] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {customers?.customers?.data?.length > 0 &&
            [
              ...customers?.customers?.data
                .filter(
                  (customer: ICustomer) => customer?.attributes?.regularCustomer
                )
                .filter((customer: ICustomer) =>
                  customer?.attributes?.name
                    ?.toLowerCase()
                    ?.includes(needle?.toLowerCase())
                ),
              ...(selectedCustomer ? [selectedCustomer] : []),
            ]
              .sort((customerA: any, customerB: any) => {
                return ("" + customerA?.attributes?.name).localeCompare(
                  customerB?.attributes?.name
                );
              })
              .map((customer: any) => (
                <Combobox.Option
                  key={customer?.id}
                  value={customer}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-green-500 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <div className="flex">
                      <span
                        className={classNames(
                          "truncate",
                          selected ? "font-semibold" : ""
                        )}
                      >
                        {customer?.attributes?.name}
                      </span>
                    </div>
                  )}
                </Combobox.Option>
              ))}
          <Combobox.Option
            key={"new_customer"}
            value={"Neuen Auftraggeber anlegen"}
            className={({ active }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-3 pr-9",
                active ? "bg-green-500 text-white" : "text-gray-900"
              )
            }
            onClick={handleNonRecurringCustomer}
          >
            {({ active, selected }) => (
              <div className="flex">
                <span
                  className={classNames(
                    "truncate italic hover:font-semibold",
                    selected ? "font-semibold" : ""
                  )}
                >
                  Neuen Auftraggeber anlegen
                </span>
              </div>
            )}
          </Combobox.Option>
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
