import React from "react";

export const WohnobjekteIcon = ({ color = 'black' }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.wohnobjekte-${color}-cls-1{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;}`}</style>
    </defs>
    <line className={`wohnobjekte-${color}-cls-1`} x1=".35" y1="28.94" x2="29.35" y2="28.94" />
    <g>
      <rect className={`wohnobjekte-${color}-cls-1`} x="6.77" y="24.19" width="4.27" height="4.69" />
      <rect className={`wohnobjekte-${color}-cls-1`} x="5.06" y="9.41" width="14.87" height="19.47" />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="13.94"
        y="24.39"
        width="4.27"
        height="2.24"
        transform="translate(32.16 51.02) rotate(180)"
      />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="6.76"
        y="20.06"
        width="4.27"
        height="2.24"
        transform="translate(17.8 42.36) rotate(180)"
      />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="13.94"
        y="20"
        width="4.27"
        height="2.24"
        transform="translate(32.16 42.25) rotate(180)"
      />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="13.94"
        y="15.62"
        width="4.27"
        height="2.24"
        transform="translate(32.16 33.48) rotate(180)"
      />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="6.77"
        y="15.62"
        width="4.27"
        height="2.24"
        transform="translate(17.81 33.48) rotate(180)"
      />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="6.76"
        y="11.39"
        width="4.27"
        height="2.24"
        transform="translate(17.8 25.03) rotate(180)"
      />
      <rect
        className={`wohnobjekte-${color}-cls-1`}
        x="13.94"
        y="11.39"
        width="4.27"
        height="2.24"
        transform="translate(32.15 25.03) rotate(180)"
      />
      <polygon
        className={`wohnobjekte-${color}-cls-1`}
        points="21.03 9.41 3.94 9.41 5.92 3.87 19.02 3.87 21.03 9.41"
      />
      <g>
        <line className={`wohnobjekte-${color}-cls-1`} x1="24.81" y1="28.65" x2="24.81" y2="23.73" />
        <path
          className={`wohnobjekte-${color}-cls-1`}
          d="M24.24,20.11l-1.54,3.77c-.35,.86,.28,1.79,1.2,1.79h1.79c.92,0,1.55-.94,1.2-1.79l-1.54-3.77c-.21-.5-.92-.5-1.13,0Z"
        />
      </g>
    </g>
  </svg>
);
