import { MapPinIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { currentUserMay } from "../../../common/role-management";
import { statusMap } from "../../../common/status-map";
import { Card } from "../../../components/basics/card";
import { ProfilePicture } from "../../../components/layout/profiles/profile-picture";
import { StatusDropdown } from "../../../components/layout/status-dropdown";
import { MagicUpdate } from "../../../components/magic-update/magic-update";
import { BuildingType } from "../../../components/layout/building-type";
import { classNames } from "../../../shared/utils/class-names";
import { EditorSidebarEdit } from "./editor-sidebar-edit";
import { Notes } from "./notes";
import { SidebarCompletionStatus } from "./sidebar-completion-status";
import { SidebarEmployeeDropdown } from "./sidebar-employee-dropdown";
import { SidebarPicture } from "./SidebarPicture";
import { Button } from "../../../components/basics/button";
import { DuplicateModal } from "./duplicate-modal";
import { open as openNewProjectModal } from "../../../components/layout/new-project/new-project-reducer";
import { useDispatch } from "react-redux";
import { buildNewProjectValuesFromProject, buildNewProjectValuesHistoryFromProject } from "../../../model/project/project-helper";

export const Sidebar = ({ project, refetch }: any) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  return (
     <section
      aria-labelledby="timeline-title"
      className={classNames('lg:col-start-4 lg:col-span-1')}
    >
      <Card className={classNames("mb-4 bg-white", project?.project?.data?.attributes?.rests ? 'striped' : '')}>
        <SidebarPicture
          url={
            project?.project?.data?.attributes?.images?.data?.[0]?.attributes
              ?.url
          }
        />
        <div className="grid grid-cols-[25%_75%] mt-4">
          <div className="flex">
            <MapPinIcon className="text-orange-500 w-12 h-10" />
          </div>
          <div className="flex-2 flex flex-col items-start justify-between">
            <p className="text-sm uppercase text-gray-500 semibold tracking-wide">
              Standort
            </p>
            <p>
              {
                project?.project?.data?.attributes?.building?.data?.attributes
                  ?.address?.data?.attributes?.city
              }
              ,{" "}
              {
                project?.project?.data?.attributes?.building?.data?.attributes
                  ?.address?.data?.attributes?.street
              }
            </p>
          </div>
        </div>
      </Card>
      <Card className={classNames("mb-4 bg-white", project?.project?.data?.attributes?.rests ? 'striped' : '')}>
        <div className="grid grid-cols-[60%_15%] gap-2 items-center">
          <StatusDropdown
            id={project?.project?.data?.id}
            value={project?.project?.data?.attributes?.status}
            refetch={refetch}
          />
          <BuildingType
            type={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.type
            }
          />
        </div>
        <div className='mt-4 mb-6'>
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={refetch}
            type="BooleanButton"
            gqlType='Boolean'
            field="rests"
            extra={{
              buttonTextTrue: 'Auftrag weiter bearbeiten',
              buttonTextFalse: 'Auftrag pausieren'
            }}
            className='text-sm w-full'
            label=""
            value={
              project?.project?.data?.attributes?.rests
            }
          />
          {currentUserMay("createProjects") ?
          <DuplicateModal
            onClickHistory={()=> { 
              dispatch(openNewProjectModal({ ...buildNewProjectValuesHistoryFromProject(project?.project?.data), predecessor: project?.project?.data?.id, predecessorDisplayId: project?.project?.data?.attributes?.displayId }))
            }}
            onClickDuplicate={() => { dispatch(openNewProjectModal(buildNewProjectValuesFromProject(project?.project?.data))) }}
          >
          <Button primary>
            Auftrag duplizieren
          </Button>
          </DuplicateModal> : null }
        </div>
        <div className="mt-4 mb-6">
          <p className="text-sm uppercase text-gray-500 semibold tracking-wide">
            Fortschritt
          </p>
          <SidebarCompletionStatus
            projectStatus={
              statusMap?.[project?.project?.data?.attributes?.status]
            }
          />
        </div>
        <div className="w-3/4">
          <MagicUpdate
            model="contact"
            id={project?.project?.data?.attributes?.contact?.data?.id}
            reload={refetch}
            type="String"
            field="name"
            label="Kontaktperson"
            labelGap="mt-1"
            value={
              project?.project?.data?.attributes?.contact?.data?.attributes
                ?.name
            }
          />
          <MagicUpdate
            model="contact"
            id={project?.project?.data?.attributes?.contact?.data?.id}
            reload={refetch}
            type="String"
            field="phone"
            label="Telefon"
            labelGap="mt-1"
            value={
              project?.project?.data?.attributes?.contact?.data?.attributes
                ?.phone
            }
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={refetch}
            type="Date"
            field="deadlineDate"
            label="Stichtag"
            value={project?.project?.data?.attributes?.deadlineDate}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={refetch}
            type="Date"
            field="servicelevel"
            label="Servicelevel"
            labelGap="mt-1"
            value={project?.project?.data?.attributes?.servicelevel}
          />
          <MagicUpdate
            model="contact"
            id={project?.project?.data?.attributes?.contact?.data?.id}
            reload={refetch}
            type="String"
            field="email"
            label="E-Mail"
            labelGap="mt-1"
            value={
              project?.project?.data?.attributes?.contact?.data?.attributes
                ?.email
            }
          />
        </div>
        <div className="mt-4 mb-6">
          <p className="text-sm uppercase text-gray-500 semibold tracking-wide mb-1">
            Bearbeiter
          </p>
          {currentUserMay("editEditor") ? <EditorSidebarEdit project={project} refetch={refetch} /> : (<div className='flex flex-row gap-2'>
            <ProfilePicture
              profilePicturePath={
                project?.project?.data?.attributes?.editor?.data?.attributes
                  ?.image?.data?.attributes?.url
              }
            />
            <ProfilePicture
              profilePicturePath={
                project?.project?.data?.attributes?.editor2?.data?.attributes
                  ?.image?.data?.attributes?.url
              }
            /></div>
          )}
        </div>
        <div className="mt-4 mb-6">
          <p className="text-sm uppercase text-gray-500 semibold tracking-wide mb-1">
            Gutachter
          </p>
          {currentUserMay("editAppraiser") ? (
            <SidebarEmployeeDropdown
              model="project"
              id={project?.project?.data?.id}
              refetch={refetch}
              type="ID"
              field="appraiser"
              label="Gutachter"
              labelGap="mt-1"
              filter={(employee: any) => employee?.attributes?.isAppraiser}
              value={project?.project?.data?.attributes?.appraiser}
            />
          ) : (
            <ProfilePicture
              profilePicturePath={
                project?.project?.data?.attributes?.appraiser?.data?.attributes
                  ?.image?.data?.attributes?.url
              }
            />
          )}
        </div>
      </Card>
      <div className={classNames("bg-white px-2 py-5 shadow sm:rounded-xl sm:px-4",project?.project?.data?.attributes?.rests ? 'striped' : '')}>
        <Notes
          notes={project?.project?.data?.attributes?.notes}
          projectId={project?.project?.data?.id}
          refetch={refetch}
        />
      </div>
    </section>
  );
};
