import React from "react";
import { Controller } from "react-hook-form";
import { classNames } from "../../shared/utils/class-names";
import { defaultInputClasses } from "../basics/input-classes";

import { DateUpdateField } from "../magic-update/date-update-field";
import { InputDate } from "./input-date";

export interface IInputDateControlled extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type?: string;
  placeholder?: string;
  control ?: any;
  validateRequired?: object;
  pattern?: any;
  name: string;
}

export const InputDateControlled = ({
  children,
  name,
  validateRequired,
  control,
  pattern,
  className,
  ...rest
}: IInputDateControlled) => (
  <Controller
    control={control}
    name={name}
    rules={{
      ...validateRequired
    }}
    render={({
      field: { onChange, value },
      fieldState: { error },
      formState,
    }) => {
      return (
        <InputDate
          value={value}
          onUpdate={onChange}
          className={classNames(...defaultInputClasses, className)}
          { ...rest }
        />
      );
    }}
  />
);
