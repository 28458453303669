import { gql, useQuery } from "@apollo/client";
import { Popover, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";
import { Spinner } from "../../../common/spinner/base-spinner";
import Alert from "../../layout/alert";
import { classNames } from "../../../shared/utils/class-names";
import { EmployeeSearchItem } from "./employee-search-item";

const EMPLOYEE_QUERY = gql`
  query ($needle: String) {
    usersPermissionsUsers(
      filters: { username: { contains: $needle } }
      pagination: { limit: 100 }
    ) {
      data {
        id
        attributes {
          username
          isAppraiser
          isEditor
          email
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const identityFn = (a: any) => a;

export const EmployeeDropDown = ({
  isOpen,
  close,
  onChange,
  selected,
  children,
  filter,
}: any) => {
  const {
    data: employees,
    error,
    loading,
    refetch,
  } = useQuery(EMPLOYEE_QUERY, {
    variables: {
      needle: "",
    },
  });

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="group rounded-md inline-flex items-center text-sm">
            {children}
          </Popover.Button>

          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="absolute z-[20000] left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
            >
              <div className="rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 max-h-[600px] overflow-y-scroll">
                <div className="relative border-b border-gray-300 bg-white text-gray-700 cursor-input pointer-events-none">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={searchTerm}
                    id="search"
                    onChange={(e) => {
                      if (
                        e.target.value.length > 1 ||
                        e.target.value.length === 0
                      ) {
                        refetch({ needle: e.target.value });
                      }
                      setSearchTerm(e.target.value);
                    }}
                    className="block w-full pl-10 sm:text-sm md:text-md font-weight-500 border-none pointer-events-auto focus:border-gray-500 focus:shadow-none focus:ring-gray-500"
                    placeholder="Suche"
                    autoComplete="off"
                  />
                  <div
                    className="searchcancel absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-auto"
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (searchTerm) {
                        setSearchTerm("");
                        refetch({ needle: "" });
                      } else {
                        close();
                      }
                    }}
                  >
                    <XMarkIcon
                      className={classNames(
                        searchTerm
                          ? "bg-gray-500 rounded-full text-white p-1"
                          : "text-gray-400",
                        "searchcancel h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 pointer-events-auto">
                  {error ? (
                    <Alert
                      title="Ein Fehler ist aufgetreten"
                      message="Es konnten keine Daten vom Server geholt werden."
                    />
                  ) : null}
                  {loading ? (
                    <div className="flex flex-row items-center justify-center w-full">
                      <Spinner className="w-8 h-8 stroke-gray-500" />
                    </div>
                  ) : null}
                  {employees?.usersPermissionsUsers?.data
                    ?.filter(filter || identityFn)
                    ?.map((employee: any) => (
                      <div
                        key={employee.id}
                        onClick={() => {
                          if (selected?.includes(employee.id)) {
                            onChange([]);
                          } else {
                            onChange([employee.id]);
                          }
                          close();
                        }}
                        className={classNames(
                          selected?.includes(employee.id)
                            ? "bg-green-200 hover:bg-green-100"
                            : "",
                          "-m-3 p-3 block rounded-xl hover:bg-green-100 transition ease-in-out duration-150"
                        )}
                      >
                        <EmployeeSearchItem
                          key={employee.id}
                          employee={employee}
                        />
                      </div>
                    ))}
                  {employees?.usersPermissionsUsers?.data?.filter(
                    filter || identityFn
                  )?.length === 0 ? (
                    <span className="text-gray-400">
                      Kein Mitarbeiter gefunden
                    </span>
                  ) : null}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
