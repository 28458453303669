import React from "react";
import Alert from "../../../../components/layout/alert";
import { Spinner } from "../../../../common/spinner/base-spinner";
import { ProjectStatus } from "../../../../components/layout/project-status";
import { BuildingType } from "../../../../components/layout/building-type";
import { AuthImg } from "../../../../components/layout/auth-img";
import { getLoginUrl } from "../../../../shared/utils/url-utils";
import { useFetchOneQuery } from "../../../../model/project/project-api";
import { CalculatedItem } from "../../../../components/calculated-item";
import { AvatarHead } from "../../../../components/basics/avatar-head";
import { calcBruttoAnfangsrendite, calcFaktorAktuell, calcFaktorMarkt, calcNettoAnfangsrendite, calcWert, calcWertPerQm } from "../../../project/research/research-calc";


// TODO: Refactor - devide between Layout and Data loading

export const MapMarkerNumberItem = ({ projectId }: { projectId: number }) => {
  const {
    data: projectData,
    error,
    isLoading,
  } = useFetchOneQuery({
    id: projectId,
    queryParams: {
      populate: ["building", "images", "editor", "editor.image", "appraiser", "appraiser.image", "customer", "project_research"],
    },
  });

  if (!projectId || error) {
    return (
      <Alert
        title="Details konnten nicht geladen werden"
        message="Laden Sie die Seite erneut"
      />
    );
  }

  if (isLoading) {
    return (
      <div className="w-[380px] h-[475px] flex flex-row justify-center">
        <Spinner className="h-6 w-6 stroke-gray-500" />
      </div>
    );
  }

  const project = projectData?.data;
  const projectResearchData = project?.attributes?.project_research;

  return (
    <div key={project?.id} className="w-[380px] h-[410px]">
      <div className="flex flex-row font-sans">
        <div className="w-[280px]">
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex flex-row items-center mb-2">
            <ProjectStatus status={project?.attributes?.status} />
            <BuildingType
              type={project?.attributes?.building?.data?.attributes?.type}
            />
          </div>
          <div className="w-full">
            <h3 className="text-lg font-special font-semibold text-gray-700 mb-2 truncate mr-4">
              {project?.attributes?.building?.data?.attributes?.name}
            </h3>
            <div className="text-base text-gray-500">
              <strong className="font-semibold">
                #{project?.attributes?.displayId}
              </strong>
            </div>
          </div>
        </div>
        <div className="w-[100px]">
          <div className="invisible lg:visible">
            {project?.attributes?.images?.data?.length > 0 ? (
              <AuthImg
                className="rounded-xl h-20 w-[100px]"
                src={`${getLoginUrl()}${project?.attributes?.images?.data?.[0]?.attributes?.url
                  }`}
                size={"thumbnail"}
              />
            ) : (
              <div className="rounded-xl h-20 bg-gray-500 w-[100px]">
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="font-special text-gray-800 grid grid-cols-2 gap-4 mt-4">
        <div className="flex flex-col justify-between h-[260px] bg-gray-100 rounded p-3">
          <CalculatedItem label="Verkehrswert" value1={calcWert(projectResearchData, "VKW")} value2={calcWertPerQm(projectResearchData, "VKW")} unit1="€" unit2="€/m²" />
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(projectResearchData, "VKW")} unit1="%" />
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(projectResearchData, "VKW")} unit1="%" />
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(projectResearchData, "VKW")} unit1="" />
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(projectResearchData, "VKW")} unit1="" />
        </div>
        <div className="flex flex-col justify-between h-[260px] bg-gray-100 rounded p-3">
          <CalculatedItem label="Kaufpreis" value1={calcWert(projectResearchData, "KP")} value2={calcWertPerQm(projectResearchData, "KP")} unit1="€" unit2="€/m²" />
          <CalculatedItem label="Brutto-Anfangsrendite" value1={calcBruttoAnfangsrendite(projectResearchData, "KP")} unit1="%" />
          <CalculatedItem label="Netto-Anfangsrendite" value1={calcNettoAnfangsrendite(projectResearchData, "KP")} unit1="%" />
          <CalculatedItem label="Faktor Aktuell" value1={calcFaktorAktuell(projectResearchData, "KP")} unit1="" />
          <CalculatedItem label="Faktor Marktüblich" value1={calcFaktorMarkt(projectResearchData, "KP")} unit1="" />
        </div>
      </div>
      <div className="flex flex-row gap-2 mt-4">
        <AvatarHead user={project?.attributes?.editor} />
        <AvatarHead user={project?.attributes?.appraiser} />
      </div>
    </div>
  );
};
