import { Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { CogIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { MapConfig } from "../../model/map-config";
import { PgToggle } from "../layout/toggle";

export const MapConfigOverlayLayer = ({
  mapConfig,
  setMapConfig,
}: {
  mapConfig: MapConfig;
  setMapConfig: React.Dispatch<React.SetStateAction<MapConfig>>;
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(!open);
        }}
        className="absolute z-[2002] -right-2 w-12 h-10 top-2 rounded-xl p-1 bg-gray-100 flex flex-row items-center"
      >
        <CogIcon className="text-gray-600 p-1 h-8" />
      </button>
      <Transition.Root show={open}>
        <div
          className={`absolute z-[2001] right-0 h-full flex flex-col mt-2`}
        >
          <Transition.Child
            enter="transform transition ease-in-out duration-300 sm:duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300 sm:duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="rounded-l-xl font-sans rounded-r-none p-2 bg-gray-100 truncate pr-9 flex flew-row items-center justify-between">
              Marker Clustern?
              <PgToggle
                value={mapConfig.clusterIcons}
                onChange={(val) => {
                  setMapConfig({ ...mapConfig, clusterIcons: val });
                }}
              />
              <ChevronRightIcon className="h-6 ml-4 cursor-pointer" onClick={(e) => { e.stopPropagation(); e.preventDefault(); setOpen(!open); }}/>
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </>
  );
};
