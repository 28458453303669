import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./shared/auth/login";
import Logout from "./shared/auth/logout";
import ForgotPassword from "./shared/auth/forgot-password";
import PageNotFound from "./shared/error/page-not-found";
import PrivateRoutes from "./private-routes";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./shared/auth/reset-password";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";
import { ReferencesTable } from "./pages/analytics/references/table/references-table";
import { ReferencesCharts } from "./pages/analytics/references/charts/references-charts";
const { parse, stringify } = queryString;


const AppRoutes = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/references-export-table-view" element={<ReferencesTable showExport={false} excludeLinesVisible={false} printView={true} />} />
          <Route path="/references-export-chart-view" element={<ReferencesCharts showExport={false} printView={true} />} />
          <Route path="/*" element={<PrivateRoutes />} />
          <Route element={<PageNotFound />} />
        </Routes>
      </QueryParamProvider>
    </div>
  );
};

export default AppRoutes;
