import { IKeyValuePair } from "../shared/utils/entity-utils";

export type IContractorMap = {
  [key: string]: {
    displayString: string,
  }
};

export const contractorMap: IContractorMap = {
  "schraeder": { displayString: "Schräder & Co." },
  "naxis": { displayString: "Naxis GmbH" },
  "esgium": { displayString: "ESGium GmbH" }
}

export const contractorKeyValuePairs: IKeyValuePair[] = Object.keys(contractorMap).map((key: string) => ({ key, label: contractorMap[key].displayString }));