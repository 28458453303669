import React, { useEffect, useState } from "react";
import { classNames } from "../../shared/utils/class-names";
import { defaultButtonClasses, primaryButtonClasses } from "../basics/buttons-classes";
import { IUpdateField, IUpdateFieldProps } from "./types";
import { Switch } from "@headlessui/react";

export const BooleanSwitchUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  loading,
  extra,
  className
}: IUpdateFieldProps<string>) => {

  return (
    <Switch
    checked={!!value}
    onChange={(val: boolean) => { onUpdate(val); }}
    className={`${
      value ? "bg-green-500" : "bg-gray-200"
    } relative inline-flex items-center h-6 rounded-full w-11 ml-2`}
  >
    <span className="sr-only">{label}</span>
    <span
      className={`${
        value ? "translate-x-6" : "translate-x-1"
      } inline-block w-4 h-4 transform bg-white rounded-full`}
    />
  </Switch>
  );
};
