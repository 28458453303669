import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { classNames } from '../../shared/utils/class-names';
import { IKeyValuePair } from '../../shared/utils/entity-utils';
import { defaultInputClasses } from '../basics/input-classes';
import Typeahead from '../layout/basics/typeahead';
import { StringUpdateField } from './string-update-field';
import { IUpdateField } from './types';

interface IErrorMessage {
  message: string;
}

export const CountryUpdateField: IUpdateField<string> = ({ label, value, error, onUpdate, className }) => {

  const [ countries, setCountries ] = useState([]);
  const [ responseError, setResponseError ] = useState<IErrorMessage | null>(null);

  useEffect(() => {
    async function getCountries() {
      try {
        const response = await axios.get('/countries.json');
        const countryItems = response.data.map((c: any) => ({ key: c.name, label: c.name }));
        setCountries(countryItems);
      } catch (e) {
        setResponseError({message: 'Fehler beim Abrufen der Länderdatei. Bitte manuell eintragen'});
      }
    }
    getCountries();
  }, []);


  return <> {
    responseError ? <StringUpdateField label={label} value={value} error={error} onUpdate={onUpdate} className={className} /> :
    <Typeahead
      placeholder={label || "Auftragnehmer"}
      value={countries.find((item: IKeyValuePair) => ( item.key === value ))}
      onChange={(newVal: IKeyValuePair) => { onUpdate(newVal?.key) }}
      items={countries}
      error={error}
      className={classNames(className)}
    /> }</>
};
