import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  CheckIcon,
  ChevronDownIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { Spinner } from "../../../common/spinner/base-spinner";
import { Employee } from "../../../model/analytics/employees/analytics-employees";
import { classNames } from "../../../shared/utils/class-names";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import { Button } from "../../basics/button";
import { AuthImg } from "../../layout/auth-img";

export interface IEmployeeSingleSelect {
  placeholder?: string;
  onChange: (value: Employee | null) => void;
  exclude: number[];
  selected: number | null;
  employees: Employee[];
  isLoading?: boolean;
  className?: string;
}

export const EmployeeSingleSelect = ({
  placeholder,
  isLoading,
  className,
  onChange,
  selected,
  exclude = [],
  employees,
}: IEmployeeSingleSelect) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [query, setQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    setFilteredEmployees(
      (query === ""
        ? employees || []
        : employees?.filter((employee: Employee) => {
            const stringMatches = employee?.attributes?.username
              ?.toLowerCase()
              ?.includes(query.toLowerCase());
            return stringMatches;
          }) || []
      ).filter((employee: any) => !exclude?.includes(parseInt(employee?.id))) ||
        []
    );
  }, [query, employees, exclude]);

  return (
    <Combobox
      as="div"
      value={employees?.find((e) => selected === parseInt(e.id))}
      onChange={onChange}
      className={className}
    >
      <div className="relative">
        <Combobox.Input
          onClick={() => {
            // This is a small hack to also toggle the Dropdown if only the Input Field is clicked.
            buttonRef.current?.click();
          }}
          className="w-full rounded-xl border border-gray-300 bg-white pl-3 pr-16 truncate shadow-sm focus:border-green-500 focus:outline-none sm:text-base focus:ring-0 placeholder:text-base p-1.5"
          onChange={(event) => {setQuery(event.target.value);}}
          displayValue={(employee: Employee) => employee?.attributes?.username}
          placeholder={placeholder || "Mitarbeiter auswählen"}
        />
        {selected ? (
          <span
            className="absolute inset-y-0 right-6 flex items-center pr-1 pointer-events-auto text-gray-400"
            onClick={() => {
              onChange(null);
            }}
          >
            <XCircleIcon className="h-5 m-1 cursor-pointer" />
          </span>
        ) : null}
        <Combobox.Button
          ref={buttonRef}
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          {isLoading ? (
            <Spinner className="h-5 w-5 text-gray-400" />
          ) : (
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </Combobox.Button>

        {filteredEmployees?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-80 min-w-full overflow-y-scroll overflow-x-visible rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm md:text-base">
            {filteredEmployees?.map((employee: Employee) => (
              <Combobox.Option
                key={employee?.id}
                value={employee}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-green-500 text-white" : "text-gray-900"
                  )
                }
                onClick={(e: MouseEvent) => { e.preventDefault(); e.stopPropagation(); onChange(employee); }}
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center w-full">
                      <AuthImg
                        src={`${getLoginUrl()}${
                          employee?.attributes?.image?.data?.attributes?.url
                        }`}
                        size="thumbnail"
                        className="h-6 w-6 flex-shrink-0 rounded-full"
                      />
                      <div
                        className={classNames(
                          "ml-3",
                          selected ? "font-semibold" : ""
                        )}
                      >
                        {employee?.attributes?.username}
                      </div>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-green-500"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
