import React, { useEffect, useState } from 'react';
import { IUpdateField, IUpdateFieldProps } from './types';

export const StringUpdateField: IUpdateField<string> = ({ label, value, onUpdate, loading }: IUpdateFieldProps<string>) => {
  const [ newFieldValue, setNewFieldValue ] = useState(value);

  useEffect(() => {
    setNewFieldValue(value);
  }, [value]);

  return (<input
    type="text"
    name={label}
    className="flex-1 min-w-0 block w-full px-3 mb-2 py-2 rounded focus:ring-green-500 focus:border-green-500 sm:text-sm border-gray-300"
    value={newFieldValue}
    onChange={(e) => { setNewFieldValue(e?.target?.value); e.stopPropagation(); }}
    onKeyDown={async (e) => { if(e.key === 'Enter') { onUpdate(newFieldValue); } }}
  />)
}