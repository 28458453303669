import {IKeyValuePair} from "../shared/utils/entity-utils";

export type IStatusMap = {
  [key: string]: {
    displayString: string,
    percentage: number,
    color: string,
    weight: number
  }
}

// When adding states where projects should not be counted as overdue,
// adjust overdueCount endpoint within the stats controller (backend/src/api) accordingly.
export const statusMap : IStatusMap = {
  "Vorlauf": { displayString: 'Vorlauf', percentage: 0.0, color: "#C4C4C4", weight: 1 },
  "Auftragsanlage": { displayString: 'Auftragsanlage', percentage: 0.10, color: "#92929D", weight: 2 },
  "Bearbeitung": { displayString: 'Bearbeitung', percentage: 0.70, color: "#F6B944", weight: 3 },
  "KorrekturIntern": { displayString: 'Korrektur intern', percentage: 0.80, color: "#FF974A", weight: 4 },
  "KorrekturNG": { displayString: 'Korrektur NG', percentage: 0.85, color: "#C0504DCC", weight: 5 },
  "KorrekturAG": { displayString: 'Korrektur AG', percentage: 0.90, color: "#DB5353", weight: 6 },
  "Finalisierung": { displayString: 'Finalisierung', percentage: 0.95, color: "#95C067", weight: 7 },
  "Abrechnung": { displayString: 'Abrechnung', percentage: 1, color: "#96B69F", weight: 8 },
  "Abgeschlossen": { displayString: 'Abgeschlossen', percentage: 1, color: "#004F15", weight: 9 },
  "Storno": { displayString: 'Storno', percentage: 1, color: "#558ED5", weight: 10 },
};

export const statusKeyValuePairs: IKeyValuePair[] = Object.keys(statusMap).map((key: string) => ({key, label: statusMap[key].displayString}));

export type StatusKey = keyof typeof statusMap;
