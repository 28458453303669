import React, { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { formatCurrency } from '../../shared/utils/formatters';

interface Props {
  className?: string;
  onValueChange: (value: number) => void;
  value?: number;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput: FC<Props> = ({
  className = '',
  onValueChange,
  value,
}) => {
  const max = Number.MAX_SAFE_INTEGER;
  const [displayValue, setDisplayValue] = useState<number>(0);

  useEffect(() => {
    if(value !== undefined) {
      const valueAbsTrunc = Math.round(Math.abs(value*100));
      setDisplayValue(valueAbsTrunc);
    }
  }, [value])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      const { key, keyCode } = e;
      if (
        (!VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = displayValue?.toString() || '';
      let nextValue: number;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString: string = displayValue === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(nextValue/100);
    },
    [max, onValueChange, displayValue]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = value !== null && value !== undefined && !Number.isNaN(value) ? formatCurrency(value) : '-';

  return (
    <input
      className={className}
      inputMode="numeric"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={valueDisplay}
    />
  );
};

export default CurrencyInput;