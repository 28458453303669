import { StringParam, withDefault } from "use-query-params";

export const USERS_LIST_NEEDLE_FILTER = "needle";

const NeedleParam = withDefault(StringParam, "");

export const usersListQueryParams = {
  [USERS_LIST_NEEDLE_FILTER]: NeedleParam,
}

export function usersListilterResetToDefault(updateParams: (value: any) => void) {
  updateParams({
    [USERS_LIST_NEEDLE_FILTER]: null,
  })
}