import React from "react";
import { FilterSelect } from "./filter-select";

export interface ICoAppraiserSelect {
  onChange: (selected: boolean | null) => void;
  value: boolean | null;
  placeholder?: string;
}

export const CoAppraiserSelect = ({ onChange, placeholder, value }: ICoAppraiserSelect) => {

  return <FilterSelect
  placeholder={placeholder}
  selected={value === null ? null : value ? { key: "NG", label: "Nebengutachter" } : { key: "HG", label: "Hauptgutachter" } }
  onChange={(val) => {
    switch(val?.key) {
      case 'NG': onChange(true); break;
      case 'HG': onChange(false); break;
      default: onChange(null)
  }}}
  items={[
    { key: 'HG', label: 'Hauptgutacher' },
    { key: 'NG', label: 'Nebengutacher' }
  ]}
  />;
};
