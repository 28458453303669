import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useQueryParams } from 'use-query-params';
import { INVOICE_QUERY_PARAM_CONFIG } from '../../../pages/invoicing/query-param-config';
import { getItemTransparent } from '../../../shared/utils/storage-utils';
import { getLoginUrl } from '../../../shared/utils/url-utils';
import { buildQueryStringInvoice, InvoiceStatusKey } from './invoice-table';
import { SumElement } from './sum-element';


import {
  CUSTOMER_STATUS,
  DATE_FILTER_FROM,
  DATE_FILTER_UNTIL,
  INVOICE_STATUS, PROJECT_STATUS, SEARCH_TERM,
  SORT_BY
} from "../../filters/project-filter";

export const SumRow = () => {
  const [metrics, setMetrics] = useState<any>()
  const [projectParams] = useQueryParams(INVOICE_QUERY_PARAM_CONFIG);

  useEffect(() => {

    // TODO: Refactor to Redux
    async function fetchData() {
      const queryString = buildQueryStringInvoice(projectParams[SEARCH_TERM],true,1,projectParams[SORT_BY],projectParams[PROJECT_STATUS],projectParams[CUSTOMER_STATUS],{ from: dayjs(projectParams[DATE_FILTER_FROM]), until: dayjs(projectParams[DATE_FILTER_UNTIL])}, projectParams[INVOICE_STATUS] as InvoiceStatusKey[], []);
      const metricsResponse = await axios.get(`${getLoginUrl()}/api/get-all-invoice-metrics?${queryString}`, {
          headers: {
            'Authorization': 'Bearer ' + getItemTransparent('JWT')
          }
      });
      setMetrics(metricsResponse?.data?.data);
    };
    fetchData();
  }, [projectParams]);

  return <div className='flex justify-between gap-3 mt-4 mb-6'>
    <SumElement heading='Netto-Honorar' value={metrics?.netIncome} className='w-1/5' key={0} />
    <SumElement heading='Auslagen' value={metrics?.expenses} className='w-1/5' key={1} />
    <SumElement heading='Gesamt Netto' value={metrics?.completeNetIncome} className='w-1/5' key={2} />
    <SumElement heading='Umsatzsteuer' value={metrics?.vat} className='w-1/5' key={3} />
    <SumElement heading='Gesamt Brutto' value={metrics?.grossInvoiceAmount} className='w-1/5' key={4} />
  </div>
}
