import React from "react";
import {
  MapContainer,
  TileLayer,
} from "react-leaflet";
import { LegendOverlayLayer } from "../../../project-list/project-map/map-layers/legend-overlay-layer";
import AddressMapLayer from "./main-address-map-layer";
import { BackgroundAddressMapLayer } from "./background-address-map-layer";

export const MapAddress = ({ address, buildingType }: any) => {
  return (
    <div className="w-full rounded-xl">
      {address?.attributes?.lat && address?.attributes?.lng ? (
        <MapContainer
          center={[address?.attributes?.lat, address?.attributes?.lng]}
          className="rounded-2xl"
          zoom={18}
          minZoom={14}
          style={{ width: "100%", height: 600 }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <BackgroundAddressMapLayer />
          <LegendOverlayLayer />
          <AddressMapLayer address={address} buildingType={buildingType} />
        </MapContainer>
      ) : (
        <div className="w-full h-72 bg-gray-500"></div>
      )}
    </div>
  );
};
