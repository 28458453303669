import React from 'react';
import { getLoginUrl } from '../../../shared/utils/url-utils';
import {AuthImg} from "../../../components/layout/auth-img";

export interface ISidebarPicture {
  url: string
}

export const SidebarPicture = ({ url }: ISidebarPicture) => {
  if (!url) {
    return (<div className="flex-shrink-0">
      <img className='rounded-xl' src='/placeholder.png' alt='' />
    </div> );
  }
  return (
    <div className="w-full">
      <AuthImg className='rounded-xl' src={`${getLoginUrl()}${url}`} size="medium" />
    </div>
  )
}