import React from "react";
import { classNames } from "../../shared/utils/class-names";

interface IButton {
  primary?: Boolean;
  secondary?: Boolean;
  children: React.ReactNode;
  type?: string;
  className?: string,
}

export const Button = ({
  primary = false,
  secondary = false,
  children,
  className,
}: IButton) => {
  return (
    <button
      className={classNames(
        primary
          ? "bg-green-500 text-white"
          : secondary
          ? "bg-orange-500 text-white"
          : "bg-gray-400 text-white",
        `font-special rounded-xl b-2 p-2 w-full text-sm outline-none`,
        className || ''
      )}
    >
      {children}
    </button>
  );
};
