import { IKeyValuePair } from "../shared/utils/entity-utils";

export interface IResearchObjectTypeMap {
  [key:string]: {
    displayString: string;
  }
}

export const researchObjectTypeMap: IResearchObjectTypeMap = {
  "Buero" : { displayString: "Büro" },
  "Handel": { displayString: "Handel" },
  "Industrie(Lager,Hallen)": { displayString: "Industrie(Lager,Hallen)" },
  "Keller/Archiv": { displayString: "Keller/Archiv" },
  "Gastronomie": { displayString: "Gastronomie" },
  "Hotel": { displayString: "Hotel" },
  "Wohnen": { displayString: "Wohnen" },
  "Freizeit": { displayString: "Freizeit" },
  "Soziales": { displayString: "Soziales" },
  "GewerbeAllgemein": { displayString: "GewerbeAllgemein" },
  "Garage/TG": { displayString: "Garage/TG" },
  "Aussenstellplaetze": { displayString: "Aussenstellplätze" },
  "unbekannt": { displayString: "unbekannt" }
}



export const researchObjectTypeKeyValuePairs: IKeyValuePair[] = Object.keys(researchObjectTypeMap).map((key: string) => ({key, label: researchObjectTypeMap[key]?.displayString}));