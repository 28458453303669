import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Input } from '../../components/form-components/input';
import { classNames } from '../../shared/utils/class-names';
import { defaultButtonClasses, primaryButtonClasses } from '../../components/basics/buttons-classes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoleUpdateField } from '../../components/magic-update/role-update-field';
import { ValidationMessage } from '../../components/layout/validation';
import { useFetchUserQuery, useUpdateUserMutation } from '../../model/users/user-api';
import './styles.css';
import { PortraitUpload } from './portrait-upload';
import { ProfilePicture } from '../../components/layout/profiles/profile-picture';
import Alert from '../../components/layout/alert';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner/base-spinner';

export const UserEditForm = ({ userId }: { userId: number }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [image, setImage] = useState<string>();
    const [newImage, setNewImage] = useState<boolean>(false);
    const { data: serverUser, error: usersError, isFetching, refetch } =
        useFetchUserQuery({ id: userId, queryParams: { filters: {}, populate: ["image", "role"] } });
    const [updateUser, { status, error }] = useUpdateUserMutation();
    const user = serverUser ? { ...serverUser, role: serverUser?.role?.id } : undefined;
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({ values: user });

    useEffect(() => { refetch(); }, [location.key]);

    const doSubmit = async (data: FieldValues) => {

        try {
            let result: any;
            if (image) {
                result = await updateUser({ id: userId, ...data, image });
            } else {
                result = await updateUser({ id: userId, ...data });
            }
            if (result?.error) {
                toast.error("Es ist ein Fehler aufgetreten.");
            } else {
                toast.info("Nutzer erfolgreich aktualisiert.");
                navigate('/users');
            }
        } catch (e) {
            console.error(e);
        }
    }

    if (isFetching) {
        return (
            <Spinner className="h-12 w-12 stroke-gray-500" />
        )
    }

    return <form onSubmit={handleSubmit(doSubmit)}>
        {error && <Alert title="Es ist ein Fehler beim Anlegen des Nutzers aufgetreten" message={(error as any)?.data?.error?.message} />}
        <div className="sm:items-start sm:pt-5">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-start">
                    <Input
                        name="username"
                        placeholder="Vor- und Nachname *"
                        register={register}
                        validateRequired={{
                            required: "Bitte Vor- und Nachname mit Leerzeichen getrennt eingeben",
                        }}
                        className={`w-full ${errors.username ? "border-red-800 placeholder-red-800" : ""
                            }`}
                    />
                </div>
                {errors.username?.type === "required" && (
                    <ValidationMessage message={errors.username?.message as string} />
                )}
            </div>
        </div>
        <div className="sm:items-start sm:pt-5">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-start">
                    <Input
                        name="email"
                        placeholder="E-Mail *"
                        register={register}
                        validateRequired={{
                            required: "Bitte E-Mail eingeben",
                        }}
                        className={`w-full ${errors.email ? "border-red-800 placeholder-red-800" : ""
                            }`}
                    />
                    {errors.email?.type === "required" && (
                        <ValidationMessage message={errors.email?.message as string} />
                    )}
                </div>
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
                <Controller
                    control={control}
                    name="role"
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                        formState,
                    }) => {
                        return (
                            <RoleUpdateField
                                label="Rolle"
                                value={value}
                                onUpdate={onChange}
                                error={!!error}
                                className={classNames(
                                    error ? "border-red-800 placeholder-red-800" : "",
                                    "text-base bg-gray-100"
                                )}
                            />
                        );
                    }}
                />
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg">
                <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            aria-describedby="isEditor-description"
                            type="checkbox"
                            className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                            {...register("isEditor")}
                        />
                    </div>
                    <div className="ml-3 text">
                        <label htmlFor="isEditor" className="text-gray-700">
                            Darf Aufträge bearbeiten?
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg">
                <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            aria-describedby="isAppraiser-description"
                            type="checkbox"
                            className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                            {...register("isAppraiser")}
                        />
                    </div>
                    <div className="ml-3 text">
                        <label htmlFor="isAppraiser" className="text-gray-700">
                            Gutachter?
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg w-full">
                <div className="flex items-center justify-center">
                    {
                        user?.image && !newImage
                        && ( <div className="flex flex-col items-center gap-2">
                            <ProfilePicture className="h-12 w-12" profilePicturePath={user?.image?.url} />
                            <button
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setNewImage(!newImage); }} 
                                className={newImage ? classNames(...defaultButtonClasses) : classNames(...primaryButtonClasses)}>
                                    Neues Bild
                                </button>
                            </div>)
                    }
                    {(newImage || !user?.image) &&
                        <PortraitUpload onChange={(imageId: string) => (setImage(imageId))} />}
                </div>
            </div>
        </div>
        <div className="flex justify-between mt-6">
            <Link to="/users" className={classNames(...defaultButtonClasses, "w-40 text-center")} {...register} > Abbrechen </Link>
            <button className={classNames(...primaryButtonClasses, "w-40 text-center")} {...register} > Speichern </button>
        </div>
    </form>
}