import React, { useEffect, useState } from "react";
import {
  buildingSubTypeKeyValuePairs,
  buildingSubTypeMap,
} from "../../common/building-type-map";
import { FilterSelect } from "../filters/filter-select";
import { classNames } from "../../shared/utils/class-names";
import { IKeyValuePair } from "../../shared/utils/entity-utils";
import { IUpdateField } from "./types";

function filterKeyValuePairs(buildingType: string | undefined ): IKeyValuePair[] {
  return buildingType
    ? Object.keys(buildingSubTypeMap)
      .filter((st) => buildingSubTypeMap[st].type === buildingType)
      .map((st) => ({
        key: st,
        label: buildingSubTypeMap[st].displayString,
      }))
    : buildingSubTypeKeyValuePairs;
}

export const BuildingSubTypeUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  error,
  className,
  extra,
}) => {
  const [items, setItems] = useState<IKeyValuePair[]>(filterKeyValuePairs(extra?.buildingType));

    useEffect(() => {
      if(!items.some((st) => (st.key === value))) {
        onUpdate(null);
      }
    }, [items, value, onUpdate]);

  useEffect(() => {
    setItems(filterKeyValuePairs(extra?.buildingType));
  }, [extra.buildingType]);

  return (
    <FilterSelect
      placeholder={label || "Objektart 2. Ebene"}
      selected={items.find((item) => item.key === value)}
      onChange={(newVal) => {
        onUpdate(newVal?.key);
      }}
      error={error}
      items={items}
      className={classNames(className)}
      nonNullable
    />
  );
};


