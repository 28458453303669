import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import { SearchButton } from "./search-button";
import { SearchDropdown } from "./search-dropdown";

export const Search = (props: any) => {
  const [isShowing, setIsShowing] = useState(false);
  const [query, setQuery] = useState("");

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => {
          return (
            <>
              <SearchButton
                isShowing={isShowing}
                handleSearchClick={() => {
                  setIsShowing(true);
                }}
                handleClose={() => {
                  setQuery('');
                  setIsShowing(false);
                }}
                handleChange={(e: string) => {
                  setQuery(e);
                }}
              />
              {isShowing && (
                <Popover.Panel
                  static
                  className="absolute z-[10010] left-1/2 transform -translate-x-2/3 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-md"
                >
                  <SearchDropdown query={query} />
                </Popover.Panel>
              )}
            </>
          );
        }}
      </Popover>
    </>
  );
};
