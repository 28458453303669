import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../store'
import { LatLng, LatLngBounds, LatLngBoundsExpression, LatLngExpression } from 'leaflet'

// Define a type for the slice state
interface PolygonPickerState {
    editing: boolean;
    polygon: LatLngExpression[];
}

// Define the initial state using that type
const initialState: PolygonPickerState = {
    editing: false,
    polygon: [],
}

export const polygonPickerSlice = createSlice({
    name: 'polygonPicker',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        addPoint: (state, action: PayloadAction<LatLngExpression>) => {
            state.polygon = [...state.polygon, action.payload];
        },
        toggleEditing: (state) => {
            state.editing = !state.editing;
        },
        removePolygon: (state) => {
            state.polygon = [];
        }
    },
})

export const { addPoint, toggleEditing, removePolygon } = polygonPickerSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectPolygon = (state: RootState) => state.polygonPicker.polygon;
export const selectEditing = (state: RootState) => state.polygonPicker.editing;

export default polygonPickerSlice.reducer