import React, { createContext, useState } from 'react';

export interface ITooltipState {
  id: string,
  open: boolean,
}

export interface ITooltipManagerState {
  tooltips: ITooltipState[]
  toggleTooltip: Function
  addTooltip: Function
  isOpen: Function
}

export const TooltipContext = createContext<ITooltipManagerState>({ tooltips: [], addTooltip: () => {}, toggleTooltip: () => {}, isOpen: () => false });

export const TooltipManager = ( { children }: any ) => {
  const [ tooltips, setTooltips ] = useState<ITooltipState[]>([]);

  const addTooltip = (newTooltip: ITooltipState) => {
    if(tooltips.some((tt: ITooltipState) => (tt.id === newTooltip.id))) {
      console.error(`Registering ${newTooltip.id} failed. Id already exists`);
    } else {
      setTooltips([ ...tooltips, newTooltip ])
    }
  }

  const toggleTooltip = (id: string) => {
    const currentTooltip = tooltips.filter((tt) => ( tt.id === id ))?.[0];
    if(currentTooltip) {
      setTooltips([
        ...tooltips.filter((tt) => ( tt.id !== id )),
        { id, open: !currentTooltip.open }
      ])
    }
  }

  const isOpen = (id: string) => {
    const currentTooltip = tooltips.filter((tt) => ( tt.id === id ))?.[0];
    return currentTooltip?.open;
  }

  return <TooltipContext.Provider value={{tooltips, addTooltip, toggleTooltip, isOpen}}>
    {children}
  </TooltipContext.Provider>
}