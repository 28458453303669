export enum MarketValueEnum {
  ALL = "ALL",
  BELOW_ONE_MILLION = "BELOW_ONE_MILLION",
  ONE_TO_FIVE_MILLION = "ONE_TO_FIVE_MILLION",
  FIVE_TO_TEN_MILLION = "FIVE_TO_TEN_MILLION",
  TEN_TO_TWENTYFIVE_MILLION = "TEN_TO_TWENTYFIVE_MILLION",
  ABOVE_TWENTYFIVE_MILLION = "ABOVE_TWENTYFIVE_MILLION",
}

export interface MarketValueMap {
  [key: string]: { label: string; lowerBounds: number; upperBounds: number; color: string };
}

export const marketValueMap: MarketValueMap = {
  [MarketValueEnum.ALL]: {
    label: "Alle",
    lowerBounds: 0,
    upperBounds: Number.MAX_SAFE_INTEGER,
    color: "#aaaaaa"
  },
  [MarketValueEnum.BELOW_ONE_MILLION]: {
    label: "< 1 Mio. €",
    lowerBounds: 0,
    upperBounds: 1_000_000,
    color: "#004f14",
  },
  [MarketValueEnum.ONE_TO_FIVE_MILLION]: {
    label: "1-5 Mio. €",
    lowerBounds: 1_000_000,
    upperBounds: 5_000_000,
    color: "#7d9249",
  },
  [MarketValueEnum.FIVE_TO_TEN_MILLION]: {
    label: "5-10 Mio. €",
    lowerBounds: 5_000_000,
    upperBounds: 10_000_000,
    color: "#c7d5a1",
  },
  [MarketValueEnum.TEN_TO_TWENTYFIVE_MILLION]: {
    label: "10-25 Mio. €",
    lowerBounds: 10_000_000,
    upperBounds: 25_000_000,
    color: "#f1c297",
  },
  [MarketValueEnum.ABOVE_TWENTYFIVE_MILLION]: {
    label: "> 25 Mio. €",
    lowerBounds: 25_000_000,
    upperBounds: Number.MAX_SAFE_INTEGER,
    color: "#f6d6ba"
  },
};

export function getMarketValueCategory(inputNumber:number) {
  const marketValueRange = Object.keys(marketValueMap)
    .filter((key) => (key !== MarketValueEnum.ALL))
    .find((key) => (
      inputNumber >= marketValueMap[key].lowerBounds && inputNumber <= marketValueMap[key].upperBounds
    ))
    return marketValueRange
}

export function formatMarketValue(inputNumber: number) {
  const marketValueRange = getMarketValueCategory(inputNumber);
  return marketValueRange
      ? marketValueMap[marketValueRange].label
      : "?";
}