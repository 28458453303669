export const BueroIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.buero-${color}-cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;stroke: ${color}}`}</style>
    </defs>
    <g>
      <rect className={`buero-${color}-cls-1`} x="8.27" y="5.27" width="13.15" height="23.67" />
      <rect
        className={`buero-${color}-cls-1`}
        x="1.85"
        y="11.25"
        width="6.42"
        height="17.69"
        transform="translate(10.12 40.19) rotate(180)"
      />
      <rect className={`buero-${color}-cls-1`} x="10.23" y="2.85" width="9.23" height="2.42" />
      <g>
        <line className={`buero-${color}-cls-1`} x1="4.16" y1="13.7" x2="4.16" y2="15" />
        <line className={`buero-${color}-cls-1`} x1="4.16" y1="16.7" x2="4.16" y2="18" />
        <line className={`buero-${color}-cls-1`} x1="4.16" y1="19.7" x2="4.16" y2="21" />
        <line className={`buero-${color}-cls-1`} x1="4.16" y1="22.7" x2="4.16" y2="23.99" />
        <line className={`buero-${color}-cls-1`} x1="4.16" y1="25.69" x2="4.16" y2="26.99" />
      </g>
      <g>
        <g>
          <line className={`buero-${color}-cls-1`} x1="11.01" y1="13.7" x2="11.01" y2="15" />
          <line className={`buero-${color}-cls-1`} x1="11.01" y1="10.7" x2="11.01" y2="12" />
          <line className={`buero-${color}-cls-1`} x1="11.01" y1="16.7" x2="11.01" y2="18" />
          <line className={`buero-${color}-cls-1`} x1="11.01" y1="19.7" x2="11.01" y2="21" />
          <line className={`buero-${color}-cls-1`} x1="11.01" y1="22.7" x2="11.01" y2="23.99" />
          <line className={`buero-${color}-cls-1`} x1="11.01" y1="25.69" x2="11.01" y2="26.99" />
        </g>
        <g>
          <line className={`buero-${color}-cls-1`} x1="13.62" y1="13.7" x2="13.62" y2="15" />
          <line className={`buero-${color}-cls-1`} x1="13.62" y1="10.7" x2="13.62" y2="12" />
          <line className={`buero-${color}-cls-1`} x1="13.62" y1="16.7" x2="13.62" y2="18" />
          <line className={`buero-${color}-cls-1`} x1="13.62" y1="19.7" x2="13.62" y2="21" />
          <line className={`buero-${color}-cls-1`} x1="13.62" y1="22.7" x2="13.62" y2="23.99" />
          <line className={`buero-${color}-cls-1`} x1="13.62" y1="25.69" x2="13.62" y2="26.99" />
        </g>
        <g>
          <line className={`buero-${color}-cls-1`} x1="16.08" y1="13.7" x2="16.08" y2="15" />
          <line className={`buero-${color}-cls-1`} x1="16.08" y1="10.7" x2="16.08" y2="12" />
          <line className={`buero-${color}-cls-1`} x1="16.08" y1="16.7" x2="16.08" y2="18" />
          <line className={`buero-${color}-cls-1`} x1="16.08" y1="19.7" x2="16.08" y2="21" />
          <line className={`buero-${color}-cls-1`} x1="16.08" y1="22.7" x2="16.08" y2="23.99" />
          <line className={`buero-${color}-cls-1`} x1="16.08" y1="25.69" x2="16.08" y2="26.99" />
        </g>
        <g>
          <line className={`buero-${color}-cls-1`} x1="18.68" y1="13.7" x2="18.68" y2="15" />
          <line className={`buero-${color}-cls-1`} x1="18.68" y1="10.7" x2="18.68" y2="12" />
          <line className={`buero-${color}-cls-1`} x1="18.68" y1="16.7" x2="18.68" y2="18" />
          <line className={`buero-${color}-cls-1`} x1="18.68" y1="19.7" x2="18.68" y2="21" />
          <line className={`buero-${color}-cls-1`} x1="18.68" y1="22.7" x2="18.68" y2="23.99" />
          <line className={`buero-${color}-cls-1`} x1="18.68" y1="25.69" x2="18.68" y2="26.99" />
        </g>
      </g>
      <g>
        <line className={`buero-${color}-cls-1`} x1="6.11" y1="13.7" x2="6.11" y2="15" />
        <line className={`buero-${color}-cls-1`} x1="6.11" y1="16.7" x2="6.11" y2="18" />
        <line className={`buero-${color}-cls-1`} x1="6.11" y1="19.7" x2="6.11" y2="21" />
        <line className={`buero-${color}-cls-1`} x1="6.11" y1="22.7" x2="6.11" y2="23.99" />
        <line className={`buero-${color}-cls-1`} x1="6.11" y1="25.69" x2="6.11" y2="26.99" />
      </g>
      <rect className={`buero-${color}-cls-1`} x="21.42" y="11.25" width="6.42" height="17.69" />
      <g>
        <line className={`buero-${color}-cls-1`} x1="25.53" y1="13.7" x2="25.53" y2="15" />
        <line className={`buero-${color}-cls-1`} x1="25.53" y1="16.7" x2="25.53" y2="18" />
        <line className={`buero-${color}-cls-1`} x1="25.53" y1="19.7" x2="25.53" y2="21" />
        <line className={`buero-${color}-cls-1`} x1="25.53" y1="22.7" x2="25.53" y2="23.99" />
        <line className={`buero-${color}-cls-1`} x1="25.53" y1="25.69" x2="25.53" y2="26.99" />
      </g>
      <g>
        <line className={`buero-${color}-cls-1`} x1="23.58" y1="13.7" x2="23.58" y2="15" />
        <line className={`buero-${color}-cls-1`} x1="23.58" y1="16.7" x2="23.58" y2="18" />
        <line className={`buero-${color}-cls-1`} x1="23.58" y1="19.7" x2="23.58" y2="21" />
        <line className={`buero-${color}-cls-1`} x1="23.58" y1="22.7" x2="23.58" y2="23.99" />
        <line className={`buero-${color}-cls-1`} x1="23.58" y1="25.69" x2="23.58" y2="26.99" />
      </g>
    </g>
    <line className={`buero-${color}-cls-1`} x1=".35" y1="28.94" x2="29.35" y2="28.94" />
  </svg>
);
