import { IKeyValuePair } from "../shared/utils/entity-utils";
import { BueroIcon } from "./building-type-icons/buero";
import { WohnobjekteIcon } from "./building-type-icons/wohnobjekte";
import { HandelsimmobilienIcon } from "./building-type-icons/handelsimmobilien";
import { HospitalityIcon } from "./building-type-icons/hospitality";
import { LogistikIcon } from "./building-type-icons/logistik";
import { SozialimmobilienIcon } from "./building-type-icons/sozialimmobilien";
import { UnbebautesGrundstueckIcon } from "./building-type-icons/unbebautesGrundstueck";
import { SonstigeIcon } from "./building-type-icons/sonstige";

export type IBuildingTypeItem = {
    displayString: string,
    icon: (props: any) => JSX.Element,
    color: string,
}

export type IBuildingTypeMap = {
  [key: string]: IBuildingTypeItem
}

export const buildingTypeMap: IBuildingTypeMap = {
  "Buero": { displayString: "Büroobjekte", icon: BueroIcon, color: "#95C067" },
  "Wohnobjekte": { displayString: "Wohnobjekte", icon: WohnobjekteIcon, color: "#FF974A" },
  "Handelsimmobilien": { displayString: "Handelsimmobilien", icon: HandelsimmobilienIcon, color: "#C0504D" },
  "Logistik": { displayString: "Logistik- / Gewerbeobjekte", icon: LogistikIcon, color: "#DB5353" },
  "Hospitality": { displayString: "Hospitality", icon: HospitalityIcon, color: "#004F15" },
  "Sozialimmobilien": { displayString: "Sozialimmobilien", icon: SozialimmobilienIcon, color: "#558ED5" },
  "unbebautesGrundstueck": { displayString: "unbebautes Grundstück", icon: UnbebautesGrundstueckIcon, color: "#F6B944" },
  "Sonstiges": { displayString: "Sonstiges", icon: SonstigeIcon, color: "#C4C4C4" },
}

export type IBuildingSubTypeMap = {
  [key: string]: IBuildingSubTypeItem
}

export type IBuildingSubTypeItem = {
  type: string,
  displayString: string
}

export const buildingSubTypeMap: IBuildingSubTypeMap = {
  "reinesBueroobjekt": {
    type: "Buero",
    displayString: "Reines Büroobjekt"
  },
  "BuerogebaeudemitMischnutzungsanteil": {
    type: "Buero",
    displayString: "Bürogebäude mit Mischnutzungsanteil (< 50%)"
  },
  "reinesMehrfamilienhaus": {
    type: "Wohnobjekte",
    displayString: "reines Mehrfamilienhaus"
  },
  "MehrfamilienhausmitMischnutzungsanteil": {
    type: "Wohnobjekte",
    displayString: "Mehrfamilienhaus mit Mischnutzungsanteil (< 50 %)"
  },
  "EinZweifamilienhaus": {
    type: "Wohnobjekte",
    displayString: "Ein- / Zweifamilienhaus"
  },
  "Eigentumswohnung": {
    type: "Wohnobjekte",
    displayString: "Eigentumswohnung(en)"
  },
  "Studentenwohnheim": {
    type: "Wohnobjekte",
    displayString: "Studentenwohnheim"
  },
  "MicroAppartements": {
    type: "Wohnobjekte",
    displayString: "Micro-Appartements"
  },
  "betreutesWohnen": {
    type: "Wohnobjekte",
    displayString: "betreutes Wohnen"
  },
  "Highstreet": {
    type: "Handelsimmobilien",
    displayString: "Highstreet (1a/b Lage)"
  },
  "KaufhausInnerstaedtisch": {
    type: "Handelsimmobilien",
    displayString: "Kaufhaus (innerstädtisch)"
  },
  "dezentralesGeschaeftsobjekt": {
    type: "Handelsimmobilien",
    displayString: "dezentrales Geschäftsobjekt (ggf. mit Mischnutzung)"
  },
  "SBWarenhaus": {
    type: "Handelsimmobilien",
    displayString: "SB-Warenhaus"
  },
  "VerbraucherSupermarktVollsortimenter": {
    type: "Handelsimmobilien",
    displayString: "Verbraucher- / Supermarkt (Vollsortimenter)"
  },
  "Discounter": {
    type: "Handelsimmobilien",
    displayString: "Discounter"
  },
  "Fachmarkt": {
    type: "Handelsimmobilien",
    displayString: "Fachmarkt"
  },
  "FachmarktCenter": {
    type: "Handelsimmobilien",
    displayString: "Fachmarkt-Center"
  },
  "ShoppingCenter": {
    type: "Handelsimmobilien",
    displayString: "Shopping-Center"
  },
  "FactoryOutlet": {
    type: "Handelsimmobilien",
    displayString: "Factory-Outlet"
  },
  "LagerDistribution": {
    type: "Logistik",
    displayString: "Lager- / Distribution"
  },
  "CrossDock": {
    type: "Logistik",
    displayString: "Cross-Dock"
  },
  "LightIndustrial": {
    type: "Logistik",
    displayString: "Light-Industrial"
  },
  "IndustrieProduktionWerkstatt": {
    type: "Logistik",
    displayString: "Industrie / Produktion / Werkstatt"
  },
  "SelfStorage": {
    type: "Logistik",
    displayString: "Self-Storage"
  },
  "Hotel": {
    type: "Hospitality",
    displayString: "Hotel"
  },
  "Gastronomie": {
    type: "Hospitality",
    displayString: "Gastronomie"
  },
  "Freizeitimmobilie": {
    type: "Hospitality",
    displayString: "Freizeitimmobilie"
  },
  "Pflegeheim": {
    type: "Sozialimmobilien",
    displayString: "Pflegeheim"
  },
  "GesundheitsimmobilieAerztehaus": {
    type: "Sozialimmobilien",
    displayString: "Gesundheitsimmobilie / Ärztehaus"
  },
  "KiTa": {
    type: "Sozialimmobilien",
    displayString: "KiTa"
  },
  "StellplatzParkhausTiefgarage": {
    type: "Sonstiges",
    displayString: "Stellplatz / Parkhaus / Tiefgarage"
  },
  "LandForstwirtschaft": {
    type: "Sonstiges",
    displayString: "Land- / Forstwirtschaft"
  },
  "Solarpark": {
    type: "Sonstiges",
    displayString: "Solarpark"
  },
  "Windkraftanlage": {
    type: "Sonstiges",
    displayString: "Windkraftanlage"
  },
  "Kultureinrichtung": {
    type: "Sonstiges",
    displayString: "Kultureinrichtung"
  },
  "Tankstelle": {
    type: "Sonstiges",
    displayString: "Tankstelle"
  },
  "Sonstiges": {
    type: "Sonstiges",
    displayString: "Sonstiges"
  },
  "unbebautesGrundstueck": {
    type: "unbebautesGrundstueck",
    displayString: "unbebautes Grundstück"
  }
}

export const buildingTypeKeyValuePairs: IKeyValuePair[] = Object.keys(buildingTypeMap).map((key: string) => ({ key, label: buildingTypeMap[key].displayString }));

export const buildingSubTypeKeyValuePairs: IKeyValuePair[] = Object.keys(buildingSubTypeMap).map((key: string) => ({
  key, label: buildingSubTypeMap[key].displayString
}))