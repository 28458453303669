import { Project } from "./project-model"

export const buildNewProjectValuesFromProject = (project: Project) => {
    return {
        contractor: undefined,
        customer: undefined,
        clientType: undefined,
        projectType: undefined,
        objectType: undefined,
        objectSubType: undefined,
        objectName: undefined,
        street: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.street,
        streetNumber: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.streetNumber,
        zip: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.zip,
        city: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.city,
        country: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.country,
        contactName: undefined,
        contactPhone: undefined,
    }
}

export const buildNewProjectValuesHistoryFromProject = (project: Project) => {
    return {
        contractor: project?.attributes?.contractor,
        customer: project?.attributes?.customer?.data,
        clientType: project?.attributes?.clientType?.data?.id,
        projectType: project?.attributes?.type,
        objectType: project?.attributes?.building?.data?.attributes?.type,
        objectSubType: project?.attributes?.building?.data?.attributes?.subtype,
        objectName: project?.attributes?.building?.data?.attributes?.name,
        street: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.street,
        streetNumber: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.streetNumber,
        zip: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.zip,
        city: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.city,
        country: project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.country,
        contactName: project?.attributes?.contact?.data?.attributes?.name,
        contactPhone: project?.attributes?.contact?.data?.attributes?.phone,
    }
}