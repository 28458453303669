import React, { useEffect, useState } from "react";
import { Spinner } from "../../../common/spinner/base-spinner";
import { mapProjectItem } from "./flatten-invoice-data";
import { Link } from "react-router-dom";
import { dayjsDateFormatter } from "../../../shared/utils/date-utils";
import { formatCurrency } from "../../../shared/utils/formatters";
import { DaysToDeadline } from "../days-to-deadline";
import { formatInvoiceStatus } from "./invoice-status-formatter";
import dayjs, { Dayjs } from "dayjs";
import { formatCustomer } from "./customer-formatter";
import { Card } from "../../basics/card";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import QueryString from "qs";
import {
  INVOICE_QUERY_PARAM_CONFIG,

} from "../../../pages/invoicing/query-param-config";
import { useQueryParams } from "use-query-params";


import {
  CUSTOMER_STATUS,
  DATE_FILTER_FROM,
  DATE_FILTER_UNTIL,
  INVOICE_STATUS, PROJECT_STATUS, SEARCH_TERM,
  SORT_BY
} from "../../filters/project-filter";

export const invoiceStatusFilterMap = {
  offen: {
    outgoingInvoiceDate: { $null: true },
  },
  ausstehend: {
    $and: [
      { outgoingInvoiceDate: { $notNull: true } },
      { paymentReceived: { $null: true } },
      { paymentReminder: { $null: true } },
      { lateNotice1: { $null: true } },
      { lateNotice2: { $null: true } },
    ],
  },
  bezahlt: {
    $and: [{ paymentReceived: { $notNull: true } }],
  },
  zahlungserinnerung: {
    $and: [
      { outgoingInvoiceDate: { $notNull: true } },
      { paymentReceived: { $null: true } },
      { paymentReminder: { $notNull: true } },
      { lateNotice1: { $null: true } },
      { lateNotice2: { $null: true } },
    ],
  },
  mahnung1: {
    $and: [
      { outgoingInvoiceDate: { $notNull: true } },
      { paymentReceived: { $null: true } },
      { lateNotice1: { $notNull: true } },
      { lateNotice2: { $null: true } },
    ],
  },
  mahnung2: {
    $and: [
      { outgoingInvoiceDate: { $notNull: true } },
      { paymentReceived: { $null: true } },
      { lateNotice1: { $notNull: true } },
      { lateNotice2: { $notNull: true } },
    ],
  },
};

export type InvoiceStatusKey = keyof typeof invoiceStatusFilterMap;

export const InvoiceTable = () => {
  const [pagesLoaded, setPagesLoaded] = useState<number>(1);
  const [maxPages, setMaxPages] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [expanded, setExpanded] = useState<string[]>([]);

  const [data, setData] = useState<any[]>([]);

  const [projectParams] = useQueryParams(
    INVOICE_QUERY_PARAM_CONFIG
  );

  // TODO: Refactor to Redux
  async function fetchDataFromServer(reset = false) {
    const query = buildQueryStringInvoice(
      projectParams[SEARCH_TERM],
      reset,
      pagesLoaded,
      projectParams[SORT_BY],
      projectParams[PROJECT_STATUS],
      projectParams[CUSTOMER_STATUS],
      {
        from: dayjs(projectParams[DATE_FILTER_FROM]),
        until: dayjs(projectParams[DATE_FILTER_UNTIL]),
      },
      projectParams[INVOICE_STATUS] as InvoiceStatusKey[],
      []
    );
    const result = await axios.get(
      `${getLoginUrl()}/api/find-all-projects-with-search?${query}`,
      {
        headers: {
          Authorization: "Bearer " + getItemTransparent("JWT"),
        },
      }
    );
    const response = result?.data;
    const mappedData: [] = response?.data?.map(mapProjectItem);
    let newData: any[] = [];
    let ids: number[] = [];
    mappedData?.forEach((d: any) => {
      if (!ids.includes(d.id)) {
        ids.push(d.id);
        newData.push(d);
      }
    });
    if (reset) {
      setData(newData);
    } else {
      setData([...data, ...newData]);
    }
    setMaxPages(response?.meta?.pagination?.pageCount);
    setPagesLoaded(reset ? 2 : pagesLoaded + 1);
  }

  useEffect(() => {
    async function resetAfterFilterChange() {
      setExpanded([]);
      await fetchDataFromServer(true);
    }
    resetAfterFilterChange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectParams]);

  const toggleRow = (id: string) => {
    if (expanded?.includes(id)) {
      const filtered = expanded.filter((e: string) => e !== id);
      setExpanded(filtered);
    } else {
      setExpanded([...expanded, id]);
    }
  };

  return (
    <>
      <ul className="border rounded-2xl p-1 shadow-sm text-gray-700">
        <li className="grid grid-cols-[2%_5%_17%_8%_8%_8%_8%_7%_7%_6%_8%_6%_6%] gap-1 font-special font-semibold py-4">
          <div></div>
          <div className="truncate">Status</div>
          <div className="truncate">Auftraggeber</div>
          <div className="truncate">Auftr.Nr.</div>
          <div className="truncate">Rg.Nr.</div>
          <div className="truncate">Netto-Hon.</div>
          <div className="truncate">Auslagen</div>
          <div className="truncate">USt.</div>
          <div className="truncate">Summe</div>
          <div className="truncate">Rg.-Datum</div>
          <div className="truncate">Zahlungsziel</div>
          <div className="truncate">Zahlungserinnerung</div>
          <div className="truncate">Akt.</div>
        </li>
        <InfiniteScroll
          dataLength={data?.length}
          hasMore={pagesLoaded < maxPages}
          next={async () => {
            await fetchDataFromServer();
          }}
          loader={
            <div className="flex w-full justify-center items-center">
              <Spinner className="stroke-gray-500 w-8 h-8" />
            </div>
          }
          endMessage={
            <p className="text-center mt-4 text-gray-500">
              Alle Aufträge geladen
            </p>
          }
        >
          {" "}
          {data?.map((project: any) => {
            return (
              <div key={project.id} className="font-semibold text-gray-600">
                <li className="border-none mb-3 rounded-2xl grid grid-cols-[2%_5%_17%_8%_8%_8%_8%_7%_7%_6%_8%_6%_6%] gap-1 items-center bg-white font-special text-sm py-4">
                  <div className="ml-3 -mr-3">
                    {project?.invoices?.length > 1 ? (
                      <button
                        onClick={() => {
                          toggleRow(project.id);
                        }}
                        className="cursor-pointer w-full flex flex-col justify-around items-center"
                      >
                        {expanded?.includes(project.id) ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </button>
                    ) : null}
                  </div>
                  <div className="truncate">
                    {formatInvoiceStatus(project.invoiceStatusCumulated)}
                  </div>
                  <div className="truncate pr-2">{formatCustomer(project)}</div>
                  <div className="truncate">#{project?.projectDisplayId}</div>
                  <div className="truncate">
                    {project?.invoices?.map((i: any) => i.invoiceNumber)?.[0]}
                  </div>
                  <div className="truncate">
                    {formatCurrency(aggregate(project?.invoices, "netIncome"))}
                  </div>
                  <div className="truncate">
                    {formatCurrency(aggregate(project?.invoices, "expenses"))}
                  </div>
                  <div className="truncate">
                    {formatCurrency(aggregate(project?.invoices, "vat"))}
                  </div>
                  <div className="truncate">
                    {formatCurrency(
                      aggregate(project?.invoices, "grossInvoiceAmount")
                    )}
                  </div>
                  <div className="truncate">
                    {formatDateCell(
                      aggregate(
                        project?.invoices,
                        "outgoingInvoiceDate",
                        aggregateMinDate
                      )
                    )}
                  </div>
                  <div className="truncate font-normal">
                    <DaysToDeadline
                      deadline={aggregate(
                        project?.invoices,
                        "periodOfPayment",
                        aggregateMinDate
                      )}
                    />
                  </div>
                  <div className="truncate">
                    {formatDateCell(
                      aggregate(
                        project?.invoices,
                        "paymentReceived",
                        aggregateMinDate
                      )
                    )}
                  </div>
                  <div className="truncate">
                    <Link to={`/projects/${project?.id}/invoicing`}>
                      <PencilIcon className="w-5 h-5" />
                    </Link>
                  </div>
                </li>
                {expanded.includes(project.id)
                  ? project?.invoices?.map((invoice: any) => (
                      <li
                        key={invoice.id}
                        className="ml-8 border-none mb-3 rounded-2xl grid grid-cols-[5%_17%_8%_8%_8%_8%_7%_7%_6%_8%_6%_6%] justify-between items-center bg-white font-special text-sm"
                      >
                        <div className="truncate py-4">
                          {formatInvoiceStatus(invoice.invoiceStatus)}
                        </div>
                        <div className="truncate">
                          {formatCustomer(project)}
                        </div>
                        <div className="truncate">
                          #{project?.projectDisplayId}
                        </div>
                        <div className="truncate">{invoice?.invoiceNumber}</div>
                        <div className="truncate">
                          {formatCurrency(invoice?.netIncome)}
                        </div>
                        <div className="truncate">
                          {formatCurrency(invoice?.expenses)}
                        </div>
                        <div className="truncate">
                          {formatCurrency(invoice?.vat)}
                        </div>
                        <div className="truncate">
                          {formatCurrency(invoice?.grossInvoiceAmount)}
                        </div>
                        <div className="truncate">
                          {formatDateCell(invoice?.outgoingInvoiceDate)}
                        </div>
                        <div className="truncate font-normal">
                          <DaysToDeadline deadline={invoice?.periodOfPayment} />
                        </div>
                        <div className="truncate">
                          {formatDateCell(invoice?.paymentReceived)}
                        </div>
                        <div className="truncate">
                          <Link to={`/projects/${project?.id}/invoicing`}>
                            <PencilIcon className="w-5 h-5" />
                          </Link>
                        </div>
                      </li>
                    ))
                  : null}
              </div>
            );
          })}
        </InfiniteScroll>
        {data?.length === 0 ? (
          <Card className="text-center font-semibold font-special">
            Keine Daten gefunden
          </Card>
        ) : null}
      </ul>
    </>
  );
};
const aggregateMinDate = (a: any, b: any) => {
  if (a?.isValid() && b?.isValid()) {
    return a?.isBefore(b) ? a : b;
  } else {
    if (a?.isValid()) {
      return a;
    } else {
      if (b?.isValid()) {
        return b;
      } else {
        return null;
      }
    }
  }
};

const aggregateSum = (a: any, b: any) => {
  const parseNum = (num: any) => (num ? parseFloat(num) : 0.0);
  const intA = parseNum(a),
    intB = parseNum(b);
  return intA + intB;
};

const aggregate = (invoices: any, key: string, aggregateFn = aggregateSum) => {
  return invoices
    ?.map((i: any) => i?.[key])
    ?.reduce((acc: any, i: any) => aggregateFn(acc, i), undefined);
};

const formatDateCell = (info: any) => (
  <span className="font-semibold font-special">
    {info?.isValid ? dayjsDateFormatter(info) : "-"}
  </span>
);

export function buildQueryStringInvoice(
  globalSearchFilter: string | null | undefined,
  reset: boolean,
  pagesLoaded: number,
  sortBy: string | null | undefined,
  statusFilter: (string | null)[] | null | undefined,
  customerFilter: (number | null)[] | null | undefined,
  dateFilter: { from: Dayjs; until: Dayjs } | undefined,
  invoiceStatusFilter:
    | (
        | "offen"
        | "ausstehend"
        | "bezahlt"
        | "zahlungserinnerung"
        | "mahnung1"
        | "mahnung2"
      )[]
    | undefined
    | null,
  contractorFilter: ("naxis" | "schraeder" | "esgium")[] | undefined | null
) {
  let outgoingInvoiceDateBetweenFilter = {};

  if (!invoiceStatusFilter?.includes("offen")) {
    outgoingInvoiceDateBetweenFilter = {
      $or: [
        {
          fullfilmentDate: {
            $between: [
              dateFilter?.from.format("YYYY-MM-DD"),
              dateFilter?.until.format("YYYY-MM-DD"),
            ],
          },
        },
        { fullfilmentDate: { $null: true } },
      ],
    };
  }

  const invoiceStatusOrFilter = {
    $or: invoiceStatusFilter?.map(
      (field: InvoiceStatusKey) => invoiceStatusFilterMap[field]
    ),
  };

  return QueryString.stringify(
    {
      pagination: {
        page: reset ? 1 : pagesLoaded,
        pageSize: 50,
      },
      populate: {
        project: {
          populate: {
            customer: true,
          },
        },
      },
      sort: sortBy,
      filters: {
        $and: [
          {
            project: {
              $and: [
                {
                  status: {
                    $in: statusFilter,
                  },
                },
                {
                  customer: {
                    id: { $in: customerFilter },
                  },
                },
                {
                  contractor: { $in: contractorFilter },
                },
              ],
            },
          },
          {
            ...outgoingInvoiceDateBetweenFilter,
          },
          {
            ...invoiceStatusOrFilter,
          },
          { $or: [
            {
              invoiceNumber: {
                $contains: globalSearchFilter
              }
            },
            {
              project: {
                displayId: {
                  $contains: globalSearchFilter
                }
              }
            }
          ]}
        ],
      },
    },
    { encodeValuesOnly: true }
  );
}
