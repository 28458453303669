import React from "react";
import { ClientTypeFormatter } from "../../common/client-type-formatter";
import { contractorMap } from "../../common/contractor-map";
import { Card } from "../../components/basics/card";
import { MagicUpdate } from "../../components/magic-update/magic-update";
import { ICustomer } from "../../model/customer";
import { formatCurrency } from "../../shared/utils/formatters";

export const BaseData = ({ project, reload }: any) => {
  return (
    <>
      <Card className="bg-green-100">
        <h2 className="text-xl font-semibold">Stammdaten</h2>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3 text-sm">
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            gqlType="ID"
            type="CUSTOMER"
            field="customer"
            label="Auftraggeber"
            value={project?.project?.data?.attributes?.customer?.data}
            formatter={(customer: ICustomer) => (customer?.attributes?.name)}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            gqlType="ID"
            type="CLIENT_TYPE"
            field="clientType"
            label="Auftraggeberart"
            value={project?.project?.data?.attributes?.clientType?.data?.id}
            formatter={(id: string) =>(<ClientTypeFormatter id={id}/>)}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="DoubleDigitYear"
            gqlType="String"
            field="displayId"
            label="Auftragsnummer"
            value={project?.project?.data?.attributes?.displayId}
          />
          <MagicUpdate
            model="building"
            id={project?.project?.data?.attributes?.building?.data?.id}
            reload={reload}
            type="String"
            field="name"
            label="Objektname"
            value={project?.project?.data?.attributes?.building?.data?.attributes?.name}
          />
          <MagicUpdate
            model="building"
            id={project?.project?.data?.attributes?.building?.data?.id}
            reload={reload}
            type="ENUM_BUILDING_TYPE"
            field="type"
            label="Objektart 1. Ebene"
            value={project?.project?.data?.attributes?.building?.data?.attributes?.type}
          />
          <MagicUpdate
            model="building"
            id={project?.project?.data?.attributes?.building?.data?.id}
            reload={reload}
            type="ENUM_BUILDING_SUBTYPE"
            field="subtype"
            label="Objektart 2. Ebene"
            value={project?.project?.data?.attributes?.building?.data?.attributes?.subtype}
            extra={{ buildingType: project?.project?.data?.attributes?.building?.data?.attributes?.type  }}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="ENUM_PROJECT_TYPE"
            field="type"
            label="Auftragsart"
            value={project?.project?.data?.attributes?.type}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="String"
            field="reasonForRating"
            label="Bewertungsgrund"
            value={project?.project?.data?.attributes?.reasonForRating}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="String"
            field="invoiceTo"
            label="Rechungsempfänger"
            value={project?.project?.data?.attributes?.invoiceTo}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="String"
            field="owner"
            label="Eigentümer/ Verfahren/ Fonds"
            value={project?.project?.data?.attributes?.owner}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="Date"
            field="dateOfReceipt"
            label="Auftragseingang"
            value={project?.project?.data?.attributes?.dateOfReceipt}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="Date"
            field="dateOfVisit"
            label="Besichtigungstermin"
            value={project?.project?.data?.attributes?.dateOfVisit}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="ENUM_CONTRACTOR"
            gqlType="ENUM_PROJECT_CONTRACTOR"
            formatter={(val) => (contractorMap[val]?.displayString)}
            field="contractor"
            label="Auftragnehmer"
            value={project?.project?.data?.attributes?.contractor}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="String"
            field="mainAppraiser"
            label="Hauptgutachter"
            value={project?.project?.data?.attributes?.mainAppraiser}
          />
          <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="String"
            field="coAppraiser1"
            label="Nebengutachter 1"
            value={project?.project?.data?.attributes?.coAppraiser1}
          />
          <MagicUpdate
          model="project"
          id={project?.project?.data?.id}
          reload={reload}
          type="String"
          field="coAppraiser2"
          label="Nebengutachter 2"
          value={project?.project?.data?.attributes?.coAppraiser2}
        />
        <MagicUpdate
            model="project"
            id={project?.project?.data?.id}
            reload={reload}
            type="Boolean"
            field="isCoappraiser"
            label="Nebengutachter?"
            value={
              project?.project?.data?.attributes?.isCoappraiser
            }
          />
          <MagicUpdate
            model="project"
            id={
              project?.project?.data?.id
            }
            reload={reload}
            type="String"
            field="loraId"
            label="externe Objekt-ID"
            className="text-sm"
            value={
              project?.project?.data?.attributes?.loraId
            }
          />
          <MagicUpdate
            model="project"
            id={
              project?.project?.data?.id
            }
            reload={reload}
            type="Number"
            gqlType="Int"
            field="numberOfRating"
            label="Nr. der Folgebewertung"
            className="text-sm"
            value={
              project?.project?.data?.attributes?.numberOfRating
            }
          />
          <MagicUpdate
            model="building"
            id={
              project?.project?.data?.attributes?.building?.data?.id
            }
            reload={reload}
            type="Number"
            gqlType="Float"
            field="marketValue"
            label="Verkehrswert"
            className="text-sm"
            formatter={formatCurrency}
            value={
              project?.project?.data?.attributes?.building?.data?.attributes?.marketValue
            }
          />
        </div>
      </Card>
      <Card className="bg-green-100 mt-4">
        <h2 className="text-xl font-semibold">Adresse</h2>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3">
          <MagicUpdate
            model="address"
            id={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.id
            }
            reload={reload}
            type="String"
            field="street"
            label="Straße"
            value={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.attributes?.street
            }
          />
          <MagicUpdate
            model="address"
            id={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.id
            }
            reload={reload}
            type="String"
            field="streetNumber"
            label="Hausnummer"
            value={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.attributes?.streetNumber
            }
          />
          <MagicUpdate
            model="address"
            id={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.id
            }
            reload={reload}
            type="String"
            field="zip"
            label="PLZ"
            value={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.attributes?.zip
            }
          />
          <MagicUpdate
            model="address"
            id={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.id
            }
            reload={reload}
            type="String"
            field="city"
            label="Ort"
            value={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.attributes?.city
            }
          />
          <MagicUpdate
            model="address"
            id={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.id
            }
            reload={reload}
            type="COUNTRY"
            gqlType="String"
            field="country"
            label="Land"
            className="text-sm"
            value={
              project?.project?.data?.attributes?.building?.data?.attributes
                ?.address?.data?.attributes?.country
            }
          />
        </div>
      </Card>
    </>
  );
};
