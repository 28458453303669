import { IKeyValuePair } from "../shared/utils/entity-utils";

export function extractKey(pair: IKeyValuePair) {
  return pair.key;
}

export function compareArrayWithKeyValuePairArrayByKey(
  arr1: any[],
  arr2: IKeyValuePair[]
) {
  if (arr1.length !== arr2.length) {
    return false;
  } else {
    return arr2.every((item) => arr1.includes(item.key));
  }
}

export function compareArray(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false;
  } else {
    return arr2.every((item) => arr1.includes(item));
  }
}
