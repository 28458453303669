import React, { useEffect, useState } from 'react';
import { ICustomer } from '../../model/customer';
import { classNames } from '../../shared/utils/class-names';
import { CustomerSearchDropDown } from '../customer-search-dropdown/customer-search-dropdown';
import { IUpdateField } from './types';

export const CustomerUpdateField: IUpdateField<ICustomer> = ({ label, value, onUpdate, className='' }) => {
  const [ selected, setSelected ] = useState<any>();

  useEffect(() => {
    if(!selected) {
      setSelected(value);
    }
  }, [value]);

  return (
    <CustomerSearchDropDown
      onChange={(newVal:any) => { if(newVal?.id) { onUpdate(newVal.id); setSelected(newVal); } }}
      selected={selected}
      error={false}
      className = {classNames(className, 'text-sm')}
    />
  );
};
