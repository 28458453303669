import React from "react";
import axios from "axios";
import {saveAs} from "file-saver";
import {toast} from "react-toastify";
import {getItemTransparent} from "../../shared/utils/storage-utils";

export interface IAuthDownload {
    href: string
    filename: string
    children: React.ReactNode
    [rest:string]: any
}

export const AuthDownload = ({ href, filename, children, ...rest }: IAuthDownload) => {
    async function fetchData() {
        try {
            const response = await axios.get(
                href,
                {
                    responseType: "blob",
                    headers: {
                        Authorization: "Bearer " + getItemTransparent("JWT"),
                    },
                }
            );

            saveAs(response.data, filename);
        } catch(e) {
            toast.error("Sie haben keine Berechtigung, die Datei herunterzuladen.");
        }
    }

    return <a href={"#"} onClick={() => { fetchData(); }}
    {...rest}>{children}</a>
}