import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import { CustomerList } from './customers-list';

const Layout = () => {
  return <div className='flex flex-col'>
      <div>
        <Outlet />
      </div>
  </div>
}

const CustomerRoute = () => {
  return <Routes>
    <Route path='/' element={<Layout />}>
      <Route index element={<CustomerList />} />
    </Route>
  </Routes>
}

export default CustomerRoute;