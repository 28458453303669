import { Bar } from "react-chartjs-2";
import { renderCountryBarChartTooltip } from "../../employees/line-chart/chart-tooltip";
import { PreparedDataType, prepareFlatData } from "./helpers";
import { PreparedFlatData } from "./references-by-project-type";
import Alert from "../../../../components/layout/alert";
import { ReferenceByCountryBarChartProps } from "./references-by-country-card";

const prepareData = ({ labels, aggregatedData }: PreparedFlatData) => {
  return {
    labels: labels.map((label, index) => {
      return `${label} (${aggregatedData[index]})`;
    }),
    datasets: [
      {
        data: aggregatedData,
        backgroundColor: "#b1c5ab",
        hoverBackgroundColor: "#004f14",
        borderRadius: [5],
      },
    ],
  };
};

const options = {
  indexAxis: "y" as const,
  interaction: {
    axis: "x",
    mode: "nearest",
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        font: {
          family: "Roboto",
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          family: "Roboto",
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      external: renderCountryBarChartTooltip,
    },
  },
};

const interactionOptions: any = {
  mode: "y",
  intersect: false,
};

export const ReferencesByCountryBarChart: React.FC<
  ReferenceByCountryBarChartProps
> = ({ data, width, height }) => {
  const preparedFlatData =
    data && data.length > 0
      ? prepareFlatData(
          data.filter(
            (item) => item.building.address.country !== "Deutschland"
          ),
          (item: any) => item.building.address.country,
          PreparedDataType.COUNT
        )
      : { labels: [], aggregatedData: [] };

  return preparedFlatData?.aggregatedData?.length > 0 ? (
    <Bar
      data={prepareData(preparedFlatData)}
      options={{
        ...options,
        plugins: {
          ...options.plugins,
        },
        interaction: interactionOptions,
      }}
      width={width}
      height={height}
    />
  ) : (
    <Alert
      title="Keine Daten vorhanden"
      message="Anderen Filter wählen"
      severity="info"
    />
  );
};
