import React, {ReactNode} from 'react';
import {gql, useMutation} from '@apollo/client';
import {toast} from 'react-toastify';
import {Spinner} from '../../../common/spinner/base-spinner';

export interface IRemoveFile {
  fileToRemove: string
  afterDelete: () => void
  children: ReactNode
}

const deleteFile = gql`
mutation deleteMedia($mediaId: ID!) {
  deleteUploadFile(id: $mediaId) {
    data {
      id
    }
  }
}
`;

export const RemoveFile = ({ fileToRemove, afterDelete, children }: IRemoveFile) => {

  const [updateProject, { loading }] = useMutation(deleteFile);

  if(loading) {
    return <Spinner className='h-3 w-3' />
  }

  return (
    <button className='h-3 w-3' onClick={async () => {
      try {
        await updateProject({
          variables: {
            mediaId: fileToRemove,
          }
        });
        toast.info("Datei wurde entfernt.");
        afterDelete();
      } catch (e) {
        toast.error("Datei konnte nicht entfernt werden.")
      }
    }}>
      {children}
    </button>
  )
}