export const buttonClasses =  [
  'rounded-xl',
  'font-special',
  'b-2',
  'p-2',
  'outline-none',
  'ring-0',
  'focus-visible:border-0',
  'focus-visible:ring-0',
  'focus-visible:outline-0'
];

export const defaultButtonClasses = [
  ...buttonClasses,
  'bg-transparent',
  'text-gray-500',
  'border',
];

export const primaryButtonClasses = [
  ...buttonClasses,
  'text-white',
  'bg-green-500',
];

export const warnButtonClasses = [
  ...buttonClasses,
  'text-white',
  'bg-red-500'
];