import Dropzone, { IDropzoneProps, IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { getItemTransparent } from "../../shared/utils/storage-utils";
import { getLoginUrl } from "../../shared/utils/url-utils";
import { toast } from "react-toastify";

export const PortraitUpload = ({ file, onChange }: { file?: File, onChange: Function }) => {

    const getUploadParams: IDropzoneProps["getUploadParams"] = ({
        file,
        meta,
      }) => {
        const body = new FormData();
        body.append("files", file);
    
        const headers = {
          Authorization: `Bearer ${getItemTransparent("JWT")}`,
        };
        return {
          url: `${getLoginUrl()}/api/upload`,
          body,
          headers,
          method: "POST",
          meta,
          withCredentials: true,
        };
      };

      const handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => {
        switch (status) {
          case "done": {
            const responseText = file?.xhr?.responseText;
            if(responseText) {
                const json = JSON.parse(responseText);
                onChange(json?.[0]?.id);
                toast.info(`${file.meta.name} erfolgreich hochgeladen!`);
            } else {
                toast.error(`${file.meta.name}, hochladen fehlgeschlagen.`);
            }
            break;
          }
          case "aborted": {
            toast.error(`${file.meta.name}, hochladen fehlgeschlagen.`);
            break;
          }
          case "rejected_file_type": {
            toast.error(`${file.meta.name}, Dieser Dateityp ist hier nicht erlaubt.`);
            break;
          }
        }
      };

    return (
        <Dropzone
            key={"portrait"}
            maxFiles={1}
            multiple={false}
            canCancel={false}
            accept=".png,.jpg,.jpeg"
            inputContent={
                <div className="text-gray-500 flex flex-col items-center justify-center h-28 text-sm">
                        <span className="text-green-500">Datei ablegen</span> oder Klicken
                </div>
            }
            onChangeStatus={handleChangeStatus}
            getUploadParams={getUploadParams}
            submitButtonDisabled={true}
            addClassNames={{
                dropzone: "w-28 h-28 rounded-full bg-green-100 cursor-pointer",
                dropzoneActive: "border-green-500 border-2",
                dropzoneReject: "border-red-500 border-2 bg-red-100 text-red-500",
                previewImage: "rounded-full w-28 h-28",
                preview: "hide-progress",
                input: "hidden",
            }}
        />
    )

}