import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "../../common/spinner/base-spinner";
import { Card } from "../../components/basics/card";
import { ProjectListWrapper } from "../../components/project-list/project-list-wrapper";
import { getItemTransparent } from "../../shared/utils/storage-utils";
import { Metric } from "./metric";
import { OrderOverview } from "./order-overview";
import { StatusOverview } from "./status-overview";
import { EmployeesSelect } from "../../components/filters/employee-dropdown/employee-select";
import {
  calcActive,
  calcComplete,
  dateRangeFilter,
  useSimpleStatsEndpoint,
  useStatsEndpoint
} from "./apiHooks";
import { formatCurrency } from "../../shared/utils/formatters";
import {
  BellIcon,
  ClockIcon, CreditCardIcon, CurrencyEuroIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon, QueueListIcon
} from "@heroicons/react/24/outline";
import { classNames } from "../../shared/utils/class-names";
import dayjs from "dayjs";
import { User } from "../../model/users/user";
import { TooltipManager } from "../../components/basics/tooltip-manager";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "../../components/basics/tooltip";

// ! this is not the strapi filter format, but the format of the project-list-wrapper
const calculateRoleBasedFilter = (role: string) => {
  if (["geschaftsfuhrer", "teamleiter", "sachbearbeiter", "superadmin"].includes(role)) {
    return {
      status: ["Auftragsanlage", "Bearbeitung", "KorrekturIntern", "KorrekturNG"],
      serviceLevelNull: true,
    }
  }
  if (["admin"].includes(role)) {
    return {
      status: ["Vorlauf", "Auftragsanlage", "Finalisierung", "Abrechnung"],
      serviceLevelNull: true,
    }
  }
  return {}
}

const calculateProjectListFilter = (user: User) => {
  const roleBasedFilter = calculateRoleBasedFilter(user?.role?.type);
  return {
    ...roleBasedFilter,
    employees: [user?.id],
  }
}

const calculateSortBy = (role: string) => {
  return {
    sortBy: "servicelevel",
    direction: "asc"
  }
}

export const Home = () => {
  const [employees, setEmployees] = useState<(string)[]>([]);

  const [_13, _14, last12MonthSelectedByStatus, _15] = useStatsEndpoint({ endpoint: "byStatus", employees, defaultFilter: dateRangeFilter('deadlineDate', { from: dayjs().subtract(1, 'year'), until: dayjs() }) });
  const [allByStatus, _16, selectedByStatus, byStatusLoading] = useStatsEndpoint({endpoint: "byStatus", employees, defaultFilter: { displayId: { $startsWith: dayjs().format('YY')} }});
  const [_1, _2, selectedByMonthCurrentYear = [], byMonthLoadingCurrentYear] = useStatsEndpoint({endpoint: "byMonthAndType", employees, defaultFilter: { displayId: { $startsWith: dayjs().format('YY')} }});
  const [_9, _10, selectedByMonthLastYear = [], _17] = useStatsEndpoint({endpoint: "byMonthAndType", employees, defaultFilter: { displayId: { $startsWith: dayjs().subtract(1, 'year').format('YY')} }});
  const [_11, _12, selectedByMonthTwoYearsAgo = [], _18] = useStatsEndpoint({endpoint: "byMonthAndType", employees, defaultFilter: { displayId: { $startsWith: dayjs().subtract(2, 'year').format('YY')} }});
  const [allOverdue, _3, selectedOverdue, _4] = useStatsEndpoint({ endpoint: "overdueCount", employees, defaultValue: 0, errorValue: 0});
  const [totalOverdueInvoices, _5, selectedOverdueInvoices, _6] = useStatsEndpoint({ endpoint: "overdueInvoicesCount", employees, defaultValue: 0, errorValue: 0});
  const [totalOutstandingInvoices, _7, selectedOutstandingInvoices, _8] = useStatsEndpoint({ endpoint: "outstandingInvoicesCount", employees, defaultValue: 0, errorValue: 0});
  const { totalInvoiceAmount, outstandingInvoiceAmount } = useSimpleStatsEndpoint(
    "completeNetInvoiceAmount",
    {},
    { totalInvoiceAmount: 0, outstandingInvoiceAmount: 0 }
  );

  const user = getItemTransparent("user");
  const showBasicInvoiceStats = ["superadmin", "geschaftsfuhrer", "admin"].includes(user.role?.type);
  const showAdvancedInvoiceStats = ["superadmin", "geschaftsfuhrer"].includes(user.role?.type);

  const projectListFilter = calculateProjectListFilter(user);
  const sortBy = calculateSortBy(user?.role?.type);

  return (
    <TooltipManager>
      <div className="flex">
        <span className="flex-grow"></span>
        <EmployeesSelect
          selected={employees}
          onChange={setEmployees}
        />
      </div>
      <div className="h-full flex flex-col lg:flex-row gap-4 mt-2 ">
        <Card className="flex-grow">
          <h2 className="uppercase font-semibold font-special text-lg float-left">
            STATUSÜBERSICHT
          </h2>
          <div className="inline-block h-6 p-0.5">
            <Tooltip
            id="status-overview"
            align="left"
            tooltipContent={<p>
              Die Statusübersicht bezieht sich auf die letzen 12 Monate</p>
            }
          >
            <InformationCircleIcon className="w-5 mx-2 text-gray-500" />
          </Tooltip>
          </div>
          {byStatusLoading ? (
            <div className="flex flex-row items-center justify-center w-full h-[24.5rem]">
              <Spinner className="w-8 h-8 stroke-gray-500" />
            </div>
          ) : (
            <div className="h-full flex flex-row items-center justify-center pr-8 p-4">
              <StatusOverview data={last12MonthSelectedByStatus} />
            </div>
          )}
        </Card>
        <div className={classNames("lg:w-3/5 lg:min-w-3/5 grid gap-4 content-between xl:content-evenly", showAdvancedInvoiceStats ? "grid-cols-2 md:grid-cols-4" : "grid-cols-2 md:grid-cols-3")}>
          {byStatusLoading ? (
            <div className="flex flex-row items-center justify-center w-full">
              <Spinner className="w-8 h-8 stroke-gray-500" />
            </div>
          ) : (
            <>
              <Metric
                name={<>Aufträge<br /> gesamt</>}
                number={calcComplete(selectedByStatus)}
                smallNumber={calcComplete(allByStatus)}
                icon={<QueueListIcon />}
                linkTarget="project-list/cards"
                className="bg-[#96B69F]"
                infoText={<><h4 className="font-bold">Oben:</h4>Mitarbeiter<h4 className="font-bold">Unten:</h4>(Gesamt)<br/><br/> Bezieht sich auf das aktuelle Jahr ({dayjs().year()})</>}
              />
              <Metric
                name={<>aktive<br /> Aufträge</>}
                number={calcActive(selectedByStatus)}
                smallNumber={calcActive(allByStatus)}
                linkTarget="/project-list/cards?status=Vorlauf&status=Auftragsanlage&status=Bearbeitung&status=KorrekturIntern&status=KorrekturNG&status=KorrekturAG&status=Finalisierung"
                icon={<BellIcon />}
                className="bg-[#f59d2e]"
                infoText={<><h4 className="font-bold">Oben:</h4>Mitarbeiter<h4 className="font-bold">Unten:</h4>(Gesamt)<br/><br/> Bezieht sich auf das aktuelle Jahr ({dayjs().year()})</>}
              />
              <Metric
                name={<>abgeschlossene<br /> Aufträge</>}
                number={selectedByStatus?.["Abgeschlossen"]}
                smallNumber={allByStatus?.["Abgeschlossen"]}
                linkTarget="/project-list/cards?status=Abgeschlossen"
                icon={<DocumentCheckIcon />}
                className="bg-green-500"
                infoText={<><h4 className="font-bold">Oben:</h4>Mitarbeiter<h4 className="font-bold">Unten:</h4>(Gesamt)<br/><br/> Bezieht sich auf das aktuelle Jahr ({dayjs().year()})</>}

              />
              <Metric
                name={<>überfällige<br /> Aufträge</>}
                number={selectedOverdue}
                smallNumber={allOverdue}
                icon={<ExclamationTriangleIcon />}
                className="bg-[#db5353]"
                infoText={<><h4 className="font-bold">Oben:</h4>Mitarbeiter<h4 className="font-bold">Unten:</h4>(Gesamt)<br/><br/> Bezieht sich auf das aktuelle Jahr ({dayjs().year()})</>}
              />
              {
                showAdvancedInvoiceStats && <>
                  <Metric
                    name={<>Rechnungsbetrag<br /> gesamt</>}
                    number={formatCurrency(totalInvoiceAmount,0)}
                    icon={<CurrencyEuroIcon />}
                    linkTarget={`/invoices?from=${dayjs().startOf('year').format('YYYY-MM-DD')}&until=${dayjs().endOf('year').format('YYYY-MM-DD')}`}
                    className="bg-[#96B69F]"
                    infoText={<>Bezieht sich auf das aktuelle Jahr ({dayjs().year()})</>}

                  />
                  <Metric
                    name={<>Rechnungsbetrag<br /> ausstehend</>}
                    number={formatCurrency(outstandingInvoiceAmount,0)}
                    icon={<CreditCardIcon />}
                    linkTarget="/invoices?customerStatus=&from=1970-01-01&until=2999-12-31&invoiceStatus=ausstehend"
                    infoText={<>gesamt (inkl. Vorjahren)</>}
                    className="bg-[#f59d2e]"
                  />
                </>
              }
              {
                showBasicInvoiceStats && <>
                  <Metric
                    name={<>überfällige<br /> Rechnungen</>}
                    number={selectedOverdueInvoices}
                    smallNumber={totalOverdueInvoices}
                    icon={<ClockIcon />}
                    className="bg-[#db5353]"
                    infoText={<>gesamt (inkl. Vorjahren)</>}
                  />
                  <Metric
                    name={<>ausstehende<br /> Rechnungen</>}
                    number={selectedOutstandingInvoices}
                    smallNumber={totalOutstandingInvoices}
                    linkTarget="/invoices?invoiceStatus=offen&projectStatus=Finalisierung&from=1970-01-01&until=2999-12-31"
                    icon={<DocumentTextIcon />}
                    className="bg-green-500"
                    infoText={<>gesamt (inkl. Vorjahren)</>}
                  />
                </>
              }
            </>
          )}
        </div>
      </div>
      <div className="grid md:grid-cols-1 gap-4 mt-4">
        <Card>
          <h2 className="uppercase font-semibold font-special text-lg">
            AUFTRAGSÜBERSICHT { `${dayjs().subtract(2,'year').year()} - ${dayjs().year()}` }
          </h2>
          {byMonthLoadingCurrentYear ? (
            <div className="flex flex-row items-center justify-center w-full">
              <Spinner className="w-8 h-8 stroke-gray-500" />
            </div>
          ) : (
            <div>
              <OrderOverview data={{ twoYearsAgo: selectedByMonthTwoYearsAgo || [], lastYear: selectedByMonthLastYear || [], currentYear: selectedByMonthCurrentYear || [] }} />
            </div>
          )}
        </Card>
      </div>
      <div className="h-full grid md:grid-cols-1 gap-4 mt-4">
        <Card>
          <h2 className="uppercase font-semibold font-special text-lg mb-4">MEINE AUFTRÄGE</h2>
          <ProjectListWrapper startCount={15} filter={projectListFilter} sortBy={sortBy} />
          <div className="text-center w-full mt-2 font-special text-green-500">
            <Link to="/project-list/cards" title="Alle Projekte">
              ALLE AUFTRÄGE
            </Link>
          </div>
        </Card>
      </div>
    </TooltipManager>
  );
};

export default Home;
