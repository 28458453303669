import React from "react";

export const SidebarCompletionStatus = ({ projectStatus }: any) => {
  return (
    <div className='flex flex-col'>
      <div className='text-right text-sm text-gray-600'>{(projectStatus?.percentage || 0) * 100} %</div>
      <div className="w-full bg-gray-200 h-1 rounded">
        <div
          className="bg-orange-500 h-1 rounded"
          style={{ width: `${(projectStatus?.percentage || 0) * 100}%` }}
        ></div>
      </div>
    </div>
  );
};
