import ReactDOM from "react-dom";
import { ReactElement } from "react";
import { formatCurrency, formatLargeCurrency } from "../../../../shared/utils/formatters";
import { sumReducer } from "../../references/charts/helpers";

function addTooltipRoot() {
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = '<div id="tooltip-inner"></div>';
    document.body.appendChild(tooltipEl);
  }

  return tooltipEl;
}

export function renderTooltip(
  tooltip: ({ context }: any) => ReactElement,
  positionFn: (context: any) => SimpleTooltipPosition
) {
  return function (context: any) {
    // Tooltip Element
    const tooltipEl = addTooltipRoot();
    const tooltipModel = context.tooltip;

    ReactDOM.render(
      <div
        className="absolute shadow-2xl"
        style={{
          ...positionFn(context),
          opacity: tooltipModel.opacity,
        }}
      >
        {tooltip({ context })}
      </div>,
      tooltipEl
    );
  };
}

interface SimpleTooltipPosition {
  left: string;
  top: string;
}

export function calculateAveragePosition(context: any): SimpleTooltipPosition {
  const tooltipModel = context.tooltip;
  const { offsetLeft, offsetTop } = context.chart.canvas;
  const position: SimpleTooltipPosition = {
    left: offsetLeft + tooltipModel.caretX - 192 + "px",
    top: offsetTop + tooltipModel.caretY / 2 + "px",
  };
  return position;
}

export const LineChartTooltip = ({ context }: any) => {
  const tooltipModel = context.tooltip;
  const bodyLines = tooltipModel.body.map((body: any) => body.lines);
  const preparedBodyItems = prepareLabels(bodyLines);
  const labelColors = tooltipModel.labelColors;

  return (
    <div className="bg-white shadow-md rounded-2xl absolute w-96 p-4 pt-6 pointer-events-none transition-all ease-in duration-100">
      {Object.keys(preparedBodyItems).map((year: any, i: number) => (
        <div key={`label-year-${i}`} className="flex flex-row justify-center mb-2">
          <div className="w-1/3 font-special font-semibold">{year}</div>
          {preparedBodyItems[year].map((item: any, j: number) => (
            item?.label !== "undefined" ?
            <div key={`label-year-${i};${j}`} className="w-1/3 flex flex-row gap-2">
              <div
                className="w-3 h-4 rounded-xl"
                style={{
                  backgroundColor:
                    labelColors?.[j * Object.keys(preparedBodyItems).length + i]
                      ?.backgroundColor || "#ccc",
                }}
              ></div>
              <div className="flex flex-col items-center">
                <div className="font-semibold font-special w-full">
                  {item.value
                    ? formatCurrency(
                        parseFloat(item.value.trim().replace(".", ""))
                      )
                    : "-"}
                </div>
                <div className="font-light font-special w-full">
                  {item.label}
                </div>
              </div>
            </div> : null
          ))}
        </div>
      ))}
    </div>
  );
};

// This needs an alignment of right center for the vertical bars.
// For positioning of elements see: https://www.chartjs.org/docs/latest/configuration/tooltip.html
export function calculateBarChartPosition(context: any): SimpleTooltipPosition {
  const tooltipModel = context.tooltip;
  const { offsetLeft, offsetTop } = context.chart.canvas;
  const activeBarElement = tooltipModel._active?.[0]?.element

  const position: SimpleTooltipPosition = {
    left: offsetLeft + tooltipModel.caretX - (activeBarElement?.width || 0)/2 - 64 + "px",
    top: offsetTop + tooltipModel.caretY - (activeBarElement?.height || 0) + "px",
  };
  return position;
}

export function calculateCountryBarChartPosition(context: any): SimpleTooltipPosition {
  const tooltipModel = context.tooltip;
  const { offsetLeft, offsetTop } = context.chart.canvas;
  const activeBarElement = tooltipModel._active?.[0]?.element

  const position: SimpleTooltipPosition = {
    left: offsetLeft + tooltipModel.caretX - (activeBarElement?.width || 0)/2 - 40 + "px",
    top: offsetTop + tooltipModel.caretY - (activeBarElement?.height || 0) - 10 + "px",
  };
  return position;
}

export const BarChartTooltip = ({ context }: any) => {
  const tooltipModel = context.tooltip;
  const bodyLines = tooltipModel.body.map((body: any) => body.lines);
  const value = bodyLines[0][0].split(":")[1] + "€";

  return (
    <div className="bg-white drop-shadow-md rounded-2xl w-32 absolute flex flex-col items-center font-special font-semibold p-2 pointer-events-none transition-all ease-in duration-100">
      {value}
    </div>
  );
};

export const CountryBarChartTooltip = ({ context }: any) => {
  const tooltipModel = context.tooltip;
  const bodyLines = tooltipModel.body.map((body: any) => body.lines);
  // As you can see, stuff is very specific to the datastructure provided for this chart. Not nice - but worth it here.
  const value = bodyLines[0][0].replace(/\./g, "");
  const dataset = context.chart.data?.datasets?.[0]?.data;
  const overall = dataset.reduce(sumReducer,0);
  const percentage = Math.floor(parseInt(value)/overall*100);

  return (
    <div className="bg-gray text-white drop-shadow-md rounded-2xl w-20 absolute flex flex-col items-center font-special font-semibold p-2 pointer-events-none transition-all ease-in duration-100">
      {percentage === 0 ? "< 1" : percentage} %
    </div>
  );
};

export const BuildingTypeBarChartTooltip = ({ context }: any) => {
  const tooltipModel = context.tooltip;
  const bodyLines = tooltipModel.body.map((body: any) => body.lines);
  // As you can see, stuff is very specific to the datastructure provided for this chart. Not nice - but worth it here.
  const pointsReplaced = bodyLines[0][0].replace(/\./g, "")
  const value = parseFloat(pointsReplaced);

  return (
    <div className="bg-gray text-white drop-shadow-md rounded-2xl w-32 absolute flex flex-col items-center font-special font-semibold p-2 pointer-events-none transition-all ease-in duration-100 truncate">
      {formatLargeCurrency(value)}
    </div>
  );
};



export const renderLineChartTooltip = renderTooltip(LineChartTooltip, calculateAveragePosition);
export const renderBarChartTooltip = renderTooltip(BarChartTooltip, calculateBarChartPosition);

export const renderCountryBarChartTooltip = renderTooltip(CountryBarChartTooltip, calculateCountryBarChartPosition);

export const renderBuildingTypeBarChartTooltip = renderTooltip(BuildingTypeBarChartTooltip, calculateBarChartPosition);

function prepareLabels(bodyLines: any) {
  return bodyLines
    .map((bl: any) => {
      const kv = bl[0].split(":");
      const val = kv[1];
      const key = kv[0].split("/");
      return [key[0], key[1], val];
    })
    .reduce((acc: any, line: string[]) => {
      if (line[0] && !Object.keys(acc).includes(line[0])) {
        acc[line[0]] = [];
      }
      if(line[2]){
        acc[line[0]].push({ label: line[1], value: line[2] });
      }
      return acc;
    }, {});
}
