import React from 'react';
import { buildingTypeKeyValuePairs } from '../../common/building-type-map';
import { FilterSelect } from '../filters/filter-select';
import { IUpdateField } from './types';

export const BuildingTypeUpdateField: IUpdateField<string> = ({ label, value, onUpdate, error, className }) => {
  const items = buildingTypeKeyValuePairs;
  return (
    <FilterSelect
      placeholder={label || "Objektart"}
      selected={items.find((item) => ( item.key === value ))}
      onChange={(newVal) => { onUpdate(newVal?.key) }}
      error={error}
      items={items}
      className={className}
      nonNullable
    />
  );
};
