import React, {ReactNode} from "react";
import {classNames} from "../../shared/utils/class-names";
import {Link, To} from "react-router-dom";
import { Tooltip } from "../../components/basics/tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

export interface IMetric {
  name: string | ReactNode;
  number: number | string;
  icon: ReactNode;
  linkTarget?: To | undefined,
  className?: string,
  smallNumber?: number | string,
  infoText?: string | ReactNode | undefined,
}

export const Metric = ({
                         name,
                         number,
                         icon,
                         linkTarget = undefined,
                         className = 'bg-green-500',
                         smallNumber = undefined,
                         infoText = undefined
                       }: IMetric) => {
  const layout = <>
    <div className="absolute w-full">
      <div className={classNames(className, "mx-auto text-white rounded-xl p-4 h-14 w-14")}>
        {icon}
      </div>
    </div>
    <div className={classNames("rounded-3xl bg-white p-4 mt-6 pt-8 text-center h-48")}>
      <h2 className="xl:text-2xl lg:text-xl md:text-lg text-base font-special font-semibold mt-8 whitespace-nowrap">{number || "0"}</h2>
      {smallNumber !== undefined ? <p className="text-gray-600 mb-2">({smallNumber})</p> : <div className="mb-2 h-[1em]">&nbsp;</div>}
      <p className="text-gray-700">{name}</p>
      <div className='absolute right-2 bottom-2 h-5'>
      { infoText ? (
      <Tooltip
            id={name}
            tooltipContent={
              infoText
            }
          >
            <InformationCircleIcon className="w-5 mx-2 text-gray-500" />
          </Tooltip>) : <div className="h-5">&nbsp;</div>}
        </div>
    </div>
  </>

  if (linkTarget) {
    return (
      <Link to={linkTarget} className="relative">
        {layout}
      </Link>
    );
  } else {
    return <div className="relative">
      {layout}
    </div>;
  }
};
