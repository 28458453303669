import axios, { AxiosResponse } from "axios";
import { Storage } from "../utils/storage-utils";
import { getLoginUrl } from "../utils/url-utils";
import { log } from "console";

export const logoutServer = async () => {
  clearAuthentication();
  redirectToLogin();
};

interface Credentials {
  identifier: string;
  password: string;
}

export const authenticate = (auth: Credentials) => {
  return axios.post<any>(`${getLoginUrl()}/api/auth/local`, auth);
};

export const login = async (
  identifier: string,
  password: string,
  rememberMe = false
) => {
  const result = await authenticate({ identifier, password });
  const response = result as AxiosResponse;
  const bearerToken = response?.data?.jwt;
  const user = response?.data?.user;
  if (bearerToken) {
    const completeUser = await getUser(user.id, bearerToken);
    if (rememberMe) {
      Storage.local.set("JWT", bearerToken);
      Storage.local.set("user", completeUser);
    } else {
      Storage.session.set("JWT", bearerToken);
      Storage.session.set("user", completeUser);
    }
  }
};

export const resetUser = async (userId: string) => {
  if(Storage.local.get("JWT")) {
    const user = await getUser(userId, Storage.local.get("JWT"));
    Storage.local.set("user", user);
  } else {
    if(Storage.session.get("JWT")) {
      const user = await getUser(userId, Storage.session.get("JWT"));
      Storage.session.set("user", user);
    } else {
      console.error("Kein eingeloggter Nutzer, Redirect zu Login");
      window.location.href = "/login";
    }
  }
}

// TODO: Make sensible populate rules.
export const getUser = async (userId: string, jwt: string) => {
  const response = await axios.get(`${getLoginUrl()}/api/users/${userId}?populate[0]=role&populate[1]=image`, {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  });
  return response?.data;
};

export const logout = async () => {
  await logoutServer();
};

export const redirectToLogin = () => {
  window.location.href = "/login";
};

export const clearAuthentication = () => {
  Storage.session.remove("JWT");
  Storage.local.remove("JWT");
  Storage.session.remove("user");
  Storage.local.remove("user");
};
