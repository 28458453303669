import React from 'react';
import { projectTypeKeyValuePairs } from '../../common/project-type-map';
import { FilterSelect } from '../filters/filter-select';
import { IUpdateField } from './types';

export const ProjectTypeUpdateField: IUpdateField<string> = ({ label, value, error, onUpdate, className='' }) => {
  const items = projectTypeKeyValuePairs;
  return (
    <FilterSelect
      placeholder={label || "Auftragsart"}
      selected={items.find((item) => ( item.key === value ))}
      onChange={(newVal) => { onUpdate(newVal?.key) }}
      items={items}
      error={error}
      className={className}
    />
  );
};
