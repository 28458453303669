import React, { useState } from "react";
import { ProjectMapContainer } from "./project-map-wrapper";
import { ProjectListFilterBar } from "../project-list-filter-bar";
import { useQueryParams } from "use-query-params";
import { mapQueryParamsToFilter, projectListQueryParams } from "../project-list-query-params";

export const ProjectListMap = () => {

  const [ filterQueryParams ] = useQueryParams(projectListQueryParams);

  return (
    <>
      <ProjectListFilterBar />
      <div className="border-2 border-gray-300 rounded-xl flex flex-col items-center pt-8 px-4">
        <ProjectMapContainer filter={mapQueryParamsToFilter(filterQueryParams)} />
      </div>
    </>
  );
};
