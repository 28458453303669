import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQueryParams } from "use-query-params";
import {
  getCurrentYearAsNumber,
  strToKeyValuePair,
} from "../../../components/project-list/project-utils";
import { Employee } from "../../../model/analytics/employees/analytics-employees";
import {
  IKeyValuePair,
  toKeyValuePair,
} from "../../../shared/utils/entity-utils";
import { FilterSelect } from "../../../components/filters/filter-select";
import { LineChartFilter } from "./employee-line-chart-filter";
import {
  employeeFilterParams,
  employeeTypeMap,
  EMPLOYEE_TYPE_FILTER,
  EMPLOYEE_YEARS_FILTER,
} from "./employees-table";

const yearsList = [...Array(getCurrentYearAsNumber() - 2011 - 1).keys()]
  .map((i) => dayjs().subtract(2, "year").subtract(i, "year").format("YYYY"))
  .map(strToKeyValuePair);

export const AnalyticsInvoicesFilterBar = ({
  employees,
  withEmployeeSelect,
}: {
  employees: Employee[];
  withEmployeeSelect: boolean;
}) => {
  const [selectedYear, setSelectedYear] = useState<string | null | undefined>(
    null
  );
  const [selectedPosition, setSelectedPosition] = useState<
    IKeyValuePair | null | undefined
  >(null);

  const [filterParams, setFilterParams] = useQueryParams(employeeFilterParams);

  const employeeTypeItems = toKeyValuePair(employeeTypeMap);

  useEffect(() => {
    setSelectedYear(filterParams[EMPLOYEE_YEARS_FILTER]?.toString());
    setSelectedPosition(
      employeeTypeItems.find(
        (item) => item.key === filterParams[EMPLOYEE_TYPE_FILTER]
      )
    );
  }, [filterParams, employees]);

  return (
    <div className="flex flex-row gap-2">
      <div className="w-32">
        <FilterSelect
          placeholder="Vergleichsjahr"
          selected={yearsList.find((y) => y.key === selectedYear)}
          onChange={(val: IKeyValuePair | null) => {
            setSelectedYear(val?.key);
            setFilterParams({
              ...filterParams,
              [EMPLOYEE_YEARS_FILTER]: val?.key ? parseInt(val?.key) : null,
            });
          }}
          items={yearsList}
        />
      </div>
      <div className="w-40">
        <FilterSelect
          placeholder="Mitarbeiterposition"
          selected={selectedPosition}
          onChange={(val: IKeyValuePair | null) => {
            setSelectedPosition(val);
            setFilterParams({
              ...filterParams,
              [EMPLOYEE_TYPE_FILTER]: val?.key,
            });
          }}
          items={employeeTypeItems}
        />
      </div>
      {withEmployeeSelect ? (
        <div className="w-full">
          <LineChartFilter employees={employees} />
        </div>
      ) : null}
    </div>
  );
};
