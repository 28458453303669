import {gql} from "@apollo/client";
import dayjs from "dayjs";
import {IProjectFilter} from "../filters/project-filter";
import {IKeyValuePair} from "../../shared/utils/entity-utils";

export function strToKeyValuePair(str: string): IKeyValuePair {
  return {key: str, label: str};
}

export function getCurrentYear(format = 'YY'): IKeyValuePair {
  const currentYear = dayjs().format(format);
  return strToKeyValuePair(currentYear);
}

export function getCurrentYearAsNumber(): number {
  return dayjs().year();
}

export function generateYears(format = 'YY', from = 2011): IKeyValuePair[] {
  return [...Array(getCurrentYearAsNumber() - from).keys()]
    .map(i => (dayjs().add(1, 'year').subtract(i, 'year').format(format)))
    .map(strToKeyValuePair);
}

// TODO: Refactor to REST
export const PROJECTS_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $filters: ProjectFiltersInput!, $sort: [String]) {
    projects(
      sort: $sort,
      pagination: { page: $page, pageSize: $pageSize },
      filters: $filters,
    ) {
      data {
        id
        attributes {
          name
          description
          type
          status
          customer {
            data {
              id
              attributes {
                name
              }
            }
          }
          contractor
          isCoappraiser
          mainAppraiser
          coAppraiser1
          coAppraiser2
          projectReceived
          deadlineDate
          displayId
          servicelevel
          owner
          rests
          editor {
            data {
              attributes {
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          appraiser {
            data {
              attributes {
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          images {
            data {
              attributes {
                url
              }
            }
          }
          clientAddress {
            data {
              attributes {
                street
                city
                zip
                line1
              }
            }
          }
          building {
            data {
              id
              attributes {
                name
                type
                constructionMeassures
                dateOfConstruction
                dateOfLastRenovation
                monumentProtection
                address {
                  data {
                    id
                    attributes {
                      street
                      streetNumber
                      city
                      zip
                      country
                      lat
                      lng
                    }
                  }
                }
              }
            }
          }
          successor {
            data {
              id
            }
          }
          predecessor {
            data {
              id
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageCount
          page
          pageSize
        }
      }
    }
  }
`;

export const buildFilter = (filter: IProjectFilter) => {
  const {status,
    projectTypes,
    employees,
    clientTypes,
    customers,
    contractor,
    appraiser,
    years,
    geoBounds,
    serviceLevelNull,
    history,
    buildingTypes1,
    buildingTypes2,
    deadlineStart,
    deadlineEnd,
    dateOfConstruction,
    valuation,
    useForResearch,
  } = filter;
  const conditions = [
    status && status.length > 0 ? { status: { in: [...status] } } : null,
    employees && employees?.length > 0 ?
      {or: [
        { editor: { id: { in: [...employees] } } },
        { editor2: { id: { in: [...employees] } } },
        { appraiser: { id: { in: [...employees] } } },
      ]} : null,
    // }, // GraphQL filter works with nested objects
    clientTypes && clientTypes.length > 0 ? { clientType: { id: { in: clientTypes } } } : null,
    customers && customers.length > 0 ? { customer: { id: { in: customers } } } : null,
    projectTypes && projectTypes.length > 0 ? { type: { in: [...projectTypes] } } : null,
    contractor && { contractor: { eq: contractor } },
    appraiser && { isCoappraiser: { eq: appraiser } },
    years && years.length > 0 ? {
      or: years.filter((year: string | null) => (year)).map((year: string | null) => ({ displayId: { startsWith: year }}))
    } : null,
    deadlineStart && deadlineEnd ? { deadlineDate: { between: [ dayjs(deadlineStart).format('YYYY-MM-DD'), dayjs(deadlineEnd).format('YYYY-MM-DD') ]} } : null,
    dateOfConstruction && { project_research: { originalYearOfConstructionAsYearNumber: { between: dateOfConstruction } } },
    geoBounds && {
      building: {
        address: {
          and: [
            {lat: {between: [geoBounds.getSouth(), geoBounds.getNorth()]}},
            {lng: {between: [geoBounds.getWest(), geoBounds.getEast()]}},
          ]
        }
      }
    },
    buildingTypes1 && buildingTypes1.length > 0 ? { building: { type: { in: [...buildingTypes1] } } } : null,
    buildingTypes2 && buildingTypes2.length > 0 ? { building: { subtype: { in: [...buildingTypes2] }}} : null,
    history && { successor: { id: { null: true } } },
    serviceLevelNull && { servicelevel: { notNull: true } },
    (valuation && valuation === "kp") && { project_research: { priceOfSale: { notNull: true } } },
    (valuation && valuation === "vkw") && { project_research: { marketValue: { notNull: true } } },
    useForResearch && { project_research: { useForResearch: { eq: true } }},
  ];
  // console.warn(conditions);
  return {
    and: [
      ...conditions.filter((cond: any) => (!!cond))
    ],
  };
};
