import { useQuery } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { ListBulletIcon, ChartBarIcon } from "@heroicons/react/24/outline";
import queryString from "query-string";
import React from "react";
import {
  matchPath,
  resolvePath,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { encodeQueryParams, useQueryParams } from "use-query-params";
import { h1Classes } from "../../../components/basics/typography-classes";
import Alert from "../../../components/layout/alert";
import { EMPLOYEE_QUERY } from "../../../model/analytics/employees/analytics-employees";
import { classNames } from "../../../shared/utils/class-names";
import { EmployeeFilterEmployeeSelect } from "./employee-filter-employee-select";
import { EmployeesChart } from "./employees-chart";
import { AnalyticsInvoicesFilterBar } from "./employees-filter-bar";
import { employeeFilterParams, EmployeesTable } from "./employees-table";

const listViews = [
  {
    id: "table-view",
    href: "/table",
    icon: ListBulletIcon,
    component: () => <EmployeesTable />,
  },
  {
    id: "chart-view",
    href: "/chart",
    icon: ChartBarIcon,
    component: EmployeesChart,
  },
];

export const EmployeeAnalytics = ({ parentPath }: { parentPath: string }) => {
  const location = useLocation();

  const { data: employees, error, loading } = useQuery(EMPLOYEE_QUERY, { variables: { needle: "" }});

  const selectedItem = listViews.find((listView) => {
    return matchPath(`${parentPath}${listView.href}`, location.pathname);
  });

  const [searchParams] = useQueryParams(employeeFilterParams);
  const navigate = useNavigate();

  const selectedIndex = selectedItem ? listViews.indexOf(selectedItem) : 0;

  const withEmployeeSelect = matchPath(`${parentPath}/chart`, location.pathname);

  if(error) {
    <Alert title={"Fehler beim Abholen der Daten vom Server"} message={JSON.stringify(error)} />
  }

  return (
    <>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(idx) => {
          const url = `${
            resolvePath(
              `${parentPath}${listViews[idx].href}`,
              location.pathname
            ).pathname
          }?${queryString.stringify(
            encodeQueryParams(employeeFilterParams, { ...searchParams })
          )}`;
          navigate(url);
        }}
      >
        <div className="flex flex-row justify-between">
          <h1 className={classNames(...h1Classes)}>Mitarbeiter</h1>
          { withEmployeeSelect ? <EmployeeFilterEmployeeSelect className="ml-4 w-80 flex-grow-0" employees={employees?.usersPermissionsUsers?.data} /> : null }
          <div className="w-full flex-grow"></div>
          <div className="flex flex-row items-center">
            <Tab.List className="flex p-1 space-x-1 bg-white rounded-xl">
              {listViews.map((listView) => (
                <Tab
                  key={listView.id}
                  className={({ selected }) =>
                    classNames(
                      "w-full py-1.5 leading-5 font-medium  rounded-lg",
                      selected
                        ? "bg-green-700 text-white"
                        : "text-green-700 bg-white"
                    )
                  }
                >
                  <listView.icon className="w-8 h-5" />
                </Tab>
              ))}
            </Tab.List>
          </div>
        </div>

        <Tab.Panels className="mt-2">
          <AnalyticsInvoicesFilterBar employees={employees?.usersPermissionsUsers?.data} withEmployeeSelect={!!withEmployeeSelect} />
          <div className="h-2"></div>
          <Routes>
            {listViews.map((listView) => (
              <Route
                key={listView.id}
                path={listView.href}
                element={<listView.component employees={employees?.usersPermissionsUsers?.data} />}
              />
            ))}
          </Routes>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};
