import React, { useEffect, useState } from "react";
import { ProfilePicture } from "./profile-picture";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { JsonParam } from "use-query-params";

export const ProfileShort = () => {
  const [user, setUser] = useState(getItemTransparent('user'));
  // TODO: This is ugly as hell, but needs a REDUX-based refactoring. Do as early as possible!
  useEffect(() => {
    if(JSON.stringify(user) !== JSON.stringify(getItemTransparent("user"))) {
      setUser(getItemTransparent('user'));
    }
  });

  return (
    <div className="flex flex-row items-center">
      <span className="sr-only">Open user menu</span>
      <ProfilePicture profilePicturePath={user.image?.url} />
      <div className="flex flex-col items-start ml-2">
        <div className="text-white test-sm font-semibold">{user.username}</div>
        <div className="text-gray-200 text-xs font-sans">{user.role?.name}</div>
      </div>
      <div>
        <ChevronDownIcon className='ml-2 w-4 text-white'/>
      </div>
    </div>
  );
};
