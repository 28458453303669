import React, { useEffect, useState } from "react";
import { classNames } from "../../shared/utils/class-names";
import { defaultButtonClasses, primaryButtonClasses } from "../basics/buttons-classes";
import { IUpdateField, IUpdateFieldProps } from "./types";

export const BooleanButtonUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  loading,
  extra,
  className
}: IUpdateFieldProps<string>) => {
  const [newFieldValue, setNewFieldValue] = useState(value);

  useEffect(() => {
    setNewFieldValue(value);
  }, [value]);

  return (
    <div className="w-full">
      {value 
      ? <button className={classNames(...primaryButtonClasses, className)} onClick={() => { onUpdate(false); } }>{extra.buttonTextTrue}</button>
      : <button className={classNames(...defaultButtonClasses, className, 'border border-green-500 text-green-500')} onClick={() => { onUpdate(true); } }>{extra.buttonTextFalse}</button>
    }
    </div>
  );
};
