import { useEffect } from "react"
import Alert from "../../../../components/layout/alert"
import { ReferencesDataWrapper } from "../references-data-wrapper"
import { ReferencesTableLayout } from "./references-table-layout"

export interface IReferencesTableProps {
  showExport: boolean,
  excludeLinesVisible?: boolean,
  printView?: boolean
}

export const ReferencesTable = ({ showExport, excludeLinesVisible, printView }: IReferencesTableProps) => {

  return (
    <ReferencesDataWrapper  callback={() => {
      if(printView) {
        setTimeout(() => {
          window.print();
         }, 1000);
      }
    }}>
      {({ data, error, isLoading }: any) => {
        if(error) {
          return <Alert title="Fehler bei der Datenübertragung" message={JSON.stringify(error)} />
        }
        return (<div>
          <ReferencesTableLayout
            data={data} isLoading={isLoading} exportActive={showExport} excludeLinesVisible={excludeLinesVisible} />
        </div>)
      }}
    </ReferencesDataWrapper>
  )
}