import React from "react";

export const LogistikIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.logistik-${color}-cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;stroke:${color}`}</style>
    </defs>
    <line className={`logistik-${color}-cls-1`} x1=".32" y1="28.94" x2="29.32" y2="28.94" />
    <g>
      <polyline className={`logistik-${color}-cls-1`} points="18.68 28.94 13.08 28.94 7.49 28.94" />
      <polyline className={`logistik-${color}-cls-1`} points="18.68 28.94 13.08 28.94 7.49 28.94" />
      <g>
        <path
          className={`logistik-${color}-cls-1`}
          d="M1.86,10.63l-.03,18.31h2.23V14.81H25.54v14.13h2.29l.03-18.31H1.86Z"
        />
        <line className={`logistik-${color}-cls-1`} x1="4.06" y1="17.05" x2="25.54" y2="17.05" />
        <line className={`logistik-${color}-cls-1`} x1="4.06" y1="19.3" x2="25.54" y2="19.3" />
        <rect className={`logistik-${color}-cls-1`} x="7.51" y="23.22" width="5.59" height="5.72" />
        <line className={`logistik-${color}-cls-1`} x1="10.3" y1="23.22" x2="10.3" y2="25.18" />
        <rect className={`logistik-${color}-cls-1`} x="13.1" y="23.22" width="5.59" height="5.72" />
        <line className={`logistik-${color}-cls-1`} x1="15.9" y1="23.22" x2="15.9" y2="25.18" />
      </g>
    </g>
  </svg>
);
