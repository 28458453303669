import { Fragment, Key, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "../shared/utils/class-names";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link, matchPath } from "react-router-dom";

export interface ISubMenuProps {
  name: string;
  subMenuItems: {
    name: string;
    href: string;
    activeString: string;
  }[];
  mainActive: boolean;
}

export default function SubMenu({
  name,
  subMenuItems,
  mainActive,
}: ISubMenuProps) {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <Popover className="flex">
      {({ open }) => (
        <>
          <Popover.Button
            as={"span"}
            className={classNames(
              mainActive || open ? "border-orange-500 text-orange-500" : "border-transparent text-white",
              "items-center text-base font-medium hover:border-gray-100 inline-flex px-1 pt-1 border-b-4"
            )}
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
          >
            {" "}
            {name}
            <ChevronDownIcon
              className={classNames(
                open || mainActive ? "text-orange-500" : "text-white",
                "ml-2 h-5 w-5"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            show={isShowing}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="absolute z-10 mt-14 transform px-2 sm:px-0"
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-8 py-4 sm:gap-2 sm:p-4">
                  {subMenuItems.map((item) => {
                    const isActive = !!matchPath(
                      item.activeString,
                      location.pathname
                    );
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          "-m-3 block rounded-md p-3 py-4 transition duration-150 ease-in-out",
                          isActive ? "font-semibold" : ""
                        )}
                      >
                        <p className="text-base text-gray-900">{item.name}</p>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
