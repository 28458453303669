import { faker } from "@faker-js/faker";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tick } from "chart.js";
import { Bar } from "react-chartjs-2";
import { projectTypeKeyValuePairs } from "../../../common/project-type-map";

const options = {
  indexAxis: "y" as const,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          family: "Roboto",
        },
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          family: "Roboto",
        },
        display: true,
        callback: function (value: any, index: number, ticks: Tick[]) {
          return `${value} €`;
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          family: "Roboto",
        },
      },
      grid: {
        display: false,
      },
    },
  },
};

export const DummyBarChart = ({ height }: any) => {
  return (
    <div className='min-h-full'>
        <div className="relative z-10 min-h-full flex flex-row items-center justify-center top-[50px]">
          <div className="bg-white rounded-2xl p-6 flex flex-row items-center">
            <InformationCircleIcon className="w-8 h-8 text-gray-500 mr-5"/> Bitte wählen Sie zunächst einen bzw mehrere Mitarbeiter aus.
          </div>
        </div>
      <div className="filter blur-sm">
        <Bar
          options={options}
          style={{ height }}
          data={{
            labels: ["2021", "2022","2023"],
            datasets: Object.values(projectTypeKeyValuePairs).map((pt) => {
              return {
                label: pt.label,
                data: ["2022","2023"].map((y) => (
                  faker.datatype.number({ min: 0, max: 100000 })
                )),
              }
          })
        }}
        />
      </div>
    </div>
  );
};
