import React, { Fragment, MouseEventHandler, ReactNode, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { defaultButtonClasses, warnButtonClasses } from '../basics/buttons-classes';
import { classNames } from '../../shared/utils/class-names';

export interface IDeleteWithPrompt {
  onClick: MouseEventHandler,
  children: ReactNode,
  text: ReactNode,
  cancelButtonText: string,
  confirmButtonText: string
}

export const DeleteWithPrompt = ({ onClick, children, text, cancelButtonText, confirmButtonText }: IDeleteWithPrompt) => {
  const [open, setOpen] = useState(false);
  return (<>
    <button onClick={() => { setOpen(true); }}>{children}</button>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-[1000] inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="relative">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title as="h3" className="text-xl font-special leading-6 font-medium text-gray-900">
                  Wirklich löschen?
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="py-2 mb-3">{text}</div>
                    <div className='flex flex-row gap-2'>
                    <button className={classNames(...defaultButtonClasses, "bg-gray-500")} onClick={() => { setOpen(false); }} >{cancelButtonText}</button>
                    <button className={classNames(...warnButtonClasses)} onClick={async (e) => { await onClick(e); setOpen(false); }}>{confirmButtonText}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    </>);
}