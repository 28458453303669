import { gql, useMutation } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ProfilePicture } from "../../../components/layout/profiles/profile-picture";
import { EmployeeDropDown } from "../../../components/filters/employee-dropdown/employee-dropdown";

export const SidebarEmployeeDropdown = ({
  value,
  model,
  id,
  field,
  type,
  refetch,
  filter
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const updateMutation = gql`
    mutation UpdateFieldInModel($value: ${type}) {
      update${model
        .trim()
        .replace(/^\w/, (c: string) =>
          c.toUpperCase()
        )} (id: ${id}, data: { ${field}: $value }) {
        data {
          id
        }
      }
    }
  `;

  useEffect(() => {
    setSelected([value]);
  }, [value]);

  const [updateField, { data, loading, error }] = useMutation(updateMutation);

  const updateEmployee = async (newFieldValue: string[]) => {
    if (newFieldValue.length > 0) {
      await updateField({ variables: { value: newFieldValue?.[0] } });
    } else {
      await updateField({ variables: { value: null } });
    }
    refetch();
    toast.info(`Erfolgreich aktualisiert`)
    setIsOpen(false);
  };

  return !value?.data ? (
    <>
      <div
        className="rounded-full bg-gray-500 w-8 h-8 cursor-pointer p-1"
        onClick={(e) => {
          const target = e.target as HTMLElement
          if(target.id === 'search' || target.classList.contains('searchcancel')) {
             return false;
            }
          setIsOpen(!isOpen);
        }}
      >
        <PlusIcon className="text-white" />
        <EmployeeDropDown
        filter={filter}
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
        onChange={(newFieldValue: string[]) => updateEmployee(newFieldValue)}
        selected={selected}
      />
      </div>
    </>
  ) : (
    <div className="relative w-9">
      <ProfilePicture profilePicturePath={value?.data?.attributes?.image?.data?.attributes?.url} />
      <div className="absolute rounded-full h-3 w-3 p-0.5 right-0 top-0 bg-gray-600 text-white cursor-pointer" onClick={() => (updateEmployee([]))}>
        <XMarkIcon />
      </div>
    </div>
  );
};
