import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/basics/button";
import { Card } from "../../components/basics/card";
import { Input } from "../../components/form-components/input";
import { useForm } from "react-hook-form";

import { forgotPassword } from "./password-management";
import Alert from "../../components/layout/alert";
import { ValidationMessage } from "../../components/layout/validation";
import { toast } from "react-toastify";

const defaultErrorState = { isError: false, title: "", message: "" };

export const ForgotPassword = (props: any) => {
  const [error, setError] = useState({ ...defaultErrorState });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleForgotPassword = async (data: any) => {
    const { email } = data;
    try {
      await forgotPassword(email);
      toast.info(
        "Sie erhalten in Kürze eine E-Mail mit weiteren Informationen."
      );
      setError({ ...defaultErrorState });
      navigate("/");
    } catch (e) {
      toast.error("Daten nicht korrekt angegeben.");
      setError({
        isError: true,
        title: "Fehler beim Login",
        message: "Bitte überprüfen Sie die eingegebenen Daten.",
      });
    }
  };

  return (
    <div className="h-screen bg-green-500 flex flex-col items-center justify-center">
      <Card className="xl:w-1/4 md:w-2/5">
        <div className="container mx-auto flex flex-col items-center w-full">
          <img
            src="../logo_green.png"
            className="w-[194px] mb-8 mt-4 mt-4"
            alt="Logo der Schräder GmbH und Co. KG - ein stilisiertes Gebäude im klassischen Stil"
          />
          <h1 className="font-special font-semibold text-xl mt-4 mb-4 text-green-900">
            Passwort vergessen
          </h1>
          <form
            className="w-full"
            method="post"
            onSubmit={handleSubmit(handleForgotPassword)}
          >
            <div className="mt-2 mb-2">
              <Input
                className={`w-full text-base${
                  errors.email ? "border-red-800 placeholder-red-800" : ""
                }`}
                placeholder="E-Mail"
                type="email"
                register={register}
                validateRequired={{
                  required: "Bitte E-Mail Adresse eingeben",
                }}
                name="email"
              />
              {errors.email?.type === "required" && (
                <ValidationMessage message="Bitte E-Mail Adresse eingeben" />
              )}
            </div>

            <div className="flex flex-col items-center mb-8 mt-8">
              <Button primary type="submit" className="font-semibold">
                Passwort vergessen
              </Button>
            </div>
            {error.isError ? (
              <Alert title={error.title} message={error.message} />
            ) : null}
          </form>
          <a
            href="/login"
            className="text-sm text-green-500 font-medium self-start ml-3"
            title="Zurück"
          >
            Zurück
          </a>
        </div>
      </Card>
    </div>
  );
};

export default ForgotPassword;
