import React, { useEffect, useState } from "react";
import { useInterval } from "../../common/use-interval-hook";
import { IUpdateField, IUpdateFieldProps } from "./types";

export const TextareaUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
}: IUpdateFieldProps<string>) => {
  const [newFieldValue, setNewFieldValue] = useState(value);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    if(!running) {
      setNewFieldValue(value);
    }
  }, [value]);

  useInterval(async() => {
    if(running) {
      await onUpdate(newFieldValue);
    }
  }, 2000);

  const startUpdateLoop = () => {
    setRunning(true);
  };

  const stopUpdateLoop = async () => {
    await onUpdate(newFieldValue);
    setRunning(false);
  };

  return (
    <div className="flex flex-col">
      <textarea
        onFocus={() => {
          startUpdateLoop();
        }}
        onBlur={() => {
          stopUpdateLoop();
        }}
        name={label}
        className="min-w-0 min-h-72 block w-full px-3 mb-2 py-2 rounded focus:ring-green-500 focus:border-green-500 sm:text-sm border-gray-300 resize-y"
        value={newFieldValue}
        rows={10}
        onChange={(e) => {
          setNewFieldValue(e?.target?.value);
          e.stopPropagation();
        }}
      />
    </div>
  );
};
