import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getItemTransparent } from '../shared/utils/storage-utils';
import { backendUrl } from '../shared/utils/url-utils';

const httpLink = createHttpLink({
  uri: backendUrl ? `${backendUrl}/graphql` : `/strapi/graphql`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists
  const jwt = getItemTransparent("JWT");
  // return the headers to the context so httpLink can read them
  if(!jwt) {
    throw Error('Invalid State of Application.');
  }
  return {
    headers: {
      ...headers,
      "Authorization": jwt ? `Bearer ${jwt}` : "",
    }
  }
});

export const createApolloClient = () => {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            projects: {
              keyArgs: false,
              merge(existing = { data: [], meta: {} }, incoming) {
                return {
                  meta: incoming.meta,
                  data: [...existing.data, ...incoming.data]
                };
              }
            }
          }
        },
        ProjectEntity : {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        AddressEntity : {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
      },
    })
  });
}