import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { NotificationList } from "./notifications/notification-list";
import { classNames } from "../../shared/utils/class-names";
import { NotificationBell } from "./notifications/notification-bell";

export default function NotificationDropdown() {


  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-300" : "text-white",
              "p-1 rounded-full text-white hover:text-gray-100 relative focus:outline-none"
            )}
          >
            <span className="sr-only">View notifications</span>
            <NotificationBell className='w-6 h-6' />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-[10011] left-1/2 transform -translate-x-2/3 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-md">
              <NotificationList />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
