import { InformationCircleIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { classNames } from "../../../shared/utils/class-names";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import { Input } from "../../form-components/input";
import { Tooltip } from "../../basics/tooltip";
import { TooltipManager } from "../../basics/tooltip-manager";
import { CustomerSearchDropDown } from "../../customer-search-dropdown/customer-search-dropdown";
import { BuildingSubTypeUpdateField } from "../../magic-update/building-subtype-field";
import { BuildingTypeUpdateField } from "../../magic-update/building-type-field";
import { ClientTypeUpdateField } from "../../magic-update/client-type-update-field";
import { ContractorUpdateField } from "../../magic-update/contractor-update-field";
import { CountryUpdateField } from "../../magic-update/country-update-field";
import { ProjectTypeUpdateField } from "../../magic-update/project-type-field";
import { ValidationMessage } from "../validation";

export interface INewProjectForm {
  onClose: () => void;
  defaultValues?: any;
}

export const NewProjectForm = ({ onClose, defaultValues }: INewProjectForm) => {
  const [buildingType, setBuildingType] = useState();

  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues });

  const watchCustomer = watch('customer');

  useEffect(() => {
    if(watchCustomer?.attributes?.client_type?.data) {
      setValue('clientType', watchCustomer?.attributes?.client_type?.data?.id, { shouldDirty: true });
    }
  }, [watchCustomer]);

  const onSubmit = async (data: any) => {
    try {
      const displayIdYear = data?.idForNextYear
        ? dayjs().add(1, "year").format("YY")
        : dayjs().format("YY");
      const newProject = await axios.post(
        `${getLoginUrl()}/api/project-new/create`,
        { data: { ...data, displayIdYear } },
        {
          headers: {
            Authorization: "Bearer " + getItemTransparent("JWT"),
          },
        }
      );
      if (newProject?.data?.data?.id) {
        navigate(`/projects/${newProject.data.data.id}`);
        onClose();
      } else {
        toast.error("Unbekannter Fehler. Bitte prüfen Sie ihre Eingaben.");
      }
    } catch (e: any) {
      toast.error(
        "Auftrag konnte nicht erstellt werden. Bitte Eingaben prüfen."
      );
    }
  };

  return (
    <TooltipManager>
      <form
        className="flex flex-col justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="sm:pt-5 flex flex-row items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Controller
              control={control}
              name="contractor"
              rules={{
                required: "Es muss ein Auftragnehmer ausgewählt werden",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <ContractorUpdateField
                    label="Auftragnehmer *"
                    value={value}
                    onUpdate={onChange}
                    error={!!error}
                    className={classNames(
                      error ? "border-red-800 placeholder-red-800" : "",
                      "text-base bg-gray-100"
                    )}
                  />
                );
              }}
            />
            {errors.contractor?.type === "required" && (
              <ValidationMessage message={errors.contractor?.message as string} />
            )}
          </div>
          <Tooltip
            id="contractor"
            tooltipContent={
              <span>Bitte hier SCHRÄDER &amp; Co., Naxis GmbH oder ESGium GmbH auswählen. Falls dieser bei Auftragsanlage noch nicht klar ist, ggf. mit dem zuständigen Gutachter oder Teamleiter Rücksprache halten. <br />Mit Rechnungsstellung ist der Auftragnehmer zu überprüfen.</span>
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
      <div className="sm:pt-5 flex flex-row items-center">
        <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
          <Controller
            control={control}
            name='customer'
            rules={{ required: 'Es muss ein Auftraggeber ausgewählt werden' }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
              return <CustomerSearchDropDown
              selected={value}
              onChange={onChange}
              error={!!error}
              className={classNames(
                error
                  ? "border-red-800 placeholder-red-800"
                  : "",
                "text-base bg-gray-100"
              )}
            />
          }} />
            {errors?.customer?.type === "required" && (
              <ValidationMessage message={errors.customer?.message as string} />
            )}
        </div>
          <Tooltip
            id="customer"
            tooltipContent={
              <span>Bitte die Stammauftraggeber aus der Liste auswählen. Fehlende oder neue Stammauftraggeber bitte über die Datenbank-Administration anlegen lassen. <br/> Einmalige oder seltene Kunden können individuell eingegeben werden.</span>
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:pt-5 flex flex-row items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Controller
              control={control}
              name="clientType"
              rules={{
                required: "Es muss eine Auftraggeberart ausgewählt werden",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <ClientTypeUpdateField
                    label="Auftraggeberart *"
                    value={value}
                    onUpdate={onChange}
                    error={!!error}
                    className={classNames(
                      error ? "border-red-800 placeholder-red-800" : "",
                      "text-base bg-gray-100"
                    )}
                  />
                );
              }}
            />
            {errors?.clientType?.type === "required" && (
              <ValidationMessage message={errors.clientType?.message as string} />
            )}
          </div>
          <Tooltip
            id="clientType"
            tooltipContent={
              <div>
                <p>
                  <strong>Institutionell:</strong> Fondsgesellschaften (2IP,
                  CommerzReal, Deka, etc.)
                </p>
                <p>
                  <strong>Finanzinstitut:</strong> u.a. Sparkassen
                </p>
                <p>
                  <strong>Unternehmen:</strong> Projektentwickler,
                  Steuerberater, Firmen etc.
                </p>
                <p>
                  <strong>Privat:</strong> Privatpersonen
                </p>
                <p>
                  <strong>Gericht:</strong> Gerichte
                </p>
                <br />
                <p>
                  Einmalige oder seltene Kunden können individuell eingegeben
                  werden. Bei Unklarheiten im Nachhinein mit dem zuständigen
                  Gutachter oder Teamleiter Rücksprache halten.
                </p>
              </div>
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:pt-5 flex flex-row items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Controller
              control={control}
              name="projectType"
              rules={{ required: "Es muss eine Auftragsart ausgewählt werden" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <ProjectTypeUpdateField
                    label="Auftragsart *"
                    value={value}
                    onUpdate={onChange}
                    error={!!error}
                    className={classNames(
                      error ? "border-red-800 placeholder-red-800" : "",
                      "text-base bg-gray-100"
                    )}
                  />
                );
              }}
            />
            {errors?.projectType?.type === "required" && (
              <ValidationMessage message={errors.projectType?.message as string} />
            )}
          </div>
          <Tooltip
            id="projectType"
            tooltipContent={
              "Bitte die Auftragsart aus der Liste auswählen. Bei Unklarheiten mit dem zuständigen Gutachter oder Teamleiter Rücksprache halten"
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:pt-5 flex flex-row items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Controller
              control={control}
              name="objectType"
              rules={{ required: "Es muss eine Objektart ausgewählt werden" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <BuildingTypeUpdateField
                    label="Objektart 1. Ebene *"
                    value={value}
                    onUpdate={(val: any) => {
                      onChange(val);
                      setBuildingType(val);
                    }}
                    error={!!error}
                    className={classNames(
                      error
                        ? "border-red-800 placeholder-red-800 text-red-800"
                        : "",
                      "text-base bg-gray-100"
                    )}
                  />
                );
              }}
            />
            {errors.objectType?.type === "required" && (
              <ValidationMessage message={errors.objectType?.message as string} />
            )}
          </div>
          <Tooltip
            id="objectType"
            tooltipContent={
              <span>Die richtige Bestimmung der Objektart ist für die spätere Auswertung sehr wichtig.<br/> Falls diese bei Auftragsanlage noch nicht klar ist, ggf. mit dem zuständigen Gutachter oder Teamleiter Rücksprache halten. <br/>Bei Mischobjekten ist i. d. R. die Primärnutzung relevant. „Sonstiges“ sollte nur in Ausnahmefällen gewählt werden.</span>
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:pt-5 flex flex-row items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Controller
              control={control}
              name="objectSubType"
              rules={{
                required: "Es muss eine Objektart 2. Ebene ausgewählt werden",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <BuildingSubTypeUpdateField
                    label="Objektart 2. Ebene *"
                    value={value}
                    onUpdate={onChange}
                    error={!!error}
                    extra={{ buildingType }}
                    className={classNames(
                      error
                        ? "border-red-800 placeholder-red-800 text-red-800"
                        : "",
                      "text-base bg-gray-100"
                    )}
                  />
                );
              }}
            />
            {errors.objectSubType?.type === "required" && (
              <ValidationMessage message={errors.objectType?.message as string} />
            )}
          </div>
          <Tooltip
            id="objectSubType"
            tooltipContent={
              <span>Die richtige Bestimmung der Objektart ist für die spätere Auswertung sehr wichtig.<br/> Falls diese bei Auftragsanlage noch nicht klar ist, ggf. mit dem zuständigen Gutachter oder Teamleiter Rücksprache halten. <br/>Bei Mischobjekten ist i. d. R. die Primärnutzung relevant. „Sonstiges“ sollte nur in Ausnahmefällen gewählt werden.</span>
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:pt-5 flex flex-row items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Input
              name="objectName"
              placeholder="Objektname"
              register={register}
              className={`w-full ${
                errors.objectName
                  ? "border-red-800 placeholder-red-800 focus:border-red-800"
                  : ""
              }`}
            />
          </div>
          <Tooltip
            id="objectName"
            tooltipContent={
              <span>Für größere bzw. bekannte Objekte oder Projektentwicklungen gibt es teilweise Objektnamen. <br/> Dieser wird meist vom Auftraggeber mitgeteilt, sofern es einen gibt, ansonsten Feld leer lassen.</span>
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:items-start sm:pt-5">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="coAppraiser"
                  aria-describedby="coAppraiser-description"
                  type="checkbox"
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                  {...register("coAppraiser")}
                />
              </div>
              <div className="ml-3 text">
                <label htmlFor="coAppraiser" className="text-gray-700">
                  Nebengutachter?
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row w-full">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="idForNextYear"
                  aria-describedby="idForNextYear-description"
                  type="checkbox"
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                  {...register("idForNextYear")}
                />
              </div>
              <div className="ml-3 text">
                <label htmlFor="idForNextYear" className="text-gray-700">
                  Auftrag für nächstes Jahr anlegen
                </label>
              </div>
            </div>
          </div>
          <Tooltip
            id="idForNextYear"
            tooltipContent={
              "Bitte Häkchen setzen, wenn die Bearbeitung des Auftrags in das kommende Jahr fällt. Damit generiert sich automatisch die Auftragsnummer für das nächste Jahr."
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:pt-5 flex flex-row gap-4 items-center">
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Input
                name="street"
                placeholder="Straße *"
                register={register}
                validateRequired={{
                  required: "Bitte Straße eingeben",
                }}
                className={`w-full ${
                  errors.street
                    ? "border-red-800 placeholder-red-800 focus:border-red-800"
                    : ""
                }`}
              />
              {errors.street?.type === "required" && (
                <ValidationMessage message={errors.street?.message as string} />
              )}
            </div>
          </div>
          <div className="mt-1 sm:mt-0 sm:col-span-2 basis-24">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Input
                name="streetNumber"
                placeholder="Nr. *"
                register={register}
                validateRequired={{
                  required: "Bitte Hausnummer eingeben",
                }}
                className={`w-full ${
                  errors.streetNumber
                    ? "border-red-800 placeholder-red-800 focus:border-red-800"
                    : ""
                }`}
              />
              {errors.streetNumber?.type === "required" && (
                <ValidationMessage message={errors.streetNumber?.message as string} />
              )}
            </div>
          </div>
          <Tooltip
            id="streetNumber"
            tooltipContent={
              "Bitte auf die richtige Angabe der Adresse achten und die Lage in der Karte überprüfen. Bei Unklarheiten (z. B. Projektentwicklungen/Grundstücken) ggf. mit dem zuständigen Gutachter oder Teamleiter Rücksprache halten."
            }
          >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Input
              name="zip"
              placeholder="PLZ *"
              register={register}
              validateRequired={{
                required: "Bitte PLZ eingeben",
              }}
              className={`w-24 ${
                errors.zip
                  ? "border-red-800 placeholder-red-800 focus:border-red-800"
                  : ""
              }`}
            />
            {errors.zip?.type === "required" && (
              <ValidationMessage message={errors.zip?.message as string} />
            )}
          </div>
          <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
            <Input
              name="city"
              placeholder="Ort *"
              register={register}
              validateRequired={{
                required: "Bitte Ort eingeben",
              }}
              className={`w-full ${
                errors.city
                  ? "border-red-800 placeholder-red-800 focus:border-red-800"
                  : ""
              }`}
            />
            {errors.city?.type === "required" && (
              <ValidationMessage message={errors.city?.message as string} />
            )}
          </div>
        </div>
        <div className="sm:items-start sm:pt-5">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Controller
              control={control}
              name="country"
              rules={{ required: "Es muss ein Land ausgewählt werden" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => {
                return (
                  <CountryUpdateField
                    label="Land *"
                    value={value}
                    onUpdate={onChange}
                    error={!!error}
                    className={classNames(
                      error
                        ? "border-red-800 placeholder-red-800 text-red-800"
                        : "",
                      "text-base"
                    )}
                  />
                );
              }}
            />
            {errors.objectType?.type === "required" && (
              <ValidationMessage message={errors.country?.message as string} />
            )}
          </div>
        </div>
        <div className="sm:items-start sm:pt-5">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Input
              name="contactName"
              placeholder="Ansprechpartner *"
              register={register}
              validateRequired={{
                required: "Bitte Ansprechpartner eingeben",
              }}
              className={`w-full ${
                errors.contactName
                  ? "border-red-800 placeholder-red-800 focus:border-red-800"
                  : ""
              }`}
            />
            {errors.contactName?.type === "required" && (
              <ValidationMessage message={errors.contactName?.message as string} />
            )}
          </div>
        </div>
        <div className="sm:items-start sm:pt-5">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Input
              name="contactPhone"
              placeholder="Telefon *"
              register={register}
              validateRequired={{
                required: "Bitte Telefonnummer eingeben",
              }}
              className={`w-full ${
                errors.contactPhone
                  ? "border-red-800 placeholder-red-800 focus:border-red-800"
                  : ""
              }`}
            />
            {errors.contactPhone?.type === "required" && (
              <ValidationMessage message={errors.contactPhone?.message as string} />
            )}
          </div>
        </div>
        <div className="sm:items-start sm:pt-5">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="note"
              autoComplete="Notiz"
              placeholder="Fügen Sie hier eine Notiz hinzu"
              className="max-w-lg w-full shadow-sm text-base focus:ring-green-500 focus:border-green-500 border-gray-300 bg-gray-100 rounded-xl"
              {...register("note")}
            />
          </div>
        </div>
        <input
          type="hidden"
          {...register("predecessor")}
        />
        <div className="flex flex-row justify-center mt-4 text-sm font-special">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 rounded-xl bg-gray-300 hover:bg-gray-200 mr-4 text-gray-600 font-semibold"
          >
            Abbrechen
          </button>
          <input
            type="submit"
            value="Speichern"
            className="inline-flex items-center px-4 py-2 shadow-sm font-semibold rounded-xl text-white cursor-pointer bg-green-500 hover:bg-green-600 uppercase"
          />
        </div>
      </form>
    </TooltipManager>
  );
};
