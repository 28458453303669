import React, { useEffect, useState } from "react";
import { useLazyFetchUsersQuery } from "../../../model/users/user-api";

export type ReferencesDataWrapperProps = {
  children: (data: [], error: any, isLoading: boolean) => void;
  onlyEditors?: boolean,
  onlyAppraisers?: boolean,
};

function convertToBackendFilter({ onlyEditors, onlyAppraisers }: { onlyEditors?: boolean, onlyAppraisers?: boolean }) {
  const backendFilter: any = [
    onlyEditors ? { isEditor: true } : null,
    onlyAppraisers ? { isAppraiser: true} : null
  ];
  return {
    $and: [...backendFilter.filter((cond: any) => !!cond)],
  };
}
export interface Employee {
  id: string,
  attributes: {
    username: string,
    abbreviation: string,
    image: {
      data: {
        id: number,
        attributes: {
          url: string
        }
      }
    }
  }
}
export const UserDataWrapper = ({
  onlyEditors,
  onlyAppraisers,
  children,
}: ReferencesDataWrapperProps) => {
  const [data, setData] = useState<Employee[]>([]);
  const [fetchData, { data: fetchedData, error, isFetching }] =
    useLazyFetchUsersQuery();

  useEffect(() => {
    async function fetchDataInternal() {
      await fetchData({
        queryParams: { filters: convertToBackendFilter({onlyEditors, onlyAppraisers}), populate: ["image"] },
      });
    }
    fetchDataInternal();
  }, [onlyEditors, onlyAppraisers]);

  useEffect(() => {
    const newData = fetchedData?.map((d) => ({
      id: d?.id,
      attributes: {
        username: d?.username,
        abbreviation: d?.abbreviation,
        image: {
          data: {
            id: d?.image?.id,
            attributes: {
              url: d?.image?.url
            }
          }
        }
      }
    }));
    setData(newData || [])
  },[fetchedData])

  return (children as Function)(data || [], error, isFetching);
};
