import React from "react";

type INotificationTypeMap = {
  [key: string]: {
    displayName: string,
    icon: React.ReactNode,
    bgColor: string
  }
};

export const notificationTypeMap: INotificationTypeMap = {
  "project_assigned" : {
    displayName: 'Projekt zugewiesen',
    icon: <img className='h-6 w-6' src='/icons/Icon_Objektzuweisung.svg' alt='' />,
    bgColor: 'rgba(250,227,254)'
  },
  project_new_note: {
    displayName: 'Neue Notiz für Projekt',
    icon: <img className='h-6 w-6' src='/icons/Icon_Kommentar.svg' alt='' />,
    bgColor: 'rgba(255, 243,217)'
  },
  project_deadline_close: {
    displayName: 'Projektdeadline kommt näher',
    icon: <img className='h-6 w-6' src='/icons/Icon_Fällige_Aufgabe.svg' alt='' />,
    bgColor: 'rgba(254, 227, 227)'
  },
  project_created: {
    displayName: 'Neues Projekt erstellt',
    icon: <img className='h-6 w-6' src='/icons/Icon_Neuer_Status.svg' alt='' />,
    bgColor: 'rgba(204, 220, 208)'
  }
}