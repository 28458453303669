import React, {useEffect} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {InvoiceEditor} from "./invoice-editor";
import {UploadZone} from "../prepare-visit/upload-zone";
import {AuthDownloadItem} from "../../../components/layout/auth-download-item";

const INVOICING_QUERY = gql`
  query ($projectId: ID!) {
    projectInvoicings(filters: { project: { id: { eq: $projectId } } }, pagination: { start: 0, limit: 100 }) {
      data {
        id
        attributes {
          type
          invoiceNumber
          outgoingInvoiceDate
          periodOfPayment
          netIncome
          expenses
          vat
          grossInvoiceAmount
          paymentReminder
          lateNotice1
          lateNotice2
          paymentReceived
          fullfilmentDate
          vatId
          receipts(pagination: { limit: 200 }) {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
          project {
            data {
              id
              attributes {
                name
                displayId
                status
                customer {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                building {
                  data {
                    id
                    attributes {
                      name
                      type
                      subtype
                      dateOfConstruction
                      dateOfLastRenovation
                      constructionMeassures
                      monumentProtection
                      parcel
                      floorSpace
                      brw
                      constructionPlanningLaw
                      sectionII
                      publicEasement
                      legacyIssues
                      leasehold
                      yearOfConstruction
                      lastRenovation
                      existingIssues
                      plannedMeassures
                      usageStructure
                      locationQuality
                      foundationArea
                      itemsSectionII
                      additionalInfo
                      leaseholdDate
                      leaseholdInterest
                      leaseholdNote
                      gnd
                      rnd
                      conditionOfConstruction
                      equipmentCondition
                      completionStatus
                      maintenanceStopped
                      additionalObjectInfo
                      primaryTypeOfUse
                      ratePrimaryTypeOfUse
                      secondaryTypeOfUse
                      rateSecondaryTypeOfUse
                      singleTenant
                      walt
                      interestRateProperty
                      nonApportionableCosts
                      managementCosts
                      maintenanceCosts
                      leaseVenture
                      modernisationCosts
                      specialValueVacancy
                      landValueRatePerQm
                      landValue
                      landValueShare
                      resultTangibleAssetValueMethod
                      earningValueWOSpecialValue
                      resultEarningValueInclSpecialValue
                      marketValue
                      mortgageValue
                      incidentalAcquisitionCosts
                      address {
                        data {
                          id
                          attributes {
                            street
                            streetNumber
                            city
                            zip
                            country
                            lat
                            lng
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Invoicing = ({project}: any) => {
    const [getInvoicing, { data: invoicing, refetch: reload}] =
        useLazyQuery(INVOICING_QUERY);

    useEffect(() => {
        if (project) {
            getInvoicing({variables: {projectId: project?.project?.data?.id}});
        }
    }, [project, getInvoicing]);


    return (
        <>
            <InvoiceEditor projectId={project?.project?.data?.id} address={project?.project?.data?.attributes?.building?.data?.attributes?.address?.data} invoices={invoicing?.projectInvoicings?.data}
                           reload={reload}/>
            <div className="w-1/2 mt-5">
                <h3 className="text-sm uppercase text-gray-500 flex flex-row group mb-3">
                    Belege
                </h3>
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                    <UploadZone
                        objectId={invoicing?.projectInvoicings?.data?.[0]?.id}
                        objectTypeRef="api::project-invoicing.project-invoicing"
                        objectField="receipts"
                        icon={<img src={`/icons/file-placeholder.svg`} alt=""/>}
                        reload={reload}
                        fileTypeAccept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.xlsx,.xls,application/msexcel"
                        fileTypeText="DOC/PDF/EXCEL"
                    />
                    <div className="text-blue-500">
                        <ul>
                            {invoicing?.projectInvoicings?.data?.[0]?.attributes?.receipts?.data.map(
                                (item: any) => {
                                    return (
                                        <li key={item.id}>
                                            <AuthDownloadItem key={item.id} item={item} afterDelete={reload} />
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
