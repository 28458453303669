import { Switch } from "@headlessui/react";

export const PgToggle = ({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (val: boolean) => void;
}) => (
  <Switch
    checked={value}
    onChange={onChange}
    className={`${
      value ? "bg-green-500" : "bg-gray-200"
    } relative inline-flex items-center h-6 rounded-full w-11 ml-2`}
  >
    <span className="sr-only">Alle als gelesen markieren</span>
    <span
      className={`${
        value ? "translate-x-6" : "translate-x-1"
      } inline-block w-4 h-4 transform bg-white rounded-full`}
    />
  </Switch>
);
