import { CurrencyEuroIcon } from "@heroicons/react/24/outline";
import { formatLargeCurrency } from "../../../../shared/utils/formatters";
import { ChartProps, PreparedDataType, prepareFlatData } from "./helpers";
import { Tile } from "./tile";

export const TileOverallVolume: React.FC<ChartProps> = ({ data }) => {
  const preparedFlatData = data && data.length > 0 ? prepareFlatData(
    data,
    (item: any) => ("overallVolume"),
    PreparedDataType.SUM,
    (item: any) => (item?.building?.marketValue)
  ) : { labels: [], aggregatedData: [] };

  return (
    <Tile
      name="Gesamtbewertungsvolumen"
      number={preparedFlatData?.aggregatedData?.[0] ? formatLargeCurrency(preparedFlatData?.aggregatedData?.[0]): 0}
      icon={<CurrencyEuroIcon />}
      className="bg-green-500"
    />
  );
};
