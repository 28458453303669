import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IUpdateField, IUpdateFieldProps } from "./types";

export const NumberUpdateField: IUpdateField<string> = ({
  label,
  value,
  onUpdate,
  stepBase = 1,
}: IUpdateFieldProps<string>) => {
  const [newFieldValue, setNewFieldValue] = useState(value);

  useEffect(() => {
    if(value) {
      setNewFieldValue(String(value).replace('.', ','));
    }
  }, [value]);

  return (
    <input
      type="text"
      name={label}
      className="flex-1 min-w-0 block w-full px-3 mb-2 py-2 rounded focus:ring-green-500 focus:border-green-500 sm:text-sm border-gray-300"
      value={newFieldValue}
      onChange={(e) => {
        setNewFieldValue(e?.target?.value);
        e.stopPropagation();
      }}
      onKeyDown={async (e) => {
        if (e.key === "Enter") {
          if(newFieldValue === '') { onUpdate(null); }

          const strValue = String(newFieldValue).replace(',', '.');
          const parsedVal = parseFloat(strValue);
          if(Number.isNaN(parsedVal)) {
            toast.error('Bitte geben Sie eine korrekte Zahl ein');
          } else {
            onUpdate(parsedVal);
          }
        }
      }}
    />
  );
};
