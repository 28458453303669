import React from "react";
import { projectTypeMap } from "../../common/project-type-map";
import { statusMap } from "../../common/status-map";
import { classNames } from "../../shared/utils/class-names";
import { dateFormatter } from "../../shared/utils/date-utils";
import { getLoginUrl } from "../../shared/utils/url-utils";
import { AvatarHead } from "../basics/avatar-head";
import { BuildingType } from "../layout/building-type";
import { CompletionStatus } from "../layout/completion-status";
import { DaysToDeadline } from "../layout/days-to-deadline";
import { ProjectStatus } from "../layout/project-status";
import {AuthImg} from "../layout/auth-img";
import { Link } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/outline";
import { HistoryMarker } from "../history-marker";

const projectHasImage = (project: any) => {
  return !!project?.attributes?.images?.data?.[0]?.attributes?.url;
};

export interface IProjectListItem {
  project: any
  currentProjectId?: string
}

export const ProjectListItem = ({ project, currentProjectId }: IProjectListItem) => {
  return (
    <Link
      key={project?.id}
      to={`/projects/${project.id}`}
      className="focus:outline-none"
    >
      <div className={classNames("relative grid grid-cols-[0%_70%_0%_0%_0%_30%] lg:grid-cols-[10%_20%_19%_18%_18%_10%] gap-[1%] rounded-lg border border-gray-300 px-5 py-3 shadow-sm space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-600 mb-3", project?.attributes?.rests && 'striped', currentProjectId && project?.id === parseInt(currentProjectId) ? 'bg-[rgb(216,224,215)]' : 'bg-white')}>
        <div className="invisible lg:visible flex-shrink-0">
          {projectHasImage(project) ? (
            <AuthImg
              className="h-24 rounded-xl w-28"
              src={`${getLoginUrl()}${
                project?.attributes?.images?.data?.[0]?.attributes?.url
              }`}
              size="thumbnail"
            />
          ) : (
            <div className="h-full rounded-xl bg-gray-300 w-full bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url("/placeholder.png")' }}>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between">
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex flex-row items-center mb-1">
            {project?.attributes?.successor?.data?.id || project?.attributes?.predecessor?.data?.id ? <HistoryMarker className="w-8 h-[1.8em] mr-1 rounded" /> : null}
            <ProjectStatus status={project?.attributes?.status} />
            <BuildingType type={project?.attributes?.building?.data?.attributes?.type} />
          </div>
          <div>
            <p className="text-lg font-special font-semibold text-gray-900 truncate leading-5">
              {project?.attributes?.building?.data?.attributes?.name}
            </p>
            <p className="text-sm leading-[0.85rem] font-sans text-gray-500 truncate">
              {project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.street} {project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.streetNumber} <br />
              {project?.attributes?.building?.data?.attributes?.address?.data?.attributes?.city}
            </p>
          </div>
          <p className="text-sm text-gray-600">
            <strong>#{project?.attributes?.displayId}</strong>
          </p>
        </div>
        <div className="flex flex-col invisible lg:visible justify-between">
          <div>
            <h5 className="text-[13px] text-gray-600 font-bold truncate">
              Auftragsart + Nr. der Folgebewertung
            </h5>
            <p className="text-sm text-gray-600 truncate">
              {project?.attributes?.type &&
                projectTypeMap[project?.attributes?.type].displayString}
            </p>
          </div>
          <div className='flex flex-row justify-between'>
            <div className='w-1/2'>
              <h5 className="text-[13px] text-gray-600 font-bold">Auftraggeber</h5>
              <p className="text-sm text-gray-600 truncate">
                {project?.attributes?.customer?.data?.attributes?.name || '-'}
              </p>
            </div>
            <div className='w-1/2'>
              <h5 className="text-[13px] text-gray-600 font-bold">Stichtag</h5>
              <p className="text-sm text-gray-600 truncate">
                {dateFormatter(project?.attributes?.deadlineDate)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col invisible lg:visible justify-between">
          <div>
            <h5 className="text-[13px] text-gray-600 font-bold truncate">
              Verfahren / Eigentümer
            </h5>
            <p className="text-sm text-gray-600 truncate">
              {project?.attributes?.owner || "-"}
            </p>
          </div>
          <div>
            <h5 className="text-[13px] text-gray-600 font-bold truncate">
              Servicelevel
            </h5>
            <p className="text-sm text-gray-600 truncate">
              {dateFormatter(project?.attributes?.servicelevel)}
            </p>
          </div>
        </div>
        <div className="invisible lg:visible flex flex-col justify-between">
          <div>
            <h5 className="text-[13px] text-gray-600 font-bold">
              {project?.attributes?.isCoappraiser ? "Nebengutachter" : "Hauptgutachter" || "-"}
            </h5>
            <p className="text-sm text-gray-600 truncate">
            </p>
          </div>
          <div className="h-14 py-6">
            <CompletionStatus
              projectStatus={
                project?.attributes?.status &&
                statusMap[project?.attributes?.status]
              }
            />
          </div>
        </div>
        <div className="flex flex-col justify-between items-center">
          <div>
            <DaysToDeadline deadline={project?.attributes?.servicelevel} />
          </div>
          <div className="mt-6 flex flex-row gap-2">
            <AvatarHead user={project?.attributes?.editor} />
            <AvatarHead user={project?.attributes?.appraiser} />
          </div>
        </div>
      </div>
    </Link>
  );
};
