import React from 'react';
import { statusMap } from '../../common/status-map';
import { classNames } from '../../shared/utils/class-names';

export const ProjectStatus = ({status}: any) => {
  if(status) {
    const key: string = status;
    return <div style={{ backgroundColor: statusMap?.[key]?.color }} className={classNames("text-white font-special font-semibold text-sm rounded p-1 px-2 max-w-32 text-center uppercase truncate")}>{statusMap[key]?.displayString}</div>
  } else {
    return <div className={classNames('bg-gray-500' ,"text-white font-special font-semibold text-sm rounded p-1 px-2 max-w-32 text-center uppercase truncate")}>Kein Status</div>;
  }
}