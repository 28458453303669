import React from 'react';
import { ProfilePicture } from '../../layout/profiles/profile-picture';

export const EmployeeSearchItem = ({ employee }: any) => (
  <div className='flex flex-row gap-5'>
    <div className=''>
      <ProfilePicture profilePicturePath={employee?.attributes?.image?.data?.attributes?.url} />
    </div>
    <div className='text-black'>
      <h5 className="font-medium text-md">{employee?.attributes?.username}</h5>
      <p className="text-gray-500 text-sm">{employee?.attributes?.email}</p>
    </div>
  </div>
)
