import { gql } from "@apollo/client";
import { IAddress } from "./address";

export type ICustomer = {
  id: string;
  attributes: {
    name: string;
    shortName: string;
    regularCustomer: boolean;
    client_type?: {
      data?: any
    };
    invoiceAddress?: {
      data: IAddress;
    };
  };
};

export const CUSTOMER_CREATE_QUERY = gql`
  mutation($data: CustomerInput!) {
    createCustomer(data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const CUSTOMER_UPDATE_QUERY = gql`
  mutation($id: ID!, $data: CustomerInput!) {
    updateCustomer(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const CUSTOMER_DELETE_QUERY = gql`
  mutation deleteCustomer($customerId: ID!) {
    deleteCustomer(id: $customerId) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`
// TODO: Refactor to Redux
export const CUSTOMER_QUERY = gql`
  query {
    customers (pagination: { limit: 1000 }) {
      data {
        id
        attributes {
          name
          regularCustomer
          client_type {
            data {
              id
              attributes {
                label
              }
            }
          }
          invoiceAddress {
            data {
              id
              attributes {
                street
                city
                zip
                line1
                country
                lat
                lng
              }
            }
          }
        }
      }
    }
  }
`;

export const CUSTOMER_SINGLE_QUERY = gql`
  query ($id: ID!) {
    customer (id: $id) {
      data {
        id
        attributes {
          name
          shortName
          regularCustomer
          client_type {
            data {
              id
              attributes {
                label
              }
            }
          }
          invoiceAddress {
            data {
              id
              attributes {
                street
                city
                zip
                line1
                streetNumber
                country
                lat
                lng
              }
            }
          }
        }
      }
    }
  }
`;

export const CUSTOMER_EXISTS_QUERY = gql`
  query ($name: String!) {
    customers (filters: { name : {eq: $name} }, pagination: { limit: 1 }) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const CUSTOMER_EXISTS_SHORTNAME_QUERY = gql`
  query ($shortName: String!) {
    customers (filters: { shortName : {eq: $shortName} }, pagination: { limit: 1 }) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;