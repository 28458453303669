import React, { useState } from 'react';
import { SearchDropdown } from '../../components/layout/search/search-dropdown';

export const ProjectListMobile = () => {
  const [query, setQuery] = useState<any>();

  return (
    <div>
      <div className="mb-2">
        <input placeholder="Suchen..." type="text" onChange={(e) => { setQuery(e.target.value) }} className="rounded-xl border-gray-300 w-full text-base" />
      </div>
      <SearchDropdown query={query} />
    </div>
  )
}

export default ProjectListMobile;