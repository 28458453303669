import { useQuery } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { ListBulletIcon, ChartBarIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import queryString from "query-string";
import React, { useState } from "react";
import {
  Link,
  matchPath,
  resolvePath,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  encodeQueryParams,
  useQueryParam,
  useQueryParams,
} from "use-query-params";
import {
  buttonClasses,
  defaultButtonClasses,
  primaryButtonClasses,
} from "../../../components/basics/buttons-classes";
import { h1Classes } from "../../../components/basics/typography-classes";
import { HoverTooltip } from "../../../components/layout/basics/hover-tooltip";
import { classNames } from "../../../shared/utils/class-names";
import { ReferencesCharts } from "./charts/references-charts";
import {
  ExcludeParam,
  referenceListQueryParams,
  REFERENCE_LIST_EXCLUDE,
} from "./filter/reference-filter-query-params";
import { ReferencesFilterBar } from "./filter/references-filter";
import { ReferencesTable } from "./table/references-table";

const listViews = [
  {
    id: "table-view",
    href: "/table",
    icon: ListBulletIcon,
    component: ReferencesTable,
  },
  {
    id: "chart-view",
    href: "/chart",
    icon: ChartBarIcon,
    component: ReferencesCharts,
  },
];

export const ReferenceAnalytics = ({ parentPath }: { parentPath: string }) => {
  const location = useLocation();
  const [showExport, setShowExport] = useState<boolean>(false);

  const selectedItem = listViews.find((listView) => {
    return matchPath(`${parentPath}${listView.href}`, location.pathname);
  });

  const [referenceListParams] = useQueryParams(referenceListQueryParams);
  const [excludeParam] = useQueryParam(REFERENCE_LIST_EXCLUDE, ExcludeParam);
  const navigate = useNavigate();

  const selectedIndex = selectedItem ? listViews.indexOf(selectedItem) : 0;

  return (
    <>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(idx) => {
          const url = `${
            resolvePath(
              `${parentPath}${listViews[idx].href}`,
              location.pathname
            ).pathname
          }?${queryString.stringify(
            encodeQueryParams(
              {
                ...referenceListQueryParams,
                [REFERENCE_LIST_EXCLUDE]: ExcludeParam,
              },
              {
                ...referenceListParams,
                [REFERENCE_LIST_EXCLUDE]: excludeParam,
              }
            )
          )}`;
          navigate(url);
        }}
      >
        <div className="flex flex-row justify-between">
          <h1 className={classNames(...h1Classes)}>Referenzen</h1>
          <div className="w-full flex-grow"></div>
          <div className="flex flex-row items-center">
            <Tab.List className="flex p-1 space-x-1 bg-white rounded-xl">
              {listViews.map((listView) => (
                <Tab
                  key={listView.id}
                  className={({ selected }) =>
                    classNames(
                      "w-full py-1.5 leading-5 font-medium  rounded-lg",
                      selected
                        ? "bg-green-700 text-white"
                        : "text-green-700 bg-white"
                    )
                  }
                >
                  <listView.icon className="w-8 h-5" />
                </Tab>
              ))}
            </Tab.List>
            <button
              className={classNames(
                showExport ? "" : "bg-white",
                ...(showExport ? primaryButtonClasses : buttonClasses),
                "h-10 p-2 ml-2 border"
              )}
              onClick={() => {
                setShowExport(!showExport);
              }}
            >
              <EllipsisHorizontalIcon
                className={classNames(
                  "h-8 w-8 -mt-1",
                  showExport ? "text-white" : "text-gray-500"
                )}
              />
            </button>
          </div>
        </div>

        <Tab.Panels className="mt-2">
          {showExport ? (
            <div className="h-12 mb-[7px] flex justify-start items-center">
              <button
                className={classNames(
                  ...buttonClasses,
                  "text-gray-500 border w-32 mr-2"
                )}
                onClick={() => {
                  setShowExport(false);
                }}
              >
                Abbrechen
              </button>
              <Link
                to={`/references-export-${
                  selectedItem?.id
                }?${queryString.stringify(
                  encodeQueryParams(
                    {
                      ...referenceListQueryParams,
                      [REFERENCE_LIST_EXCLUDE]: ExcludeParam,
                    },
                    {
                      ...referenceListParams,
                      [REFERENCE_LIST_EXCLUDE]: excludeParam,
                    }
                  )
                )}`}
                className={classNames(...primaryButtonClasses, "w-48 text-center px-4")}
              >
                Objekte exportieren
              </Link>
              <span>
                <HoverTooltip message={<div className="w-60">Um die Farben der Legende anzuzeigen muss die Option "Hintergrund drucken" o.ä. aktiviert werden.</div>}>
                  <InformationCircleIcon className="w-5 h-5 ml-2 text-gray-500 cursor-pointer" />
                </HoverTooltip>
              </span>
            </div>
          ) : (
            <ReferencesFilterBar />
          )}
          <div className="h-2"></div>
          <Routes>
            {listViews.map((listView) => (
              <Route
                key={listView.id}
                path={listView.href}
                element={<listView.component showExport={showExport} />}
              />
            ))}
          </Routes>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};
