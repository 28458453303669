export const calcWert = (projectResearchData: any, type: string) => (
    type === "VKW"
        ? projectResearchData?.data?.attributes?.marketValue
        : projectResearchData?.data?.attributes?.priceOfSale
);
export const calcWertPerQm = (projectResearchData: any, type: string) => {
    const rentableArea = projectResearchData?.data?.attributes?.totalRentableArea;
    return rentableArea ? (calcWert(projectResearchData, type) / rentableArea) : 0;
}

export const calcBodenwertPerQm = (projectResearchData: any) => {
    const landSize = projectResearchData?.data?.attributes?.landSize;
    const landValue = projectResearchData?.data?.attributes?.landValue
    return landSize ? (landValue / landSize) : 0;
};

export const calcNettoAnfangsrendite = (projectResearchData: any, type: string) => {
    const verkehrswert = calcWert(projectResearchData, type);
    const faktorBetriebskosten = projectResearchData?.data?.attributes?.faktorNichtUmlagefaehigeBetriebskosten || 0;
    const faktorAnschaffungsnebenkosten = projectResearchData?.data?.attributes?.faktorAnschaffungsnebenkosten || 0;
    return verkehrswert ? ((calcRohertrag(projectResearchData) * (1 - faktorBetriebskosten / 100)) / (verkehrswert * (1 + faktorAnschaffungsnebenkosten / 100))) * 100 : null;
}

export const calcBruttoAnfangsrendite = (projectResearchData: any, type: string) => {
    const verkehrswert = calcWert(projectResearchData, type);
    return verkehrswert ? calcRohertrag(projectResearchData) / calcWert(projectResearchData, type) * 100 : 0;
}
export const calcFaktorAktuell = (projectResearchData: any, type: string) => {
    const wert = calcWert(projectResearchData, type);
    return wert ? wert / calcRohertrag(projectResearchData) : 0;
};
export const calcFaktorMarkt = (projectResearchData: any, type: string) => {
    const wert = calcWert(projectResearchData, type);
    return wert ? wert / calcBewertungsMieteProJahr(projectResearchData) : 0;
};

export const calcVertragsmieteProJahr = (projectResearchData: any) => {
    let sum = projectResearchData?.data?.attributes?.rentalSituationOfficeContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationRetailContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationStorageContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationArchiveContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationGastroContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationResidentialContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationHotelContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationLeisureContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationSocialContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationBusGenContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationInparkContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationOutparkContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscArea1ContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscArea2ContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscnumbers1ContractualAnnualRent || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscnumbers2ContractualAnnualRent || 0;
    return sum;
}

export const calcLeerstandsertragProJahr = (projectResearchData: any) => {
    let sum = projectResearchData?.data?.attributes?.rentalSituationOfficeEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationRetailEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationStorageEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationArchiveEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationGastroEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationResidentialEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationHotelEstimatedAnnualRentVacant || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationLeisureEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationSocialEstimatedAnnualRentVacant || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationBusGenEstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationInparkEstAnnRentForVacantNumbers || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationOutparkEstAnnRentForVacantNumbers || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscArea1EstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscArea2EstAnnRentForVacArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscnumbers1EstAnnRentForVacantNumbers || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscnumbers2EstAnnRentForVacantNumbers || 0;
    return sum;
}

export const calcBewertungsMieteProJahr = (projectResearchData: any) => {
    let sum = projectResearchData?.data?.attributes?.rentalSituationOfficeEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationRetailEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationStorageEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationArchiveEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationGastroEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationResidentialEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationHotelEstimatedAnnualRentLet || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationLeisureEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationSocialEstimatedAnnualRentLet || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationBusGenEstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationInparkEstAnnRentForLetNumbers || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationOutparkEstAnnRentForLetNumbers || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscArea1EstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscArea2EstAnnRentForLetArea || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscnumbers1EstAnnRentForLetNumbers || 0;
    sum += projectResearchData?.data?.attributes?.rentalSituationMiscnumbers2EstAnnRentForLetNumbers || 0;
    return sum;
}

export const calcRohertrag = (projectResearchData: any) => calcVertragsmieteProJahr(projectResearchData) + calcLeerstandsertragProJahr(projectResearchData);
export const calcMarktertrag = (projectResearchData: any) => calcBewertungsMieteProJahr(projectResearchData) + calcLeerstandsertragProJahr(projectResearchData);

export const calcCap = (projectResearchData: any) => {
    const faktorBetriebskosten = projectResearchData?.data?.attributes?.faktorNichtUmlagefaehigeBetriebskosten || 0;
    return ((calcRohertrag(projectResearchData) * (1 - faktorBetriebskosten / 100)) / projectResearchData?.data?.attributes?.marketValue) * 100;
}
