import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import { UsersList } from './users-list';
import { UserEdit } from './users-edit';
import { UserCreate } from './users-create';
import { UserDelete } from './users-delete';

const Layout = () => {
  return <div className='flex flex-col'>
      <div>
        <Outlet />
      </div>
  </div>
}

const UserManagementRoute = () => {
  return <Routes>
    <Route path='/' element={<Layout />}>
      <Route element={<UsersList />}>
        <Route index />
        <Route path='/new' element={<UserCreate />} />
        <Route path='/:userId/delete' element={<UserDelete />} />
        <Route path='/:userId' element={<UserEdit />} />
      </Route>
    </Route>
  </Routes>
}

export default UserManagementRoute;