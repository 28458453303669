import React, { useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../shared/utils/class-names";
import { IKeyValuePair } from "../../shared/utils/entity-utils";

export interface IFilterSelect {
  items: IKeyValuePair[] | null | undefined;
  selected?: (IKeyValuePair | null);
  onChange: (selected: (IKeyValuePair | null)) => void;
  placeholder?: string;
  prefix?: string;
  className?: string;
  error?: boolean;
  nonNullable?: boolean;
}

export const FilterSelect = ({ items, selected, onChange, placeholder, error, prefix, className='', nonNullable }: IFilterSelect) => {
  return (
      <Listbox
        as="div"
        className="space-y-1 w-full"
        value={selected}
        onChange={onChange}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <span className="inline-block w-full rounded-xl shadow-sm">
                <Listbox.Button className={classNames("cursor-default relative w-full rounded-xl border border-gray-300 bg-white pl-3 pr-8 py-2 text-left focus:outline-none transition ease-in-out duration-150 sm:leading-5", className, !error ? 'focus:border-green-500 focus:shadow-outline-green' : '')}>
                  <span className={classNames( !selected ? 'text-gray-500' : 'text-gray-900', error ? 'text-red-800' : '', 'block truncate')}>
                    {prefix
                      ? <span>{prefix}<strong>{!selected ? placeholder : selected.label}</strong></span>
                      : !selected ? placeholder : selected.label
                    }
                  </span>

                  {selected && placeholder && !nonNullable ? (
                    <span
                      className="absolute inset-y-0 right-6 flex items-center pr-1 text-gray-400 cursor-pointer"
                      onClick={(e) => { e.stopPropagation(); onChange(null);} }>
                      <XCircleIcon className="h-5" />
                    </span>
                  ) : null}
                  <span className="absolute bg-transparent inset-y-0 right-0 flex items-center pr-1 pointer-events-none text-gray-400">
                    <ChevronDownIcon className="h-6" />
                  </span>
                </Listbox.Button>
              </span>

              <Transition
                unmount={false}
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute mt-1 min-w-72 rounded-xl bg-white shadow-lg z-[4000]"
              >
                <Listbox.Options
                  static
                  className="rounded-xl py-1 leading-6 shadow overflow-auto focus:outline-none z-20 text-gray-600"
                >
                  {items?.map((item) => {
                    const isSelected = item.key === selected?.key;
                    return (
                      <Listbox.Option key={item.key} value={item}>
                        {({ active }) => (
                          <div
                            className={`${
                              active
                                ? "text-white bg-green-600"
                                : "text-gray-900"
                            } cursor-default select-none relative py-2 pl-4 pr-4`}
                          >
                            <span
                              className={classNames(
                                isSelected ? "font-bold" : ""
                              )}
                            >
                              {item.label}
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    );
                  })}
                  { items?.length === 0
                    ? <Listbox.Option key={0} value={0}>
                      <div
                        className={"text-gray-900 cursor-default select-none relative py-2 pl-4 pr-4"}
                      >Keine Optionen verfügbar</div>
                      </Listbox.Option>
                    : null
                  }
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
  );
};
