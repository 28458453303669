import React, {useEffect} from "react";
import { IProjectFilter } from "../../../components/filters/project-filter";
import { buildRestFilter } from "../../../model/meta/request-utils";
import { useLazyFetchAllQuery as useProjectMapLazyFetchAll } from "../../../model/project-map/project-map-api";
import Alert from "../../../components/layout/alert";
import { ProjectsPolygonPickerMap } from "./projects-map";

export interface IProjectsMap {
  filter: IProjectFilter;
  jsFilter?: (project: any) => boolean;
  drawing: boolean;
}

export const ProjectMapPolygonContainer = ({ filter, jsFilter, drawing }: IProjectsMap) => {
  const [fetchProjectItems, {
    data: projectMapItems,
    error,
    isFetching
  }] = useProjectMapLazyFetchAll();

  useEffect(() => {
    fetchProjectItems({
      queryParams: {
        filters: buildRestFilter( { ...filter } ),
      },
    })
  }, [filter, jsFilter])

  let safeJsFilter = jsFilter || (() => true);

  if (error) {
    return (
      <Alert
        title={"Daten konnten nicht geladen werden"}
        message={error as string}
      />
    );
  }

  return (
    <ProjectsPolygonPickerMap
      projectMapItems={projectMapItems?.data?.filter(safeJsFilter)}
      drawing={drawing}
      reloading={isFetching}
      maxBounds={filter.forceBounds ? filter.geoBounds : undefined}
    />
  );
};
