import { UserIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import React from "react";
import { currentUserMay } from "../../../common/role-management";
import { IData } from "../../../model/entity";
import { INote } from "../../../model/note";
import { formatRichText } from "../../../shared/utils/formatters";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import { LockNote } from "./lock-note";
import {AuthImg} from "../../../components/layout/auth-img";

export interface INoteListItem {
  comment: IData<INote>;
  refetch: Function;
}

export const NoteListItem = ({ comment, refetch }: INoteListItem) => {
  return (
    <>
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          {comment?.attributes?.owner?.data?.attributes?.image?.data?.attributes
            ?.url ? (
            <AuthImg
              className="h-10 w-10 rounded-full"
              src={`${getLoginUrl()}${
                comment?.attributes?.owner?.data?.attributes?.image?.data
                  ?.attributes?.url
              }`}
              size="thumbnail"
            />
          ) : (
            <div className="h-10 w-10 p-2 rounded-full bg-gray-300 text-white">
              <UserIcon />
            </div>
          )}
        </div>
        <div className="flex-grow">
          <div>
            <p className="font-semibold">
              {comment?.attributes?.owner?.data?.attributes?.username ||
                "Import"}
            </p>
            <p>{comment?.attributes?.owner?.data?.attributes?.role?.data?.attributes?.name}</p>
          </div>
          <div className="text-sm space-x-2">
            <span className="text-gray-500">
              {comment?.attributes?.createdAt &&
                dayjs(comment?.attributes?.createdAt)
                  .locale("de")
                  .format("DD.MM.YYYY")}
            </span>
          </div>
        </div>
        <div className="text-right">
          {currentUserMay("restrictNotes") ? (
            <LockNote comment={comment} refetch={refetch} />
          ) : null}
        </div>
      </div>
      <div className="mt-2 text-gray-800">
        <p>{formatRichText(comment?.attributes?.message)}</p>
      </div>
    </>
  );
};
