import React, { useEffect, useState } from "react";
import { useQueryParams } from "use-query-params";
import { IKeyValuePair } from "../../../shared/utils/entity-utils";
import {projectListQueryParams} from "../project-list-query-params";
import { SortDirectionControl } from "./sort-direction-control";
import { SortDropdown } from "./sort-dropdown";
import {DIRECTION, SORT_BY} from "../../../components/filters/project-filter";

const directionItems = [
  { key: "asc", label: "Aufsteigend" },
  { key: "desc", label: "Absteigend" },
];

const sortItems = [
  { key: "servicelevel", label: "Service Level" },
  { key: "displayId", label: "Auftragsnummer" },
  { key: "deadlineDate", label: "Stichtag" },
];

export const SortWrapper = () => {
  const [selected, setSelected] = useState<IKeyValuePair>(sortItems[0]);
  const [direction, setDirection] = useState<IKeyValuePair>(directionItems[1]);
  const [searchParams, setSearchParams] = useQueryParams(projectListQueryParams);

  const getSortItem = (needle: string | null | undefined) => {
    return sortItems.find((item) => item.key === needle);
  };

  //Update Sort Params from URL
  useEffect(() => {
    const urlSortBy = getSortItem(searchParams[SORT_BY]);
    if (urlSortBy) {
      setSelected(urlSortBy);
    } else {
      setSelected(sortItems[0]);
    }

    const urlDirection = searchParams[DIRECTION];
    const foundDirectionItem = directionItems.find(
      (directionKv) => directionKv.key === urlDirection
    );
    if (foundDirectionItem) {
      setDirection(foundDirectionItem);
    } else {
      setDirection(directionItems[1]);
    }
  }, [searchParams]);

  return (
    <div className="flex flex-row gap-2">
      <SortDropdown
        items={sortItems}
        onChange={(val) => {
          if (val) {
            setSelected(val);
            setSearchParams({...searchParams, [SORT_BY]: val.key});
          }
        }}
        selected={selected}
      />
      <SortDirectionControl
        selected={direction}
        items={directionItems}
        onChange={(val) => {
          if (val) {
            setDirection(val);
            setSearchParams({...searchParams, [DIRECTION]: val.key});
          }
        }}
      />
    </div>
  );
};
