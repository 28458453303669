import React from "react";

export const UnitSelect = ({
                             value,
                             onChange,
                             prefix = "",
                             suffix = "",
                           }: {
  value: string,
  onChange: (newValue: string) => void
  prefix?: string,
  suffix?: string,
}) => {
  return (
    <>
      <input
        onChange={(e) => {
          // assumes prefix & suffix do not contain numbers
          onChange(e.target.value.replace(/[^.,0-9]/g, "").replace(",", "."));
        }}
        onBeforeInput={e =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length - suffix.length,
            e.currentTarget.value.length - suffix.length,
          )}
        onFocus={(e) => e.currentTarget.setSelectionRange(
          e.currentTarget.value.length - suffix.length,
          e.currentTarget.value.length - suffix.length,
          )}
        className="h-10 rounded-xl border border-gray-300 w-full bg-white pl-4 text-gray-800 placeholder-gray-500 sm:text-sm focus:ring-0 focus:outline-none focus:border focus:border-green-500 2xl:text-base pr-10 shadow-sm"
        value={prefix + (value || '') + suffix}
      />
    </>
  );
};
