import { gql } from "@apollo/client";

export type IAddress = {
  id: string;
  attributes: {
    street: string;
    streetNumber: string;
    city: string;
    zip: string;
    line1?: string;
    country?: string;
    lat?: number;
    lng?: number;
  };
};

export const ADDRESS_CREATE_QUERY = gql`
  mutation ($data: AddressInput!) {
    createAddress(data: $data) {
      data {
        id
      }
    }
  }
`;

export const ADDRESS_UPDATE_QUERY = gql`
  mutation ($id: ID!, $data: AddressInput!) {
    updateAddress(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const ADDRESS_DELETE_QUERY = gql`
  mutation deleteAddress($addressId: ID!) {
    deleteAddress(id: $addressId) {
      data {
        id
        attributes {
          street
          city
          zip
        }
      }
    }
  }
`;
