import React from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";

export const DaysToDeadline = ({ deadline }: any) => {
  const numberOfDays =
    !deadline || (deadline?.isValid && !deadline?.isValid())
      ? "Nicht gesetzt"
      : dayjs(deadline).locale("de").fromNow(false);
  return (
    <div className="text-gray-500 text-xs bg-gray-100 rounded flex flex-row justify-around gap-1 h-7 p-1 w-16 lg:w-24 items-center px-1 font-sans">
      <ClockIcon className="min-w-4 w-4 flex-initial" />{" "}
      <div className="truncate flex-initial">{numberOfDays}</div>
    </div>
  );
};
