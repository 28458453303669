import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { MultiFilterSelect } from "./multi-filter-select";
import { IKeyValuePair } from "../../shared/utils/entity-utils";

export interface IClientTypeSelect {
  selected: Array<number | null>;
  onChange: (selected: number[]) => void;
  placeholder?: string;
}

// TODO: Refactor to Redux, handle loading and errors
const REGULAR_CUSTOMER_QUERY = gql`
  query {
    customers(
      filters: { regularCustomer: { eq: true } }
      pagination: { limit: 50 }
      sort: "name"
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const CustomerMultiSelect = ({
  placeholder = "Stammkunden",
  selected,
  onChange,
}: IClientTypeSelect) => {
  const { data } = useQuery(REGULAR_CUSTOMER_QUERY);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    setCustomers(
      data?.customers?.data?.map((customer: any) => {
        return { key: customer?.id, label: customer?.attributes?.name };
      })
    );
  }, [data]);

  return (
    <MultiFilterSelect
      placeholder={placeholder}
      selected={customers?.filter((customer: IKeyValuePair) => (selected.includes(parseInt(customer.key))))}
      onChange={(kvs: IKeyValuePair[]) => {
        const keys = kvs.map((kv: IKeyValuePair) => (
          parseInt(kv.key)
        ));
        onChange(keys);
      }}
      items={customers}
    />
  );
};
