import { gql, useMutation } from "@apollo/client";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { currentUserMay } from "../../../common/role-management";
import { Spinner } from "../../../common/spinner/base-spinner";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import { NoteListItem } from "./note";

export interface INotes {
  projectId: string;
  notes: any;
  refetch: Function;
}

const CREATE_NOTE_QUERY = gql`
  mutation CreateNote($data: NoteInput!) {
    createNote(data: $data) {
      data {
        id
        attributes {
          owner {
            data {
              id
            }
          }
          message
          createdAt
        }
      }
    }
  }
`;

export const Notes = ({ projectId, notes, refetch }: INotes) => {
  const [noteText, setNoteText] = useState("");
  const [restrictNewNote, setRestrictNewNote] = useState(false);
  const [createNewNote, { loading }] = useMutation(CREATE_NOTE_QUERY);

  const saveNote = async () => {
    try {
      if(noteText.length > 0 ) {
        const newNote = {
          message: noteText,
          owner: `${getItemTransparent('user')?.id}`,
          project: projectId,
          accessRestricted: restrictNewNote,
        };
        await createNewNote({
          variables: { data: newNote },
        });
        setNoteText("");
        setRestrictNewNote(false);
        refetch();
      }
      else {
        toast.error("Bitte geben Sie einen Text an.")
      }
    } catch (e) {
      toast.error("Notiz konnte nicht angelegt werden.");
      console.error(e);
    }
  };

  return (
    <section aria-labelledby="notes-title">
      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
        Notizen
      </h2>
      <div className="bg-gray-100 px-2 rounded-xl py-4 sm:px-4">
        <div className="flex space-x-1">
          <div className="min-w-0 flex-1">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                saveNote();
              }}
            >
              <div>
                <label htmlFor="comment" className="sr-only">
                  Kommentar schreiben
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  rows={10}
                  className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-xl leading-4 pt-4"
                  placeholder="Fügen Sie ihre Notiz hinzu"
                  value={noteText}
                  onChange={(e) => {
                    setNoteText(e.target.value);
                  }}
                />
              </div>
              <div className="mt-3 flex items-center justify-between">
              <button
                  type="submit"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  Notiz speichern
                  {
                    loading ? <Spinner className='w-4 h-4 ml-2 stroke-white' /> : null
                  }
                </button>
                { currentUserMay('restrictNotes') ? <div
                  onClick={() => {
                    setRestrictNewNote(!restrictNewNote);
                  }}
                  className='mr-4 cursor-pointer'
                >
                  {restrictNewNote ? (
                    <LockClosedIcon className="w-5 text-gray-500" />
                  ) : (
                    <LockOpenIcon className="w-5 text-gray-500" />
                  )}
                </div> : null }
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="my-2">
        <ul>
          {notes?.data?.map((comment: any) => (
            <li key={comment?.id} className="border rounded-xl p-3 mt-3 bg-white">
              <NoteListItem comment={comment} refetch={refetch} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
