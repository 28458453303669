import { useEffect, useRef } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import Alert from "../../../../components/layout/alert";
import { formatBigNumber } from "../../../../shared/utils/formatters";
import { DummyBarChart } from "../../employees/dummy-bar-chart";
import {
  getMarketValueCategory,
  MarketValueEnum,
  marketValueMap,
} from "../market-value";
import {
  ChartProps,
  PreparedDataType,
  PreparedFlatData,
  prepareFlatData,
  sumReducer,
} from "./helpers";

const prepareData = ({ labels, aggregatedData }: PreparedFlatData) => ({
  labels: labels.map((type: string) => marketValueMap[type]?.label),
  datasets: [
    {
      data: aggregatedData,
      backgroundColor: labels.map(
        (type: string) => marketValueMap[type]?.color
      ),
      innerRadius: "90%",
      outerRadius: "100%",
    },
  ],
});

const options = {
  maintainAspectRatio: true,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
      labels: {
        boxWidth: 15,
        padding: 20,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
};

function enrichDataWithMarketValueCategory(data: any[]) {
  return data.map((project: any) => {
    const marketValue = project?.building?.marketValue;
    return {
      ...project,
      marketValueCategory: getMarketValueCategory(marketValue),
    };
  });
}

const LegendTable = ({ preparedData }: { preparedData: PreparedFlatData }) => {
  const all = preparedData.aggregatedData.reduce(sumReducer, 0);
  return (
    <div className="grid grid-cols-2 gap-x-6 gap-y-2">
      {Object.keys(marketValueMap)
        .filter((key) => key !== MarketValueEnum.ALL)
        .map((type: string) => {
          const amount =
            preparedData.aggregatedData[preparedData.labels.indexOf(type)];
          const percentage = Math.floor((amount / all) * 100);
          return (
            <div
              key={type}
              className="text-sm text-gray-600 truncate flex flex-row justify-between items-start"
            >
              <div
                className="rounded-md w-5 h-4 mr-4 grow-0"
                style={{ backgroundColor: marketValueMap[type].color }}
              >
                {" "}
              </div>
              <div className="grow">{marketValueMap[type].label}</div>
              <div className="font-semibold align-self-end">
                {percentage || 0}% {amount ? `(${formatBigNumber(amount, false)})` : null}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const ReferencesByMarketValueCategory: React.FC<ChartProps> = ({
  data,
}) => {
  const chart = useRef(null);
  const enrichedData =
    data && data.length > 0 ? enrichDataWithMarketValueCategory(data) : [];
  const preparedFlatData =
    enrichedData.length > 0
      ? prepareFlatData(
          enrichedData,
          (item: any) => item?.marketValueCategory,
          PreparedDataType.COUNT
        )
      : { labels: [], aggregatedData: [] };

  return preparedFlatData?.aggregatedData?.length ? (
    <>
      <div className="h-48 xl:h-64 print:h-64 mt-4">
        <div className="w-48 xl:w-60 print:w-60 mx-auto">
          <Doughnut
            ref={chart}
            data={prepareData(preparedFlatData)}
            options={options}
          />
        </div>
        <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">NACH <br/>ANZAHL</div>
      </div>
      <div className="h-28 mt-8 xl:px-20 px-3">
        <LegendTable preparedData={preparedFlatData} />
      </div>
    </>
  ) : (
    <Alert title="Keine Daten vorhanden" message="Anderen Filter wählen" severity="info" />
  );
};
