import React from 'react';

export const SozialimmobilienIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.sozialimmobilien-${color}-cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;stroke:${color}}`}</style>
    </defs>
    <line className={`sozialimmobilien-${color}-cls-1`} x1=".35" y1="28.94" x2="29.35" y2="28.94" />
    <g>
      <polygon
        className={`sozialimmobilien-${color}-cls-1`}
        points="24.93 16.96 24.93 28.74 4.88 28.74 4.88 16.96 14.91 7.96 24.93 16.96"
      />
      <polyline className={`sozialimmobilien-${color}-cls-1`} points="5.06 16.76 14.85 7.96 24.63 16.76" />
      <polyline
        className={`sozialimmobilien-${color}-cls-1`}
        points="26.59 18.52 24.63 16.76 14.85 7.96 5.06 16.76 3.11 18.52"
      />
      <polygon
        className={`sozialimmobilien-${color}-cls-1`}
        points="28.35 16.76 26.59 18.52 24.63 16.76 14.85 7.96 5.06 16.76 3.11 18.52 1.35 16.76 14.85 4.61 28.35 16.76"
      />
    </g>
    <g>
      <g>
        <g>
          <path
            className={`sozialimmobilien-${color}-cls-1`}
            d="M14.37,18.84v2.66c0,.33-.27,.6-.6,.6s-.6-.27-.6-.6v6.19c0,.42-.34,.76-.76,.76s-.76-.34-.76-.76c0,.42-.34,.76-.76,.76s-.76-.34-.76-.76v-6.19c0,.33-.27,.6-.6,.6s-.6-.27-.6-.6v-2.66c0-.75,.51-1.37,1.21-1.54,.12-.03,.25-.05,.38-.05h2.27c.13,0,.26,.02,.38,.05,.69,.17,1.21,.8,1.21,1.54Z"
          />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="10.12" y1="21.5" x2="10.12" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="13.16" y1="21.5" x2="13.16" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="10.12" y1="21.5" x2="10.12" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="13.16" y1="21.5" x2="13.16" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="11.64" y1="27.7" x2="11.64" y2="22.85" />
        </g>
        <circle className={`sozialimmobilien-${color}-cls-1`} cx="11.64" cy="15.42" r="1.16" />
      </g>
      <g>
        <g>
          <path
            className={`sozialimmobilien-${color}-cls-1`}
            d="M20.78,18.84v2.66c0,.33-.27,.6-.6,.6s-.6-.27-.6-.6v6.19c0,.42-.34,.76-.76,.76s-.76-.34-.76-.76c0,.42-.34,.76-.76,.76s-.76-.34-.76-.76v-6.19c0,.33-.27,.6-.6,.6s-.6-.27-.6-.6v-2.66c0-.75,.51-1.37,1.21-1.54,.12-.03,.25-.05,.38-.05h2.27c.13,0,.26,.02,.38,.05,.69,.17,1.21,.8,1.21,1.54Z"
          />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="16.54" y1="21.5" x2="16.54" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="19.58" y1="21.5" x2="19.58" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="16.54" y1="21.5" x2="16.54" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="19.58" y1="21.5" x2="19.58" y2="18.98" />
          <line className={`sozialimmobilien-${color}-cls-1`} x1="18.06" y1="27.7" x2="18.06" y2="22.85" />
        </g>
        <circle className={`sozialimmobilien-${color}-cls-1`} cx="18.06" cy="15.42" r="1.16" />
      </g>
    </g>
  </svg>
);
