import React, { useEffect, useState } from 'react';
import { IUpdateField, IUpdateFieldProps } from './types';

export const DoubleDigitYearUpdateField: IUpdateField<string> = ({ label, value, onUpdate, loading }: IUpdateFieldProps<string>) => {
  const [year, id] = value.split('-');
  const [ newFieldValue, setNewFieldValue ] = useState(year);

  useEffect(() => {
    setNewFieldValue(year);
  }, [year]);


  return (<div className="flex flex-row items-center justify-start"><input
    type="text"
    name={label}
    className="w-12 px-3 mb-2 py-2 rounded focus:ring-green-500 focus:border-green-500 sm:text-sm border-gray-300"
    value={newFieldValue}
    onChange={(e) => { setNewFieldValue(e?.target?.value); e.stopPropagation(); }}
    onKeyDown={async (e) => { if(e.key === 'Enter') { onUpdate(`${newFieldValue}-${id}`); } }}
    maxLength={2}
  /><div className="text-sm mb-2">-{id}</div></div>)
}