import React, { useEffect } from "react";
import { useQueryParams } from "use-query-params";
import { useLazyFetchAnalyticsReferencesQuery } from "../../../model/analytics/references/analytics-references-api";
import {
  referenceListQueryParams,
  REFERENCE_LIST_APPRAISER_FILTER,
  REFERENCE_LIST_CLIENT_TYPE_FILTER,
  REFERENCE_LIST_EDITOR_FILTER,
  REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER,
  REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER,
  REFERENCE_LIST_COUNTRY_FILTER,
  REFERENCE_LIST_TYPE_FILTER,
  REFERENCE_LIST_YEARS_FILTER,
  CountryParamEnum,
} from "./filter/reference-filter-query-params";

export type ReferencesDataWrapperProps = {
  callback: Function,
  children: ({data, error, isLoading}: { data: [], error?: any, isLoading?: boolean}) => void;
};

function convertToBackendFilter(referenceFilterParams: any) {
  const years = referenceFilterParams[REFERENCE_LIST_YEARS_FILTER];
  const countryFilter = referenceFilterParams[REFERENCE_LIST_COUNTRY_FILTER];
  const marketValueLowerBounds =
    referenceFilterParams[REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER];
  const marketValueUpperBounds =
    referenceFilterParams[REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER];
  const clientTypes = referenceFilterParams[REFERENCE_LIST_CLIENT_TYPE_FILTER];
  const projectTypes = referenceFilterParams[REFERENCE_LIST_TYPE_FILTER];
  const editor = referenceFilterParams[REFERENCE_LIST_EDITOR_FILTER];
  const appraiser = referenceFilterParams[REFERENCE_LIST_APPRAISER_FILTER];

  const backendFilter = [
    years && years.length > 0
      ? {
          $or: years
            .filter((year: string | null) => year)
            .map((year: string | null) => ({
              displayId: { $startsWith: year },
            })),
        }
      : null,
    countryFilter === CountryParamEnum.GERMANY
      ? { building: { address: { country: { $eq: "Deutschland" } } } }
      : null,
    countryFilter === CountryParamEnum.NOT_GERMANY
      ? { building: { address: { country: { $ne: "Deutschland" } } } }
      : null,
    marketValueLowerBounds !== null && marketValueUpperBounds !== null
      ? {
          building: {
            marketValue: {
              $between: [marketValueLowerBounds, marketValueUpperBounds],
            },
          },
        }
      : null,
    clientTypes && clientTypes.length > 0
      ? { clientType: { id: { $in: [...clientTypes] } } }
      : null,
    projectTypes && projectTypes.length > 0
      ? { type: { $in: [...projectTypes] } }
      : null,
    editor || appraiser ? { $or: [
        ...(editor && appraiser ? [{ editor }, { appraiser }] :
        editor ? [{ editor }] : [{ appraiser }])
      ] } : null
  ];
  return {
    $and: [...backendFilter.filter((cond: any) => !!cond)],
  };
}

export const ReferencesDataWrapper = ({
  callback,
  children,
}: ReferencesDataWrapperProps) => {
  const [referenceFilterParams] = useQueryParams(referenceListQueryParams);
  const [fetchData, { data, error, isFetching }] =
    useLazyFetchAnalyticsReferencesQuery();

  useEffect(() => {
    async function fetchDataInternal() {
      await fetchData({
        queryParams: { filters: convertToBackendFilter(referenceFilterParams) },
      });
      callback();
    }
    fetchDataInternal();
  }, [referenceFilterParams]);

  return (children as Function)({data: data?.data || [], error, isLoading: isFetching});
};