import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface NewProjectModalState {
  isOpen: boolean,
  defaultValues?: NewProjectModalDefaultValues
}

export interface NewProjectModalDefaultValues {
    contractor?: string
    customer?: object
    clientType?: number
    projectType?: string
    objectType?: string
    objectSubType?: string
    objectName?: string
    street?: string
    streetNumber?: string
    zip?: string
    city?: string
    country?: string
    contactName?: string
    contactPhone?: string
    predecessor?: number
    predecessorDisplayId?: string
}

const initialState: NewProjectModalState = {
  isOpen: false,
  defaultValues: undefined
}

export const newProjectModalSlice = createSlice({
  name: 'newProjectModal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<NewProjectModalDefaultValues | undefined>) => {
      state.isOpen = true
      if(action.payload) {
        state.defaultValues = action.payload!!
      }
    },
    close: (state) => {
      state.isOpen = false;
      state.defaultValues = undefined;
    },
  },
})

// Action creators are generated for each case reducer function
export const { open, close } = newProjectModalSlice.actions

export default newProjectModalSlice.reducer