import { useQuery } from '@apollo/client';
import React, { createContext } from 'react';
import { ME_QUERY, ProfileMe } from '../layout/me.apollo';

// TODO: Refactor this Context to a store slice

export const ProfileContext = createContext<ProfileMe | null>(null);

export const ProfileContextProvider = ({children}: any) => {
  const { data: profile } = useQuery(ME_QUERY);

  return <ProfileContext.Provider value={profile}>
    {children}
  </ProfileContext.Provider>

}