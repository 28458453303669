import {MagnifyingGlassIcon, XCircleIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import {classNames} from "../../shared/utils/class-names";
import {useDebouncedCallback} from 'use-debounce';
import {Spinner} from "../../common/spinner/base-spinner";

export const SearchBox = ({
  className,
  defaultValue,
  onChange,
  onCancel = () => {},
  placeholder,
}: {
  className: string;
  defaultValue?: string | null;
  onChange: (val: string) => void;
  onCancel?: () => void,
  placeholder?: string;
}) => {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]);

  const debounced = useDebouncedCallback((
    (value:string) => {
      onChange(value);
      setLoading(false);
    }
  ), 1000);
  return (
    <div className={classNames(className, "relative")}>
      <input
        onChange={(e) => {
          setValue(e.target.value);
          setLoading(true);
          debounced(e.target.value);
        }}
        className="h-10 rounded-xl border border-gray-300 w-full bg-white pl-4 text-gray-800 placeholder-gray-500 sm:text-sm focus:ring-0 focus:outline-none focus:border focus:border-green-500 pr-10 shadow-sm"
        placeholder={placeholder}
        value={value || ''}
      />
      {(
        loading ?
          <Spinner className="absolute top-2.5 right-2 w-5 cursor-pointer stroke-gray-500"
          />
        : (
      value && value?.length > 0 ? (
        <XCircleIcon
          className="absolute top-2.5 right-2 w-5 cursor-pointer text-gray-600"
          aria-hidden="true"
          onClick={() => {
            setValue("");
            onCancel();
          }}
        />
      ) : (
        <MagnifyingGlassIcon
          className="absolute top-1.5 right-3 h-7 w-5 text-gray-500"
          aria-hidden="true"
        />
      )))}
    </div>
  );
};
