import { IKeyValuePair } from "../shared/utils/entity-utils";

export interface IProjectTypeMap {
  [key:string]: {
    displayString: string;
    color: string;
    offColor: string;
  }
}

export const projectTypeMap: IProjectTypeMap = {
  "Beleihungswertgutachten": { displayString: "Beleihungswert", color: "#C4C4C4", offColor: "#C4C4C4a4" },
"Privatgutachten": { displayString: "Privatgutachten", color: "#92929D", offColor: "#92929Da4"},
"Ankaufsgutachten": { displayString: "Ankaufsgutachten / Erstbewertung", color: "#FF974A", offColor: "#FF974Aa4" },
"Bautenstand": { displayString: "Bautenstand", color: "#C0504D", offColor: "#C0504Da4"},
"BestaetigungFertigstellung": { displayString: "Fertigstellungs- / Wertbestätigung", color: "#DB5353", offColor: "#DB5353a4"},
"Quartalsbewertungen": { displayString: "Quartalsbewertung", color: "#004F15", offColor: "#004F15a4"},
"FolgebewertungenJaehrl": { displayString: "Folgebewertung jährl./halbjährl.", color: "#558ED5", offColor: "#558ED5a4"},
"Indikation": { displayString: "Indikation", color: "#C4C4C4", offColor: "#C4C4C4a4"},
"Versicherungswertgutachten": { displayString: "Versicherungswertgutachten", color: "#F6B944", offColor: "#F6B944a4"},
"Stellungnahme": { displayString: "Stellungnahme", color: "#96B69F", offColor: "#96B69Fa4"},
"Ueberpruefung": { displayString: "Überprüfung Drittgutachten", color: "#95C067", offColor: "#95C067a4"},
"Sonstiges": { displayString: "Sonstiges", color: "#DB5353", offColor: "#DB5353a4"},
}



export const projectTypeKeyValuePairs: IKeyValuePair[] = Object.keys(projectTypeMap).map((key: string) => ({key, label: projectTypeMap[key]?.displayString}));