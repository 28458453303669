import dayjs from "dayjs";
import { IProjectFilter } from "../../components/filters/project-filter";

export const buildRestFilter = ({
  status,
  projectTypes,
  employees,
  clientTypes,
  customers,
  contractor,
  appraiser,
  years,
  geoBounds,
  buildingTypes1,
  buildingTypes2,
  dateOfConstruction,
  deadlineStart,
  deadlineEnd,
  valuation,
  useForResearch
}: IProjectFilter) => {
  const conditions = [
    status && status.length > 0 ? { status: { $in: [...status] } } : null,
    employees && employees?.length > 0
      ? {
          $or: [
            { editor: { id: { $in: [...employees] } } },
            { editor2: { id: { $in: [...employees] } } },
            { appraiser: { id: { $in: [...employees] } } },
          ],
        }
      : null,
    // }, // GraphQL filter works with nested objects
    clientTypes && clientTypes.length > 0
      ? { clientType: { id: { $in: [...clientTypes] } } }
      : null,
    customers && customers.length > 0
      ? { customer: { id: { $in: [...customers] } } }
      : null,
    projectTypes && projectTypes.length > 0
      ? { type: { $in: [...projectTypes] } }
      : null,
    contractor && { contractor: { $eq: contractor } },
    appraiser !== null && { isCoappraiser: { $eq: appraiser } },
    years && years.length > 0 ? {
      $or: years.filter((year: string | null) => (year)).map((year: string | null) => ({ displayId: { $startsWith: year }}))
    } : null,
    deadlineStart && deadlineEnd ? { deadlineDate: { $between: [ dayjs(deadlineStart).format('YYYY-MM-DD'), dayjs(deadlineEnd).format('YYYY-MM-DD') ]} } : null,
    dateOfConstruction && { project_research: { originalYearOfConstructionAsYearNumber: { $between: dateOfConstruction } } },
    geoBounds && {
      building: {
        address: {
          $and: [
            {
              lat: {
                $between: [geoBounds.getSouth(), geoBounds.getNorth()],
              },
            },
            {
              lng: {
                $between: [geoBounds.getWest(), geoBounds.getEast()],
              },
            },
          ],
        },
      },
    },
    buildingTypes1 && { building: { type: { $in: [...buildingTypes1] }} },
    buildingTypes2 && { building: { subtype: { $in: [...buildingTypes2] }} },
    (valuation && valuation === "kp") && { project_research: { priceOfSale: { $notNull: true } } },
    (valuation && valuation === "vkw") && { project_research: { marketValue: { $notNull: true } } },
    useForResearch && { project_research: { useForResearch: true } },
  ];
  return {
    $and: [...conditions.filter((cond: any) => !!cond)],
  };
};
