import { Icon, latLng } from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import { ProjectMap } from "../../../../model/project-map/project-map-model";
import { MapMarkerItem } from "../../../../components/map/map-marker-item";
import { Link } from "react-router-dom";

const InternalMarker = ({ mapMarkerItem }: { mapMarkerItem: ProjectMap } ) => {
  return (
    <Marker
      key={mapMarkerItem?.projectId}
      position={latLng(mapMarkerItem.lat, mapMarkerItem.lng)}
      zIndexOffset={-10000}
      icon={
        new Icon({
          iconUrl: `/icons/map_${mapMarkerItem?.objectType}_gray.svg`,
          iconSize: [30, 30],
          iconAnchor: [15, 15],
        })
      }
    >
      <Popup
        key={mapMarkerItem?.projectId}
        position={latLng(mapMarkerItem.lat, mapMarkerItem.lng)}
        offset={[0, 0]}
        closeButton={false}
        maxWidth={380}
        minWidth={380}
        keepInView={false}
        autoPan={true}
        closeOnClick={false}
      >
        <Link to={`/projects/${mapMarkerItem?.projectId}`}>
          <MapMarkerItem projectId={mapMarkerItem?.projectId} />
        </Link>
      </Popup>
    </Marker>
  )
}

export const MemoizedMapMarker = React.memo(
  InternalMarker,
  ({ mapMarkerItem: prevMarker }, { mapMarkerItem: nextMarker }) => {
    return prevMarker.projectId === nextMarker.projectId;
  }
);
