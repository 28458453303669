import React from "react";
import { buildingTypeMap, IBuildingTypeItem } from "../../../../common/building-type-map";

export const Legend = () => {
  return (
    <div className="flex flex-col px-8 py-6 font-normal align-center">
      {Object.values(buildingTypeMap).map((bt: IBuildingTypeItem) => {
        return (
          <div key={bt.displayString}>
            <div className="flex flex-row h-12 md:h-12 font-sans text-base items-center mr-4">
              <div className="h-6 w-6 md:w-8 md:h-8 rounded-full bg-green-500 text-white mr-2 shrink-0 px-1.5 pt-1">
                <bt.icon color='white' />
              </div>{" "}
              <div>{bt.displayString}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
