import { gql } from "@apollo/client";

export type IClientType = {
  id: string;
  attributes: {
    label: string;
  };
};

// TODO: Refactor to REST
export const CLIENT_TYPE_QUERY = gql`
  query {
    clientTypes {
      data {
        id
        attributes {
          label
        }
      }
    }
  }
`;
