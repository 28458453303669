import { useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { buildingTypeMap } from "../../../../common/building-type-map";
import Alert from "../../../../components/layout/alert";
import {
  formatBigNumber,
} from "../../../../shared/utils/formatters";

import {
  ChartProps,
  PreparedDataType,
  PreparedFlatData,
  prepareFlatData,
} from "./helpers";

interface ReferenceByBuildingTypeBarChart extends ChartProps {
  height?: number;
  width?: number;
}

const prepareData = ({ labels, aggregatedData }: PreparedFlatData) => ({
  labels: labels.map((type) => buildingTypeMap[type]?.displayString),
  datasets: [
    {
      data: aggregatedData,
      backgroundColor: labels.map((type) => buildingTypeMap[type]?.color),
    },
  ],
});

const LegendTable = ({ preparedData }: { preparedData: PreparedFlatData }) => {
  const all = preparedData.aggregatedData.reduce((acc, item) => acc + item, 0);
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      {Object.keys(buildingTypeMap).map((type: string) => {
        const labelIndex = preparedData?.labels?.indexOf(type);
        const amount = preparedData.aggregatedData[labelIndex];
        const percentage = Math.floor((amount / all) * 100);
        return (
          amount > 0 && (
            <div
              key={type}
              className="text-gray-600 truncate flex flex-row justify-between items-center text-[8px] xl:text-xs"
            >
              <div
                className="rounded-md min-w-[16px] h-4 mr-2"
                style={{ backgroundColor: buildingTypeMap[type].color }}
              >
                {" "}
              </div>
              <div className="grow">{buildingTypeMap[type].displayString}</div>
              <div className="font-semibold align-self-end">
                {percentage || (amount > 0 ? '<1' : '0')}%{" "}
                {amount ? `(${formatBigNumber(amount, false)}*)` : ""}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

const options = {
  maintainAspectRatio: true,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const ReferencesByTypeVolume: React.FC<
  ReferenceByBuildingTypeBarChart
> = ({ data, width, height }) => {
  const chart = useRef(null);
  const preparedFlatData =
    data && data.length > 0
      ? prepareFlatData(
          data,
          (item: any) => item.building.type,
          PreparedDataType.COUNT,
          (item: any) => item.building.marketValue
        )
      : { labels: [], aggregatedData: [] };

  const preparedVolumeFlatData =
    data.length > 0
      ? prepareFlatData(
          data,
          (item: any) => item.building.type,
          PreparedDataType.SUM,
          (item: any) => item?.building?.marketValue
        )
      : { labels: [], aggregatedData: [] };

  const interactionOptions: any = {
    mode: "y",
    intersect: false,
  };

  return preparedFlatData?.aggregatedData?.length > 0 ? (
    <>
      <div className="h-48 xl:h-64 print:h-64 mt-4 flex flex-row justify-around">
        <div className="w-48 xl:w-60 print:w-60 mx-2">
          <Doughnut data={prepareData(preparedFlatData)} options={options} />
          <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">
            NACH <br />
            ANZAHL*
          </div>
        </div>
        <div className="w-48 xl:w-60 print:w-60 mx-2">
          <Doughnut
            data={prepareData(preparedVolumeFlatData)}
            options={options}
          />
          <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">
            NACH <br />
            VOLUMEN
          </div>
        </div>
      </div>
      <div className="h-28 mt-8 xl:px-20 px-3">
        <LegendTable preparedData={preparedFlatData} />
      </div>
    </>
  ) : (
    <Alert
      title="Keine Daten vorhanden"
      message="Anderen Filter wählen"
      severity="info"
    />
  );
};
