import React from 'react';
import { FilterSelect } from '../filters/filter-select';
import { IUpdateField } from './types';
import { toKeyValuePair } from '../../shared/utils/entity-utils';

export const GenericEnumUpdateField: IUpdateField<string> = ({ label, value, extra, onUpdate, error, className }) => {
  const items = toKeyValuePair(extra?.labelMap);
  return (
    <FilterSelect
      placeholder={label}
      selected={items.find((item: any) => ( item.key === value ))}
      onChange={(newVal) => { onUpdate(newVal?.key) }}
      error={error}
      items={items}
      className={className}
      nonNullable
    />
  );
};
