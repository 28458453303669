import { ArrowTrendingUpIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useQueryParams } from "use-query-params";

import { ProfilePicture } from "../../../components/layout/profiles/profile-picture";
import { Employee } from "../../../model/analytics/employees/analytics-employees";
import { classNames } from "../../../shared/utils/class-names";

import {
  employeeFilterParams,
  EMPLOYEE_BAR_CHART_FILTER,
  EMPLOYEE_LINE_CHART_FILTER_1,
  EMPLOYEE_LINE_CHART_FILTER_2,
} from "./employees-table";

enum GreenYellow {
  green,
  yellow,
}

export const LineChartFilter = ({ employees }: { employees: Employee[] }) => {
  const [filterParams, setFilterParams] = useQueryParams(employeeFilterParams);
  // First Item: Green, Second Item: Yellow
  const [lineChartFilter, setLineChartFilter] = useState<(number | null)[]>([]);
  const [nextItem, setNextItem] = useState<GreenYellow>(GreenYellow.green);
  const [currentHover, setCurrentHover] = useState<number | null>(null);
  const [preselectedEmployees, setPreselectedEmployees] = useState<number[]>(
    []
  );

  useEffect(() => {
    setPreselectedEmployees(
      (filterParams[EMPLOYEE_BAR_CHART_FILTER] as number[]) || []
    );
    if (
      filterParams[EMPLOYEE_LINE_CHART_FILTER_1] &&
      !filterParams[EMPLOYEE_BAR_CHART_FILTER]?.includes(
        filterParams[EMPLOYEE_LINE_CHART_FILTER_1]
      )
    ) {
      setFilterParams({
        ...filterParams,
        [EMPLOYEE_LINE_CHART_FILTER_1]: null,
      });
    } else if (
      filterParams[EMPLOYEE_LINE_CHART_FILTER_2] &&
      !filterParams[EMPLOYEE_BAR_CHART_FILTER]?.includes(
        filterParams[EMPLOYEE_LINE_CHART_FILTER_2]
      )
    ) {
      setFilterParams({
        ...filterParams,
        [EMPLOYEE_LINE_CHART_FILTER_2]: null,
      });
    } else {
      setLineChartFilter([
        filterParams[EMPLOYEE_LINE_CHART_FILTER_1],
        filterParams[EMPLOYEE_LINE_CHART_FILTER_2],
      ]);
    }
  }, [filterParams]);

  const removeEmployeeFromBothFilters = (id: string) => {
    setFilterParams({
      ...filterParams,
      [EMPLOYEE_BAR_CHART_FILTER]: preselectedEmployees.filter(
        (pe) => pe?.toString() !== id
      ),
      [EMPLOYEE_LINE_CHART_FILTER_1]:
        lineChartFilter[0]?.toString() === id ? null : lineChartFilter[0],
      [EMPLOYEE_LINE_CHART_FILTER_2]:
        lineChartFilter[1]?.toString() === id ? null : lineChartFilter[1],
    });
  };

  return (
    <div className="mt-1 flex flex-row gap-1">
      {employees
        ?.filter((employee) => {
          return preselectedEmployees.includes(parseInt(employee.id));
        })
        ?.map((se) => (
          <div
            key={se.id}
            className="group min-w-8 min-h-8"
            onClick={() => {
              if (!lineChartFilter.includes(parseInt(se.id))) {
                const lineChartFilterEmployees = [
                  nextItem === GreenYellow.green
                    ? parseInt(se.id)
                    : lineChartFilter[0],
                  nextItem === GreenYellow.yellow
                    ? parseInt(se.id)
                    : lineChartFilter[1],
                ];
                setNextItem(
                  nextItem === GreenYellow.green
                    ? GreenYellow.yellow
                    : GreenYellow.green
                );
                setLineChartFilter(lineChartFilterEmployees as number[]);
                setFilterParams({
                  ...filterParams,
                  [EMPLOYEE_LINE_CHART_FILTER_1]: lineChartFilterEmployees[0],
                  [EMPLOYEE_LINE_CHART_FILTER_2]: lineChartFilterEmployees[1],
                });
              }
            }}
            onMouseOver={() => {
              if (!lineChartFilter.includes(parseInt(se.id))) {
                setCurrentHover(parseInt(se.id));
              }
            }}
            onMouseLeave={() => {
              setCurrentHover(null);
            }}
          >
            <ProfilePicture
              profilePicturePath={`${se?.attributes?.image?.data?.attributes?.url}`}
            />
            <div
              className=""
              onClick={(e) => {
                e.stopPropagation();
                removeEmployeeFromBothFilters(se?.id);
              }}
            >
              <XCircleIcon className="absolute -mt-10 ml-5 text-gray-500 pointer-events-auto hover:cursor-pointer hover:text-gray-600 w-4 h-4 opacity-100" />
            </div>
            {lineChartFilter[0] === parseInt(se?.id) ? (
              <div className="relative left-0 -mt-8 h-8 w-8 p-1 rounded-full opacity-30 bg-green-500"></div>
            ) : lineChartFilter[1] === parseInt(se?.id) ? (
              <div className="relative left-0 -mt-8 h-8 w-8 p-1 rounded-full opacity-30 bg-orange-500"></div>
            ) : (
              <div
                className={classNames(
                  currentHover === parseInt(se.id) ? "block" : "hidden",
                  "relative left-0 -mt-8 h-8 w-8 p-1 rounded-full opacity-30 hover:cursor-pointer",
                  nextItem === GreenYellow.green
                    ? "bg-green-500"
                    : "bg-orange-500"
                )}
              >
                <ArrowTrendingUpIcon className="h-6 w-6 text-white" />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
