import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { backendImageUrl } from '../../shared/utils/url-utils';
import {AuthImg} from "../layout/auth-img";

export const AvatarHead = ({ user }:any) => {
  return (
    <div className="max-w-xs bg-white text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
      { user?.data
      ? <AuthImg
          className="h-8 w-8 rounded-full"
          src={backendImageUrl(user?.data?.attributes?.image?.data?.attributes?.url)}
          size={"thumbnail"}
          placeholder={"/avatar.svg"}
        />
      : <div
        className="h-8 w-8 rounded-full bg-gray-500 text-white p-2">
          <QuestionMarkCircleIcon />
        </div>
    }
    </div>
  )
}