import { GlobeEuropeAfricaIcon } from "@heroicons/react/24/outline";
import { CountryCategory, enrichDataWithCountryCategory } from "./country-category-helpers";
import { ChartProps, PreparedDataType, prepareFlatData } from "./helpers";
import { Tile } from "./tile";

export const TileByCountryAbroad: React.FC<ChartProps> = ({ data }) => {
  const enrichedData = data && data.length > 0 ? enrichDataWithCountryCategory(data) : [];
  const preparedFlatData = enrichedData.length > 0 ? prepareFlatData(
    enrichedData,
    (item: any) => (item?.countryCategory),
    PreparedDataType.COUNT
  ) : { labels: [], aggregatedData: [] };

  const abroadIndex = preparedFlatData?.labels.indexOf(CountryCategory.ABROAD);

  return (
    <Tile
      name="Auslandssobjekte"
      number={preparedFlatData?.aggregatedData?.[abroadIndex]}
      icon={<GlobeEuropeAfricaIcon />}
      className="bg-green-500"
    />
  );
};
