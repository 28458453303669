import React from "react";
import Dropzone, { IDropzoneProps, IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { getItemTransparent } from "../../shared/utils/storage-utils";
import { getLoginUrl } from "../../shared/utils/url-utils";
import { classNames } from "../../shared/utils/class-names";
import { defaultButtonClasses, primaryButtonClasses } from "./buttons-classes";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

export interface IUploadButton {
  objectId: string;
  fileTypeText?: string;
  fileTypeAccept: string;
  objectTypeRef: string;
  objectField: string;
  buttonText: string;
  reload: Function;
  disabled?: boolean;
}

export const UploadButton = ({ objectId, objectTypeRef, objectField, reload, buttonText, fileTypeAccept, disabled = false }: IUploadButton) => {
  const getUploadParams: IDropzoneProps["getUploadParams"] = ({
    file,
    meta,
  }) => {
    const body = new FormData();
    body.append("files", file);
    body.append("refId", objectId);
    body.append("ref", objectTypeRef);
    body.append("field", objectField);

    const headers = {
      Authorization: `Bearer ${getItemTransparent("JWT")}`,
    };
    return {
      url: `${getLoginUrl()}/api/upload`,
      body,
      headers,
      method: "POST",
      meta,
      withCredentials: true,
    };
  };

  const handleChangeStatus = ({ meta, remove }: IFileWithMeta, status: StatusValue) => {
    switch (status) {
      case "headers_received": {
        toast.info(`${meta.name} erfolgreich hochgeladen!`);
        remove();
        reload();
        break;
      }
      case "aborted": {
        toast.error(`${meta.name}, hochladen fehlgeschlagen.`);
        break;
      }
      case "rejected_file_type": {
        toast.error(`${meta.name}, Dieser Dateityp ist hier nicht erlaubt.`);
        break;
      }
    }
  };

  return (
    disabled ? <div className={classNames("bg-[#004F1519] border-none rounded-lg h-10 p-2.5 w-40 font-bold text-base text-white flex flex-row gap-1")}><ArrowUpOnSquareIcon className="w-5 h-5"/> {buttonText}</div> 
    : <Dropzone
      key={objectId}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      accept={fileTypeAccept}
      inputContent={
        <div className={classNames("bg-green-500 border-none rounded-lg h-10 p-2.5 w-40 font-bold text-base text-white flex flex-row gap-1")}><ArrowUpOnSquareIcon className="w-5 h-5"/> {buttonText}</div>
      }
      addClassNames={{
        dropzone: "",
        dropzoneActive: "",
        dropzoneReject: "",
        previewImage: "hidden",
        input: "hidden",
      }}
    />
  );
};
