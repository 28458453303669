import { Doughnut } from "react-chartjs-2";
import Alert from "../../../../components/layout/alert";
import { formatBigNumber } from "../../../../shared/utils/formatters";
import {
  countryCategoryMap,
  enrichDataWithCountryCategory,
} from "./country-category-helpers";
import { PreparedDataType, PreparedFlatData, prepareFlatData } from "./helpers";

interface BarChartProps {
  data: any;
  options: any;
}

const colors = ["#004f14", "#7d9249", "#c7d5a1", "#f1c297", "#f6d6ba"];
const prepareData = ({ labels, aggregatedData }: PreparedFlatData) => ({
  labels: labels.map((type: string) => countryCategoryMap[type]?.displayString),
  datasets: [
    {
      data: aggregatedData,
      backgroundColor: colors,
    },
  ],
});

const LegendTable = ({ preparedData }: { preparedData: PreparedFlatData }) => {
  const all = preparedData.aggregatedData.reduce((acc, item) => acc + item, 0);
  return (
    <div className="grid grid-cols-2 gap-x-6 gap-y-2">
      {preparedData.labels.map((label: string) => {
        const labelIndex = preparedData?.labels?.indexOf(label);
        const amount = preparedData.aggregatedData[labelIndex];
        const percentage = Math.floor((amount / all) * 100);
        return (
          <div
            key={label}
            className="text-sm text-gray-600 truncate flex flex-row justify-between items-start"
          >
            <div
              className="rounded-md w-5 h-4 lg:mr-4 mr-2 grow-0"
              style={{ backgroundColor: colors[labelIndex] }}
            >
              {" "}
            </div>
            <div className="grow">
              {countryCategoryMap[label].displayString}
            </div>
            <div className="font-semibold align-self-end">
              {percentage}% ({formatBigNumber(amount, false)}*)
            </div>
          </div>
        );
      })}
    </div>
  );
};

const options = {
  maintainAspectRatio: true,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const ReferencesByCountryCategory: React.FC<BarChartProps> = ({
  data,
}) => {
  const enrichedData =
    data && data.length > 0 ? enrichDataWithCountryCategory(data) : [];
  const preparedFlatData =
    enrichedData.length > 0
      ? prepareFlatData(
          enrichedData,
          (item: any) => item?.countryCategory,
          PreparedDataType.COUNT
        )
      : { labels: [], aggregatedData: [] };

      const preparedVolumeFlatData =
      enrichedData.length > 0
        ? prepareFlatData(
            enrichedData,
            (item: any) => item?.countryCategory,
            PreparedDataType.SUM,
            (item: any) => item?.building?.marketValue,
          )
        : { labels: [], aggregatedData: [] };

  return preparedFlatData?.aggregatedData?.length > 0 ? (
    <>
      <div className="h-48 xl:h-64 print:h-64 mt-4 flex flex-row justify-around">
        <div className="w-48 xl:w-60 print:w-60 mx-2">
          <Doughnut
            data={prepareData(preparedFlatData)}
            options={options}
          />
          <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">
          NACH <br/>ANZAHL*
          </div>
        </div>
        <div className="w-48 xl:w-60 print:w-60 mx-2">
          <Doughnut
            data={prepareData(preparedVolumeFlatData)}
            options={options}
          />
          <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">
          NACH <br/>VOLUMEN
          </div>
        </div>
      </div>
      <div className="h-28 mt-8 xl:px-20 px-3">
        <LegendTable preparedData={preparedFlatData} />
      </div>
    </>
  ) : (
    <Alert title="Keine Daten vorhanden" message="Anderen Filter wählen" severity="info" />
  );
};
