import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeleteUserMutation, useFetchUserQuery } from "../../model/users/user-api";
import { defaultButtonClasses, primaryButtonClasses } from "../../components/basics/buttons-classes";
import { classNames } from "../../shared/utils/class-names";
import Alert from "../../components/layout/alert";
import { toast } from "react-toastify";
import { Spinner } from "../../common/spinner/base-spinner";

export const UserDelete = () => {
  const params = useParams();
  const userId = params?.userId ? parseInt(params?.userId) : null;
  const navigate = useNavigate();
  const [sendDeleteUser] = useDeleteUserMutation();

  const { data: user, error: usersError, isFetching } =
    useFetchUserQuery({ id: userId!!, queryParams: { filters: {}, populate: ["image", "role"] } });

  if (!userId) {
    return <Alert title="Löschen nicht möglich" message="Sie müssen eine Nutzernummer zum Löschen angeben" />
  }

  const deleteUser = async () => {
    const result: any = await sendDeleteUser(userId);
    if(result?.error) {
      toast.error("Nutzer konnte nicht gelöscht werden.");
      console.error(result?.error?.data?.error?.message);
    } else {
      toast.info("Nutzer erfolgreich gelöscht.")
      navigate("/users");
    }
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="fixed z-[1000] inset-0 overflow-y-auto" onClose={() => { }}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="relative">
                <Link to="/users" className="absolute top-0 right-0 cursor-pointer">
                  <XMarkIcon className="h-6 w-6 text-black" aria-hidden="true" />
                </Link>
                {isFetching
                  ? <Spinner className="h-12 w-12 stroke-gray-500" />
                  : (<div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-xl font-special leading-6 font-medium text-gray-900">
                      Löschen des Nutzers: {user?.username}
                    </Dialog.Title>
                    <div className="mt-2 flex flex-col items-center gap-2">
                      <div>Wollen Sie den Nutzer {user?.username} ({user?.email}) wirklich löschen?</div>
                      <div className="w-3/4 flex flex-row justify-between">
                        <Link className={classNames(...defaultButtonClasses, "w-40 text-center")} to="/users">Abbrechen</Link>
                        <button className={classNames(...primaryButtonClasses, "bg-red-500 w-40")} onClick={deleteUser}>Löschen</button>
                      </div>
                    </div>
                  </div>)}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}