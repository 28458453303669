import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Align,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { statusMap } from "../../common/status-map";

ChartJS.register(ArcElement, Tooltip, Legend);

export const generateChartConfig = (labels: string[], colors: string[], data: number[]) => ({
  labels,
  datasets: [
    {
      label: "Auftragsstatus",
      data,
      backgroundColor: colors,
      borderWidth: 0,
    },
  ],
});

const position: "right" = "right";

const generateOptions = (labels: string[]) => ({
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      position,
      align: "center" as Align,
      labels: {
        usePointStyle: true,
        font: {
          family: "Roboto",
        },
      },
    },
  },
});

export const StatusOverview = ({
  data,
}: {
  data: { [key: string]: number };
}) => {
  const orderedData = data && Object.keys(data).sort((a: string, b: string) => {
    return statusMap[a].weight - statusMap[b].weight;
  }).reduce((acc: { [key: string]: number }, key: string) => { acc[key] = data[key]; return acc;} ,{});
  const labels = orderedData && Object.keys(orderedData).map((label: string) => (statusMap[label].displayString));
  const colors =  orderedData && Object.keys(orderedData).map((label: string) => (statusMap[label].color));
  return (
    <Pie
      data={generateChartConfig(labels, colors, orderedData ? Object.values(orderedData) : [] )}
      options={generateOptions(labels)}
    />
  );
};
