import React from "react";
import {Card} from "../../../components/basics/card";
import {MagicUpdate} from "../../../components/magic-update/magic-update";
import {getLoginUrl} from "../../../shared/utils/url-utils";
import {UploadZone} from "./upload-zone";
import {MapAddress} from "./detail-map/map-address";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {RemoveFile} from "./remove-file";
import {AuthImg} from "../../../components/layout/auth-img";
import {AuthDownloadItem} from "../../../components/layout/auth-download-item";
import {useFetchOneProjectResearchQuery} from "../../../model/project-research/project-research-api";
import {formatBigNumber} from "../../../shared/utils/formatters";

export const PrepareVisit = ({ project, reload }: any) => {
  const projectResearchId = project?.project?.data?.attributes?.project_research?.data?.id;
  const projectBuildingId = project?.project?.data?.attributes?.building?.data?.id;
  const projectBuildingAttributes = project?.project?.data?.attributes?.building?.data?.attributes;

  const {
    data: projectResearchData,
    error,
    isLoading,
    refetch,
  } = useFetchOneProjectResearchQuery({id: projectResearchId, queryParams: {populate: ["valxml"]}})

  return (
    <>
      <MapAddress address={ projectBuildingAttributes?.address?.data} buildingType={ projectBuildingAttributes?.type} />
      <Card className="bg-green-100 mt-6">
        <h2 className="text-lg font-semibold">
          Objektinformationen
        </h2>
        <div className="grid gap-6 grid-cols-2 lg:grid-cols-4 mt-3">
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="parcel"
            label="Flurstück"
            value={projectBuildingAttributes?.parcel}
          />
          <MagicUpdate
            model="projectResearch"
            id={projectResearchId}
            key={"landSize"}
            reload={refetch}
            type="Number"
            gqlType="Float"
            field="landSize"
            label={"Grundstücksfläche"}
            value={projectResearchData?.data?.attributes["landSize"]}
            formatter={formatBigNumber}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="brw"
            label="BRW"
            value={projectBuildingAttributes?.brw}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="constructionPlanningLaw"
            label="Bauplanungsrecht"
            value={projectBuildingAttributes?.constructionPlanningLaw}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="sectionII"
            label="Abt II."
            value={projectBuildingAttributes?.sectionII}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="publicEasement"
            label="Baulasten"
            value={projectBuildingAttributes?.publicEasement}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="legacyIssues"
            label="Altlasten"
            value={projectBuildingAttributes?.legacyIssues}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="leasehold"
            label="Erbbaurecht"
            value={projectBuildingAttributes?.leasehold}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="Number"
            gqlType="Int"
            field="yearOfConstruction"
            label="Baujahr"
            value={projectBuildingAttributes?.yearOfConstruction}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="lastRenovation"
            label="Letzte Sanierungen"
            value={projectBuildingAttributes?.lastRenovation}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="existingIssues"
            label="Bestehende Mängel"
            value={projectBuildingAttributes?.existingIssues}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="String"
            field="plannedMeassures"
            label="Geplante Maßnahmen"
            value={projectBuildingAttributes?.plannedMeassures}
          />
          <MagicUpdate
            model="building"
            id={projectBuildingId}
            reload={reload}
            type="Boolean"
            field="monumentProtection"
            label="Denkmalschutz"
            value={projectBuildingAttributes?.monumentProtection}
          />
        </div>
      </Card>
      <Card className="mt-6 bg-green-100">
        <h2 className="text-lg font-semibold mb-2">
          Vermietungs- / Nutzungsstruktur
        </h2>
        <MagicUpdate
          model="building"
          id={projectBuildingId}
          reload={reload}
          type="Textarea"
          gqlType="String"
          field="usageStructure"
          label=""
          value={projectBuildingAttributes?.usageStructure}
        />
      </Card>
      <Card className="mt-6 bg-green-100">
        <h2 className="text-lg font-semibold mb-2">Fragen / Sonstiges</h2>
        <MagicUpdate
          model="project"
          id={project?.project?.data?.id}
          reload={reload}
          type="Textarea"
          gqlType="String"
          field="questions"
          label=""
          value={project?.project?.data?.attributes?.questions}
        />
      </Card>
      <div className="mt-6">
        <h2 className="text-lg font-semibold mb-2">Fotos</h2>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-2 lg:grid-cols-4">
          {project?.project?.data?.attributes?.images?.data.map(
            (image: any) => {
              return (
                <div key={image?.id} className="w-full relative group">
                  <AuthImg
                    src={`${getLoginUrl()}${image?.attributes?.url}`}
                    className="h-32 rounded-2xl"
                    size="thumbnail"
                  />
                  <RemoveFile fileToRemove={image?.id} afterDelete={reload}>
                    <div className="opacity-0 group-hover:opacity-100 absolute w-3 h-3 top-0 right-0 cursor-pointer">
                      <XMarkIcon className="bg-gray-600 text-white opacity-70 rounded-full p-1" />
                    </div>
                  </RemoveFile>
                </div>
              );
            }
          )}
          {project?.project?.data?.id
            ? [
                ...Array(
                  4 -
                    (project?.project?.data?.attributes?.images?.data.length %
                      4)
                ).keys(),
              ].map((key) => (
                <UploadZone
                  key={key}
                  objectId={project?.project?.data?.id}
                  objectTypeRef="api::project.project"
                  objectField="images"
                  icon={<img src={`/icons/image-placeholder.svg`} alt="" />}
                  reload={reload}
                  fileTypeAccept="image/*"
                  fileTypeText=".JPG/.JPEG/.PNG DATEIEN"
                />
              ))
            : null}
        </div>
      </div>
      <div className="mt-6 flex flex-row gap-3">
        <div className="w-1/2">
          <h3 className="text-sm uppercase text-gray-500 flex flex-row group mb-3">
            Aktuelle Marktdaten
          </h3>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <UploadZone
              key={project?.project?.data?.id}
              objectId={project?.project?.data?.id}
              objectTypeRef="api::project.project"
              objectField="marketDataFiles"
              icon={<img src={`/icons/file-placeholder.svg`} alt="" />}
              reload={reload}
              fileTypeAccept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.xlsx,.xls,application/msexcel"
              fileTypeText="DOC/PDF/EXCEL"
            />
            <div className="text-blue-500">
              <ul>
                {project?.project?.data?.attributes?.marketDataFiles?.data.map(
                  (item: any) => {
                    return (
                      <li key={item?.id} className="truncate group flex">
                        <AuthDownloadItem item={item} afterDelete={reload} />
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <h3 className="text-sm uppercase text-gray-500 flex flex-row group mb-3">
            RCA Vergleichsdaten
          </h3>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <UploadZone
              objectId={project?.project?.data?.id}
              objectTypeRef="api::project.project"
              objectField="rcaComparisonFiles"
              icon={<img src={`/icons/file-placeholder.svg`} alt="" />}
              reload={reload}
              fileTypeAccept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.xlsx,.xls,application/msexcel"
              fileTypeText="DOC/PDF/EXCEL"
            />
            <div className="text-blue-500">
              <ul>
                {project?.project?.data?.attributes?.rcaComparisonFiles?.data.map(
                  (item: any) => {
                    return (
                      <li key={item?.id} className="truncate group flex">
                        <AuthDownloadItem item={item} afterDelete={reload} />
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
