import { gql, useQuery } from '@apollo/client';
import { BellIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { classNames } from '../../../shared/utils/class-names';

export const NotificationBell = ({ className }: any) => {
  const [hasUnread, setHasUnread] = useState();

  const { data: unreadData, error: unreadError, loading: unreadLoading, refetch: refetchUnread } = useQuery(gql`query {
    unreadInfo {
          hasUnreadNotifications
      }
    }
  `);

  useEffect(() => {
    setHasUnread(unreadData?.unreadInfo?.hasUnreadNotifications)
  }, [unreadData?.unreadInfo?.hasUnreadNotifications]);


  return (<div className={classNames(className ,"relative rounded-lg h-8 w-8 flex justify-center items-center", hasUnread ? "bg-red-500" : "bg-transparent")}>
    <BellIcon className="w-6 h-6" aria-hidden="true" />
     </div>
  )
}