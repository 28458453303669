import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getItemTransparent } from "../../../shared/utils/storage-utils";
import {
  NOTIFICATIONS_MARKALLREAD,
  NOTIFICATION_MARKREAD_QUERY,
  NOTIFICATION_QUERY,
} from "../../../model/notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "../../../common/spinner/base-spinner";
import { NOTIFICATIONS_GET_UNREAD_QUERY } from "../../../model/notifications";
import { toast } from "react-toastify";
import { NotificationListItem } from "./notification-list-item";
import { PgToggle } from "../toggle";

const PAGE_SIZE = 12;
const INITIAL_MAX_PAGES = 1000001;


export const NotificationList = () => {
  const username = getItemTransparent('user')?.username;
  const [pagesLoaded, setPagesLoaded] = useState(1);
  const [maxPages, setMaxPages] = useState(1000);
  const [hasUnread, setHasUnread] = useState(false);

  // TODO: Refactor to redux, handle loading state and errors
  const { data, refetch } = useQuery(NOTIFICATION_QUERY, {
    variables: {
      username,
      limit: PAGE_SIZE,
      start: 0
    },
    pollInterval: 30000,
    onCompleted: (data:any) => {
      if(maxPages === INITIAL_MAX_PAGES) {
        setMaxPages(data?.notifications?.meta?.pagination?.pageCount);
      }
    }
  });

  const { data: unreadData, refetch: refetchUnread } = useQuery(NOTIFICATIONS_GET_UNREAD_QUERY, {
    pollInterval: 5000,
    onError() {
      toast.error('Benachrichtigungsstatus konnte nicht abgerufen werden');
    }
  });

  const [markAllReadServer] = useMutation(NOTIFICATIONS_MARKALLREAD, {
    onError() {
      toast.error('Konnte nicht alle Benachrichtigungen auf gelesen setzen');
    }
  })

  const navigate = useNavigate();
  const [markRead] = useMutation(NOTIFICATION_MARKREAD_QUERY, {
    onCompleted(data) {
      refetchUnread();
      refetch();
    }
  });

  useEffect(() => {
    setHasUnread(unreadData?.unreadInfo?.hasUnreadNotifications);
  }, [unreadData?.unreadInfo?.hasUnreadNotifications]);

  return (
    <div className="rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-x-hidden">
      <div className="relative grid gap-8 lg:grid-cols-1 bg-gray-100">
        <div className="text-sm text-gray-600 flex flex-row justify-end pr-8 pt-8">
          Alle gelesen
          <PgToggle value={!hasUnread} onChange={async (val: boolean) => {
            if (val) {
              await markAllReadServer();
              refetchUnread();
              refetch();
            }
          } } />
        </div>
        <InfiniteScroll
          dataLength={data?.notifications?.data?.length || PAGE_SIZE}
          hasMore={pagesLoaded < maxPages}
          height='20rem'
          style={{ overflowX: 'hidden' }}
          next={async () => {
            await refetch({ limit: (pagesLoaded + 1) * PAGE_SIZE });
            setPagesLoaded(pagesLoaded + 1);
          }}
          loader={
            <div className="flex w-full justify-center items-center">
              <Spinner className="stroke-gray-500 w-8 h-8" />
            </div>
          }
          endMessage={
              <p className="text-center mt-4 text-gray-500 mb-5">
                Alle Benachrichtigungen geladen
              </p>
          }
          className="relative grid gap-3 sm:px-8 lg:grid-cols-1 bg-gray-100"
        >
        {data?.notifications?.data?.map((notification: any) => {
          return (
            notification && (
              <NotificationListItem
                key={notification.id}
                notification={notification}
                onClick={() => {
                markRead({
                  variables: { notificationId: notification.id },
                });
                const projectId = notification?.attributes?.project?.data?.id;
                if (projectId) {
                  navigate(`/projects/${projectId}`);
                }
              }}
              onMarkReadClick={(e) => {
                e.stopPropagation();
                markRead({
                  variables: { notificationId: notification.id },
                });
              }} />
            )
          );
        })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
