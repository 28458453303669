import {
  ArrayParam,
  BooleanParam,
  DecodedValueMap,
  DelimitedNumericArrayParam,
  ObjectParam,
  StringParam,
  withDefault
} from "use-query-params";
import {
  DIRECTION,
  IProjectFilter,
  PROJECT_LIST_APPRAISER_FILTER,
  PROJECT_LIST_CLIENT_TYPE_FILTER,
  PROJECT_LIST_CONTRACTOR_FILTER,
  PROJECT_LIST_CUSTOMER_FILTER,
  PROJECT_LIST_EMPLOYEE_FILTER,
  PROJECT_LIST_GEOBOUNDS_FILTER,
  PROJECT_LIST_HIDE_HISTORY,
  PROJECT_LIST_STATUS_FILTER,
  PROJECT_LIST_TYPE_FILTER,
  PROJECT_LIST_YEARS_FILTER,
  SORT_BY
} from "../../components/filters/project-filter";
import {getCurrentYear} from "../../components/project-list/project-utils";


const SortByParam = withDefault(StringParam, 'servicelevel');
const DirectionParam = withDefault(StringParam, 'desc');
const StatusParam = withDefault(ArrayParam, [] as string[]);
const ProjectTypeParam = withDefault(ArrayParam, [] as string []);
const EmployeeParam = withDefault(ArrayParam, [] as string[]);
const ClientTypeParam = withDefault(DelimitedNumericArrayParam, [] as number[]);
const CustomerParam = withDefault(DelimitedNumericArrayParam, [] as number[]);
const AppraiserParam = withDefault(BooleanParam, null);
const GeoboundsParam = withDefault(ObjectParam, undefined);
const ContractorParam = withDefault(StringParam, undefined);
const YearParam = withDefault(ArrayParam, [getCurrentYear().key] as string[])
const HistoryParam = withDefault(BooleanParam, null);

export const projectListQueryParams = {
  [SORT_BY]: SortByParam,
  [DIRECTION]: DirectionParam,
  [PROJECT_LIST_STATUS_FILTER]: StatusParam,
  [PROJECT_LIST_TYPE_FILTER]: ProjectTypeParam,
  [PROJECT_LIST_EMPLOYEE_FILTER]: EmployeeParam,
  [PROJECT_LIST_CLIENT_TYPE_FILTER]: ClientTypeParam,
  [PROJECT_LIST_CUSTOMER_FILTER]: CustomerParam,
  [PROJECT_LIST_APPRAISER_FILTER]: AppraiserParam,
  [PROJECT_LIST_GEOBOUNDS_FILTER]: GeoboundsParam,
  [PROJECT_LIST_CONTRACTOR_FILTER]: ContractorParam,
  [PROJECT_LIST_YEARS_FILTER]: YearParam,
  [PROJECT_LIST_HIDE_HISTORY]: HistoryParam,
};

export function mapQueryParamsToFilter(filterQueryParams: DecodedValueMap<typeof projectListQueryParams>): IProjectFilter {
  return {
    status: filterQueryParams[PROJECT_LIST_STATUS_FILTER],
    projectTypes: filterQueryParams[PROJECT_LIST_TYPE_FILTER],
    employees: filterQueryParams[PROJECT_LIST_EMPLOYEE_FILTER],
    clientTypes: filterQueryParams[PROJECT_LIST_CLIENT_TYPE_FILTER],
    customers: filterQueryParams[PROJECT_LIST_CUSTOMER_FILTER],
    appraiser: filterQueryParams[PROJECT_LIST_APPRAISER_FILTER],
    // geoBounds: filterQueryParams[PROJECT_LIST_GEOBOUNDS_FILTER],
    contractor: filterQueryParams[PROJECT_LIST_CONTRACTOR_FILTER],
    years: filterQueryParams[PROJECT_LIST_YEARS_FILTER],
    history: filterQueryParams[PROJECT_LIST_HIDE_HISTORY]
  }
}
