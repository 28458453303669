import React from "react";
import {PaperClipIcon} from "@heroicons/react/20/solid";
import {AuthDownload} from "./auth-download";
import {getLoginUrl} from "../../shared/utils/url-utils";
import {RemoveFile} from "../../pages/project/prepare-visit/remove-file";
import {XMarkIcon} from "@heroicons/react/24/outline";

export interface IAuthDownloadItem
{
    item: {
        id: string
        attributes: {
            url: string
            name: string
        }
    }
    afterDelete: () => void
}

export function AuthDownloadItem({ item, afterDelete }: IAuthDownloadItem) {
    return <div className="truncate group flex">
        <PaperClipIcon className="w-5 float-left mr-1"/>
        <AuthDownload
            href={`${getLoginUrl()}${item?.attributes?.url}`}
            title="Herunterladen"

            filename={item?.attributes?.name}
            className={"truncate"}
        >
            {item?.attributes?.name}
        </AuthDownload>
        <div className="opacity-0 group-hover:opacity-100 w-5">
            <RemoveFile fileToRemove={item?.id} afterDelete={afterDelete}>
                <XMarkIcon/>
            </RemoveFile>
        </div>
    </div>;
}
