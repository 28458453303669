import { PreparedDataType, PreparedFlatData, prepareFlatData } from "./helpers";
import { ReferenceByCountryBarChartProps } from "./references-by-country-card";
import acceptedCities from "./cities.json";
import { Bar } from "react-chartjs-2";
import { renderCountryBarChartTooltip } from "../../employees/line-chart/chart-tooltip";
import Alert from "../../../../components/layout/alert";
import { formatBigNumber } from "../../../../shared/utils/formatters";

function enrichDataWithCity(data: any[]) {
  return data.map((item: any) => {
    const zipCode = item?.building?.address?.zip as keyof typeof acceptedCities;
    const city = acceptedCities[zipCode];
    return {
      ...item,
      aggregatedCity: city || "Sonstige",
    };
  });
}

const prepareData = ({ labels, aggregatedData }: PreparedFlatData) => {
  return {
    labels: labels.map((label, index) => {
      return `${label} (${formatBigNumber(aggregatedData[index], false)})`;
    }),
    datasets: [
      {
        data: aggregatedData,
        backgroundColor: "#b1c5ab",
        hoverBackgroundColor: "#004f14",
        borderRadius: [5],
      },
    ],
  };
};

const options = {
  indexAxis: "y" as const,
  interaction: {
    axis: "x",
    mode: "nearest",
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        font: {
          family: "Roboto",
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          family: "Roboto",
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      external: renderCountryBarChartTooltip,
    },
  },
};

const interactionOptions: any = {
  mode: "y",
  intersect: false,
};

export const ReferencesByCity: React.FC<ReferenceByCountryBarChartProps> = ({
  data,
  width,
  height,
}) => {
  const enrichedData = data && data.length > 0 ? enrichDataWithCity(data) : [];

  const preparedFlatData =
    data && data.length > 0
      ? prepareFlatData(
          enrichedData,
          (item: any) => item.aggregatedCity,
          PreparedDataType.COUNT
        )
      : { labels: [], aggregatedData: [] };

  return preparedFlatData?.aggregatedData?.length ? (
    <Bar
      data={prepareData(preparedFlatData)}
      options={{
        ...options,
        plugins: {
          ...options.plugins,
        },
        interaction: interactionOptions,
      }}
      width={width}
      height={height}
    />
  ) : (
    <Alert
      title="Keine Daten vorhanden"
      message="Anderen Filter wählen"
      severity="info"
    />
  );
};