import {LatLngBounds} from "leaflet";

export interface IProjectFilter {
  status?: (string | null)[] | null | undefined;
  projectTypes?: (string | null)[] | null | undefined;
  buildingTypes1?: (string | null)[] | null | undefined;
  buildingTypes2?: (string | null)[] | null | undefined;
  employees?: (string | null)[] | null | undefined;
  clientTypes?: (number | null)[] | null | undefined;
  customers?: (number | null)[] | null | undefined;
  contractor?: string | null | undefined;
  appraiser?: boolean | null | undefined;
  years?: (string | null)[] | null | undefined;
  dateOfConstruction?: (number | null)[] | null | undefined;
  valuation?: string | null | undefined;
  geoBounds?: LatLngBounds;
  forceBounds?: boolean;
  locationLat?: number;
  locationLng?: number;
  locationRange?: number;
  deadlineStart?: Date | null | undefined;
  deadlineEnd?: Date | null | undefined;
  history?: boolean | null | undefined;
  serviceLevelNull?: boolean | null | undefined;
  useForResearch?: boolean | null | undefined;
}

// Aufträge
export const SORT_BY = "sortBy";
export const DIRECTION = "direction";
export const PROJECT_LIST_STATUS_FILTER = "status";
export const PROJECT_LIST_TYPE_FILTER = "project-type";
export const PROJECT_LIST_EMPLOYEE_FILTER = "employees";
export const PROJECT_LIST_CLIENT_TYPE_FILTER = "client-type";
export const PROJECT_LIST_CUSTOMER_FILTER = "customers";
export const PROJECT_LIST_APPRAISER_FILTER = "appraiser";
export const PROJECT_LIST_YEARS_FILTER = "years";
export const PROJECT_LIST_GEOBOUNDS_FILTER = "geo-bounds";
export const PROJECT_LIST_CONTRACTOR_FILTER = "contractor;"
export const PROJECT_LIST_HIDE_HISTORY = "hide-history"

// Rechnungen
export const SEARCH_TERM = 'searchTerm';
export const PROJECT_STATUS = 'projectStatus';
export const INVOICE_STATUS = 'invoiceStatus';
export const CUSTOMER_STATUS = 'customerStatus';
export const DATE_FILTER_FROM = 'from';
export const DATE_FILTER_UNTIL = 'until';

// Research
export const LOCATION_LAT = 'lat';
export const LOCATION_LNG = 'lng';
export const LOCATION_RANGE = 'range';
export const ADDRESS = 'address';
export const BUILDING_TYPES_1 = "objectType1";
export const BUILDING_TYPES_2 = "objectType2";
export const PROJECT_LIST_DEADLINE_DATE_START="deadlineStart";
export const PROJECT_LIST_DEADLINE_DATE_END="deadlineEnd";
export const PROJECT_RESEARCH_YEAR_RANGE="dateOfConstructionRange";
export const PROJECT_RESEARCH_VALUATION="valuation";

