import React from "react";
export interface IInput extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  children?: React.ReactNode;
  type?: string;
  placeholder?: string;
  register?: any;
  validateRequired?: object;
  pattern?: any;
}

export const Input = ({
  children,
  name,
  validateRequired,
  register,
  pattern,
  className,
  ...rest
}: IInput) => (
  <input
    className={`${className} border rounded-xl pl-4 p-2 text-base border-gray-300 bg-gray-100 shadow-sm focus:outline-none focus:shadow-outline-green focus:border-green-500`}
    {...register(name, { ...validateRequired, pattern })}
    {...rest}
  >
    {children}
  </input>
);
