import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../shared/utils/class-names';
import { IKeyValuePair } from '../../../shared/utils/entity-utils';


export interface ISortDropdown {
  items: IKeyValuePair[];
  selected: IKeyValuePair;
  onChange: (val: IKeyValuePair)  => void
}

export const SortDropdown = ({ items, selected, onChange }: ISortDropdown) => {

  return (<Listbox
  as="div"
  className="space-y-1 max-w-[13rem] w-[13rem]"
  value={selected}
  onChange={onChange}
>
  {({ open }) => (
    <>
      <div className="relative">
        <span className="inline-block w-full rounded-xl shadow-sm ">
          <Listbox.Button className="cursor-default relative w-full rounded-xl border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-green focus:border-green-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex-grow-0">
            <span className="block truncate">
              Sortieren: <span className='font-medium'>{selected?.label}</span>
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-gray-400">
              <ChevronDownIcon className="h-6" />
            </span>
          </Listbox.Button>
        </span>

        <Transition
          unmount={false}
          show={open}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute mt-1 w-full rounded-xl bg-white shadow-lg z-[1000]"
        >
          <Listbox.Options
            static
            className="max-h-60 rounded-xl py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 z-20"
          >
            {items?.map((item) => {
              const isSelected = item.key === selected?.key;
              return (
                <Listbox.Option key={item.key} value={item}>
                  {({ active }) => (
                    <div
                      className={`${
                        active
                          ? "text-white bg-green-600"
                          : "text-gray-900"
                      } cursor-default select-none relative py-2 pl-8 pr-4`}
                    >
                      <span
                        className={classNames(
                          isSelected ? "font-medium" : ""
                        )}
                      >
                        {item.label}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </>
  )}
</Listbox>)
}
