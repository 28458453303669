import React from "react";

export const SonstigeIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>
        {`.sonstige-${color}-cls-1{fill:none;stroke:${color}}.sonstige-${color}-cls-1,.sonstige-${color}-cls-2{stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;}.sonstige-${color}-cls-2{fill:${color};}`}
      </style>
    </defs>
    <line className={`sonstige-${color}-cls-1`} x1=".35" y1="28.87" x2="29.35" y2="28.87" />
    <g>
      <g>
        <polygon
          className={`sonstige-${color}-cls-1`}
          points="24.91 17.07 24.91 28.84 4.87 28.84 4.87 17.07 14.89 8.06 24.91 17.07"
        />
        <polyline
          className={`sonstige-${color}-cls-1`}
          points="5.05 16.86 14.83 8.06 24.62 16.86"
        />
        <polyline
          className={`sonstige-${color}-cls-1`}
          points="26.57 18.62 24.62 16.86 14.83 8.06 5.05 16.86 3.09 18.62"
        />
        <polygon
          className={`sonstige-${color}-cls-1`}
          points="28.33 16.86 26.57 18.62 24.62 16.86 14.83 8.06 5.05 16.86 3.09 18.62 1.33 16.86 14.83 4.71 28.33 16.86"
        />
      </g>
      <g>
        <circle className={`sonstige-${color}-cls-2`} cx="14.87" cy="20.78" r="1.09" />
        <circle className={`sonstige-${color}-cls-2`} cx="19.68" cy="20.78" r="1.09" />
        <circle className={`sonstige-${color}-cls-2`} cx="10.06" cy="20.78" r="1.09" />
      </g>
    </g>
  </svg>
);
