import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import { InvoiceOverview } from './overview';

const Layout = () => {
  return <>
      <Outlet />
  </>
}

const InvoiceRoute = () => {
  return <Routes>
    <Route path='/' element={<Layout />}>
      <Route index element={<InvoiceOverview />} />
    </Route>
  </Routes>
}

export default InvoiceRoute;