import React, { useEffect, useState } from "react";
import { classNames } from "../../../shared/utils/class-names";
import { SearchDropdownItem } from "./search-dropdown-item";
import { Spinner } from "../../../common/spinner/base-spinner";
import axios from "axios";
import { getLoginUrl } from "../../../shared/utils/url-utils";
import { getItemTransparent } from "../../../shared/utils/storage-utils";

export interface ISearchDropdown {
  query: string;
}

export const SearchDropdown = ({ query }: ISearchDropdown) => {

  const [ projects, setProjects ] = useState<any>();
  const [ loading, setLoading ] = useState<boolean>(false);

  useEffect(() => {
    // TODO: Refactor to Redux, handle errors
    async function fetchData() {
      setLoading(true);
      const response = await axios.post<any>(`${getLoginUrl()}/api/project-search`, {
        "q": query,
        "limit": 8
      }, {
        headers: {
          'Authorization': 'Bearer ' + getItemTransparent('JWT')
        }
      });
      setProjects(response);
      setLoading(false);
    }
    fetchData();
  }, [query])

  return (
    <div className="rounded-xl md:rounded-2xl md:shadow-lg md:ring-1 ring-black ring-opacity-5 overflow-hidden">
      {loading ? (
        <div className="flex justify-center items-center min-h-12">
          <Spinner className="w-10 h-10 stroke-gray-500 my-5" />
        </div>
      ) : (
        <div className="relative grid gap-8 p-4 md:p-8 lg:grid-cols-1 bg-gray-100">
          {projects?.data.map((project: any) => (
            <div
              key={project.id}
              className={classNames(
                "bg-white hover:bg-gray-300",
                "-m-3 p-3 flex items-start rounded-xl transition ease-in-out duration-150 relative"
              )}
            >
              <a
                href={`/projects/${project.id}`}
                className="ml-4 pt-1 block text-left cursor-pointer w-full"
              >
                <SearchDropdownItem project={project} />
              </a>
            </div>
          ))}
          {projects?.data.length === 0 ? "Keine Daten gefunden" : null}
        </div>
      )}
    </div>
  );
};
