import React from "react";
import { Spinner } from "../../../../common/spinner/base-spinner";

export const MapLoader = ( {loading }: { loading: boolean }) => {

  return (<>{loading
      ? (<div className="absolute z-[10002] right-12 top-2 bg-transparent flex justify-center items-center">
        <Spinner className='w-8 h-8 stroke-orange-500'/>
      </div>)
      : null }
    </>);
};
