import { useEffect } from "react"
import Alert from "../../../../components/layout/alert"
import { ReferencesDataWrapper } from "../references-data-wrapper"
import { ReferencesChartsLayout } from "./references-chart-layout"


export interface IReferencesTableProps {
  showExport: boolean,
  excludeLinesVisible?: boolean,
  printView?: boolean
}

export const ReferencesCharts = ({ showExport, excludeLinesVisible, printView }: IReferencesTableProps) => {

  return (
    <ReferencesDataWrapper callback={() => {
      if(printView) {
        setTimeout(() => {
          window.print();
         }, 1000);
      }
    }}>
      {({ data, error, isLoading }: any) => {
        if(error) {
          return <Alert title="Fehler bei der Datenübertragung" message={JSON.stringify(error)} />
        }
        return <ReferencesChartsLayout data={data} isLoading={isLoading} />
      }}
    </ReferencesDataWrapper>
  )
}