import React from 'react';
import './config/dayjs.ts';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes';

const baseHref = document?.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '');

function App() {
  return (
    <Router basename={baseHref}>
      <AppRoutes />
    </Router>
  );
}

export default App;
