import { Tab } from "@headlessui/react";
import { MapIcon, Bars4Icon } from "@heroicons/react/24/outline";
import queryString from 'query-string';
import React from "react";
import {
  matchPath,
  resolvePath,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import { encodeQueryParams, useQueryParams } from "use-query-params";
import { classNames } from "../../shared/utils/class-names";
import { ProjectListViewCards } from "./project-cards/project-list-cards";
import { ProjectListMap } from "./project-map/project-list-map";
import { projectListQueryParams } from "./project-list-query-params";
import { SortWrapper } from "./sort/sort-wrapper";

const listViews = [
  {
    id: "card-view",
    href: "/cards",
    icon: Bars4Icon,
    component: ProjectListViewCards,
  },
  {
    id: "map-view",
    href: "/map",
    icon: MapIcon,
    component: ProjectListMap,
  },
];

export const ProjectList = ({ parentPath }: { parentPath: string }) => {
  const location = useLocation();
  const selectedItem = listViews.find((listView) => {
    return matchPath(`${parentPath}${listView.href}`, location.pathname);
  });

  const [searchParams] = useQueryParams(projectListQueryParams);
  const navigate = useNavigate();

  const selectedIndex = selectedItem ? listViews.indexOf(selectedItem) : 0;
  return (
    <>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(idx) => {
          const url =
          `${resolvePath(
            `${parentPath}${listViews[idx].href}`,
            location.pathname
          ).pathname}?${queryString.stringify(encodeQueryParams(projectListQueryParams, { ...searchParams }))}`
          navigate(url);
        }}
      >
        <div className="flex flex-row justify-between">
          <h1 className="text-2xl font-special font-semibold">Aufträge</h1>
          <div className="flex flex-row items-center">
          {
            selectedIndex === 0
            ? <div className='mr-4'>
              <SortWrapper />
            </div>
            : null
          }
          <Tab.List className="flex p-1 space-x-1 bg-white rounded-xl">
            {listViews.map((listView) => (
              <Tab
                key={listView.id}
                className={({ selected }) =>
                  classNames(
                    "w-full py-1.5 leading-5 font-medium  rounded-lg",
                    selected
                      ? "bg-green-700 text-white"
                      : "text-green-700 bg-white"
                  )
                }
              >
                <listView.icon className="w-8 h-5" />
              </Tab>
            ))}
          </Tab.List>
          </div>
        </div>

        <Tab.Panels className="mt-2">
          <Routes>
            {listViews.map((listView) => (
              <Route key={listView.id} path={listView.href} element={<listView.component />} />
            ))}
          </Routes>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};
