import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Card } from "../../components/basics/card";
import { CUSTOMER_DELETE_QUERY, ICustomer } from "../../model/customer";
import { classNames } from "../../shared/utils/class-names";
import { defaultButtonClasses } from "../../components/basics/buttons-classes";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { h1Classes } from "../../components/basics/typography-classes";
import { CustomerModal } from "./customer-modal";
import { DeleteWithPrompt } from "../../components/delete-with-prompt/delete-with-prompt";
import { IAddress } from "../../model/address";

// TODO: Refactor to Redux, handle loading and errors
const CUSTOMER_QUERY = gql`
  query {
    customers(
      filters: { regularCustomer: { eq: true } }
      pagination: { limit: 100 },
      sort: "name"
    ) {
      data {
        id
        attributes {
          name
          shortName
          regularCustomer
          client_type {
            data{
              id
              attributes {
                label
              }
            }
          }
          invoiceAddress {
            data {
              id
              attributes {
                street
                streetNumber
                zip
                city
                country
              }
            }
          }
        }
      }
    }
  }
`;

export const CustomerList = () => {
  const { data, refetch } = useQuery(CUSTOMER_QUERY);

  const [deleteCustomer] =
    useMutation(CUSTOMER_DELETE_QUERY);

  const [open, setOpen] = useState(false);
  const [editCustomer, setEditCustomer] = useState<ICustomer | undefined>();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      <div className="flex justify-between">
        <h1 className={classNames(...h1Classes, "mb-8")}>Stammkunden</h1>
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className={classNames(
            ...defaultButtonClasses,
            "w-40 h-10 text-sm bg-white font-semibold text-gray-800"
          )}
        >
          + Neuer Stammkunde
        </button>
      </div>
      <Card className="border rounded-2xl bg-gray-100 font-special font-semibold text-base text-gray-600">
        <div>
          <div className="grid grid-cols-[30%_60%_10%] mb-3">
            <div className="pl-5">Name</div>
            <div>Adresse</div>
            <div>Aktionen</div>
          </div>
          {data?.customers?.data?.map((customer: ICustomer) => {
            const address: IAddress | undefined = customer?.attributes?.invoiceAddress?.data;
            return (
              <div key={customer?.id} className="bg-white rounded-2xl grid grid-cols-[30%_60%_10%] mb-3 justify-center items-center py-3">
                <div className="pl-5">
                  {customer?.attributes?.name} <span className='font-thin'>({customer?.attributes?.shortName}
                  )</span>
                </div>
                <div>
                  {
                    address?.attributes
                      ?.street
                  }{" "}
                  {
                    address?.attributes
                      ?.streetNumber
                  }
                  {address?.attributes?.zip || address?.attributes?.city
                    ? `, ${address?.attributes?.zip} ${address?.attributes?.city}`
                    : ''}
                </div>
                <div className="flex flex-row gap-2 items-center text-gray-500">
                  <button
                    onClick={() => {
                      setEditCustomer(customer);
                      setOpen(true);
                    }}
                  >
                    <PencilIcon className="w-6" />
                  </button>
                  <DeleteWithPrompt
                    text={"Wollen Sie den Kunden wirklich entfernen?"}
                    cancelButtonText="Abbrechen"
                    confirmButtonText="Löschen"
                    onClick={async () => {
                      await deleteCustomer({
                        variables: { customerId: customer?.id },
                      });
                      refetch();
                    }}
                  >
                    <TrashIcon className="w-6" />
                  </DeleteWithPrompt>
                </div>
              </div>
            );
          })}
        </div>
        <CustomerModal
          open={open}
          customer={editCustomer}
          onUpdate={(cust: ICustomer) => { setEditCustomer(cust) }}
          onClose={() => {
            setOpen(false);
            setEditCustomer(undefined);
            refetch();
          }}
        />
      </Card>
    </>
  );
};
