import { useEffect, useState } from "react";
import {
  useLazyFetchAnalyticsAppraiserInvoicesQuery,
  useLazyFetchAnalyticsEditorInvoicesQuery,
} from "../../../model/analytics/employees/analytics-employees-api";
import Alert from "../../../components/layout/alert";
import { Card } from "../../../components/basics/card";
import { H5 } from "../../../components/layout/basics/typography";
import {
  AnalyticsEmployeeResult,
  Employee,
} from "../../../model/analytics/employees/analytics-employees";
import { EmployeeChartBars } from "./employees-chart-bars";
import { DummyBarChart } from "./dummy-bar-chart";
import { Tooltip } from "../../../components/basics/tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { TooltipManager } from "../../../components/basics/tooltip-manager";
import { classNames } from "../../../shared/utils/class-names";
import { h4Classes } from "../../../components/basics/typography-classes";

export interface IEmployeeChartLinesProps {
  years: number[];
  isAppraiserChart: boolean;
  selectedEmployees: Employee[];
}

export const BarChartDataWrapper = ({
  isAppraiserChart,
  selectedEmployees,
  years,
}: IEmployeeChartLinesProps) => {
  const [data, setData] = useState<AnalyticsEmployeeResult | null>(null);
  const [
    fetchBarChartEditorData,
    { data: fetchedEditorData, error: editorError },
  ] = useLazyFetchAnalyticsEditorInvoicesQuery();
  const [
    fetchBarChartAppraiserData,
    { data: fetchedAppraiserData, error: appraiserError },
  ] = useLazyFetchAnalyticsAppraiserInvoicesQuery();

  useEffect(() => {
    if (selectedEmployees && selectedEmployees.length > 0) {
      if (isAppraiserChart) {
        fetchBarChartAppraiserData({
          queryParams: {
            filters: { years, employees: selectedEmployees.map((se) => se.id) },
          },
        });
      } else {
        fetchBarChartEditorData({
          queryParams: {
            filters: { years, employees: selectedEmployees.map((se) => se.id) },
          },
        });
      }
    } else {
      setData(null);
    }
  }, [selectedEmployees, years]);

  useEffect(() => {
    if (fetchedAppraiserData?.data) {
      setData(fetchedAppraiserData?.data);
    }
  }, [fetchedAppraiserData?.data]);

  useEffect(() => {
    if (fetchedEditorData?.data) {
      setData(fetchedEditorData?.data);
    }
  }, [fetchedEditorData?.data]);

  if (editorError || appraiserError) {
    const error = editorError || appraiserError;
    const convertedError = error as any;
    return (
      <Alert
        title="Fehler beim Abholen der Daten"
        message={convertedError?.data?.error?.message}
      />
    );
  }

  return (
    <Card className="bg-white mb-4">
      <div className="flex items-start"><h5 className={classNames(...h4Classes)}>NETTOUMSATZ JE AUFTRAGSART</h5><TooltipManager>
          <Tooltip
              id="exclude-travel-costs"
              tooltipContent={
                <span>exkl. Reiseaufwand/Auslagen</span>
              }
              align={"left"}
            >
            <InformationCircleIcon className="w-5 mx-2" />
          </Tooltip>
         </TooltipManager></div>
      <div className="h-4"></div>
      {data ? (
        <EmployeeChartBars data={data} selectedEmployees={selectedEmployees} years={years} styles={{ height: "400px" }} />
      ) : (
        <DummyBarChart style={{ height: "300px" }} />
      )}
    </Card>
  );
};
