import React from "react";
export const UnbebautesGrundstueckIcon = ({ color = "black" }: any) => (
  <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <defs>
      <style>{`.unbebaut-${color}-cls-1{fill:none; stroke: ${color} ;stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px;}`}</style>
    </defs>
    <line className={`unbebaut-${color}-cls-1`} x1=".35" y1="28.94" x2="29.35" y2="28.94" />
    <g>
      <g>
        <rect
          className={`unbebaut-${color}-cls-1`}
          x="15.06"
          y="17.58"
          width="11.46"
          height="5.6"
          rx=".45"
          ry=".45"
        />
        <rect className={`unbebaut-${color}-cls-1`} x="19.89" y="15.9" width="1.69" height="1.68" />
        <rect
          className={`unbebaut-${color}-cls-1`}
          x="19.89"
          y="23.19"
          width="1.69"
          height="5.21"
        />
      </g>
      <path
        className={`unbebaut-${color}-cls-1`}
        d="M13.8,14.3c0,2.93-2.38,5.31-5.31,5.31s-5.32-2.38-5.32-5.31c0-1.9,1.01-3.57,2.51-4.51-.33-.51-.52-1.12-.52-1.77,0-1.83,1.49-3.32,3.32-3.32s3.32,1.49,3.32,3.32c0,.65-.19,1.26-.52,1.77,1.51,.93,2.51,2.6,2.51,4.51Z"
      />
      <line className={`unbebaut-${color}-cls-1`} x1="8.48" y1="14.5" x2="8.48" y2="28.4" />
      <line className={`unbebaut-${color}-cls-1`} x1="8.48" y1="23.05" x2="11.22" y2="21.25" />
    </g>
  </svg>
);
