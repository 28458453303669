
import { useQueryParams } from "use-query-params";
import { employeeFilterParams, EMPLOYEE_BAR_CHART_FILTER, EMPLOYEE_LINE_CHART_FILTER_1, EMPLOYEE_LINE_CHART_FILTER_2, EMPLOYEE_TYPE_APPRAISER, EMPLOYEE_TYPE_FILTER, EMPLOYEE_YEARS_FILTER } from "./employees-table";
import { Employee } from "../../../model/analytics/employees/analytics-employees";
import { LineChartDataWrapper } from "./line-chart-data-wrapper";
import { BarChartDataWrapper } from "./bar-chart-data-wrapper";

export const EmployeesChart = ({employees}: { employees: Employee[] }) => {
  const [employeeFilter] = useQueryParams(employeeFilterParams);
  const selectedEmployeesLineChartItemFirst = employees?.find((employee) => ( employeeFilter[EMPLOYEE_LINE_CHART_FILTER_1] === parseInt(employee.id) ))
  const selectedEmployeesLineChartItemSecond = employees?.find((employee) => ( employeeFilter[EMPLOYEE_LINE_CHART_FILTER_2] === parseInt(employee.id) ))
  const selectedEmployeesBarChart = employees?.filter((employee) => ( employeeFilter[EMPLOYEE_BAR_CHART_FILTER]?.includes(parseInt(employee.id)) ))

  const employeeType = employeeFilter?.[EMPLOYEE_TYPE_FILTER]?.toString();

  const year1 = employeeFilter[EMPLOYEE_YEARS_FILTER],
    year2 = 2022,
    year3 = 2023;
  const years = [year1, year2, year3]?.filter?.((y) => y !== null);

  return (
        <>
          <LineChartDataWrapper selectedEmployees={[selectedEmployeesLineChartItemFirst, selectedEmployeesLineChartItemSecond]} years={years as number[]} isAppraiserChart={employeeType === EMPLOYEE_TYPE_APPRAISER} />
          <BarChartDataWrapper selectedEmployees={selectedEmployeesBarChart} years={years as number[]} isAppraiserChart={employeeType === EMPLOYEE_TYPE_APPRAISER}/>
        </>
  );
};
