import { Employee } from "../../../model/analytics/employees/analytics-employees";
import { EmployeeSingleSelect } from "./employee-select";
import { UserDataWrapper } from "./user-data-wrapper";

export interface IGenericUserSelectProps {
  placeholder?: string;
  onlyAppraisers?: boolean;
  onlyEditors?: boolean;
  onChange: (value: Employee | null) => void;
  exclude: number[];
  selected: number | null;
  className?: string;
}

export const GenericUserSelect = ({
  placeholder,
  onlyEditors,
  onlyAppraisers,
  onChange,
  selected,
  exclude,
  className,
}: IGenericUserSelectProps) => {
  return (
    <UserDataWrapper onlyEditors={onlyEditors} onlyAppraisers={onlyAppraisers}>
      {(data, error, loading) => (
        <EmployeeSingleSelect
          placeholder={placeholder}
          employees={data}
          onChange={onChange}
          selected={selected}
          exclude={exclude}
          className={className}
        />
      )}
    </UserDataWrapper>
  );
};
