/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { defaultButtonClasses, primaryButtonClasses } from '../../components/basics/buttons-classes';
import { Input } from "../../components/form-components/input";
import { ValidationMessage } from "../../components/layout/validation";
import { ClientTypeUpdateField } from '../../components/magic-update/client-type-update-field';
import { ADDRESS_CREATE_QUERY, ADDRESS_DELETE_QUERY, ADDRESS_UPDATE_QUERY } from '../../model/address';
import { CUSTOMER_CREATE_QUERY, CUSTOMER_EXISTS_QUERY, CUSTOMER_EXISTS_SHORTNAME_QUERY, CUSTOMER_SINGLE_QUERY, CUSTOMER_UPDATE_QUERY, ICustomer } from "../../model/customer";
import { classNames } from '../../shared/utils/class-names';

const CustomerExistsPrompt = ({onLoadCustomer}: { onLoadCustomer: Function }) => {
  return (<span className="text-red-500">
    Dieser Kunde existiert bereits. 
    <button className="underline" onClick={(e) => { e.preventDefault(); e.stopPropagation(); onLoadCustomer(); }}>Nachladen?</button>
  </span>)
}

// TODO: Refactor to an own API and Redux on the server
export const CustomerForm = ({
  customer,
  onUpdate,
  onClose,
}: {
  customer?: ICustomer;
  onClose: Function;
  onUpdate?: Function;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [duplicateCustomerName, setDuplicateCustomerName] = useState<number |null>(null);
  const [duplicateCustomerShortName, setDuplicateCustomerShortName] = useState<number |null>(null);

  const [createAddress, { data: address, loading: addressLoading, error: addressError }] = useMutation(ADDRESS_CREATE_QUERY);
  const [deleteAddress, { data: deleteAddressData, loading: deleteAddressLoading, error: deleteAddressError }] = useMutation(ADDRESS_DELETE_QUERY);
  const [createCustomer, { data: createdCustomer, loading: customerLoading, error: customerError }] = useMutation(CUSTOMER_CREATE_QUERY);
  const navigate = useNavigate();
  const [updateAddress, { loading: addressUpdateLoading }] = useMutation(ADDRESS_UPDATE_QUERY);
  const [updateCustomer, { loading: customerUpdateLoading }] = useMutation(CUSTOMER_UPDATE_QUERY);
  const [fetchCustomerExists] = useLazyQuery(CUSTOMER_EXISTS_QUERY);
  const [fetchCustomerExistsShortName] = useLazyQuery(CUSTOMER_EXISTS_SHORTNAME_QUERY);
  const [fetchCustomer] = useLazyQuery(CUSTOMER_SINGLE_QUERY);

  useEffect(() => {
    reset({
      name: customer?.attributes?.name,
      shortName: customer?.attributes?.shortName,
      street: customer?.attributes?.invoiceAddress?.data?.attributes?.street,
      streetNumber: customer?.attributes?.invoiceAddress?.data?.attributes?.streetNumber,
      regularCustomer: customer?.attributes?.regularCustomer,
      zip: customer?.attributes?.invoiceAddress?.data?.attributes?.zip,
      city: customer?.attributes?.invoiceAddress?.data?.attributes?.city,
      client_type: customer?.attributes?.client_type?.data?.id
    });
  }, [reset, customer]);

  const onSubmit = async (data: FieldValues) => {
    if (!customer) {
      if(duplicateCustomerName || duplicateCustomerShortName) {
        toast.error("Bitte wählen Sie einen anderen (Kurz-)Namen für den Kunden.")
      }
      const addressInput = { street: data.street || '', streetNumber: data.streetNumber || '', zip: data.zip || '', city: data.city || '' };
      const addressResult = await createAddress({ variables: { data: addressInput } });
      if (addressResult && addressResult.data && !addressError) {
        const customerInput = { name: data.name, shortName: data.shortName, invoiceAddress: addressResult.data.createAddress.data.id, regularCustomer: true, client_type: data.client_type };
        try{
          await createCustomer({ variables: { data: customerInput } });
          if (customerError) {
            toast.error("Auftraggeber konnte nicht gespeichert werden.");
            deleteAddress({ variables: { addressId: addressResult.data.createAddress.data.id } });
          } else {
            toast.info("Auftraggeber erfolgreich erstellt");
            navigate('/customers');
            onClose();
          }
        } catch (e) {
          toast.error("Auftraggeber konnte nicht gespeichert werden.");
        }
      } else {
        toast.error("Auftraggeber konnte nicht gespeichert werden.");
      }
    } else {
      try {
        let addressId;
        const addressInput = { street: data.street, streetNumber: data.streetNumber, zip: data.zip, city: data.city };
        if(!customer?.attributes?.invoiceAddress?.data) {
          const addressResult = await createAddress({ variables: { data: addressInput } });
          addressId = addressResult?.data?.createAddress?.data?.id;
        } else {
          addressId = customer.attributes?.invoiceAddress?.data?.id;
          await updateAddress({
            variables: {
              id: customer.attributes?.invoiceAddress?.data?.id,
              data: addressInput
            }
          });
        }
        await updateCustomer({ variables: { id: customer?.id, data: { name: data?.name, shortName: data?.shortName, client_type: data?.client_type, invoiceAddress: addressId, regularCustomer: data?.regularCustomer } } });
        toast.info("Erfolgreich aktualisiert.");
        navigate('/customers');
        onClose();
      } catch (e) {
        toast.error("Fehler beim Aktualisieren.")
      }
    }
  }

  const handleDuplicateCustomer = async (name: string) => {
    try {
      const customerExists = await fetchCustomerExists({ variables: { name }});
        if(customerExists?.data?.customers?.meta?.pagination?.total > 0) {
          setDuplicateCustomerName(customerExists?.data?.customers?.data?.[0]?.id);
        } else {
          setDuplicateCustomerName(null);
        }
    } catch (e) {
      toast.error("Fehler bei der Abfrage des Kunden");
    }
  }

  const handleDuplicateCustomerShortName = async (shortName: string) => {
    try {
      const customerExists = await fetchCustomerExistsShortName({ variables: { shortName }});
        if(customerExists?.data?.customers?.meta?.pagination?.total > 0) {
          setDuplicateCustomerShortName(customerExists?.data?.customers?.data?.[0]?.id);
        } else {
          setDuplicateCustomerShortName(null);
        }
    } catch (e) {
      toast.error("Fehler bei der Abfrage des Kunden");
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-1 sm:mt-5 sm:col-span-2">
        <Input
          name="name"
          placeholder="Name *"
          register={register}
          validateRequired={{
            required: "Bitte Kundenname eingeben",
          }}
          className={`w-full ${errors.name ? "border-red-800 placeholder-red-800" : ""
            }`}
          onBlur={(e) => handleDuplicateCustomer(e?.target?.value)}
        />
        {errors.name?.type === "required" && (
          <ValidationMessage message={errors.name?.message as string} />
        )}
        {duplicateCustomerName && <CustomerExistsPrompt onLoadCustomer={
          async () => {
            if(onUpdate) {
              const existingCustomer = await fetchCustomer({variables: { id: duplicateCustomerName}});
              onUpdate(existingCustomer?.data?.customer?.data);
              setDuplicateCustomerName(null);
              setDuplicateCustomerShortName(null);
            }
          }
          } /> }
      </div>
      <div className="pt-1 sm:mt-5 sm:col-span-2">
        <Input
          name="shortName"
          placeholder="Kurzname *"
          register={register}
          validateRequired={{
            required: "Bitte Kurzname für Kunden eingeben",
          }}
          className={`w-full ${errors.shortName ? "border-red-800 placeholder-red-800" : ""
            }`}
          onBlur={(e) => handleDuplicateCustomerShortName(e?.target?.value)}
        />
        {errors.shortName?.type === "required" && (
          <ValidationMessage message={errors.shortName?.message as string} />
        )}
        {
        // Check if Shortname is already used
          duplicateCustomerShortName && <CustomerExistsPrompt onLoadCustomer={
          async () => {
            if(onUpdate) {
              const existingCustomer = await fetchCustomer({variables: { id: duplicateCustomerShortName}});
              onUpdate(existingCustomer?.data?.customer?.data);
              setDuplicateCustomerShortName(null);
              setDuplicateCustomerName(null);
            }
          }
          } /> }
      </div>
      { customer && <div className="pt-1 sm:mt-5 sm:col-span-2">
        <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
              aria-describedby="regularCustomer-description"
              type="checkbox"
              className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
              {...register("regularCustomer")}
            />
          </div>
          <div className="ml-3 text">
            <label htmlFor="regularCustomer" className="text-gray-700">
              Stammkunde?
            </label>
          </div>
        </div>
        {errors.shortName?.type === "required" && (
          <ValidationMessage message={errors.shortName?.message as string} />
        )}
      </div> }
      <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
        <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Input
              name="street"
              placeholder="Straße"
              register={register}
              className={`w-full`}
            />
          </div>
        </div>
        <div className="mt-1 sm:mt-0 sm:col-span-2 basis-24">
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <Input
              name="streetNumber"
              placeholder="Nr."
              register={register}
              className={`w-full`}
            />
          </div>
        </div>
      </div>
      <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Input
            name="zip"
            placeholder="PLZ"
            register={register}
            className={`w-24`}
          />
        </div>
        <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
          <Input
            name="city"
            placeholder="Ort"
            register={register}
            className={`w-full`}
          />
        </div>
      </div>
      <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
        <div className="mt-1 sm:mt-0 sm:col-span-2 grow">
          <Controller
            control={control}
            name="client_type"
            render={({
              field: { onChange, value },
              fieldState: { error },
              formState,
            }) => {
              return (
                <ClientTypeUpdateField
                  label="Auftraggeberart"
                  value={value}
                  onUpdate={onChange}
                  error={!!error}
                  className={classNames(
                    error ? "border-red-800 placeholder-red-800" : "",
                    "text-base bg-gray-100"
                  )}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="pt-4 sm:mt-4 sm:col-span-2 flex flex-row justify-center gap-2">
        <button className={classNames(...defaultButtonClasses, 'bg-gray-500 w-40')} onClick={(e) => { e.preventDefault(); onClose(); }}>Abbrechen</button>
        <button className={classNames(...primaryButtonClasses, 'w-40')}>Speichern</button>
      </div>
    </form>
  );
};