import {gql, useQuery} from "@apollo/client";
import {generatePath, Link, matchPath, Route, Routes, useLocation, useNavigate, useParams,} from "react-router-dom";
import {Spinner} from "../../common/spinner/base-spinner";
import {Card} from "../../components/basics/card";
import Alert from "../../components/layout/alert";
import {classNames} from "../../shared/utils/class-names";
import {BaseData} from "./base-data";
import {Invoicing} from "./project-invoicing/project-invoicing";
import {PrepareVisit} from "./prepare-visit/prepare-visit";
import {Sidebar} from "./sidebar/Sidebar";
import {defaultButtonClasses, primaryButtonClasses,} from "../../components/basics/buttons-classes";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {FetchSurroundingProjectIds} from "./fetch-surrounding-project-ids";
import {getItemTransparent} from "../../shared/utils/storage-utils";
import {ProjectHistory} from "./project-history";
import {HistoryMarker} from "../../components/history-marker";
import {Research} from "./research/research";

const PROJECT_QUERY = gql`
  query ($projectId: ID!) {
    project(id: $projectId) {
      data {
        id
        attributes {
          name
          description
          customer {
            data {
              id
              attributes {
                name
              }
            }
          }
          contractor
          isCoappraiser
          mainAppraiser
          coAppraiser1
          coAppraiser2
          status
          type
          projectReceived
          deadlineDate
          servicelevel
          owner
          displayId
          dateOfReceipt
          dateOfVisit
          reasonForRating
          invoiceTo
          questions
          valuationCutoffDate
          qualityCutoffDate
          incomePrice
          purchaseDate
          baseArea
          appraisalCurrency
          exchangeRate
          exchangeDate
          additionalClientRequirements
          rests
          loraId
          numberOfRating
          workShareEditor1
          workShareEditor2
          successor {
            data {
              id
            }
          }
          predecessor {
            data {
              id
            }
          }
          contact {
            data {
              id
              attributes {
                name
                phone
                email
              }
            }
          }
          clientType {
            data {
              id
              attributes {
                label
              }
            }
          }
          project_research {
            data {
              id
            }
          }
          building {
            data {
              id
              attributes {
                name
                type
                subtype
                dateOfConstruction
                dateOfLastRenovation
                constructionMeassures
                monumentProtection
                parcel
                floorSpace
                brw
                constructionPlanningLaw
                sectionII
                publicEasement
                legacyIssues
                leasehold
                yearOfConstruction
                lastRenovation
                existingIssues
                plannedMeassures
                usageStructure
                locationQuality
                foundationArea
                itemsSectionII
                additionalInfo
                leaseholdDate
                leaseholdInterest
                leaseholdNote
                gnd
                rnd
                conditionOfConstruction
                equipmentCondition
                completionStatus
                maintenanceStopped
                additionalObjectInfo
                primaryTypeOfUse
                ratePrimaryTypeOfUse
                secondaryTypeOfUse
                rateSecondaryTypeOfUse
                singleTenant
                walt
                interestRateProperty
                nonApportionableCosts
                managementCosts
                maintenanceCosts
                leaseVenture
                modernisationCosts
                specialValueVacancy
                landValueRatePerQm
                landValue
                landValueShare
                resultTangibleAssetValueMethod
                earningValueWOSpecialValue
                resultEarningValueInclSpecialValue
                marketValue
                mortgageValue
                incidentalAcquisitionCosts
                address {
                  data {
                    id
                    attributes {
                      street
                      streetNumber
                      city
                      zip
                      country
                      lat
                      lng
                    }
                  }
                }
              }
            }
          }
          marketDataFiles(pagination: { limit: 200 }) {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
          rcaComparisonFiles(pagination: { limit: 200 }) {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
          images(pagination: { limit: 200 }) {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
          editor {
            data {
              id
              attributes {
                username
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          editor2 {
            data {
              id
              attributes {
                username
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          appraiser {
            data {
              id
              attributes {
                username
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          notes (sort: "createdAt:desc") {
            data {
              id
              attributes {
                message
                accessRestricted
                createdAt
                owner {
                  data {
                    id
                    attributes {
                      username
                      role {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const tabs = [
  {
    id: "base-data",
    label: "Grunddaten",
    href: "/",
    component: BaseData,
  },
  {
    id: "prepare-visit",
    label: "Besichtigungsvorbereitung",
    href: "/prepare-visit",
    component: PrepareVisit,
  },
  {
    id: "invoicing",
    label: "Rechnungshaltung",
    href: "/invoicing",
    roles: ["geschaftsfuhrer", "admin", "superadmin", "buchhalter"],
    component: Invoicing,
  },
  {
    id: "research",
    label: "Research",
    href: "/research",
    component: Research,
  },
  {
    id: "history",
    label: "Bewertungshistorie",
    href: "/history",
    component: ProjectHistory,
  },
];

export const Project = ({ parentPath }: { parentPath: string }) => {
  let params = useParams();
  let user = getItemTransparent('user')

  const accessibleTabs = tabs
    .filter((tab: any) => {
      return tab?.roles ? tab?.roles.includes(user?.role?.type) : true;
    })

  const location = useLocation();
  const navigate = useNavigate();
  const selectedItem = accessibleTabs.find((listView) => {
    return matchPath(`${parentPath}${listView.href}`, location.pathname);
  });

  const selectedIndex = selectedItem ? accessibleTabs.indexOf(selectedItem) : 0;

  const { error, loading, data, refetch } = useQuery(PROJECT_QUERY, {
    variables: { projectId: params.id },
  });

  if (loading) {
    return (
      <div className="flex flex-row items-center justify-center w-full">
        <Spinner className="w-8 h-8 stroke-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <Alert
          title="Daten konnten nicht geladen werden"
          message="Bitte versuchen Sie, die Seite neu zu laden oder wenden Sie sich an Ihren Administrator"
        />
      </>
    );
  }

  return (
    <>
      <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4 font-special">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          {/* Description list*/}
          <section>
            <div className="bg-white shadow sm:rounded-xl">
              <div
                className={classNames(
                  "px-4 py-5 sm:px-6 rounded-t-xl flex flex-row gap-5",
                  data?.project?.data?.attributes?.rests && "striped"
                )}
              >
                <div className="grow">
                  {data?.project?.data?.attributes?.successor?.data?.id || data?.project?.data?.attributes?.predecessor?.data?.id ? <HistoryMarker className="w-16 h-12 mr-3 float-left rounded-lg" /> : null}
                  <h1
                    id="applicant-information-title"
                    className="text-3xl leading-6 font-semibold text-gray-800"
                  >
                    {
                      data?.project?.data?.attributes?.building?.data
                        ?.attributes?.name
                    }
                  </h1>
                  <p className="mt-2 max-w-2xl text-gray-500">
                    <span className="font-sans font-bold">
                      #{data?.project?.data?.attributes?.displayId || "?"},{" "}
                    </span>
                    <span className="uppercase">
                      {
                        data?.project?.data?.attributes?.customer?.data
                          ?.attributes?.name
                      }
                    </span>
                  </p>
                </div>
                <div className="flex flex-row gap-2 h-10">
                  <FetchSurroundingProjectIds
                    displayId={data?.project?.data?.attributes?.displayId}
                  >
                    {({ prev, next }: any) => {
                      return (
                        <>
                          {prev ? (
                            <Link
                              to={`/projects/${prev}`}
                              className={classNames(
                                ...primaryButtonClasses,
                                "text-sm w-8 flex flex-row justify-center"
                              )}
                            >
                              <ChevronLeftIcon className="text-white w-5" />
                            </Link>
                          ) : (
                            <div
                              className={classNames(
                                "text-sm w-8 !bg-gray-500 border-0 flex flex-row justify-center",
                                ...defaultButtonClasses,
                              )}
                            >
                              <ChevronLeftIcon className="text-white w-5" />
                            </div>
                          )}
                          {next ? (
                            <Link
                              to={`/projects/${next}`}
                              className={classNames(
                                ...primaryButtonClasses,
                                "text-sm w-8 flex flex-row justify-center"
                              )}
                            >
                              <ChevronRightIcon className="text-white w-5" />
                            </Link>
                          ) : (
                            <div
                              className={classNames(
                                ...defaultButtonClasses,
                                "text-sm w-8 !bg-gray-500 border-0 flex flex-row justify-center"
                              )}
                            >
                              <ChevronRightIcon className="text-white w-5" />
                            </div>
                          )}
                        </>
                      );
                    }}
                  </FetchSurroundingProjectIds>
                </div>
              </div>
              <div className="px-4 py-1 sm:px-6">
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue={
                      accessibleTabs?.find((tab, index) => index === selectedIndex)?.label
                    }
                    onChange={(e) => {
                      const navigateTo = accessibleTabs
                        .filter((t) => t.label === e.target.value)?.[0];
                      navigate(
                        generatePath(`${parentPath}${navigateTo.href}`, {
                          id: params.id,
                        })
                      );
                    }}
                  >
                    {accessibleTabs.map((tab) => (
                      <option key={tab.label}>{tab.label}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="">
                    <nav className="-mb-px flex xl:space-x-8 md:space-x-2" aria-label="Tabs">
                      {accessibleTabs
                        .map((tab, tabIndex) => (
                          <Link
                            key={tab.label}
                            to={generatePath(`${parentPath}${tab.href}`, {
                              id: params.id,
                            })}
                            className={classNames(
                              tabIndex === selectedIndex
                                ? "border-green-500 text-green-500"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "whitespace-nowrap py-2 px-1 border-b-2 font-semibold text-md tracking-wide md:max-w-1/6 overflow-x-clip text-ellipsis"
                            )}
                            aria-current={
                              tabIndex === selectedIndex ? "page" : undefined
                            }
                          >
                            {tab.label}
                          </Link>
                        ))}
                    </nav>
                  </div>
                </div>
              </div>
              <Card>
                <Routes>
                  {accessibleTabs.map((tab) => (
                    <Route
                      key={tab.id}
                      path={tab.href}
                      element={
                        <tab.component project={data} reload={refetch} />
                      }
                    />
                  ))}
                </Routes>
              </Card>
            </div>
          </section>
        </div>

        <Sidebar project={data} refetch={refetch} />
      </div>
    </>
  );
};
