import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { MultiFilterSelect } from "./multi-filter-select";
import { IKeyValuePair } from "../../shared/utils/entity-utils";

export interface IClientTypeSelect {
  selected: (number | null)[];
  onChange: (selected: IKeyValuePair[]) => void;
}

const CLIENT_TYPE_QUERY = gql`
  query {
    clientTypes {
      data {
        id
        attributes {
          label
        }
      }
    }
  }
`;

export const ClientTypeSelect = ({ selected, onChange }: IClientTypeSelect) => {
  const { data } = useQuery(CLIENT_TYPE_QUERY);
  const [clientTypes, setClientTypes] = useState<IKeyValuePair[]>([]);
  const [internalSelected, setInternalSelected] = useState<IKeyValuePair[]>([]);

  useEffect(() => {
    setClientTypes(
      data?.clientTypes?.data?.map((clientType: any) => {
        return { key: clientType?.id, label: clientType?.attributes?.label };
      })
    );
  }, [data]);

  useEffect(() => {
    setInternalSelected(selected
    .map((s) => clientTypes?.find((ct) => ct?.key === s?.toString()))
    .filter(
      (s: IKeyValuePair | undefined) => s !== null && s !== undefined
    ) as IKeyValuePair[]);
  }, [selected, clientTypes])

  return (
    <MultiFilterSelect
      placeholder="Auftraggeber"
      selected={internalSelected}
      onChange={onChange}
      items={clientTypes}
    />
  );
};
