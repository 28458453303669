import React, { ReactNode } from "react";

export const formatInvoiceStatus = (status: any): ReactNode => {
  return (
    <div className="w-full flex flex-col justify-around items-center">
      <div className="h-6 w-6">
        <img src={`/icons/Icon_Invoice_${status}.svg`} alt={status} />
      </div>
    </div>
  );
};
