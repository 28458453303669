import { gql } from "@apollo/client";

export type INotification = {
  id: string;
  attributes: {
    type: string;
    read: boolean;
    title: string;
    message: string;
    createdAt: string;
  }
}

// TODO: Refactor to Redux
export const NOTIFICATION_QUERY = gql`
query GetNotifications($username: String!, $limit: Int){
  notifications (pagination: {limit: $limit}, sort: "createdAt:desc", filters: { users_permissions_user: { username : {eq: $username} } }) {
    data {
      id
      attributes {
        title
        message
        type
        read
        createdAt
        project {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        pageCount
      }
    }
  }
}`;

export const NOTIFICATION_MARKREAD_QUERY = gql`
  mutation UpdateReadForNotification($notificationId: ID!) {
    updateNotification(id: $notificationId, data: { read: true }) {
      data {
        id
        attributes {
          read
          updatedAt
        }
      }
    }
  }
`;

export const NOTIFICATIONS_GET_UNREAD_QUERY = gql`query {
  unreadInfo {
        hasUnreadNotifications
    }
  }
`;

export const NOTIFICATIONS_MARKALLREAD = gql`mutation {
  markAllRead {
    hasUnreadNotifications
  }
}`;