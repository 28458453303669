import React from 'react';

export const TimelineIcon = ({className}: any) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 640 640"
            version="1.1" 
            style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin:"round", strokeMiterlimit:2 }}>
            <g transform="matrix(6.12323e-17,1,-1,6.12323e-17,576,0)">
                <path
                    d="M608,224L544,224L544,153.2C572.2,140.9 592,112.8 592,80C592,35.82 556.2,0 512,0C467.82,0 432,35.82 432,80C432,112.79 451.77,140.89 480,153.25L480,224L160,224L160,153.2C188.2,140.9 208,112.8 208,80C208,35.82 172.2,0 128,0C83.82,0 48,35.82 48,80C48,112.79 67.77,140.89 96,153.25L96,224L32,224C14.33,224 0,238.3 0,256C0,273.7 14.33,288 32,288L288,288L288,358.75C259.8,371.1 240,399.2 240,432C240,476.18 275.82,512 320,512C364.18,512 400,476.18 400,432C400,399.21 380.23,371.11 352,358.75L352,288L608,288C625.67,288 640,273.67 640,256C640,238.33 625.7,224 608,224ZM512,48C529.64,48 544,62.36 544,80C544,97.64 529.64,112 512,112C494.36,112 480,97.64 480,80C480,62.36 494.4,48 512,48ZM128,48C145.64,48 160,62.36 160,80C160,97.64 145.64,112 128,112C110.36,112 96,97.64 96,80C96,62.36 110.4,48 128,48ZM320,464C302.36,464 288,449.64 288,432C288,414.36 302.36,400 320,400C337.64,400 352,414.36 352,432C352,449.64 337.6,464 320,464Z"
                    style={{ fillRule: "nonzero", fill: "rgb(0 79 21)" }} />
            </g>
        </svg>
    )
}