import { DomEvent, LatLngExpression } from 'leaflet';
import React, { useRef, useState } from 'react';
import { Polygon, useMapEvents } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { addPoint, removePolygon, selectEditing, selectPolygon, toggleEditing } from './polygon-picker-slice';
import { RectangleStackIcon, TrashIcon } from '@heroicons/react/24/outline';
import { RootState } from '../../../../store';
import { classNames } from '../../../../shared/utils/class-names';
import { PolygonIcon } from './polygon-icon';

const colorOptions = { color: 'green' }

export const PolygonPicker = () => {
    const polygonToggleRef = useRef(null);
    const polygonActiveRef = useRef(null);
    const trashToggleRef = useRef(null);
    const dispatch = useAppDispatch();
    const polygon = useAppSelector(selectPolygon)
    const editing = useAppSelector(selectEditing);

    const map = useMapEvents({
        click(e) {
            if(editing) {
                dispatch(addPoint([e.latlng.lat, e.latlng.lng]));
            }
        },
    })

    return (
        <>
            <div ref={polygonToggleRef}
                className={classNames("cursor-events-auto cursor-pointer absolute z-[2002] left-3 w-8 h-8 p-1.5 top-24 rounded shadow-lg", editing ? "fill-white bg-green-500 hover:bg-gray-200" : "fill-green-500 bg-white hover:bg-gray-200")}
                onClick={(e) => {
                    if (polygonToggleRef?.current) {
                        DomEvent.disableClickPropagation(polygonToggleRef.current);
                    }
                    dispatch(toggleEditing());
                }}
            >
                <PolygonIcon className={classNames("w-5 h-5")} />
            </div>
            {  polygon.length > 0 &&
            <div ref={trashToggleRef}
                className="absolute z-[2002] left-3 w-8 top-36 cursor-pointer rounded bg-white hover:bg-gray-200 shadow-lg p-1"
                onClick={(e) => {
                    if (polygonToggleRef?.current) {
                        DomEvent.disableClickPropagation(polygonToggleRef.current);
                    }
                    dispatch(removePolygon());
                }}
                >
                <TrashIcon className="w-6 h-6 stroke-green-500" />
            </div> }
            { polygon.length > 0 &&
                <div ref={polygonActiveRef}
                    className={classNames("absolute z-[2002] left-14 w-full h-5 top-4")}
                >
                    <div className="w-4 h-4 float-left mr-2">
                        <PolygonIcon className={classNames("w-5 h-5 shadow-green-500 fill-green-500")} />
                    </div>
                    <span className="text-green-500">Polygon aktiv</span>
                </div>
            }

            <Polygon pathOptions={colorOptions} positions={polygon} />
        </>
    )
}