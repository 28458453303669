import React from 'react';
import { NotificationList } from '../components/layout/notifications/notification-list';

export const Notifications = () => {
  return (
    <div className=''>
      <NotificationList />
    </div>
  )
}

export default Notifications;