export enum CountryCategory {
  GERMANY = "GER",
  ABROAD = "ABR",
  UNKNOWN = "UNK"
}

export interface CountryCategoryMap {
  [key: string]: { displayString: string }
}

export const countryCategoryMap: CountryCategoryMap = {
  [CountryCategory.GERMANY]: { displayString: "Deutschland" },
  [CountryCategory.ABROAD]: { displayString: "Ausland" },
  [CountryCategory.UNKNOWN]: { displayString: "Unbekannt" },
}

export function enrichDataWithCountryCategory(data: any[]) {
  return data.map((project: any) => {
    const country = project?.building?.address?.country;
    return {
      ...project,
      countryCategory: country
        ? country === "Deutschland"
          ? CountryCategory.GERMANY
          : CountryCategory.ABROAD
        : CountryCategory.UNKNOWN
    }
  })
}
