import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQueryParams } from "use-query-params";
import {
  compareArray,
  compareArrayWithKeyValuePairArrayByKey,
  extractKey,
} from "../../../../common/compare-helpers";
import { projectTypeKeyValuePairs } from "../../../../common/project-type-map";
import { defaultButtonClasses } from "../../../../components/basics/buttons-classes";
import { MultiYearRangePicker } from "../../../../components/form-components/multi-year-range-picker";
import { GenericUserSelect } from "../../../../components/form-components/user-select/generic-user-select";
import { MultiFilterSelect } from "../../../../components/filters/multi-filter-select";
import {
  generateYears,
  getCurrentYear,
} from "../../../../components/project-list/project-utils";
import { classNames } from "../../../../shared/utils/class-names";
import { IKeyValuePair } from "../../../../shared/utils/entity-utils";
import { ClientTypeSelect } from "../../../../components/filters/client-type-select";
import { FilterSelect } from "../../../../components/filters/filter-select";
import { generateNumberArray } from "../charts/helpers";
import { MarketValueEnum, marketValueMap } from "../market-value";
import {
  CountryParamEnum,
  referenceFilterResetToDefault,
  referenceListQueryParams,
  REFERENCE_LIST_CLIENT_TYPE_FILTER,
  REFERENCE_LIST_COUNTRY_FILTER,
  REFERENCE_LIST_EDITOR_FILTER,
  REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER,
  REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER,
  REFERENCE_LIST_TYPE_FILTER,
  REFERENCE_LIST_YEARS_FILTER,
  countryParamMap,
  REFERENCE_LIST_APPRAISER_FILTER,
} from "./reference-filter-query-params";

export const ReferencesFilterBar = () => {
  const projectTypes = projectTypeKeyValuePairs;
  const [yearRange, setYearRange] = useState<[number, number] | null>(null);
  const [country, setCountry] = useState<string>(CountryParamEnum.ALL);
  const [marketValue, setMarketValue] = useState<string>(MarketValueEnum.ALL);
  const [selectedClientTypes, setSelectedClientTypes] = useState<
    (number | null)[]
  >([]);
  const [selectedProjectTypes, setSelectedProjectTypes] = useState<
    IKeyValuePair[]
  >([]);
  const [editor, setEditor] = useState<string | null>(null);
  const [appraiser, setAppraiser] = useState<string | null>(null);
  const [referenceParams, setReferenceParams] = useQueryParams(
    referenceListQueryParams
  );

  useEffect(() => {
    const yearsSorted = referenceParams[REFERENCE_LIST_YEARS_FILTER]
      .map(y => (y ? parseInt(y) : null))
      .filter(y => (y)).sort() as number[];
    const minYear = Math.min(...yearsSorted) + 2000;
    const maxYear = Math.max(...yearsSorted) + 2000;
    if (minYear !== yearRange?.[0] && maxYear !== yearRange?.[1]) {
      setYearRange([minYear, maxYear]);
    }
    if (!(referenceParams[REFERENCE_LIST_COUNTRY_FILTER] === country)) {
      setCountry(referenceParams[REFERENCE_LIST_COUNTRY_FILTER]);
    }
    if (
      !(
        referenceParams[REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER] ===
        marketValueMap[marketValue].lowerBounds &&
        referenceParams[REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER] ===
        marketValueMap[marketValue].upperBounds
      )
    ) {
      const newMarketValue = Object.keys(marketValueMap).find(
        (mv) =>
          marketValueMap[mv].lowerBounds ===
          referenceParams[REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER] &&
          marketValueMap[mv].upperBounds ===
          referenceParams[REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER]
      );
      if (newMarketValue) {
        setMarketValue(newMarketValue);
      } else {
        setMarketValue(MarketValueEnum.ALL);
      }
    }
    if (
      !compareArray(
        referenceParams[REFERENCE_LIST_CLIENT_TYPE_FILTER],
        selectedClientTypes
      )
    ) {
      setSelectedClientTypes(
        referenceParams[REFERENCE_LIST_CLIENT_TYPE_FILTER] || []
      );
    }
    if (
      !compareArrayWithKeyValuePairArrayByKey(
        referenceParams[REFERENCE_LIST_TYPE_FILTER],
        selectedProjectTypes
      )
    ) {
      setSelectedProjectTypes(
        projectTypeKeyValuePairs.filter((p) =>
          referenceParams[REFERENCE_LIST_TYPE_FILTER]?.includes(p.key)
        ) || []
      );
    }
    if (!(referenceParams[REFERENCE_LIST_EDITOR_FILTER] === editor)) {
      setEditor(referenceParams[REFERENCE_LIST_EDITOR_FILTER]);
    }
    if (!(referenceParams[REFERENCE_LIST_APPRAISER_FILTER] === appraiser)) {
      setAppraiser(referenceParams[REFERENCE_LIST_APPRAISER_FILTER]);
    }
  }, [referenceParams]);

  return (
    <div className="mb-4 invisible h-0 md:h-full md:visible flex flex-row items-center gap-1">
      <div className="w-1/2 md:w-[12%]">
        <MultiYearRangePicker
          selected={yearRange}
          onChange={(val) => {
            if (val) {
              setYearRange(val);
              setReferenceParams({
                ...referenceParams,
                [REFERENCE_LIST_YEARS_FILTER]: generateNumberArray(
                  val[0] - 2000,
                  val[1] - 2000
                ).map((v) => v.toString()),
              });
            }
          }
          }
          placeholder="Jahre"
        />
      </div>
      <div className="w-1/2 md:w-2/12">
        <FilterSelect
          placeholder="Land"
          onChange={(selected) => {
            const newSelected = selected?.key || CountryParamEnum.ALL;
            setCountry(newSelected);
            setReferenceParams({
              ...referenceParams,
              [REFERENCE_LIST_COUNTRY_FILTER]: newSelected,
            });
          }}
          selected={{
            key: country,
            label: countryParamMap[country as CountryParamEnum]?.label,
          }}
          items={Object.keys(countryParamMap).map((key) => ({
            key,
            label: countryParamMap[key as CountryParamEnum].label,
          }))}
        />
      </div>
      <div className="w-1/2 md:w-[12%]">
        <FilterSelect
          placeholder="Verkehrswert"
          onChange={(selected) => {
            const newSelected = selected?.key || MarketValueEnum.ALL;
            setMarketValue(newSelected);
            setReferenceParams({
              ...referenceParams,
              [REFERENCE_LIST_MARKETVALUE_LOWERBOUNDS_FILTER]:
                marketValueMap[newSelected].lowerBounds,
              [REFERENCE_LIST_MARKETVALUE_UPPERBOUNDS_FILTER]:
                marketValueMap[newSelected].upperBounds,
            });
          }}
          selected={{
            key: marketValue,
            label: marketValueMap[marketValue].label,
          }}
          items={Object.keys(marketValueMap).map((key) => ({
            key,
            label: marketValueMap[key].label,
          }))}
        />
      </div>
      <div className="w-1/2 md:w-2/12">
        <ClientTypeSelect
          selected={selectedClientTypes}
          onChange={(value) => {
            setSelectedClientTypes(
              value.map(extractKey).map((v) => parseInt(v))
            );
            setReferenceParams({
              ...referenceParams,
              [REFERENCE_LIST_CLIENT_TYPE_FILTER]: value
                ?.map(extractKey)
                .map((v) => parseInt(v)),
            });
          }}
        />
      </div>
      <div className="w-1/2 md:w-2/12">
        <MultiFilterSelect
          placeholder="Auftragsart"
          selected={selectedProjectTypes}
          onChange={(value) => {
            setSelectedProjectTypes(value);
            setReferenceParams({
              ...referenceParams,
              [REFERENCE_LIST_TYPE_FILTER]: value.map(extractKey),
            });
          }}
          items={projectTypes}
        />
      </div>
      <div className="w-1/2 md:w-2/12">
        <GenericUserSelect
          placeholder="Sachverständige"
          selected={editor ? parseInt(editor) : null}
          onlyEditors={true}
          exclude={editor ? [parseInt(editor)] : []}
          onChange={(value) => {
            setEditor(value?.id || null);
            setReferenceParams({
              ...referenceParams,
              [REFERENCE_LIST_EDITOR_FILTER]: value?.id || null,
            });
          }}
          className="text-sm"
        />
      </div>
      <div className="w-1/2 md:w-2/12">
        <GenericUserSelect
          placeholder="Gutachter"
          selected={appraiser ? parseInt(appraiser) : null}
          onlyAppraisers={true}
          exclude={appraiser ? [parseInt(appraiser)] : []}
          onChange={(value) => {
            setAppraiser(value?.id || null);
            setReferenceParams({
              ...referenceParams,
              [REFERENCE_LIST_APPRAISER_FILTER]: value?.id || null,
            });
          }}
          className="text-base"
        />
      </div>
      <div className="w-1/2 md:w-1/12"></div>
      <div className="w-1/2 md:w-2/12">
        <button
          className={classNames(...defaultButtonClasses)}
          onClick={() => {
            referenceFilterResetToDefault(setReferenceParams);
          }}
        >
          Filter zurücksetzen
        </button>
      </div>
    </div>
  );
};
