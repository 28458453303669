import { useQueryParam } from "use-query-params";
import { Card } from "../../../../components/basics/card"
import { h4Classes } from "../../../../components/basics/typography-classes";
import { classNames } from "../../../../shared/utils/class-names";
import { CountryParamEnum, referenceListQueryParams, REFERENCE_LIST_COUNTRY_FILTER } from "../filter/reference-filter-query-params";
import { ChartProps } from "./helpers";
import { ReferencesByCity } from "./references-by-city";
import { ReferencesByCountryBarChart } from "./references-by-country"

export interface ReferenceByCountryBarChartProps extends ChartProps {
  height?: number;
  width?: number;
}

export const ReferencesByCountryCard: React.FC<ReferenceByCountryBarChartProps> = ({ data }) => {

  const [inGermanyQueryParam] = useQueryParam(REFERENCE_LIST_COUNTRY_FILTER, referenceListQueryParams[REFERENCE_LIST_COUNTRY_FILTER])
  const onlyGermany = inGermanyQueryParam === CountryParamEnum.GERMANY;

  return (<Card className="rounded-3xl">
  <h4 className={classNames(...h4Classes, "ml-4 mb-4")}>
    { onlyGermany ? "TOP STÄDTE (NACH ANZAHL)" : "AUSLANDSOBJEKTE (NACH ANZAHL)" }
  </h4>
  <div className="h-[800px]">
    {
      onlyGermany
      ? <ReferencesByCity data={data} height={320} />
      : <ReferencesByCountryBarChart data={data} height={320} />
    }
  </div>
</Card>)
}