import React from 'react';
import { buildingTypeMap } from '../../common/building-type-map';

export const BuildingType = ({ type }: any) => {
  if(type) {
    const key: string = type;
    const Icon = buildingTypeMap[key]?.icon;
    return <div className="w-6 h-6 ml-2"><Icon /></div>
  } else {
    return <div className="w-6 h-6 ml-2"></div>;
  }
}