import { gql } from "@apollo/client";

export type INote = {
  owner: any,
  createdAt: Date,
  message: string,
  accessRestricted: boolean
}

export const NOTE_UPDATE_ACCESS_RESTRICTION = gql`
  mutation ($id: ID!, $data: NoteInput!) {
    updateNote(id: $id, data: $data) {
      data {
        id
        attributes {
          accessRestricted
        }
      }
    }
  }
`;