import { FaceSmileIcon } from "@heroicons/react/20/solid";
import { Bar, Doughnut } from "react-chartjs-2";
import { projectTypeMap } from "../../../../common/project-type-map";
import Alert from "../../../../components/layout/alert";
import { formatBigNumber } from "../../../../shared/utils/formatters";
import { DummyBarChart } from "../../employees/dummy-bar-chart";
import { prepareFlatData } from "./helpers";

interface BarChartProps {
  data: any;
  options: any;
}

export interface PreparedFlatData {
  labels: string[];
  aggregatedData: number[];
}

export enum PreparedDataType {
  COUNT,
  SUM,
}

const prepareData = ({ labels, aggregatedData }: PreparedFlatData) => ({
  labels: labels.map((type) => projectTypeMap[type]?.displayString),
  datasets: [
    {
      data: aggregatedData,
      backgroundColor: labels.map((type) => projectTypeMap[type]?.color),
    },
  ],
});

const LegendTable = ({ preparedData }: { preparedData: PreparedFlatData }) => {
  const all = preparedData.aggregatedData.reduce((acc, item) => acc + item, 0);
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      {Object.keys(projectTypeMap).map((type: string) => {
        const labelIndex = preparedData?.labels?.indexOf(type);
        const amount = preparedData.aggregatedData[labelIndex];
        const percentage = Math.floor((amount / all) * 100);
        return (amount > 0 && (
          <div
            key={type}
            className="text-gray-600 truncate flex flex-row justify-between items-center text-[8px] xl:text-xs"
          >
            <div
              className="rounded-md min-w-[16px] h-4 mr-2"
              style={{ backgroundColor: projectTypeMap[type].color }}
            >
              {" "}
            </div>
            <div className="grow">
              {projectTypeMap[type].displayString}
            </div>
            <div className="font-semibold align-self-end">
              {percentage || 0}% {amount ? `(${formatBigNumber(amount, false)}*)` : ""}
            </div>
          </div>
        ));
      })}
    </div>
  );
};

const options = {
  maintainAspectRatio: true,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const ReferencesByProjectType: React.FC<BarChartProps> = ({ data }) => {
  const preparedFlatData =
    data && data.length > 0
      ? prepareFlatData(data, (item: any) => item?.type, PreparedDataType.COUNT)
      : { labels: [], aggregatedData: [] };
  const preparedVolumeFlatData =
    data.length > 0
      ? prepareFlatData(
          data,
          (item: any) => item?.type,
          PreparedDataType.SUM,
          (item: any) => item?.building?.marketValue
        )
      : { labels: [], aggregatedData: [] };
  return preparedFlatData?.aggregatedData?.length > 0 ? (
    <>
      <div className="h-48 xl:h-64 print:h-64 mt-4 flex flex-row justify-around">
        <div className="w-48 xl:w-60 print:w-60 mx-2">
          <Doughnut
            data={prepareData(preparedFlatData)}
            options={options}
          />
          <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">
          NACH <br/>ANZAHL*
          </div>
        </div>
        <div className="w-48 xl:w-60 print:w-60 mx-2">
          <Doughnut
            data={prepareData(preparedVolumeFlatData)}
            options={options}
          />
          <div className="text-center -mt-[7.5rem] xl:-mt-36 print:-mt-36 font-special font-semibold">
            NACH <br/>VOLUMEN
          </div>
        </div>
      </div>
      <div className="h-40 mt-8 px-3 xl:px-5">
        <LegendTable preparedData={preparedFlatData} />
      </div>
    </>
  ) : (
    <Alert title="Keine Daten vorhanden" message="Anderen Filter wählen" severity="info" />
  );
};
