import axios from "axios";
import { getLoginUrl } from "../utils/url-utils";

interface IResetPassword {
  code: string;
  password: string;
  passwordConfirmation: string;
}

export const resetPasswordRequest = (resetPw: IResetPassword) => {
  return axios.post<any>(`${getLoginUrl()}/api/auth/reset-password`, resetPw);
};

export const resetPassword = async (
  code: string,
  password: string,
  passwordConfirmation: string
) => {
  try {
    await resetPasswordRequest({
      code,
      password,
      passwordConfirmation,
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

interface IForgotPassword {
  email: string;
}

export const forgotPasswordRequest = (forgotPw: IForgotPassword) => {
  return axios.post<any>(`${getLoginUrl()}/api/auth/forgot-password`, forgotPw);
};

export const forgotPassword = async (email: string) => {
  try {
    await forgotPasswordRequest({ email });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
