import React, { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Input } from '../../components/form-components/input';
import { classNames } from '../../shared/utils/class-names';
import { defaultButtonClasses, primaryButtonClasses } from '../../components/basics/buttons-classes';
import { Link, useNavigate } from 'react-router-dom';
import { RoleUpdateField } from '../../components/magic-update/role-update-field';
import { ValidationMessage } from '../../components/layout/validation';
import { useCreateUserMutation } from '../../model/users/user-api';
import { generatePassword } from './helpers';
import { PortraitUpload } from './portrait-upload';
import './styles.css';
import { toast } from 'react-toastify';
import Alert from '../../components/layout/alert';

export const UserCreateForm = () => {
    const navigate = useNavigate();
    const [imageLink, setImageLink] = useState<string | null>(null);
    const [createUser, { error }] = useCreateUserMutation();
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm();

    const doSubmit = async (data: FieldValues) => {
        const password = generatePassword(32);
        try {
            const result = await createUser({ ...data, password, repeatPassword: password, image: imageLink }).unwrap();
            if (result?.error) {
                toast.error("Es ist ein Fehler aufgetreten.")
            } else {
                toast.info("Nutzer erfolgreich angelegt.")
                navigate('/users');
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (<form onSubmit={handleSubmit(doSubmit)}>
        {error && <Alert title="Es ist ein Fehler beim Anlegen des Nutzers aufgetreten" message={(error as any)?.data?.error?.message} />}
        <div className="sm:items-start sm:pt-5">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-start">
                    <Input
                        name="username"
                        placeholder="Vor- und Nachname *"
                        register={register}
                        validateRequired={{
                            required: "Bitte Vor- und Nachname getrennt mit Leerzeichen eingeben",
                        }}
                        className={`w-full outline-0 ${errors.username ? "border-red-800 focus:ring-red-800 placeholder-red-800" : "focus:ring-green-500"
                            }`}
                    />
                </div>
                {errors.username?.type === "required" && (
                    <ValidationMessage message={errors.username?.message as string} />
                )}
            </div>
        </div>
        <div className="sm:items-start sm:pt-5">
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-start">
                    <Input
                        name="email"
                        placeholder="E-Mail *"
                        register={register}
                        validateRequired={{
                            required: "Bitte E-Mail eingeben",
                        }}
                        className={`w-full outline-0 ${errors.email ? "focus:ring-red-800 border-red-800 placeholder-red-800" : "focus:ring-green-500"
                            }`}
                    />
                </div>
                {errors.email?.type === "required" && (
                    <ValidationMessage message={errors.email?.message as string} />
                )}
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg">
                <Controller
                    control={control}
                    name="role"
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                        formState,
                    }) => {
                        return (
                            <RoleUpdateField
                                label="Rolle"
                                value={value}
                                onUpdate={onChange}
                                error={!!error}
                                className={classNames(
                                    error ? "border-red-800 placeholder-red-800" : "",
                                    "bg-gray-100"
                                )}
                            />
                        );
                    }}
                />
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg">
                <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            aria-describedby="isEditor-description"
                            type="checkbox"
                            className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                            {...register("isEditor")}
                        />
                    </div>
                    <div className="ml-3 text">
                        <label htmlFor="isEditor" className="text-gray-700">
                            Darf Aufträge bearbeiten?
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg">
                <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                        <input
                            aria-describedby="isAppraiser-description"
                            type="checkbox"
                            className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                            {...register("isAppraiser")}
                        />
                    </div>
                    <div className="ml-3 text">
                        <label htmlFor="isAppraiser" className="text-gray-700">
                            Gutachter?
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="sm:items-start sm:pt-5 flex flex-row gap-4">
            <div className="mt-1 sm:mt-0 sm:col-span-2 grow text-lg w-full">
                <div className="flex items-center justify-center">
                    <PortraitUpload onChange={(newImageId: string) => (setImageLink(newImageId))} />
                </div>
            </div>
        </div>
        <div className="flex justify-between mt-6">
            <Link to="/users" className={classNames(...defaultButtonClasses, "w-40 text-center")} {...register} > Abbrechen </Link>
            <button className={classNames(...primaryButtonClasses, "w-40 text-center")} {...register} > Speichern </button>
        </div>
    </form>)
}