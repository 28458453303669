import { gql, useMutation } from "@apollo/client";
import { PencilIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { ReactNode, useEffect, useState } from "react";
import { classNames } from "../../shared/utils/class-names";
import { StringUpdateField } from "./string-update-field";
import { DateUpdateField } from "./date-update-field";
import {
  dateFormatter,
  dateTimeFormatter,
} from "../../shared/utils/date-utils";
import {
  buildingSubTypeMap,
  buildingTypeMap,
} from "../../common/building-type-map";
import { BuildingTypeUpdateField } from "./building-type-field";
import { ProjectTypeUpdateField } from "./project-type-field";
import { projectTypeMap } from "../../common/project-type-map";
import { SquareMeterUpdateField } from "./square-meter-update-field";
import { NumberUpdateField } from "./number-update-field";
import { BooleanUpdateField } from "./boolean-update-field";
import { TextareaUpdateField } from "./textarea-update-field";
import { toast } from "react-toastify";
import { DateTimeUpdateField } from "./datetime-update-field";
import { Spinner } from "../../common/spinner/base-spinner";
import { DoubleDigitYearUpdateField } from "./double-digit-year-update-field";
import { CustomerUpdateField } from "./customer-update-field";
import { ContractorUpdateField } from "./contractor-update-field";
import { ClientTypeUpdateField } from "./client-type-update-field";
import { BuildingSubTypeUpdateField } from "./building-subtype-field";
import { CountryUpdateField } from "./country-update-field";
import { BooleanButtonUpdateField } from "./boolean-button-update-field";
import { BooleanSwitchUpdateField } from "./boolean-switch-update-field";
import { GenericEnumUpdateField } from "./generic-enum-field";

export interface IMagicUpdateProps {
  model: string;
  id: string;
  field: string;
  label?: string;
  labelGap?: string;
  value: any;
  type:
    | "String"
    | "DoubleDigitYear"
    | "Textarea"
    | "Number"
    | "Boolean"
    | "BooleanButton"
    | "BooleanSwitch"
    | "Date"
    | "DateTime"
    | "ENUM_BUILDING_TYPE"
    | "ENUM_BUILDING_SUBTYPE"
    | "ENUM_CONTRACTOR"
    | "ENUM_PROJECT_TYPE"
    | "GENERIC_ENUM"
    | "CUSTOMER"
    | "CLIENT_TYPE"
    | "COUNTRY"
    | "SquareMeter"
    | "Percentage";
  gqlType?: "Int" | "Float" | "String" | "ID" | "Boolean" | "ENUM_PROJECT_CONTRACTOR";
  formatter?: (val: any) => string | ReactNode;
  validation?: [{ fn: (val: any) => boolean; msg: string }];
  stepBase?: number;
  reload: Function;
  extra?: any;
  className?: string;
}

const inputComponents = {
  String: StringUpdateField,
  DoubleDigitYear: DoubleDigitYearUpdateField,
  Textarea: TextareaUpdateField,
  Boolean: BooleanUpdateField,
  BooleanButton: BooleanButtonUpdateField,
  BooleanSwitch: BooleanButtonUpdateField,
  Number: NumberUpdateField,
  Percentage: NumberUpdateField,
  SquareMeter: SquareMeterUpdateField,
  Date: DateUpdateField,
  DateTime: DateTimeUpdateField,
  ENUM_BUILDING_TYPE: BuildingTypeUpdateField,
  ENUM_BUILDING_SUBTYPE: BuildingSubTypeUpdateField,
  ENUM_PROJECT_TYPE: ProjectTypeUpdateField,
  ENUM_CONTRACTOR: ContractorUpdateField,
  CLIENT_TYPE: ClientTypeUpdateField,
  CUSTOMER: CustomerUpdateField,
  COUNTRY: CountryUpdateField,
  GENERIC_ENUM: GenericEnumUpdateField
};

export const MagicUpdate = ({
  model,
  id,
  field,
  label,
  value,
  type,
  gqlType,
  reload,
  labelGap,
  stepBase,
  formatter,
  validation,
  extra,
  className,
}: IMagicUpdateProps) => {
  const [showInput, setShowInput] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newFieldValue, setNewFieldValue] = useState(value);

  const updateMutation = gql`
    mutation UpdateFieldInModel($value: ${gqlType || type}) {
      update${model
        .trim()
        .replace(/^\w/, (c: string) =>
          c.toUpperCase()
        )} (id: ${id}, data: { ${field}: $value }) {
        data {
          id
        }
      }
    }
  `;

  useEffect(() => {
    setNewFieldValue(value);
  }, [value]);

  const [updateField, { loading }] = useMutation(updateMutation);

  const onUpdate = async (newFieldValue: any) => {
    if (newFieldValue !== value) {
      try {
        if (
          !validation ||
          validation.every((v) => {
            const result = v.fn(newFieldValue);
            if(!result) {
              toast.error(v.msg);
            }
            return result;
          })
        ) {
          await updateField({ variables: { value: newFieldValue } });
          reload();
          toast.info("Änderung gespeichert");
          setShowInput(false);
        }
      } catch (e) {
        toast.error("Fehler beim Speichern");
      }
    }
  };

  const RenderInputField = inputComponents[type];

  return (
    // Boolean fields are a direct change
    type === "Boolean" ? (
      <div className="flex flex-col group cursor-pointer min-h-[4rem]">
        <div className="text-sm uppercase text-gray-500 flex flex-row group mb-3">
          {label}
        </div>
        <BooleanUpdateField
          label={label}
          onUpdate={onUpdate}
          loading={loading}
          value={value}
        />
      </div>
    ) : type === "BooleanButton" ? (
      <div className="flex flex-col group cursor-pointer min-h-[4rem]">
        <div className="text-sm uppercase text-gray-500 flex flex-row group mb-3">
          {label}
        </div>
        <BooleanButtonUpdateField
          label={label}
          onUpdate={onUpdate}
          loading={loading}
          value={value}
          extra={extra}
          className={className}
        />
      </div>
    ) : type === "BooleanSwitch" ? (
        <BooleanSwitchUpdateField
          label={label}
          onUpdate={onUpdate}
          loading={loading}
          value={value}
          extra={extra}
          className={className}
        />
    ) : type === "Textarea" ? (
      <div className="">
        <TextareaUpdateField
          label={label}
          onUpdate={onUpdate}
          loading={loading}
          value={value || ""}
        />
      </div>
    ) : (
      <div
        className="flex flex-col group cursor-pointer min-h-[4rem]"
        onClick={(e) => {
          setShowInput(true);
        }}
      >
        <div
          className="text-sm uppercase text-gray-500 flex flex-row group items-center"
          onClick={(e) => {
            if (showInput) {
              setShowInput(false);
            }
            e.stopPropagation();
          }}
        >
          {label}
          {loading ? (
            <Spinner className="w-3 stroke-gray-500 h-3 mx-2" />
          ) : (
            <XMarkIcon
              className={classNames(
                showInput ? "group-hover:opacity-100" : "",
                "opacity-0 w-3 ml-1"
              )}
            />
          )}
        </div>
        {showInput ? (
          <div className="mt-1 flex rounded-md text-sm">
            <RenderInputField
              label={label}
              onUpdate={onUpdate}
              value={value}
              stepBase={stepBase}
              loading={loading}
              extra={extra}
              className={className}
            />
          </div>
        ) : (
          <div
            className={classNames(
              labelGap || "mt-3",
              "text-sm text-black flex flex-row mb-1"
            )}
          >
            {formatter
              ? formatter(value)
              : type === "Date"
              ? dateFormatter(value)
              : type === "DateTime"
              ? dateTimeFormatter(value)
              : type === "ENUM_BUILDING_TYPE"
              ? buildingTypeMap[value]?.displayString
              : type === "ENUM_BUILDING_SUBTYPE"
              ? buildingSubTypeMap[value]?.displayString
              : type === "ENUM_PROJECT_TYPE"
              ? projectTypeMap[value].displayString
              : type === "SquareMeter"
              ? `${value || "-"} qm`
              : type === "Percentage"
              ? `${value || "-"} %`
              : type === "GENERIC_ENUM"
              ? extra.displayName
              : <div className="truncate">{value}</div> || "-"}
            <PencilIcon className="w-4 h-4 ml-3 text-gray-500 opacity-0 group-hover:opacity-100" />
          </div>
        )}
      </div>
    )
  );
};
