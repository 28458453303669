import { ListBulletIcon } from "@heroicons/react/24/outline";
import { ChartProps, PreparedDataType, prepareFlatData } from "./helpers";
import { Tile } from "./tile";

export const TileOverallCount: React.FC<ChartProps> = ({ data }) => {
  const preparedFlatData = data && data.length > 0 ? prepareFlatData(
    data,
    (item: any) => ("overallVolume"),
    PreparedDataType.COUNT,
  ) : { labels: [], aggregatedData: [] };

  return (
    <Tile
      name="Bewertungen"
      number={preparedFlatData?.aggregatedData?.[0]}
      icon={<ListBulletIcon />}
      className="bg-green-500"
    />
  );
};
