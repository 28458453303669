import QueryString from 'qs';

export interface Pagination {
  page: number,
  pageSize: number,
  pageCount: number,
  total: number
}

export interface Meta {
  pagination: Pagination
}

export interface StrapiRestResponse<T> {
  data: T
  meta: Meta
}

export interface IStrapiQueryStringObject {
  pagination?:  object | null | undefined,
  populate?: object | string[] | null | undefined,
  sort?: object | null | undefined,
  filters?: any | null | undefined,
}

export interface FindOneQuery {
  id: number,
  queryParams: IStrapiQueryStringObject
}

export interface FindManyQuery {
  queryParams: IStrapiQueryStringObject
}

export function encodeQueryString({ pagination, populate, sort, filters }: IStrapiQueryStringObject) {
  return QueryString.stringify(
  {
    pagination,
    populate,
    sort,
    filters,
  }, { encodeValuesOnly: true });
}