import React from "react";
import { FilterSelect } from "./filter-select";

export interface IHideHistorySelect {
  onChange: (selected: boolean | null) => void;
  value: boolean | null;
  placeholder?: string;
}

export const HideHistorySelect = ({ onChange, placeholder, value }: IHideHistorySelect) => {

  return <FilterSelect
  placeholder={placeholder}
  selected={value === null ? null : value ? { key: "KeineHistorie", label: "Historische Aufträge ausblenden" }: { key: "Alle", label: "Alle Aufträge anzeigen" } }
  onChange={(val) => {
    switch(val?.key) {
      case 'Alle': onChange(false); break;
      case 'KeineHistorie': onChange(true); break;
      default: onChange(null)
  }}}
  items={[
    { key: 'Alle', label: 'Alle Aufträge anzeigen' },
    { key: 'KeineHistorie', label: 'Historische Aufträge ausblenden' }
  ]}
  />;
};
