import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { classNames } from '../../../shared/utils/class-names';
import { IKeyValuePair } from '../../../shared/utils/entity-utils';

export default function Typeahead( { items, onChange, value, placeholder, className, error }: any ) {
  const [query, setQuery] = useState('')

  const filteredItems =
    query === ''
      ? items
      : items.filter((item: IKeyValuePair) => {
          return item.label.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox as="div" value={value} onChange={onChange}>
      <div className={classNames(className, "relative mt-1")}>
        <Combobox.Input
          className={classNames("cursor-default relative w-full rounded-xl border border-gray-300 bg-gray-100 pl-3 pr-10 py-2 text-left transition ease-in-out duration-150 sm:leading-5 focus:ring-0", className, !error ? 'focus:border-green-500' : 'focus:outline-none')}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: IKeyValuePair) => item?.label}
          placeholder={placeholder || ''}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.map((item: IKeyValuePair) => (
              <Combobox.Option
                key={item.key}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-green-500 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>{item.label}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-green-500'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}