import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import { classNames } from "../../shared/utils/class-names";
import { defaultInputClasses } from "../basics/input-classes";

registerLocale('de', de);

export const InputDate = ({
  label,
  value,
  onUpdate,
  className
}: any ) => {
  const [newFieldValue, setNewFieldValue] = useState(value);

  useEffect(() => {
    setNewFieldValue(value);
  }, [value]);

  return (
    <DatePicker
      selected={newFieldValue ? dayjs(newFieldValue).toDate() : null }
      dateFormat="dd.MM.yyyy"
      onChange={(date: Date) => {
        if(date === null) {
          onUpdate(null);
        } else {
          onUpdate(dayjs(date).format("YYYY-MM-DD"));
        }
      }}
      locale="de"
      className={classNames(...defaultInputClasses, className)}
      calendarClassName="font-special border-gray-300 shadow"
      dayClassName={(date: Date) => (dayjs(date).isSame(dayjs(value), 'day') ? 'bg-green-500': '')}
    />
  );
};
