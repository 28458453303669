import {UserIcon} from '@heroicons/react/24/outline';
import React from 'react';
import { classNames } from '../../../shared/utils/class-names';
import {backendImageUrl} from '../../../shared/utils/url-utils';
import {AuthImg} from "../auth-img";

export const ProfilePicture = ({profilePicturePath, className = "h-8 w-8" }: any) => {
    return <div
        className={classNames(className, "max-w-xs bg-white text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500")}>
        {profilePicturePath ? <AuthImg
                className={classNames(className, "rounded-full")}
                src={backendImageUrl(profilePicturePath)}
                size={"thumbnail"} placeholder={"/avatar.svg"} /> :
            <div className={classNames(className, ' p-1.5 rounded-full bg-gray-300 text-white')}><UserIcon/></div>}

    </div>;
}