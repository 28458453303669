import { CSSProperties } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Tick,
} from "chart.js";
import dayjs from "dayjs";
import { AnalyticsEmployeeMonthResult, Employee } from "../../../model/analytics/employees/analytics-employees";
import { renderLineChartTooltip } from "./line-chart/chart-tooltip";
import { formatCurrency } from "../../../shared/utils/formatters";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index' as const,
  },
  scales: {
    xAxis: {
      ticks: {
        font: {
          family: "Roboto"
        }
      }
    },
    yAxis: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any, index: number, ticks: Tick[]) {
          return formatCurrency(value);
        },
        font: {
          family: "Roboto"
        }
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          family: "Roboto"
        }
      }
    },
    tooltip: {
      enabled: false,
      external: renderLineChartTooltip
    }
  },
};

const colorMap = [
  ["#004f14", "#669573", "#ccddd0"],
  ["#f6ba45", "#fad58f", "#fdf1db"],
];

export interface IEmployeeChartLinesProps {
  data: AnalyticsEmployeeMonthResult,
  selectedEmployees: (Employee|undefined)[],
  years: number[],
  styles: CSSProperties
}

export const EmployeeChartLines = ({
  data: fetchedData,
  selectedEmployees: employees,
  years,
  styles
}: IEmployeeChartLinesProps) => {

  const labels = [...Array(12).keys()].map((i) =>
    dayjs().set("month", i).format("MMM")
  );

  const data = {
    labels,
    datasets: employees.flatMap((e, index) => {
      return years.flatMap((y, jndex) => {
        return {
          label: `${y}/${e?.attributes.abbreviation}`,
          data: [...Array(12).keys()].map((i) => {
            return (
              fetchedData?.employeesPerMonth?.find(
                (employee) =>
                  employee.username === e?.attributes.username &&
                  employee.year === y &&
                  employee.month === i
              )?.sum || 0
            );
          }),
          borderColor: colorMap[index][2 - (jndex % 3)],
          backgroundColor: colorMap[index][2 - (jndex % 3)],
          tension: 0.4,
          pointRadius: 1,
        };
      });
    }),
  };

  return <Line options={options} data={data} style={{ ...styles, height: "400px" }}/>
};
